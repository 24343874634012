import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { RowTitle, RowValue, RowWrapper, ViewerInfoRow } from "./ViewersStatisticBody.jsx";

const RowDivider = styled.div`
	width: 100%;
	height: 1px;
	background: #e2e8f0;
`;

export const VerifiedWrapper = styled.div`
	position: relative;
	display: inline-block;
	margin-left: 20px;
	width: 24px;
	height: 24px;

	&.disabled {
		svg {
			path {
				fill: #d1d8e1;
			}
		}
	}
	.help-tip {
		background: #fff;
		border-radius: 8px;
		bottom: calc(100% + 12px);
		display: none;
		filter: drop-shadow(8px 8px 28px rgba(46, 69, 102, 0.2));
		left: 20px;
		padding: 16px;
		pointer-events: none;
		position: absolute;
		transform: translateX(calc(-100% + 12px));
		white-space: nowrap;
		span {
			font-size: 13px !important;
			line-height: 20px !important;
			overflow: visible !important;
			position: relative !important;
			text-overflow: unset !important;
			width: auto !important;
		}
		img {
			left: calc(100% - 53px);
			position: absolute;
			top: 100%;
		}
	}
	&:hover {
		.help-tip {
			display: flex;
		}
	}
`;

export const ViewersContacts = ({ externalViewerState, viewerState: elem }) => {
	return (
		<>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Контактные данные</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "24px" }}>
					<ViewerInfoRow title="Имя" value={externalViewerState?.first_name || "Не указано"} />
					<RowDivider />
					<ViewerInfoRow title="Фамилия" value={externalViewerState?.last_name || "Не указано"} />
					<RowDivider />
					<ViewerInfoRow title="Страна" value={elem?.country || "Не указано"} />
					<RowDivider />
					<ViewerInfoRow title="Город" value={elem?.city || "Не указано"} />
					<RowDivider />
					<ViewerInfoRow title="Возраст" value={elem?.age || "Не указано"} />
					<RowDivider />
					<ViewerInfoRow title="Пол" value={elem?.sex || "Не указано"} />
				</div>
			</StatisticBlockContainer>
			{/* <StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">
						Аккаунт{" "}
						<VerifiedWrapper className={!externalViewerState?.is_verified && "disabled"}>
							<div className="help-tip">
								<span>{externalViewerState?.is_verified ? "Подтвержден" : "Не подтвержден"}</span>
								<img src="images/icons/ui/arrow.svg" alt="" />
							</div>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.7071 10.7071L17.4142 10L16 8.58579L15.2929 9.29289L11 13.5858L8.70711 11.2929L8 10.5858L6.58579 12L7.29289 12.7071L10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071L16.7071 10.7071Z"
									fill="#0DC268"
								/>
							</svg>
						</VerifiedWrapper>
					</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "24px" }}>
					<RowWrapper>
						<RowTitle style={{ userSelect: "text" }}>Email</RowTitle>
						<RowValue style={{ userSelect: "text" }}>
							{externalViewerState?.email || "Не указано"}
						</RowValue>
					</RowWrapper>
				</div>
			</StatisticBlockContainer> */}
		</>
	);
};
