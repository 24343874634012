import React, { useEffect } from "react";

export const PlaylistBookmarks = ({ activeBookMark, setActiveBookMark }) => {
	const styles = 'width: "auto"';
	return (
		<div className="bookmark-wrapper">
			<div className="video-edit-bookmarks bookmarks">
				<button
					className={`video-edit-bookmark bookmark ${activeBookMark === 0 ? "active" : ""}`}
					onClick={() => setActiveBookMark(0)}
				>
					Фильмы и сериалы
				</button>
				{/* <button
					className={`video-edit-bookmark bookmark ${activeBookMark === 1 ? "active" : ""}`}
					onClick={() => setActiveBookMark(1)}
				>
					Видео блогеров
				</button> */}
			</div>
		</div>
	);
};
