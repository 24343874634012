import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleToggleOpenClick } from "../../../scripts/PersonalAreaScripts";

const initialClickStatusState = {
	is_show: false,
	is_click: true,
};

const SideClickStatusFilters = ({ sideFilters, setSideFilters }) => {
	const [mapClickStateState, setMapClickStateState] = useState(initialClickStatusState);

	const handleSelectParamClick = (id) => {
		if (!mapClickStateState.hasOwnProperty(id)) return;

		setMapClickStateState({ ...mapClickStateState, [id]: !mapClickStateState[id] });
	};

	useEffect(() => {
		// обнуление
		if (sideFilters.is_click === null || sideFilters.is_show === null) {
			setMapClickStateState(initialClickStatusState);
		}
	}, [sideFilters, initialClickStatusState]);

	useEffect(() => {
		if (mapClickStateState.is_show && mapClickStateState.is_click) {
			// делается так, потому у нас так работает бек...
			setSideFilters({ ...sideFilters, is_click: false, is_show: false });
		} else {
			setSideFilters({ ...sideFilters, ...mapClickStateState });
		}
	}, [mapClickStateState]);

	return (
		<div className="statistics-filter-items-block">
			<div
				className="statistics-filter-items-block-title"
				onClick={(e) => handleToggleOpenClick(e)}
			>
				Статус
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="statistics-filter-items-block-subitems">
				<div className="flex-block flex-start">
					<div className="flex-block-column">
						<div className="form-field" onClick={() => handleSelectParamClick("is_show")}>
							<div
								className={`check-button-item ${mapClickStateState["is_show"] ? "selected" : ""}`}
								id="show-stat"
							>
								<span>Показы</span>
							</div>
						</div>
						<div className="form-field" onClick={() => handleSelectParamClick("is_click")}>
							<div
								className={`check-button-item ${mapClickStateState["is_click"] ? "selected" : ""}`}
								id="click-stat"
							>
								<span>Клики</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 		genresList: state.videoGenresList,
// 	};
// };

// const mapDispatchToProps = {
// getCategories,
// };

export default connect(null, null)(SideClickStatusFilters);
