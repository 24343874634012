import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import "../styles/BloggerStyles.scss";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { CancelButton, EmptyFallback } from "./Buttons.jsx";
import ExtertnalPaginationBlock from "../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import BanBloggerModal from "./modal/BanBloggerModal.jsx";
import { getAllActiveExternalBlogger } from "../../../../Actions/BloggersActions.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { useLazyQuery } from "@apollo/client";
import {
	GET_MAIN_BLOGGER_PROFIT,
	GET_TIPS_BLOGGER_SHOW,
} from "../../../../GraphQL/BloggerStatistic.js";
import {
	getTotalSubcriptions,
	getTotalViewAmount,
} from "../BloggerStatistic/meaningful-cards/get-totals.js";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip.jsx";

const ActiveBloggerPage = ({
	getAllActiveExternalBlogger,
	sellersList,
	mainFilterState,
	setMainFilterState,
}) => {
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { handleModal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const [sellersState, setSellersState] = useState([]);

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getAllActiveExternalBlogger({ ...filteredState });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	useEffect(() => {
		setSellersState(sellersList.list);
	}, [sellersList]);

	const handleCancelClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<BanBloggerModal id={id} filteredState={mainFilterState} />);
	};

	return (
		<div className="active-seller-page">
			{sellersState.length > 0 ? (
				<>
					<div className="list-table-header seller-list">
						<div
							className="list-table-header-element"
							style={{ width: "240%" }}
							onClick={() =>
								handleFilteredClick("sort", "name", mainFilterState, setMainFilterState)
							}
						>
							<span>Имя</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "166%" }}>
							<span className="non-sort">Категории</span>
						</div>
						{/* <div className="list-table-header-element" style={{ width: "136%" }}>
							<span className="non-sort">Рейтинг</span>
						</div> */}
						<div className="list-table-header-element non-sort" style={{ width: "214%" }}>
							<span className="non-sort">Конверсия</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "195%" }}
							onClick={() =>
								handleFilteredClick(
									"sort",
									"subscription_count",
									mainFilterState,
									setMainFilterState
								)
							}
						>
							<span>Подписчики</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "174%" }}
							onClick={() =>
								handleFilteredClick("sort", "views_amount", mainFilterState, setMainFilterState)
							}
						>
							<span>Просмотры</span>
						</div>
						{checkUserWritePerm(userAccessState, "bloggers") ? (
							<div
								className="list-table-header-element"
								style={{ width: "115px", minWidth: "115px" }}
							></div>
						) : null}
					</div>
					<div className="list-table stat-list selers-list">
						{sellersState.map((elem, index) => (
							<SellerListItem handleCancelClick={handleCancelClick} elem={elem} key={index} />
						))}
					</div>
				</>
			) : (
				<EmptyFallback>Пока нет блогеров</EmptyFallback>
			)}
			{Object.keys(sellersList.pagination).length !== 0 &&
			!!sellersList.pagination.max_pages &&
			sellersState.length > 0 &&
			sellersList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={sellersList.pagination}
				/>
			) : null}
		</div>
	);
};
const SellerListItem = ({ elem, handleCancelClick }) => {
	const dispatch = useDispatch();
	const { userAccessState } = useUserAccessContext();

	const [totalSubs, setTotalSubs] = useState(0);
	const [viewAmount, setViewAmount] = useState(0);
	const [getStatistic, { data: revenue, loading, error }] = useLazyQuery(GET_MAIN_BLOGGER_PROFIT, {
		variables: {
			blogger_id: null,
		},
	});
	const [getTipsShow, { data: tipsShow, loading: tipsShowLoading }] = useLazyQuery(
		GET_TIPS_BLOGGER_SHOW,
		{
			variables: {
				blogger_id: null,
			},
		}
	);
	useEffect(() => {
		getStatistic({
			variables: { blogger_id: elem?.serial_id },
		});
		getTipsShow({
			variables: { blogger_id: elem?.serial_id },
		});
		getTotalSubcriptions(
			{
				user_id: elem.id,
				group_by: "month",
				date_start: new Date("2023-01-01").toISOString(),
				date_end: new Date().toISOString(),
			},
			dispatch,
			(e) => setTotalSubs(e)
		);
		getTotalViewAmount(
			{
				content_creator_id: elem.id,
			},
			dispatch,
			(e) => setViewAmount(e)
		);
	}, [elem]);

	return (
		<Link to={`/bloggers-statistic?id=${elem?.id}`} className="list-table-item">
			<div className="list-table-item-element">
				{elem.avatar_url && elem.avatar_url?.length > 0 ? (
					<img src={elem.avatar_url} alt="profile" />
				) : (
					<img src="images/profile-frame.png" alt="profile" />
				)}
				<div className="list-table-item-element-info">
					<span>{elem.name}</span> <small>ID {elem?.serial_id}</small>
				</div>
			</div>
			<div className="list-table-item-element" style={{ width: "166%" }}>
				<span>
					{elem.categories?.length
						? elem.categories?.map((elem) => elem?.title)?.join(", ")
						: "Не указано"}
				</span>
			</div>
			{/* <div className="list-table-item-element" style={{ width: "136%" }}>
				<span>0</span>
			</div> */}

			<div className="list-table-item-element" style={{ width: "214%" }}>
				<span>
					{tipsShow?.getStatisticPage?.pageInfo?.itemsCount > 0
						? Math.round(
								(revenue?.getStatisticPage?.pageInfo?.itemsCount * 100) /
									tipsShow?.getStatisticPage?.pageInfo?.itemsCount
						  )
						: 0}
					%
				</span>
			</div>
			<div className="list-table-item-element" style={{ width: "195%" }}>
				<span>{Number(totalSubs).toLocaleString("ru-RU")}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "174%", marginRight: "0px" }}>
				<span>
					{Number(viewAmount).toLocaleString("ru-RU")} (
					{Number(elem.views_amount).toLocaleString("ru-RU")})
				</span>
				<Tooltip
					text={`Всего: ${viewAmount.toLocaleString("ru-RU")} \n Уникальных: ${
						elem.views_amount ? elem.views_amount.toLocaleString("ru-RU") : 0
					}`}
				/>
			</div>
			{checkUserWritePerm(userAccessState, "bloggers") ? (
				<div
					className="list-table-item-element"
					style={{ paddingLeft: "0", width: "115px", minWidth: "115px", marginRight: "16px" }}
				>
					<CancelButton onClick={(e) => handleCancelClick(e, elem.id)}>Отключить</CancelButton>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		sellersList: state.allExternalBloggersReducer.active,
	};
};

const mapDispatchToProps = {
	getAllActiveExternalBlogger,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveBloggerPage);
