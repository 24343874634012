import React, { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
	fetchVideoGadgetsAmountStatistic,
	fetchVideoGadgetsViewByPeriod,
	fetchVideoGadgetsWatchTimeStatistic,
	fetchVideoGadgetsAverageTimeStatistic,
} from "./fetchVideoGadgetsViewByPeriod";
import GadgetsStatisticComponent from "../../../../../common/GadgetsStatistic/GadgetsStatisticComponent";
import { initialMonthData } from "./scripts";

const initialDevicesCollection = [
	{
		name: "mobile",
		device_type: 0,
	},
	{
		name: "desktop",
		device_type: 1,
	},
	{
		name: "tv",
		device_type: 2,
	},
	{
		name: "tablet",
		device_type: 3,
	},
];

const ViewerGadgetsStatistic = ({
	videoViewGadgetGraph,
	viewerState,
	filtersState,
	videoViewGadgetStatistic,
}) => {
	const dispatch = useDispatch();
	const [dataState, setDataState] = useState(initialMonthData());
	const [statisticState, setStatisticState] = useState();
	useEffect(() => {
		if (
			!viewerState.id ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;
		dispatch({ type: "RESET_GADGET_STATISTIC" });
		fetchVideoGadgetsViewByPeriod({
			vars: {
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				group_by: filtersState?.group_by || "day",
				viewer_id: viewerState.external_id,
			},
			dispatch,
			period: filtersState.period_name,
		});
		fetchVideoGadgetsAmountStatistic({
			vars: {
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				group_by: filtersState?.group_by || "day",
				viewer_id: viewerState.external_id,
			},
			dispatch,
			period: filtersState.period_name,
		});
		initialDevicesCollection.forEach((elem) => {
			fetchVideoGadgetsAmountStatistic({
				vars: {
					date_start: new Date(filtersState.from_datetime).toISOString(),
					date_end: new Date(filtersState.to_datetime).toISOString(),
					device_type: elem.device_type,
					viewer_id: viewerState.external_id,
				},
				dispatch,
				period: filtersState.period_name,
				device_type: elem.name,
				key: "amount",
			});
			fetchVideoGadgetsWatchTimeStatistic({
				vars: {
					date_start: new Date(filtersState.from_datetime).toISOString(),
					date_end: new Date(filtersState.to_datetime).toISOString(),
					device_type: elem.device_type,
					viewer_id: viewerState.external_id,
				},
				dispatch,
				period: filtersState.period_name,
				device_type: elem.name,
				key: "total_watch_time",
			});
			fetchVideoGadgetsAverageTimeStatistic({
				vars: {
					date_start: new Date(filtersState.from_datetime).toISOString(),
					date_end: new Date(filtersState.to_datetime).toISOString(),
					device_type: elem.device_type,
					viewer_id: viewerState.external_id,
				},
				dispatch,
				period: filtersState.period_name,
				device_type: elem.name,
				key: "average_session_time",
			});
		});
	}, [filtersState, viewerState]);

	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;
		setDataState(videoViewGadgetGraph[filtersState.period_name]);
	}, [videoViewGadgetGraph, filtersState, viewerState]);

	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;

		setStatisticState(videoViewGadgetStatistic[filtersState.period_name]);
	}, [videoViewGadgetStatistic, , filtersState, viewerState]);

	return (
		<GadgetsStatisticComponent
			lines={dataState}
			period={filtersState?.period_name}
			statisticState={statisticState}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		videoViewGadgetGraph: state.videoViewGadgetGraph,
		videoViewGadgetStatistic: state.videoViewGadgetStatistic,
	};
};

const mapDispatchToProps = {
	// getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerGadgetsStatistic);
