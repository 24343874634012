import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../../Actions/consts";

const formatGraphDate = (date, type) => {
	if (type === "year" || type === "full") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
				day: "numeric",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "month") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
				day: "numeric",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "week") {
		return new Date(date)
			.toLocaleString("ru", {
				month: "short",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "day") {
		return new Date(date)
			.toLocaleString("ru", {
				month: "short",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})
			.split(" ", 3)
			.join(" ");
	}
};

const calcBehindStartDate = (start_d, type) => {
	const actualDate = new Date(start_d);
	switch (type) {
		case "day":
			const day = actualDate.getDate();
			return new Date(actualDate.setDate(day - 1)).toISOString();
		case "week":
			const day_week = actualDate.getDate();
			return new Date(actualDate.setDate(day_week - 7)).toISOString();
		case "month":
			const month = actualDate.getMonth();
			return new Date(actualDate.setMonth(month - 1)).toISOString();
		case "year":
			const year = actualDate.getFullYear();
			return new Date(actualDate.setFullYear(year - 1)).toISOString();

		default:
			return new Date(start_d).toISOString();
	}
};

export const fetchVideoViewByPeriod = async ({ vars, dispatch, period, format }) => {
	const { date_end, date_start } = vars;
	let newStartDate = calcBehindStartDate(date_start, period);

	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-sessions-by-period?${queryString}`,
			data: "",
		})
		.then(async (views) => {
			const actualData = views.data.map((elem) => {
				return {
					...elem,
					date: formatGraphDate(elem.date, period),
					type: "actual",
				};
			});
			if (period === "full") {
				dispatch({
					type: "GET_VIDEOS_BLOGGER_VIEWS_GRAPH",
					views: actualData,
					period,
					format,
					kind: "actual",
				});
				return;
			}
			dispatch({
				type: "SHOW_MAIN_LOADER",
			});
			await axios
				.post(`${baseUrl}/domain/other`, {
					method: "GET",
					service: 1,
					endpoint: `/api/video/watch-sessions-by-period?${queryString}&date_start=${new Date(
						newStartDate
					).getTime()}&date_end=${new Date(vars.date_start).getTime()}`,
					data: "",
				})
				.then((lasts) => {
					const data = [];
					lasts.data.forEach((elem, index) => {
						if (actualData[index]) {
							data.push({
								...actualData[index],
								last: elem.amount,
								lastDate: formatGraphDate(elem.date, period),
							});
						}
					});
					dispatch({
						type: "GET_VIDEOS_BLOGGER_VIEWS_GRAPH",
						views: data,
						period,
						format,
						kind: "last",
					});
				})
				.finally(() => {
					dispatch({
						type: "HIDE_MAIN_LOADER",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
