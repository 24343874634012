import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StatisticBlockContainer } from "../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { formatDate } from "../../VideosPage/sctipts.js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../../Actions/consts.js";

const RowDivider = styled.div`
	width: 100%;
	height: 1px;
	background: #e2e8f0;
`;

export const ReportInfo = ({ report }) => {
	const dispatch = useDispatch();
	const [viewerState, setViewerState] = useState(null);

	useEffect(() => {
		if (report?.ticket_type === 3) {
			dispatch({
				type: "SHOW_MAIN_LOADER",
			});
			axios
				.get(`${baseUrl}/domain/moviegoers/${report?.comment_author}`)
				.then((viewer) => {
					setViewerState(viewer.data);
				})
				.finally(() => {
					dispatch({
						type: "HIDE_MAIN_LOADER",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [report]);

	return (
		<>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Информация о тикете</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<ReportInfoRow title="Номер тикета" value={report?.id || "Не указано"} />
					<RowDivider />
					<ReportInfoRow
						title="Тема"
						value={
							report.ticket_themes?.length
								? report.ticket_themes.map((theme, index) =>
										index >= report.ticket_themes.length - 1 ? theme.title : `${theme.title}, `
								  )
								: "Не указано"
						}
					/>
					<RowDivider />
					<ReportInfoRow
						title="Блогер"
						value={report?.creator || "Не указано"}
						onClick={() =>
							window.open(`/bloggers-statistic?id=${report?.creator_id}`, "_blank", "noreferrer")
						}
					/>
					<RowDivider />
					{report?.ticket_type > 1 ? (
						<>
							<ReportInfoRow
								title="Название видео"
								value={report?.movie || "Не указано"}
								onClick={() =>
									window.open(`/blogger-video?id=${report?.movie_id}`, "_blank", "noreferrer")
								}
							/>
							<RowDivider />
							<ReportInfoRow title="Формат" value={report?.movie_format || "Не указано"} />
							<RowDivider />
						</>
					) : null}
					{report?.ticket_type === 3 ? (
						<>
							{viewerState ? (
								<ReportInfoRow
									onClick={() =>
										window.open(`/viewers-statistic?id=${viewerState.id}`, "_blank", "noreferrer")
									}
									title="Автор комментария"
									value={`${viewerState?.first_name} ${viewerState?.second_name}`}
								/>
							) : (
								<ReportInfoRow
									title="Автор комментария"
									value={report?.comment_author || "Не указано"}
								/>
							)}

							<RowDivider />
						</>
					) : null}
					<ReportInfoRow
						title="Зритель"
						value={report?.moviegoer || "Не указано"}
						onClick={() =>
							window.open(`/viewers-statistic?id=${report?.moviegoer_id}`, "_blank", "noreferrer")
						}
					/>
					<RowDivider />
					<ReportInfoRow
						title="Дата и время получения жалобы"
						value={formatDate(report.created_at, 3)}
					/>
					<RowDivider />
					<ReportInfoRow
						title="Дата и время закрытия жалобы"
						value={
							report?.updated_at && report.creator_status > 1
								? formatDate(report?.updated_at, 3)
								: "Не закрыта"
						}
					/>
					<RowDivider />
					<ReportInfoRow title="Комментарий" value={report?.comment_text || "Не указано"} />
				</div>
			</StatisticBlockContainer>
		</>
	);
};

export const RowWrapper = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;
	padding: 14px 0;
	align-items: center;
	&.clickable {
		cursor: pointer;
		&:hover {
			background: #f4f4f8;
		}
	}
`;

export const RowTitle = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #94a0b1;
	width: 100%;
`;

export const RowValue = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #212121;
	width: 100%;
`;

export const ReportInfoRow = ({ title, value, onClick }) => {
	return (
		<RowWrapper onClick={onClick} className={onClick ? "clickable" : ""}>
			<RowTitle style={{ userSelect: "text" }}>{title}</RowTitle>
			<RowValue style={{ userSelect: "text" }}>{value}</RowValue>
		</RowWrapper>
	);
};
