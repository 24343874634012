import axios from "axios";
import { removeEmpty } from "../ActionsScirpts";
import { baseUrl } from "../consts";
import { getVideo } from "./getMovieVideo";

export const putAdditionalVideos = (movie_id, additionalDatas, video_id, movie_title) => {
	if (additionalDatas?.movie) {
		delete additionalDatas.movie;
	}
	if (additionalDatas?.id) {
		delete additionalDatas.id;
	}
	return async (dispatch) => {
		await axios
			.put(`${baseUrl}/video/${video_id}`, { ...additionalDatas, title: movie_title })
			.then((video) => {
				dispatch(getVideo(movie_id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putAdditionalSerial = (movie_id, additionalDatas, video_id, movie_title) => {
	if (additionalDatas?.movie) {
		delete additionalDatas.movie;
	}
	if (additionalDatas?.id) {
		delete additionalDatas.id;
	}
	return async (dispatch) => {
		await axios
			.put(`${baseUrl}/serial/${video_id}`, { ...additionalDatas, title: movie_title })
			.then((video) => {
				dispatch(getVideo(movie_id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putCurrentVideo = (state, id, additionalData, additionalId) => {
	const filteredState = state;
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		dispatch({
			type: "HIDE_SAVE_INFO",
		});
		await axios
			.put(`${baseUrl}/domain/movies/${id}`, { ...filteredState, id: id })
			.then((video) => {
				if (video.data.movie_format_id === 1) {
					dispatch(
						putAdditionalVideos(
							id,
							state?.URL_SD_HORIZONTAL_PREVIEW
								? { ...additionalData, URL_SD_PREVIEW: state?.URL_SD_HORIZONTAL_PREVIEW }
								: additionalData,
							additionalId,
							video.data.title
						)
					);
				} else {
					dispatch(putAdditionalSerial(id, additionalData, additionalId, video.data.title));
				}
			})
			.finally(() => {
				dispatch({
					type: "SHOW_SAVE_INFO",
				});
			})
			// .then(() => {
			// 	dispatch({
			// 		type: "HIDE_MAIN_LOADER",
			// 	});
			// })
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putVideo = (state, id, additionalData, additionalId) => {
	const filteredState = removeEmpty(state);
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		dispatch({
			type: "HIDE_SAVE_INFO",
		});
		await axios
			.put(`${baseUrl}/domain/movies/${id}`, { ...filteredState, id: id })
			.then((video) => {
				dispatch(putAdditionalVideos(id, additionalData, additionalId, video.data.title));
			})
			.finally(() => {
				dispatch({
					type: "SHOW_SAVE_INFO",
				});
				// dispatch({
				// 	type: "HIDE_MAIN_LOADER",
				// });
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putCurrentMovie = (state, id) => {
	const filteredState = removeEmpty(state);
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		dispatch({
			type: "HIDE_SAVE_INFO",
		});
		await axios
			.put(`${baseUrl}/movie/${id}`, state)
			.then((video) => {
				dispatch({
					type: "PUT_VIDEO_LIST",
					video: { ...video.data, id: id },
				});
			})
			.finally(() => {
				dispatch({
					type: "SHOW_SAVE_INFO",
				});
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
