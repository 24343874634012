import * as React from "react";
import { sortableHandle } from "react-sortable-hoc";

/**
 * Draggable parent component
 */
export const ParentDragHandle = sortableHandle(() => (
	<div className="form-container-block-season-move ui-sortable-handle">
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 3.5C7 4.32843 7.67157 5 8.5 5C9.32843 5 10 4.32843 10 3.5C10 2.67157 9.32843 2 8.5 2C7.67157 2 7 2.67157 7 3.5ZM14 3.5C14 4.32843 14.6716 5 15.5 5C16.3284 5 17 4.32843 17 3.5C17 2.67157 16.3284 2 15.5 2C14.6716 2 14 2.67157 14 3.5ZM15.5 13C14.6716 13 14 12.3284 14 11.5C14 10.6716 14.6716 10 15.5 10C16.3284 10 17 10.6716 17 11.5C17 12.3284 16.3284 13 15.5 13ZM7 11.5C7 12.3284 7.67157 13 8.5 13C9.32843 13 10 12.3284 10 11.5C10 10.6716 9.32843 10 8.5 10C7.67157 10 7 10.6716 7 11.5ZM15.5 21C14.6716 21 14 20.3284 14 19.5C14 18.6716 14.6716 18 15.5 18C16.3284 18 17 18.6716 17 19.5C17 20.3284 16.3284 21 15.5 21ZM7 19.5C7 20.3284 7.67157 21 8.5 21C9.32843 21 10 20.3284 10 19.5C10 18.6716 9.32843 18 8.5 18C7.67157 18 7 18.6716 7 19.5Z"
				fill="#94A0B1"
			></path>
		</svg>
	</div>
));

/**
 * Draggable child component
 */
export const ChildDragHandle = sortableHandle(() => (
	<div className="form-container-block-season-episode-move ui-sortable-handle">
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 3.5C7 4.32843 7.67157 5 8.5 5C9.32843 5 10 4.32843 10 3.5C10 2.67157 9.32843 2 8.5 2C7.67157 2 7 2.67157 7 3.5ZM14 3.5C14 4.32843 14.6716 5 15.5 5C16.3284 5 17 4.32843 17 3.5C17 2.67157 16.3284 2 15.5 2C14.6716 2 14 2.67157 14 3.5ZM15.5 13C14.6716 13 14 12.3284 14 11.5C14 10.6716 14.6716 10 15.5 10C16.3284 10 17 10.6716 17 11.5C17 12.3284 16.3284 13 15.5 13ZM7 11.5C7 12.3284 7.67157 13 8.5 13C9.32843 13 10 12.3284 10 11.5C10 10.6716 9.32843 10 8.5 10C7.67157 10 7 10.6716 7 11.5ZM15.5 21C14.6716 21 14 20.3284 14 19.5C14 18.6716 14.6716 18 15.5 18C16.3284 18 17 18.6716 17 19.5C17 20.3284 16.3284 21 15.5 21ZM7 19.5C7 20.3284 7.67157 21 8.5 21C9.32843 21 10 20.3284 10 19.5C10 18.6716 9.32843 18 8.5 18C7.67157 18 7 18.6716 7 19.5Z"
				fill="#94A0B1"
			></path>
		</svg>
	</div>
));
