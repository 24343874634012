const changeCityArray = (arr) => {
	const firstArray = [];
	const newArray = arr.sort((a, b) => a.title.localeCompare(b.title));
	const res = newArray.filter((elem, index) => {
		if (elem.id === 377 || elem.id === 368) {
			firstArray.push(elem);
			return false;
		}
		return true;
	});
	return [...firstArray, ...res];
};

const changeCountryArray = (arr) => {
	const firstArray = [];
	const newArray = arr.sort((a, b) => a.title.localeCompare(b.title));
	const res = newArray.filter((elem, index) => {
		if (elem.id === 288) {
			firstArray.push(elem);
			return false;
		}
		return true;
	});
	return [...firstArray, ...res];
};

export const CountriesReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_COUNTRIES":
			return changeCountryArray(action.countries.data.attributes);
		default:
			return state;
	}
};
