import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { DText14 } from "../../../../ui-kit/typography/Typography";
import { DynamicSelect } from "../../../../ui-kit/select/DynamicSelect";
import { Status } from "../../../../ui-kit/status/Status";
import { putCreatorReport, putCurrentReport } from "../../../../Actions/ReportsActions";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import styled from "styled-components";

export const initialStatusesCollection = [
	{
		id: 1,
		title: "Не решена",
		color: "#ED0A34",
	},
	{
		id: 2,
		title: "На проверку",
		color: "#FF9E00",
	},
	{
		id: 3,
		title: "Решена",
		color: "#0DC268",
	},
];

const initialUpStatusesCollection = [
	{
		id: 1,
		title: "Не решена",
		color: "#ED0A34",
		creator_status: 1,
	},
	{
		id: 2,
		title: "На проверку",
		color: "#FF9E00",
	},
	{
		id: 3,
		title: "Решена",
		color: "#0DC268",
	},
];

const initialBloggerStatusesCollection = [
	{
		id: 1,
		title: "Не решена",
		color: "#ED0A34",
	},

	{
		id: 2,
		title: "Решена",
		color: "#0DC268",
	},
];

const SelectedItem = styled(Cluster)`
	width: 100%;
	position: relative;
	border-radius: 8px;
	background: #fff;
	z-index: 1;
	padding: 16px 16px;
	&.disabled {
		cursor: default;
		background: #d1d8e1;
		color: #94a0b1;
	}
`;

const StatusSelect = ({ ticket, putCurrentReport, disabled = false, putCreatorReport }) => {
	const [statusState, setStatusState] = useState(initialStatusesCollection[0]);
	const [bloggerStatusState, setBloggerStatusState] = useState(initialStatusesCollection[0]);

	useEffect(() => {
		if (!ticket.id) return;

		setStatusState(
			initialStatusesCollection.find((elem) => elem.id === ticket.status) ||
				initialStatusesCollection[0]
		);

		if (ticket.creator_status === 2) {
			setBloggerStatusState(initialBloggerStatusesCollection.find((elem) => elem.id === 2));
		} else {
			setBloggerStatusState(initialBloggerStatusesCollection[0]);
		}
	}, [ticket]);

	const handleSelectStatusClick = (elem) => {
		if (ticket.status === elem.id) return;
		if (elem.id === 1) {
			putCreatorReport(ticket.id, { status: elem.id, creator_status: 1 });
		} else if (elem.id === 3) {
			putCurrentReport(ticket.id, {
				status: elem.id,
				creator_status: 2,
			});
		} else
			putCurrentReport(ticket.id, {
				status: elem.id,
				creator_status: elem?.creator_status,
			});
	};

	console.log(ticket);
	return (
		<Cluster space="16px">
			<Stack space="5px" style={{ zIndex: 10 }}>
				<DText14 style={{ color: "#94A0B1" }}>Статус</DText14>
				<DynamicSelect
					collection={
						ticket?.creator_status >= 2 ? initialUpStatusesCollection : initialStatusesCollection
					}
					disabled={disabled}
					option={StatusesOption}
					selected={statusState}
					onSelect={handleSelectStatusClick}
				/>
			</Stack>
			<Stack space="5px" style={{ zIndex: 10, width: "220px" }}>
				<DText14 style={{ color: "#94A0B1" }}>Статус блогера</DText14>
				<SelectedItem
					className={`dynamic-select-selected-item`}
					onClick={() => !disabled && setOpen(true)}>
					<StatusesOption {...bloggerStatusState} />
				</SelectedItem>
			</Stack>
		</Cluster>
	);
};

const mapDispatchToProps = {
	putCurrentReport,
	putCreatorReport,
};

export default connect(null, mapDispatchToProps)(StatusSelect);

const StatusesOption = ({ title, color }) => {
	return <Status text={title} color={color} />;
};
