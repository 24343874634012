import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getVideoGenres, getVideos } from "../../../Actions/videos-actions/VideosActions";
import { getSellers } from "../../../Actions/SellersActions";
import Select from "react-select";
import { handleMultiSelectActiveClick, handleSelectFilters } from "../scripts/PersonalAreaScripts";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { customStyles } from "../static";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getVideoFormats } from "../../../Actions/FormatsActions";
import styled from "styled-components";
import { getCategories } from "../../../Actions/CategoriesActions";
import { CategoryModal } from "../../../common/category-modal/CategoryModal.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import { handleStatisticSelectFilters } from "./scripts";
import DropdownSellerSearch from "../BrightboxesPage/dropdownVideoSearch/DropdownSellerSearch.jsx";

const CategorySelect = styled.div`
	background: #f4f4f8;
	border: 1px solid #f4f4f8;
	border-radius: 8px;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	font-size: 15px;
	height: auto;
	min-height: 54px;
	outline: none;
	padding: 11px 80px 8px 16px;
	position: relative;
	width: 100% !important;
	display: flex;
	cursor: pointer;
	align-items: center;
	svg {
		position: absolute;
		right: 12px;
		top: calc(50% - 12px);
	}
	&:hover {
		border: 1px solid #d1d8e1;
	}
	span.category-value {
		display: block;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 60px);
		font-size: 15px;
		line-height: 54px;
	}
`;

const initialDropDownState = {
	areaId: false,
	calendar: false,
	genres: false,
};

const Backdrop = styled.div`
	position: fixed;
	left: 0;
	top: 0%;
	height: 100vh;
	width: 100vw;
	z-index: 1;
`;

const StatisticFiltersComponent = ({
	videosList,
	videoFormatsList,
	sellersList,
	getVideoFormats,
	getSellers,
	filterState,
	setFilterState,
	videoGenresList,
	getVideoGenres,
	categoriesList,
	getCategories,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleModal } = useContext(ModalContext);
	const [videoFormatsState, setVideoFormatsState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.format_id
			? JSON.parse(searchParams.get("filters")).format_ids
			: "",
		list: [],
	});

	const [sellersState, setSellersState] = useState({
		currentValue: "",
		list: [],
	});

	const [videoGenresState, setVideoGenresState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.genres
			? JSON.parse(searchParams.get("filters")).genres
			: [],
		list: [],
	});
	const [categoriesState, setCategoriesState] = useState({
		currentValue: "",
		list: [],
	});
	const calendarRef = useRef(null);

	const [dropDownState, setDropdownState] = useState(initialDropDownState);
	const [startDate, setStartDate] = useState(
		filterState?.filters?.date_from ? new Date(filterState?.filters?.from_datetime * 1000) : null
	);
	const [endDate, setEndDate] = useState(
		filterState?.filters?.date_to ? new Date(filterState?.filters?.to_datetime * 1000) : null
	);

	const onChange = (dates, e) => {
		e.stopPropagation();
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		if (end) {
			const startTimeStamp = new Date(start);
			const endTimeStamp = new Date(end);
			endTimeStamp.setHours(23);
			endTimeStamp.setMinutes(59);
			setFilterState({
				...filterState,
				page: 1,
				filters: {
					...filterState.filters,
					from_datetime: new Date(startTimeStamp).toISOString(),
					to_datetime: new Date(endTimeStamp).toISOString(),
				},
			});
			setDropdownState(initialDropDownState);
		}
	};

	useEffect(() => {
		getCategories();
		getSellers();
		if (searchParams.get("selected_sellers") && searchParams.get("sellerName")) {
			getVideoGenres();
		}
		getVideoFormats();
	}, []);

	useEffect(() => {
		setCategoriesState({
			...categoriesState,
			list: categoriesList,
			currentValue: {
				id: filterState.filters?.categories_ids,
				title: filterState.filters?.category_title,
			},
		});
	}, [categoriesList]);

	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			list: videoGenresList,
			currentValue: !!filterState.filters?.genres ? filterState.filters.genres : [],
		});
	}, [videoGenresList]);

	useEffect(() => {
		setSellersState({
			...sellersState,
			list: sellersList.list,
			currentValue:
				!!filterState.filters?.selected_sellers && sellersList.list.length
					? sellersList.list.find((elem) => elem.id === filterState.filters.selected_sellers)
					: "",
		});
	}, [sellersList]);

	useEffect(() => {
		setVideoFormatsState({
			...videoFormatsState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.format_id
				? JSON.parse(searchParams.get("filters")).format_id
				: [],
			list: videoFormatsList,
		});
	}, [videoFormatsList]);

	useEffect(() => {
		setSellersState({
			...sellersState,
			list: sellersList.list,
			currentValue:
				!!filterState.filters?.selected_sellers && sellersList.list.length
					? sellersList.list.find((elem) => elem.id === filterState.filters.selected_sellers)
					: "",
		});
		setVideoGenresState({
			...videoGenresState,
			currentValue: !!filterState.filters?.genres ? filterState.filters.genres : [],
		});
		setStartDate(
			filterState?.filters?.from_datetime ? new Date(filterState?.filters?.from_datetime) : null
		);
		setEndDate(
			filterState?.filters?.to_datetime ? new Date(filterState?.filters?.to_datetime) : null
		);
	}, [filterState]);

	const handleToggleCalendar = (event) => {
		event.stopPropagation();
		if (!!calendarRef && event.target === calendarRef.current)
			setDropdownState({ ...dropDownState, calendar: !dropDownState.calendar });
	};

	const removeAllDate = (e) => {
		e.stopPropagation();
		const timeObject = { ...filterState, page: 1 };
		timeObject.filters.from_datetime && delete timeObject.filters.from_datetime;
		timeObject.filters.to_datetime && delete timeObject.filters.to_datetime;
		setFilterState(timeObject);
	};
	return (
		<div className="list-filter flex-block" style={{ marginBottom: "38px" }}>
			<div className="form-field">
				<div className="form-field-label">Категория товаров</div>
				<div className="input-field">
					<CategorySelect
						onClick={() =>
							handleModal(
								<CategoryModal
									categories={categoriesState.list}
									handleCategoryClick={(newValue) => (
										setCategoriesState({ ...categoriesState, currentValue: newValue }),
										setFilterState({
											...filterState,
											filters: {
												...filterState.filters,
												categories_ids: newValue.id,
												category_title: newValue.title,
											},
										})
									)}
								/>
							)
						}
					>
						<span className="category-value">{categoriesState.currentValue?.title || "Все"}</span>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
								fill="#212121"
							></path>
						</svg>
					</CategorySelect>
					<button
						className={`clear-chosen-container ${
							!!categoriesState.currentValue?.title > 0 ? "active" : ""
						}`}
						onClick={() => (
							setCategoriesState({ ...categoriesState, currentValue: "" }),
							setFilterState({
								...filterState,
								filters: { ...filterState.filters, categories_ids: "", category_title: "" },
							})
						)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
								fill="#212121"
							></path>
						</svg>
					</button>
				</div>
			</div>
			{!searchParams.get("selected_sellers") || !searchParams.get("sellerName") ? (
				<div className="form-field">
					<div className="form-field-label">Продавец</div>
					<DropdownSellerSearch
						selected={sellersState.currentValue?.title}
						onClear={() => (
							setSellersState({ ...sellersState, currentValue: "" }),
							setFilterState({
								...filterState,
								filters: { ...filterState.filters, selected_sellers: "" },
							})
						)}
						onSelect={(newValue) => (
							setSellersState({ ...sellersState, currentValue: newValue }),
							setTipSettingsState({ ...tipSettingsState, seller_id: newValue.id })
						)}
					/>
					{/* <div className="input-field">
						<Select
							name="seller"
							value={sellersState.currentValue}
							options={sellersState.list}
							styles={customStyles}
							onChange={(newValue) => (
								setSellersState({ ...sellersState, currentValue: newValue }),
								setFilterState({
									...filterState,
									filters: { ...filterState.filters, selected_sellers: newValue.id },
								})
							)}
							getOptionLabel={(option) => option.title}
							getOptionValue={(option) => option.id}
							placeholder="Все"
							isSearchable={true}
							className="search-wrapper"
							classNamePrefix="select"
							noOptionsMessage={({ inputValue }) => (!inputValue ? "Загрузка" : "Нет значений")}
						/>
						<button
							className={`clear-chosen-container ${
								!!sellersState.currentValue?.title > 0 ? "active" : ""
							}`}
							onClick={() => (
								setSellersState({ ...sellersState, currentValue: "" }),
								setFilterState({
									...filterState,
									filters: { ...filterState.filters, selected_sellers: "" },
								})
							)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
									fill="#212121"
								></path>
							</svg>
						</button>
					</div> */}
				</div>
			) : null}
			<div className="form-field">
				<div className="form-field-label">Формат</div>
				<div className="input-field">
					<Select
						name="format"
						value={videoFormatsState.currentValue}
						options={videoFormatsState.list}
						styles={customStyles}
						onChange={(newValue) => (
							setVideoFormatsState({ ...videoFormatsState, currentValue: newValue }),
							setFilterState({
								...filterState,
								filters: { ...filterState.filters, format_id: newValue.id },
							})
						)}
						getOptionLabel={(option) => option.title}
						getOptionValue={(option) => option.id}
						placeholder="Все"
						isSearchable={true}
						className="search-wrapper"
						classNamePrefix="select"
						noOptionsMessage={({ inputValue }) => (!inputValue ? "Загрузка" : "Нет значений")}
					/>
					<button
						className={`clear-chosen-container ${
							!!videoFormatsState.currentValue?.title > 0 ? "active" : ""
						}`}
						onClick={() => (
							setVideoFormatsState({ ...videoFormatsState, currentValue: "" }),
							setFilterState({
								...filterState,
								filters: { ...filterState.filters, format_id: "" },
							})
						)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
								fill="#212121"
							></path>
						</svg>
					</button>
					{/* <div
						className={`select-block multiselect ${dropDownState.format_id ? "active" : ""}`}
						onFocus={() => null}
						onClick={() =>
							setDropdownState({ ...dropDownState, format_id: !dropDownState.format_id })
						}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="1"
					>
						<div className="select-block-button">
							<span>
								{videoFormatsState?.currentValue?.length > 0 &&
								videoFormatsState.currentValue.length !== videoFormatsState.list.length
									? videoFormatsState.currentValue.map((elem, index) =>
											index !== videoFormatsState.currentValue.length - 1
												? `${videoFormatsState.list.find((item) => item.id == elem)?.title}, `
												: `${videoFormatsState.list.find((item) => item.id == elem)?.title}`
									  )
									: "Все"}
							</span>
						</div>
						<div className="select-block-list">
							<div className="select-block-list-scroll">
								{videoFormatsState.list.length > 0
									? videoFormatsState.list.map((format) => (
											<div
												key={format.id}
												className={`select-block-item ${
													videoFormatsState?.currentValue?.includes(format.id) ? "selected" : ""
												}`}
												onClick={(e) =>
													handleMultiSelectActiveClick(
														e,
														videoFormatsState,
														setVideoFormatsState,
														format
													)
												}
											>
												<span>{format?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className="select-block-control">
								<div
									className="select-block-cancel button-select"
									onClick={(event) =>
										handleStatisticSelectFilters(
											event,
											"format_id",
											[],
											videoFormatsState,
											setVideoFormatsState,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}
								>
									Очистить
								</div>
								<div
									className="select-block-select button-select"
									onClick={(event) =>
										handleStatisticSelectFilters(
											event,
											"format_id",
											videoFormatsState.currentValue,
											null,
											null,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}
								>
									Применить
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			{searchParams.get("sellerId") && searchParams.get("sellerName") ? (
				<div className="form-field">
					<div className="form-field-label">Жанр</div>
					<div className="input-field">
						<div
							className={`select-block multiselect ${dropDownState.genres ? "active" : ""}`}
							onFocus={() => null}
							onClick={() => setDropdownState({ ...dropDownState, genres: !dropDownState.genres })}
							onBlur={() => setDropdownState(initialDropDownState)}
							tabIndex="2"
						>
							<div className="select-block-button">
								<span>
									{videoGenresState.currentValue.length > 0 &&
									videoGenresState.currentValue.length !== videoGenresState.list.length
										? videoGenresState.currentValue.map((elem, index) =>
												index !== videoGenresState.currentValue.length - 1
													? `${videoGenresState.list.find((item) => item.id == elem)?.title}, `
													: `${videoGenresState.list.find((item) => item.id == elem)?.title}`
										  )
										: "Все"}
								</span>
							</div>
							<div className="select-block-list">
								<div className="select-block-list-scroll">
									{videoGenresState.list.length > 0
										? videoGenresState.list.map((genres) => (
												<div
													key={genres.id}
													className={`select-block-item ${
														videoGenresState.currentValue.includes(genres.id) ? "selected" : ""
													}`}
													onClick={(e) =>
														handleMultiSelectActiveClick(
															e,
															videoGenresState,
															setVideoGenresState,
															genres
														)
													}
												>
													<span>{genres?.title}</span>
												</div>
										  ))
										: null}
								</div>
								<div className="select-block-control">
									<div
										className="select-block-cancel button-select"
										onClick={(event) =>
											handleStatisticSelectFilters(
												event,
												"genres",
												[],
												videoGenresState,
												setVideoGenresState,
												dropDownState,
												setDropdownState,
												filterState,
												setFilterState
											)
										}
									>
										Очистить
									</div>
									<div
										className="select-block-select button-select"
										onClick={(event) =>
											handleStatisticSelectFilters(
												event,
												"genres",
												videoGenresState.currentValue,
												null,
												null,
												dropDownState,
												setDropdownState,
												filterState,
												setFilterState
											)
										}
									>
										Применить
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<div className="form-field">
				<div className="form-field-label">Дата</div>

				<div className={`input-field date-range-field `}>
					<div
						id="statistics-range-visible"
						className={`statistics-range-visible ${dropDownState.calendar ? "active" : ""}`}
						onClick={(e) => handleToggleCalendar(e)}
						// onBlur={handleOnBlur}
						// onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="0"
					>
						<div className="calendar-icon" ref={calendarRef}></div>
						<input
							readOnly
							placeholder="Выберите даты"
							value={
								startDate > 0
									? `${startDate > 0 ? new Date(startDate).toLocaleDateString() : ""}${
											!!endDate > 0 ? ` - ${new Date(endDate).toLocaleDateString()}` : ""
									  } `
									: ""
							}
						/>
						<div
							className={`statistics-range-calendar-clear ${!!startDate ? "active" : ""}`}
							onClick={(e) => removeAllDate(e)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.70701 7.2928L7.99991 6.58569L6.58569 7.99991L7.2928 8.70701L10.5857 11.9999L7.2928 15.2928L6.58569 15.9999L7.99991 17.4141L8.70701 16.707L11.9999 13.4141L15.2928 16.707L15.9999 17.4141L17.4141 15.9999L16.707 15.2928L13.4141 11.9999L16.707 8.70701L17.4141 7.99991L15.9999 6.58569L15.2928 7.2928L11.9999 10.5857L8.70701 7.2928Z"
									fill="#212121"
								></path>
							</svg>
						</div>
						<Backdrop
							className="backdrop"
							onClick={() => setDropdownState({ ...dropDownState, calendar: false })}
						/>
						<div className={`date-picker-wrapper `}>
							<DatePicker
								onChange={onChange}
								startDate={startDate}
								endDate={endDate}
								// onBlur={handleOnBlur}
								dateFormat="dd.MM.yyyy"
								selectsRange
								locale={ru}
								inline
							/>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="form-field"></div> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videoFormatsList: state.videoFormatsList,
		sellersList: state.sellersList,
		videoGenresList: state.videoGenresList,
		categoriesList: state.categoriesList,
	};
};

const mapDispatchToProps = {
	getVideoFormats,
	getSellers,
	getCategories,
	getVideoGenres,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticFiltersComponent);
