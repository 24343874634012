import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { H5Text } from "../../../../../ui-kit/typography/Typography";
import { Input } from "../../../../../ui-kit/input/Input";
import { Button } from "../../../../../ui-kit/button/Button";
import { initialUser } from "../initial";
import { checkInn } from "../scripts";
import axios from "axios";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";

const StyledCluster = styled(Cluster)`
	button {
		height: 56px;
		white-space: nowrap;
	}
`;

const FirstAddOwnerStep = ({ userState = initialUser, setUserState, setActive }) => {
	const [errorState, setErrorState] = useState({});

	const handleInputChange = (e, key, secondKey) => {
		e.preventDefault();
		const value = e.target.value;
		if (key === "ogrn") {
			setUserState({
				...userState,
				ogrn: value,
				ogrnip: value,
			});
		} else if (secondKey) {
			setUserState({
				...userState,
				[key]: { ...userState[key], [secondKey]: value },
			});
		} else {
			setUserState({
				...userState,
				[key]: value,
			});
		}
	};

	const handleFillByInnClick = () => {
		if (checkInn(userState?.inn)) {
			setErrorState({ inn: false });
			var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
			var token = "48dcdf37f3b52e1e0d38ae7d207132c1aa40eb69";

			axios
				.post(
					url,
					{ query: userState?.inn },
					{
						params: { query: userState?.inn },

						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: "Token " + token,
						},
					}
				)
				.then((res) => {
					const result = res.data.suggestions[0].data;
					if (result.opf.short == "ИП") {
						setUserState({
							...userState,
							legal_name: result.name.full_with_opf,
							ogrn: result.ogrn,
							ogrnip: result.ogrn,
							legal_address: result.address.unrestricted_value,
							actual_address: result.address.unrestricted_value,
							ceo_name: result.name.full,
						});
					} else {
						setUserState({
							...userState,
							legal_name: result.name.full_with_opf,
							ogrn: result.ogrn,
							ogrnip: result.ogrn,
							legal_address: result.address.unrestricted_value,
							actual_address: result.address.unrestricted_value,
							ceo_name: result.management.name,
						});
					}
				});
		} else setErrorState({ inn: true });
	};

	return (
		<Stack space="32px">
			<Stack space="16px">
				<H5Text>Контактные данные</H5Text>
				<Stack space="24px">
					<Input
						label="Название канала*"
						type="text"
						value={userState?.name}
						error={"inn" in errorState && errorState.name}
						onChange={(e) => handleInputChange(e, "name")}
					/>
					<StyledCluster space="16px" align="flex-end">
						<Input
							label="ИНН"
							type="number"
							value={userState?.inn}
							error={"inn" in errorState && errorState.inn}
							onChange={(e) => handleInputChange(e, "inn")}
						/>
						<Button
							variant="secondary"
							text="Загрузить из ИНН"
							onClick={() => handleFillByInnClick()}
						/>
					</StyledCluster>
					<Input
						label="ОГРН или ОГРНИП"
						type="number"
						value={userState?.ogrn}
						onChange={(e) => handleInputChange(e, "ogrn")}
					/>
					<Input
						label="Название организации"
						type="text"
						value={userState?.legal_name}
						onChange={(e) => handleInputChange(e, "legal_name")}
					/>
					<Input
						label="Юридический адрес"
						type="text"
						value={userState?.legal_address}
						onChange={(e) => handleInputChange(e, "legal_address")}
					/>
					<Input
						label="Фактический адрес"
						type="text"
						value={userState?.actual_address}
						onChange={(e) => handleInputChange(e, "actual_address")}
					/>
					<Input
						label="ФИО гендиректора или представителя по доверенности"
						type="text"
						value={userState?.ceo_name}
						onChange={(e) => handleInputChange(e, "ceo_name")}
					/>
					<Input
						label="Телефон контактного лица"
						type="text"
						value={userState?.phone_number}
						onChange={(e) => handleInputChange(e, "phone_number")}
					/>
				</Stack>
			</Stack>
			<Cluster justify="flex-end">
				<Button
					size="big"
					variant="primary"
					onClick={() => setActive(2)}
					disabled={
						!userState.inn.length ||
						!userState?.ogrn.length ||
						!userState?.ogrnip.length ||
						!userState?.phone_number.length ||
						!userState?.ceo_name.length ||
						!userState?.actual_address.length ||
						!userState?.legal_address.length ||
						!userState?.legal_name.length
					}
					text="Продолжить"
				/>
			</Cluster>
		</Stack>
	);
};

// const mapStateToProps = (state) => {
//   return {
//     login: state.login,
//   };
// };

// const mapDispatchToProps = {
//   postLogin,
// };

export default connect(null, null)(FirstAddOwnerStep);
