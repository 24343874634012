import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { parseMovies } from "../../sctipts.js";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import UniversalDeleteModal from "../../../../universal/UniversalDeleteModal.jsx";
import SelectEpisodes from "../VideoStatisticSidebar/SelectEpisodes.jsx";
import { postMovies } from "../../../../../Actions/videos-actions/postMovieVideo.js";
import { putCurrentVideo } from "../../../../../Actions/videos-actions/putMovieVideo.js";
import { deleteVideo } from "../../../../../Actions/videos-actions/deleteMovieVideo.js";
import { useNavigate } from "react-router-dom";
import { CONTRUCTOR_URL } from "../../../../../Actions/consts.js";
import TokenService from "../../../../../services/token.service.js";
import { MovieTipsStatistic } from "./MovieTipsStatistic.jsx";
import { useUserAccessContext } from "../../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../../scripts/utils.js";

const VideoEditSidebar = ({
	mainVideoState,
	additionalVideoState,
	postMovies,
	deleteVideo,
	putCurrentVideo,
	errorState,
	setErrorState,
}) => {
	const { userAccessState } = useUserAccessContext();
	const { handleModal } = useContext(ModalContext);
	const navigate = useNavigate();
	const [selectedVideoState, setSelectedVideoState] = useState(mainVideoState?.video?.[0]);

	useEffect(() => {
		if (!mainVideoState.id && mainVideoState?.videos?.length > 0) {
			let sum = 0;
		}
	}, [mainVideoState]);

	const handleSaveFilmClick = () => {
		if (
			!mainVideoState.title ||
			mainVideoState.title.length === 0 ||
			!mainVideoState.movie_rating
		) {
			setErrorState(true);
			return;
		} else if (!!mainVideoState.id) {
			putCurrentVideo(
				parseMovies(mainVideoState),
				mainVideoState.id,
				additionalVideoState,
				additionalVideoState.id
			);
		} else {
			postMovies(parseMovies(mainVideoState), additionalVideoState);
		}
		setErrorState(false);
	};

	return (
		<div className="form-container-right second-col">
			{!!mainVideoState.id && mainVideoState?.serial?.[0]?.seasons?.length > 0 ? (
				<SelectEpisodes
					seasons={mainVideoState?.serial?.[0]?.seasons}
					mainVideoState={mainVideoState}
					setSideVideoStaticState={setSelectedVideoState}
				/>
			) : null}
			{!!mainVideoState.id ? (
				<>
					{(mainVideoState.movie_format.id === 1 || mainVideoState.movie_format.id === 6) &&
					checkUserWritePerm(userAccessState, "videos") ? (
						<>
							<a
								href={`${CONTRUCTOR_URL}?id=${
									additionalVideoState.id
								}&token=${TokenService.getLocalAccessToken()}&movie_id=${mainVideoState.id}`}
								target="_blank"
							>
								<div className="button action-button">
									<span>Перейти в конструктор</span>
								</div>
							</a>
							<div className="form-container-right-divider"></div>
						</>
					) : null}
					<MovieTipsStatistic videoState={selectedVideoState} />
				</>
			) : null}
			{(mainVideoState.movie_format.id !== 3 ||
				mainVideoState.movie_format.id !== 4 ||
				mainVideoState.movie_format.id !== 5) &&
			checkUserWritePerm(userAccessState, "videos") ? (
				<div className="button border-button" onClick={() => handleSaveFilmClick()}>
					Сохранить
				</div>
			) : (
				<div className="button border-button" onClick={() => navigate(-1)}>
					Вернуться
				</div>
			)}

			{mainVideoState.published === null ||
			(mainVideoState.published === false &&
				(mainVideoState.movie_format.id === 1 || mainVideoState.movie_format.id === 2) &&
				checkUserWritePerm(userAccessState, "videos")) ? (
				<div
					className={`button action-button  ${mainVideoState.id === undefined ? "disabled" : ""}`}
					onClick={() =>
						putCurrentVideo(
							parseMovies({ ...mainVideoState, published: true }),
							mainVideoState.id,
							additionalVideoState,
							additionalVideoState.id
						)
					}
				>
					Опубликовать
				</div>
			) : (mainVideoState.movie_format.id === 1 || mainVideoState.movie_format.id === 2) &&
			  checkUserWritePerm(userAccessState, "videos") ? (
				<div
					className={`button border-button`}
					onClick={() =>
						putCurrentVideo(
							parseMovies({ ...mainVideoState, published: false }),
							mainVideoState.id,
							additionalVideoState,
							additionalVideoState.id
						)
					}
				>
					Снять с публикации
				</div>
			) : null}

			{!!mainVideoState.id &&
			(mainVideoState.movie_format.id === 1 || mainVideoState.movie_format.id === 2) &&
			checkUserWritePerm(userAccessState, "videos") ? (
				<div
					className="remove-video-button action-button"
					onClick={() =>
						handleModal(
							<UniversalDeleteModal
								title={`видео`}
								deleteFunc={deleteVideo}
								id={mainVideoState.id}
								goBack={true}
							/>
						)
					}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
							fill="#94A0B1"
						></path>
					</svg>
					Удалить видео
				</div>
			) : null}
		</div>
	);
};

const mapDispatchToProps = {
	postMovies,
	putCurrentVideo,
	deleteVideo,
};

export default connect(null, mapDispatchToProps)(VideoEditSidebar);
