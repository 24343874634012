import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { removeEmpty } from "../../../Actions/ActionsScirpts.js";
import StatisticEditColumnSlide from "./SlidesMenu/StatisticEditColumnSlide.jsx";
import StatisticEditFilterSlide from "./SlidesMenu/StatisticEditFilterSlide.jsx";
import StatisticContent from "./StatisticContent/StatisticContent.jsx";
import StatisticFiltersComponent from "./StatisticFiltersComponent.jsx";
import {
	initialColumnState,
	initialSellerState,
	initialVideoState,
	initialViewerState,
} from "./statisticStatic.js";
import StatisticTitlesComponent from "./StatisticTitlesComponent/StatisticTitlesComponent.jsx";
import "./styles/StatisticPage.scss";
import { getBrightboxes } from "../../../Actions/BrightboxesActions.js";
import { formatDate } from "../VideosPage/sctipts.js";
import StatisticVideoContent from "./StatisticContent/StatisticVideoContent.jsx";
import StatisticViewerContent from "./StatisticContent/StatisticViewerContent.jsx";
import StatisticSellerContent from "./StatisticContent/StatisticSellerContent.jsx";
import { history } from "../../../Store/Store.ts";
import { GET_STATISTIC } from "../../../GraphQL/Statistic.js";
import { useLazyQuery } from "@apollo/client";
import { normalizeFilters } from "./scripts.js";
import StatisticPagination from "../../universal/PaginationBlock/StatisticPagination.jsx";
import Lottie from "react-lottie-player";
import preloaderJson from "../preloader.json";
import { SendResumeModal } from "./SendResumeModal";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";

const StatisticPage = ({ title }) => {
	const { handleModal } = useContext(ModalContext);
	const [mainStatState, setMainStatState] = useState([]);
	const [getStatistic, { data, loading }] = useLazyQuery(GET_STATISTIC, {
		variables: {
			page: 1,
			limit: 40,
			user_id: 1,
			lte: new Date().toISOString(),
			gte: "2022-03-01T03:10:34.000Z",
			format_id: null,
			category_id: null,
			seller_id: null,
			order_by: "create_date",
			direction: "DESC",
			sex_id: null,
			age_id: null,
			country_id: null,
			city_id: null,
			click: 1,
			video_id: null,
			genre_id: null,
		},
	});
	const [pagination, setPagination] = useState({
		max_elements: 0,
		max_pages: 0,
		page: 1,
		limit: 40,
	});
	let navigate = useNavigate();
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [statisticOrderType, setStatisticOrderType] = useState(0); // 0 - типы, 1 - видео, 2 - зрители, 3 - рекламодатели
	const [paramsState, setParamsState] = useState({
		id: searchParams.get("id") !== null ? searchParams.get("id") : null,
		filters: searchParams.get("filters") !== null ? JSON.parse(searchParams.get("filters")) : {},
		selected_sellers:
			searchParams.get("selected_sellers") !== null ? searchParams.get("selected_sellers") : null,
		sellerName: searchParams.get("sellerName") !== null ? searchParams.get("sellerName") : null,
		limit: searchParams.get("limit") !== null ? searchParams.get("limit") : 40,
		page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
	});

	useEffect(() => {
		if (!data) return;
		setMainStatState(data.getStatisticPage.items);
		setPagination({
			max_elements: data.getStatisticPage.pageInfo.itemsCount,
			max_pages: Math.floor(
				data.getStatisticPage.pageInfo.itemsCount / data.getStatisticPage.pageInfo.currentLimit
			),
			page: data.getStatisticPage.pageInfo.currentPage,
			limit: data.getStatisticPage.pageInfo.currentLimit,
		});
	}, [data]);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setParamsState({
					id: params.get("id") !== null ? params.get("id") : null,
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					selected_sellers:
						params.get("selected_sellers") !== null ? params.get("selected_sellers") : null,
					sellerName: params.get("sellerName") !== null ? params.get("sellerName") : null,
					limit: params.get("limit") !== null ? params.get("limit") : 40,
					page: params.get("page") !== null ? params.get("page") : 1,
				});
				if (
					!searchParams.get("selected_sellers") &&
					!searchParams.get("sellerName") &&
					!searchParams.get("id") &&
					statisticOrderType !== -1
				) {
					setStatisticOrderType(0);
				}
			}
		});
	}, [history]);

	const [activeColumnState, setActiveColumnState] = useState(initialColumnState);
	const [rightBarActiveCheck, setRightBarActiveCheck] = useState({
		active: false,
		name: "",
	});

	useEffect(() => {
		switch (statisticOrderType) {
			case -1:
				setActiveColumnState(initialColumnState);
				break;
			case 0:
				setActiveColumnState(initialColumnState);
				break;
			case 1:
				setActiveColumnState(initialVideoState);
				break;
			case 2:
				setActiveColumnState(initialViewerState);
				break;
			case 3:
				setActiveColumnState(initialSellerState);
				break;
			default:
				break;
		}
	}, [statisticOrderType]);

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		const filteredState = removeEmpty({ ...paramsState });
		const filteredFilters = removeEmpty(filteredState?.filters);

		if (!filteredState.hasOwnProperty("sideFilters") && Object.keys(filteredState).length !== 0) {
			const resultFilters = normalizeFilters({ ...filteredState, filters: filteredFilters });

			getStatistic({
				variables: {
					page: Number(resultFilters.page),
					limit: Number(resultFilters.limit),
					user_id: 1,
					tips_id: resultFilters.id ? Number(resultFilters.id) : null,
					lte: resultFilters.to_datetime ? resultFilters.to_datetime : null,
					gte: resultFilters.from_datetime ? resultFilters.from_datetime : null,
					format_id: resultFilters.format_id ? resultFilters.format_id : null,
					category_id: resultFilters.categories_ids ? resultFilters.categories_ids : null,
					seller_id: resultFilters.selected_sellers ? resultFilters.selected_sellers : null,
					order_by: resultFilters.order_by ? resultFilters.order_by : "create_date",
					direction: resultFilters.reversed_ ? "ASC" : "DESC",
					sex_id: null,
					age_id: null,
					country_id: null,
					city_id: null,
					click: 1,
					video_id: resultFilters.video_id ? resultFilters.video_id : null,
					genre_id: null,
				},
			});
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...filteredState,
					filters: JSON.stringify({ ...filteredFilters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		} else {
			if (filteredState.hasOwnProperty("sideFilters")) {
				const filteredSideFilters = filteredState?.sideFilters;
				const resultFilters = normalizeFilters({
					...filteredState,
					filters: filteredFilters,
					sideFilters: filteredSideFilters,
				});
				getStatistic({
					variables: {
						page: Number(resultFilters.page),
						limit: Number(resultFilters.limit),
						user_id: resultFilters.owner ? resultFilters.owner : 1,
						tips_id: resultFilters.id ? Number(resultFilters.id) : null,
						lte: resultFilters.to_datetime ? resultFilters.to_datetime : null,
						gte: resultFilters.from_datetime ? resultFilters.from_datetime : null,
						format_id: resultFilters.format_id ? resultFilters.format_id : null,
						category_id: resultFilters.categories_ids ? resultFilters.categories_ids : null,
						seller_id: resultFilters.selected_sellers ? resultFilters.selected_sellers : null,
						order_by: resultFilters.order_by ? resultFilters.order_by : "create_date",
						direction: resultFilters.reversed ? "ASC" : "DESC",
						sex_id: resultFilters?.gender?.length === 1 ? Number(resultFilters.gender[0]) : null,
						age_id: resultFilters.age_id ? resultFilters.age_id : null,
						country_id: resultFilters.country ? resultFilters.country : null,
						city_id: resultFilters.region ? resultFilters.region : null,
						click:
							(resultFilters.is_click && resultFilters.is_show) ||
							(!resultFilters.is_click && !resultFilters.is_show)
								? null
								: resultFilters.is_click
								? 1
								: 0,
						video_id: resultFilters.video_id,
						genre_id: resultFilters.genres ? resultFilters.genres : null,
					},
				});
				delete filteredState.sideFilters;
			}
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...filteredState,
					filters: JSON.stringify({ ...filteredFilters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		}
	}, [paramsState]);

	const handleDowloadLinkClick = () => {
		const linkString = searchParams.toString();
		window.open(`https://atips.io/export?${linkString}`);
	};
	return (
		<>
			{loading ? (
				<div className={`main-loader-wrapper ${"active"}`}>
					<Lottie loop animationData={preloaderJson} play style={{ width: 50, height: 50 }} />
				</div>
			) : null}
			<section className="personal-area-item statistic">
				{!!searchParams.get("id") ||
				(searchParams.get("selected_sellers") && searchParams.get("sellerName")) ? (
					<button
						className="form-page-back statistic-button"
						onClick={() => (navigate(-1), setParamsState({ id: null }))}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
								fill="#94A0B1"
							/>
						</svg>
						Вернуться назад
					</button>
				) : null}
				<header className="personal-area categories-header statistic-header">
					{!!searchParams.get("id") ? (
						<h1 className="current-statistic">{`Статистика типса ID ${searchParams.get("id")}`}</h1>
					) : searchParams.get("selected_sellers") && searchParams.get("sellerName") ? (
						<h1 className="current-statistic">{`Статистика ${searchParams.get("sellerName")}`}</h1>
					) : (
						<>
							<h1>
								Статистика
								<sup>{!!pagination.max_elements ? pagination.max_elements : 0}</sup>
							</h1>
						</>
					)}
					<button className="button_add-category" onClick={() => handleModal(<SendResumeModal />)}>
						<img src="images/icons/ui/files/white/upload.svg" alt="плюс" />
						Выгрузить
					</button>
				</header>
				{statisticOrderType !== -1 &&
				!searchParams.get("selected_sellers") &&
				!searchParams.get("sellerName") &&
				!searchParams.get("id") ? (
					<div className="statistics-bookmarks">
						<button
							className={`statistics-bookmark ${statisticOrderType === 0 ? "active" : ""}`}
							onClick={() => setStatisticOrderType(0)}
						>
							Типсы
						</button>
						<button
							className={`statistics-bookmark ${statisticOrderType === 1 ? "active" : ""}`}
							onClick={() => setStatisticOrderType(1)}
						>
							Видео
						</button>
						<button
							className={`statistics-bookmark ${statisticOrderType === 2 ? "active" : ""}`}
							onClick={() => setStatisticOrderType(2)}
						>
							Зрители
						</button>
						<button
							className={`statistics-bookmark ${statisticOrderType === 3 ? "active" : ""}`}
							onClick={() => setStatisticOrderType(3)}
						>
							Рекламодатели
						</button>
					</div>
				) : null}

				<div className="videos-content-wrapper content-wrapper">
					<StatisticFiltersComponent filterState={paramsState} setFilterState={setParamsState} />
					<div id="statistics-filter-buttons">
						<div
							className="statistics-all-filter-button action-button"
							onClick={() =>
								setRightBarActiveCheck({
									active: true,
									name: "filter",
								})
							}
						>
							Все фильтры
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 11L15 8L2 8V6L15 6V3H17V6L22 6V8L17 8V11H15ZM7 18V21H9V18H22L22 16L9 16L9 13H7L7 16H2V18H7Z"
									fill="#212121"
								></path>
							</svg>
						</div>
						<div
							className="statistics-column-button action-button"
							onClick={() =>
								setRightBarActiveCheck({
									active: true,
									name: "column",
								})
							}
						>
							Управление столбцами
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
									fill="#212121"
								></path>
							</svg>
						</div>
					</div>
					<StatisticTitlesComponent
						activeColumnState={activeColumnState}
						paramsState={paramsState}
						setParamsState={setParamsState}
					/>
					{statisticOrderType === 0 || statisticOrderType === -1 ? (
						<StatisticContent
							activeColumnState={activeColumnState}
							paramsState={paramsState}
							setParamsState={setParamsState}
							setStatisticOrderType={setStatisticOrderType}
							mainStatState={mainStatState}
							setMainStatState={setMainStatState}
						/>
					) : statisticOrderType === 1 ? (
						<StatisticVideoContent
							activeColumnState={activeColumnState}
							paramsState={paramsState}
							setParamsState={setParamsState}
							setStatisticOrderType={setStatisticOrderType}
							mainStatState={mainStatState}
							setMainStatState={setMainStatState}
						/>
					) : statisticOrderType === 2 ? (
						<StatisticViewerContent
							activeColumnState={activeColumnState}
							paramsState={paramsState}
							setParamsState={setParamsState}
							setStatisticOrderType={setStatisticOrderType}
							mainStatState={mainStatState}
							setMainStatState={setMainStatState}
						/>
					) : (
						<StatisticSellerContent
							activeColumnState={activeColumnState}
							paramsState={paramsState}
							setParamsState={setParamsState}
							setStatisticOrderType={setStatisticOrderType}
							mainStatState={mainStatState}
							setMainStatState={setMainStatState}
						/>
					)}
				</div>
				<StatisticEditColumnSlide
					status={rightBarActiveCheck}
					setStatus={setRightBarActiveCheck}
					columns={activeColumnState}
					setColumns={setActiveColumnState}
				/>
				<StatisticEditFilterSlide
					status={rightBarActiveCheck}
					setStatus={setRightBarActiveCheck}
					paramsState={paramsState}
					setParamsState={setParamsState}
				/>
				<div
					className={`statistics-filter-bg ${rightBarActiveCheck.active ? "active" : ""}`}
					onClick={() => setRightBarActiveCheck(false, "")}
				></div>
				{!!pagination.max_pages && mainStatState.length > 0 && pagination.max_pages > 1 ? (
					<StatisticPagination
						filterState={paramsState}
						setFilterState={setParamsState}
						pagination={pagination}
					/>
				) : null}
				<div id="fixed-statistics-itog" className="animte-menu">
					<div className="fixed-staistics-data">
						<span>Всего: {!!data && data?.getStatisticPage?.pageInfo?.itemsCount}</span>
						<span>Общий доход: {!!data && data?.getStatisticPage?.aggregation?.total_sum} ₽</span>
						<span>
							Прибыль:{" "}
							{!!data &&
								data?.getStatisticPage?.aggregation?.total_sum -
									data?.getStatisticPage?.aggregation?.income_profit}{" "}
							₽
						</span>
					</div>
					<div className="fixed-staistics-data">
						<span>
							{String(formatDate(new Date("2022/06/1").getTime()))} —{" "}
							{formatDate(new Date().getTime())}
						</span>
					</div>
				</div>
			</section>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		statisticList: state.statisticList,
	};
};

const mapDispatchToProps = {
	getBrightboxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticPage);
