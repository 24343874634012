import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getViewers } from "../../../../Actions/ViewersActions.js";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import { baseUrl } from "../../../../Actions/consts.js";
import axios from "axios";
import { VerifiedWrapper } from "../ViewerStatistic/PersonalInfo/ViewersContacts.jsx";
import styled from "styled-components";
import ExtertnalPaginationBlock from "../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { history } from "../../../../Store/Store";

const StyledVerifiedWrapper = styled(VerifiedWrapper)`
	margin-left: 0px;
`;

const BlockedViewersPage = ({
	priceState,
	loader,
	title,
	getViewers,
	viewersList,
	mainFilterState,
	setMainFilterState,
}) => {
	const dispatch = useDispatch();
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [viewersState, setViewersState] = useState([]);
	const [lastSearch, setLastSearch] = useState(true);
	const [firstLoad, setFirstLoad] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		setViewersState(viewersList.list);
	}, [viewersList]);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					page: params.get("page") !== null ? params.get("page") : 1,
					size: params.get("size") !== null ? params.get("size") : 20,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getViewers({ ...filteredState, application_status: "rejected" });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	const handleOrderClick = (key, filterState, setFilterState) => {
		if (!("order_by" in filterState)) {
			setFilterState({ ...filterState, order_by: key });
			return;
		}
		if (filterState.order_by !== key) {
			setFilterState({ ...filterState, order_by: key });
		} else {
			setFilterState({ ...filterState, order_by: `-${key}` });
		}
	};

	return (
		<>
			<div className="list-table-header seller-list viewer-list">
				<div
					className="list-table-header-element"
					style={{ width: "18.7%" }}
					onClick={() =>
						handleFilteredClick("sort", "first_name", mainFilterState, setMainFilterState)
					}
				>
					<span>Имя</span>
				</div>
				{/* <div
						className="list-table-header-element"
						style={{ width: "11.3%" }}
						onClick={() => handleFilteredClick("sort", "area", mainFilterState, setMainFilterState)}
					>
						<span>Площадка</span>
					</div> */}
				<div
					className="list-table-header-element"
					style={{ width: "8.9%" }}
					onClick={() => handleOrderClick("age", mainFilterState, setMainFilterState)}
				>
					<span>Возраст</span>
				</div>
				<div className="list-table-header-element non-sort" style={{ width: "6%" }}>
					<span>Пол</span>
				</div>
				<div
					className="list-table-header-element"
					style={{ width: "10%" }}
					onClick={() => handleOrderClick("country_id", mainFilterState, setMainFilterState)}
				>
					<span>Страна</span>
				</div>
				<div
					className="list-table-header-element"
					style={{ width: "14.9%" }}
					onClick={() => handleOrderClick("city_id", mainFilterState, setMainFilterState)}
				>
					<span>Город</span>
				</div>
				<div className="list-table-header-element non-sort" style={{ width: "11.1%" }}>
					<span>
						Просмотры
						<br />
						типсов
					</span>
				</div>
				<div className="list-table-header-element non-sort" style={{ width: "8%" }}>
					<span>
						Клики
						<br />
						типсов
					</span>
				</div>
				<div className="list-table-header-element non-sort" style={{ width: "9.6%" }}>
					<span>
						Общий
						<br />
						доход, ₽
					</span>
				</div>
				<div
					className="list-table-header-element"
					style={{ width: "24px", minWidth: "24px", padding: "0" }}
				></div>
			</div>

			<div className="list-table stat-list selers-list viewer-list">
				{viewersState.length > 0
					? viewersState.map((elem) => <ViewerListItem elem={elem} key={elem.id} />)
					: null}
			</div>
			{Object.keys(viewersList.pagination).length !== 0 &&
			!!viewersList.pagination.max_pages &&
			viewersList.list.length > 0 &&
			viewersList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={viewersList.pagination}
				/>
			) : null}
		</>
	);
};

const ViewerListItem = ({ elem }) => {
	const dispatch = useDispatch();
	const [viewerState, setViewerState] = useState(null);

	useEffect(async () => {
		dispatch({ type: "SHOW_MAIN_LOADER" });
		await axios
			.get(`${baseUrl}/domain/moviegoers/${elem.id}`, {})
			.then((viewers) => {
				setViewerState(viewers.data);
			})
			.finally(() => {
				dispatch({ type: "HIDE_MAIN_LOADER" });
			})
			.catch((error) => {
				console.log(error);
			});
	}, [elem]);

	return (
		<Link to={`/viewers-statistic?id=${viewerState?.id}`} className="list-table-item">
			<div className="list-table-item-element" style={{ width: "18.7%" }}>
				{elem && elem.avatar_url && elem.avatar_url?.length > 0 ? (
					<img src={elem.avatar_url} alt="profile" />
				) : (
					<img src="images/profile-frame.png" alt="profile" />
				)}
				<div className="list-table-item-element-info">
					<span>{`${elem?.first_name} ${elem?.last_name}`}</span>{" "}
					<small>ID {viewerState?.id}</small>
				</div>
			</div>
			<div className="list-table-item-element" style={{ width: "8.9%" }}>
				<span>{elem?.age || "Не указано"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "6%" }}>
				<span>{elem?.gender === "male" ? "Мужской" : "Женский"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "10%" }}>
				<span>{viewerState?.country || "Не указано"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "14.9%" }}>
				<span>{viewerState?.city || "Не указано"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "11.1%" }}>
				<span>{viewerState?.statistic?.showcases | "ошибка"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "8%" }}>
				<span>{viewerState?.statistic?.clickthrough | "ошибка"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "9.6%" }}>
				<span>{viewerState?.statistic?.revenue | "ошибка"}</span>
			</div>
			<div
				className="list-table-item-element"
				style={{ width: "24px", position: "relative", paddingLeft: "0" }}
			>
				<StyledVerifiedWrapper className={!elem?.is_verified && "disabled"}>
					<div className="help-tip">
						<span>{elem?.is_verified ? "Подтвержден" : "Не подтвержден"}</span>
						<img src="images/icons/ui/arrow.svg" alt="" />
					</div>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.7071 10.7071L17.4142 10L16 8.58579L15.2929 9.29289L11 13.5858L8.70711 11.2929L8 10.5858L6.58579 12L7.29289 12.7071L10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071L16.7071 10.7071Z"
							fill="#0DC268"
						/>
					</svg>
				</StyledVerifiedWrapper>
			</div>
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		viewersList: state.viewersList.active,
	};
};

const mapDispatchToProps = {
	getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedViewersPage);
