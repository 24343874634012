import axios from "axios";
import { baseUrl } from "./consts";

export const getVideoFormats = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/format`)
			.then((videoFormat) => {
				dispatch({
					type: "GET_VIDEO_FORMATS",
					videoFormat,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
