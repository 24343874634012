import axios from "axios";
import { baseUrl } from "./consts";

export const getProduct = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/product/${id}`)
			.then((prod) => {
				dispatch({
					type: "GET_PRODUCT",
					prod,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
