import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
	handleFilteredClick,
	handleMultiSelectActiveClick,
	handleSelectFilters,
} from "../../scripts/PersonalAreaScripts";
import { getAreas } from "../../../../Actions/UsersActions";
import { useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import styled from "styled-components";

const CategorySelect = styled.div`
	background: #f4f4f8;
	border: 1px solid #f4f4f8;
	border-radius: 8px;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	font-size: 15px;
	height: auto;
	min-height: 54px;
	outline: none;
	padding: 11px 80px 8px 16px;
	position: relative;
	width: 100% !important;
	display: flex;
	cursor: pointer;
	align-items: center;
	svg {
		position: absolute;
		right: 12px;
		top: calc(50% - 12px);
	}
	&:hover {
		border: 1px solid #d1d8e1;
	}
	span.category-value {
		display: block;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 60px);
		font-size: 15px;
		line-height: 54px;
	}
`;
const initialDropDownState = {
	areaId: false,
};

const OwnerFiltersComponent = ({ filterState, setFilterState, categoriesList }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleModal } = useContext(ModalContext);

	const [searchInput, setSearchInput] = useState("");

	const handleSearchInputChange = (e) => {
		e.stopPropagation();
		let value = e.target.value;
		setSearchInput(value);
	};

	useEffect(() => {
		setSearchInput(
			!!JSON.parse(searchParams.get("filters"))?.name
				? JSON.parse(searchParams.get("filters"))?.name
				: ""
		);
	}, [searchParams]);

	return (
		<div className="list-filter flex-block" style={{ marginBottom: "38px" }}>
			<div className="form-field">
				<div className="input-field left-icon">
					<input
						type="text"
						placeholder="Поиск по названию"
						onKeyPress={(e) =>
							e.key === "Enter"
								? handleFilteredClick("sellerSearch", searchInput, filterState, setFilterState)
								: null
						}
						value={searchInput.length > 0 ? searchInput : ""}
						onChange={(e) => handleSearchInputChange(e)}
					/>
					<div
						className="input-search input-icon"
						onClick={(e) =>
							handleFilteredClick("sellerSearch", searchInput, filterState, setFilterState)
						}
					></div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		categoriesList: state.extraCategoriesReducer,
		// areasList: state.areasList,
	};
};

const mapDispatchToProps = {
	getAreas,
	// getSellers,
	// getExtraCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerFiltersComponent);
