import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../../Actions/consts";

// Кол-во просмотров
export const getTotalVideoViewAmount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-amount?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.amount);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
// Кол-во всего просмотров
export const getTotalVideoViewAmountUninq = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-amount?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.amount);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
// среднее время просмотра
export const getTotalVideoAverageTime = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/average-session-time?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.average_session_time);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// общее время просмотра
export const getTotalVideoViewTime = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/total-watch-time?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.total_watch_time);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// кол-во добавленных в избранное
export const getTotalVideoFavourite = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/favourites-by-period?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			const sum = blogger.data.reduce((acc, val) => acc + val.amount, 0);
			onReady(sum);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// Источники в кинотеатре
export const getVideoTrafficSources = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/traffic-sources?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			const sum = blogger.data.reduce((acc, val) => acc + val.amount, 0);
			const result = blogger.data.map((elem) => {
				return {
					color: "#" + Math.floor(Math.random() * 16777215).toString(16),
					percentValue: Math.round((elem.amount / sum) * 100) + "%",
					title: elem.traffic_source,
					value: elem.amount,
				};
			});
			let trashObject = {
				color: "#" + Math.floor(Math.random() * 16777215).toString(16),
				title: "Прямые переходы",
				value: 0,
			};
			const filteredRes = result.filter((elem) => {
				if (elem.title) {
					return true;
				}
				{
					trashObject = {
						...trashObject,
						value: trashObject.value + elem.value,
						percentValue: Math.round(((trashObject.value + elem.value) / sum) * 100) + "%",
					};
					return false;
				}
			});
			const sorted = [...filteredRes, trashObject].sort((a, b) => {
				if (a.value > b.value) {
					return -1;
				}
				if (a.value < b.value) {
					return 1;
				}
				return 0;
			});
			onReady(sorted);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
