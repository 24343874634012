import React from "react";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
	color: #94a0b1;
	cursor: pointer;
	display: flex;
	align-items: center;
	position: relative;
	&:before {
		background: #f4f4f8;
		border: 1px solid #d1d8e1;
		border-radius: 4px;
		box-sizing: border-box;
		content: "";
		display: block;
		height: 24px;
		left: 0;
		position: absolute;
		top: calc(50% - 12px);
		width: 24px;
	}

	&.selected {
		&.changed {
			&:before {
				border-color: #5817a0;
				background-color: #5817a0;
			}
		}
		&:before {
			background: #005bff url("/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
			border-color: #005bff;
		}
	}
`;

const StyledInput = styled.input`
	display: none;
`;
const StyledLabel = styled.label`
	cursor: pointer;
	display: block;
	line-height: 24px;
	font-size: 15px;
	font-weight: 400;
	padding-left: 36px;
	position: relative;
	color: #212121;
`;

export const Checkbox = ({ title, onSelect, isActive }) => {
	return (
		<CheckboxWrapper className={`${isActive ? "selected" : ""}`} onClick={onSelect}>
			<StyledInput type="checkbox" />
			<StyledLabel>{title}</StyledLabel>
		</CheckboxWrapper>
	);
};
