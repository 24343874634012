import axios from "axios";
import { baseUrl } from "./consts";

export const getPlaylist = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/playlists`)
			.then((playlist) => {
				dispatch({
					type: "GET_PLAYLIST",
					playlist,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCurrentPlaylist = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/domain/playlists/movies/${id}`)
			.then((playlist) => {
				dispatch({
					type: "GET_CURRENT_PLAYLIST",
					playlist,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const clearPlayListsCache = (id) => {
	return axios.get(`${baseUrl}/domain/playlists/clearcache?playlist_id=${id}`);
};

export const postPlaylist = (data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/playlists/movies`, data)
			.then((playlist) => {
				dispatch({
					type: "POST_PLAYLIST",
					playlist,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
				dispatch(getPlaylist());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putPlaylist = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await clearPlayListsCache(id).then(() =>
			axios
				.put(`${baseUrl}/domain/playlists/movies/${id}`, data)
				.then((playlist) => {
					if (playlist && playlist.data) dispatch(getCurrentPlaylist(playlist.data.id));
					else
						dispatch({
							type: "PUT_PLAYLIST",
							playlist,
						});
				})
				.finally(() => {
					dispatch({
						type: "HIDE_ALL",
					});
				})
				.catch((error) => {
					console.log(error);
				})
		);
	};
};

export const deletePlaylist = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await clearPlayListsCache(id).then(() =>
			axios
				.delete(`${baseUrl}/playlist/${id}`)
				.then((playlist) => {
					dispatch({
						type: "DELETE_PLAYLIST",
						playlist,
					});
				})
				.finally(() => {
					dispatch({
						type: "HIDE_ALL",
					});
					dispatch(getPlaylist());
				})
				.catch((error) => {
					console.log(error);
				})
		);
	};
};

// playlists's movies
export const putMoviePlaylist = (type, movie, playlistId, playlists) => {
	return async (dispatch) => {
		await axios
			.put(`${baseUrl}/domain/movies/${movie.id}`, { playlists: playlists })
			.then(() => {
				if (type === "post") {
					dispatch({
						type: "ADD_PLAYLIST_VIDEO",
						playlist: {
							id: playlistId,
							video: movie,
						},
					});
				} else {
					dispatch({
						type: "DELETE_PLAYLIST_VIDEO",
						playlist: {
							id: playlistId,
							video: movie.id,
						},
					});
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

// post video from playlist
export const addMoviePlaylist = (id, playlist) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/playlists/movies/${id}`, playlist)
			.then(() => {
				dispatch(getCurrentPlaylist(id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

// delete video from playlist
export const deleteMoviePlaylist = (id, playlist) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/playlists/movies/${id}`, playlist)
			.then(() => {
				dispatch(getCurrentPlaylist(id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
