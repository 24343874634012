import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getFormatedNumberValue } from "../scripts.js";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip.jsx";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
import * as qs from "qs";

const initialVideosCountByFormats = [
	{
		id: 1,
		title: "Всего фильмов:",
	},
	{
		id: 2,
		title: "Всего сериалов:",
	},
	{
		id: 3,
		title: "Всего видео блогеров:",
	},
	{
		id: 4,
		title: "Всего видео Shorts:",
	},
	{
		id: 6,
		title: "Всего фильмов правообладателей",
	},
	{
		id: 7,
		title: "Всего сериалов правообладателей:",
	},
];

const VideosAmountTooltip = ({ videosCountByFormats }) => {
	const dispatch = useDispatch();
	const [videosCount, setVideosCount] = useState([]);
	useEffect(() => {
		initialVideosCountByFormats.forEach(async (elem) => {
			dispatch({
				type: "SHOW_MAIN_LOADER",
			});
			await axios
				.get(`${baseUrl}/domain/movies/filter?limit=1&offset=0`, {
					params: {
						formats_ids: [elem.id],
						page: 1,
						size: 1,
					},
					paramsSerializer: (params) => {
						return qs.stringify(params, { arrayFormat: "repeat" });
					},
				})
				.then((video) => {
					dispatch({
						type: "GET_VIDEO_FORMAT_COUNT",
						video,
						formatId: elem.id,
					});
				})
				.finally(() => {
					dispatch({
						type: "HIDE_MAIN_LOADER",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		});
	}, []);

	useEffect(() => {
		setVideosCount(videosCountByFormats);
	}, [videosCountByFormats]);

	return (
		<Tooltip
			text={videosCount.map((elem) => `${elem.title} ${getFormatedNumberValue(elem.value)}\n`)}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		videosCountByFormats: state.videosCountByFormats,
	};
};

export default connect(mapStateToProps, null)(VideosAmountTooltip);
