import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../components/modal/ModalContext/ModalContext";
import { Stack } from "../../ui-kit/layouts/Stack";
import { DTList14, DText15 } from "../../ui-kit/typography/Typography";
import { Cluster } from "../../ui-kit/layouts/Cluster";
import styled from "styled-components";

const StyledDepthImg = styled.img`
  width: 73px;
  height: 47px;
`;

export const ViewDepthInfoModal = () => {
  const { handleModal } = useContext(ModalContext);

  return (
    <>
      <div className="add-category-wrapper" style={{ width: "660px" }}>
        <div className="modal-field-title">Глубина просмотра</div>
        <div className="modal-field-close" onClick={() => handleModal()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
              fill="#212121"
            ></path>
          </svg>
        </div>
        <Stack space="32px">
          <DText15>
            Глубина просмотра показывает вовлеченность аудитории в просмотр
            видео. На графике отображается, как зрители смотрят ролик.
          </DText15>
          <Cluster space="32px" align="center">
            <StyledDepthImg src="images/depth1.png" alt="depth1" />
            <DTList14>
              Фрагмент видео, который зрители смотрят от начала и до конца, не
              <br />
              прерываясь.
            </DTList14>
          </Cluster>
          <Cluster space="32px" align="center">
            <StyledDepthImg src="images/depth2.png" alt="depth2" />
            <DTList14>
              Здесь снижается процент зрителей, которые смотрят видео. Интерес к
              <br />
              видео падает.
            </DTList14>
          </Cluster>
          <Cluster space="32px" align="center">
            <StyledDepthImg src="images/depth3.png" alt="depth3" />
            <DTList14>
              Провалы в графике означают, что зрители перематывают этот фрагмент
              или перестают смотреть видео.
            </DTList14>
          </Cluster>
        </Stack>
      </div>
    </>
  );
};
