export const initialUserStatic = {
	first_name: "",
	second_name: "",
	email: "",
	owner: null,
	password: null,
	avatar_url: "",
	subparam_1: 0,
	subparam_2: 0,
	subparam_3: 0,
	subparam_4: 0,
	subparam_5: 0,
	subparam_6: 0,
	subparam_7: 0,
	subparam_8: 0,
	subparam_9: 0,
	subparam_10: 0,
	subparam_11: 0,
	subparam_12: 0,
	subparam_13: 0,
};
