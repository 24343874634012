import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
	postFilmCrew,
	postUploadImages,
	putFilmCrew,
} from "../../../../Actions/videos-actions/VideosActions";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { onInputTextAreaChange } from "../sctipts";

import "./styles/CreateFilmCrew.scss";
import { checkUserWritePerm } from "../../scripts/utils";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";

const RemovePreviewButton = styled.div`
	background: #ffffff;
	position: absolute;
	border-radius: 80px;
	width: 32px;
	height: 32px;
	right: 8px;
	top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	&:hover {
		svg {
			path {
				fill: #005bff;
			}
		}
	}
`;

const CreateFilmCrew = ({
	type,
	state,
	postUploadImages,
	uploadImage,
	uploadGalleryImage,
	loadingStatus,
	postFilmCrew,
	putFilmCrew,
	userAccessState,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [imageErrorState, setImageErrorState] = useState(null);
	const [filmCrewState, setFilmCrewState] = useState({
		first_name: !!state && !!state.first_name ? state.first_name : "",
		second_name: !!state && !!state.second_name ? state.second_name : "",
		birthdate: !!state && !!state.birthdate ? state.birthdate.split("T")[0] : "",
		URL_SD_PREVIEW: !!state && !!state.URL_SD_PREVIEW ? state.URL_SD_PREVIEW : "",
		photos: !!state && !!state.photos ? state.photos : [],
		position_id: !!state && !!state.position_id ? Number(state.position_id) : Number(type),
	});
	const [prewDragState, setPrewDragState] = useState(() => false);
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
	// т.е. пока false он даже не смотрит на аплоад
	const handleCreateClick = () => {
		const timeGal = `["${filmCrewState.photos.join('","')}"]`;
		if (!!state && !!state.id) {
			putFilmCrew(
				{
					...filmCrewState,
					photos: timeGal,
					birthdate: filmCrewState.birthdate ? new Date(filmCrewState.birthdate).getTime() : 0,
				},
				state.id
			);
		} else {
			postFilmCrew({
				...filmCrewState,
				photos: timeGal,
				birthdate: filmCrewState.birthdate ? new Date(filmCrewState.birthdate).getTime() : 0,
			});
		}
		handleModal();
	};

	const dragStartHandler = (e) => {
		e.preventDefault();
		setPrewDragState(true);
	};

	const dragLeaveHandler = (e) => {
		e.preventDefault();
		setPrewDragState(false);
	};

	const onDropHandler = (e) => {
		e.preventDefault();
		let files = [...e.dataTransfer.files];
		let reader = new FileReader();
		let file = files[0];
		const validFormats = ["image/png", "image/jpeg"];
		const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
		if (!validFormats.includes(file.type)) {
			setImageErrorState({ ...imageErrorState, preview: "Недопустимый формат изображения" });
			setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: undefined });
			return;
		}

		if (file.size > maxSize) {
			setImageErrorState({ ...imageErrorState, preview: "Размер файла слишком большой" });
			setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: undefined });
			return;
		}
		setImageErrorState({ ...imageErrorState, preview: null });
		reader.onloadend = () => {
			postUploadImages(file, "preview");
			setUploadFlagState(true);
		};
		reader.readAsDataURL(files[0]);
	};

	const onPrewPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
			if (!validFormats.includes(file.type)) {
				setImageErrorState({ ...imageErrorState, preview: "Недопустимый формат изображения" });
				setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: undefined });
				return;
			}

			if (file.size > maxSize) {
				setImageErrorState({ ...imageErrorState, preview: "Размер файла слишком большой" });
				setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: undefined });
				return;
			}
			setImageErrorState({ ...imageErrorState, preview: null });
			reader.onloadend = () => {
				postUploadImages(file, "preview");
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};
	const onGalleryPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			event.target.files[0];
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
			if (!validFormats.includes(file.type)) {
				setImageErrorState({ ...imageErrorState, gallery: "Недопустимый формат изображения" });

				return;
			}

			if (file.size > maxSize) {
				setImageErrorState({ ...imageErrorState, gallery: "Размер файла слишком большой" });
				return;
			}
			setImageErrorState({ ...imageErrorState, gallery: null });
			reader.onloadend = () => {
				postUploadImages(file, "gallery");
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveGalleryPhoto = (id) => {
		const index = filmCrewState.photos.findIndex((item) => item === id);
		let arr = filmCrewState.photos;
		arr.splice(index, 1);
		setFilmCrewState({ ...filmCrewState, photos: arr });
	};

	useEffect(() => {
		if (uploadImage.filename.length > 0 && uploadFlagState) {
			setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: uploadImage.filename });
			setUploadFlagState(false);
		}
	}, [uploadImage]);

	useEffect(() => {
		if (
			(!!filmCrewState?.photos || filmCrewState?.photos === false) &&
			uploadGalleryImage.filename.length > 0 &&
			uploadFlagState
		) {
			if (filmCrewState?.photos[0] === null || filmCrewState?.photos === false) {
				let arr = [];
				arr[0] = uploadGalleryImage.filename;
				setFilmCrewState({ ...filmCrewState, photos: arr });
				setUploadFlagState(false);
				return;
			}
			const arr = filmCrewState.photos || [];
			arr.push(uploadGalleryImage.filename);
			setFilmCrewState({ ...filmCrewState, photos: arr });
			setUploadFlagState(false);
		}
	}, [uploadGalleryImage]);

	return (
		<div className="modal-field create-people-modal active">
			<div className="modal-field-title">
				{!state
					? `Новый
				${
					filmCrewState.position_id === 1
						? "актёр"
						: filmCrewState.position_id === 2
						? "режиссер"
						: filmCrewState.position_id === 3
						? "сценарист"
						: filmCrewState.position_id === 4
						? "оператор"
						: filmCrewState.position_id === 5
						? "продюсер"
						: ""
				}`
					: `${filmCrewState.first_name} ${filmCrewState.second_name}`}
			</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"></path>
				</svg>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Имя</div>
					<div className="input-field">
						<input
							disabled={!checkUserWritePerm(userAccessState, "videos")}
							name="name"
							value={filmCrewState.first_name}
							placeholder="Введите имя"
							onChange={(e) =>
								onInputTextAreaChange(e, "first_name", filmCrewState, setFilmCrewState)
							}
						/>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label">Фамилия</div>
					<div className="input-field">
						<input
							disabled={!checkUserWritePerm(userAccessState, "videos")}
							name="surname"
							value={filmCrewState.second_name}
							placeholder="Введите фамилию"
							onChange={(e) =>
								onInputTextAreaChange(e, "second_name", filmCrewState, setFilmCrewState)
							}
						/>
					</div>
				</div>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Дата рождения</div>
					<div className="input-field">
						<input
							disabled={!checkUserWritePerm(userAccessState, "videos")}
							name="birthdate"
							value={filmCrewState.birthdate}
							type="date"
							onChange={(e) =>
								onInputTextAreaChange(e, "birthdate", filmCrewState, setFilmCrewState)
							}
						/>
					</div>
				</div>
				<div className="form-field"></div>
			</div>
			<div className="form-container-block-subtitle" style={{ marginBottom: "16px" }}>
				Превью
			</div>
			<div
				className="flex-block form-container-block-preview"
				onDragStart={(e) => checkUserWritePerm(userAccessState, "videos") && dragStartHandler(e)}
				onDragLeave={(e) => checkUserWritePerm(userAccessState, "videos") && dragLeaveHandler(e)}
				onDragOver={(e) => checkUserWritePerm(userAccessState, "videos") && dragStartHandler(e)}
				onDrop={(e) => checkUserWritePerm(userAccessState, "videos") && onDropHandler(e)}>
				{filmCrewState.URL_SD_PREVIEW?.length > 0 &&
					checkUserWritePerm(userAccessState, "videos") && (
						<RemovePreviewButton
							onClick={() =>
								setFilmCrewState({ ...filmCrewState, URL_SD_PREVIEW: "", URL_HD_PREVIEW: "" })
							}>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.70711 1.29289L2 0.585785L0.585785 2L1.29289 2.70711L4.58579 6L1.29289 9.29289L0.585785 10L2 11.4142L2.70711 10.7071L6 7.41421L9.29289 10.7071L10 11.4142L11.4142 10L10.7071 9.29289L7.41421 6L10.7071 2.70711L11.4142 2L10 0.585785L9.29289 1.29289L6 4.58579L2.70711 1.29289Z"
									fill="#212121"
								/>
							</svg>
						</RemovePreviewButton>
					)}
				<div className="form-container-block-preview-data">
					<img src="images/icons/uploadimage.svg" alt="upload" />
					{!prewDragState || imageErrorState?.preview ? (
						<>
							<span>
								Перетащите изображение в это окно
								<br />
								или нажмите, чтобы выбрать
							</span>{" "}
							Рекомендуемый размер изображений
							<br />
							не менее 800×1200 px
							<br />
							<br />
							Формат изображений: jpg, png
							<br />
							Размер файла не должен превышать 5 мб
							{imageErrorState?.preview && (
								<span style={{ marginTop: "10px", marginBottom: "-10px", color: "#ED0A34" }}>
									{imageErrorState?.preview}
								</span>
							)}
						</>
					) : (
						<span>Отпустите изображение</span>
					)}
				</div>
				<div
					className="form-container-block-preview-image"
					style={{
						backgroundImage: `url('https://atips.io/${filmCrewState.URL_SD_PREVIEW}')`,
						backgroundSize: "auto 90%",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "50% 50%",
					}}></div>
				<input
					disabled={!checkUserWritePerm(userAccessState, "videos")}
					type="file"
					name="URL_SD_PREVIEW"
					accept="image/jpg, image/jpeg, image/png, image/webp"
					onChange={onPrewPhotoChange}
				/>
			</div>
			<div className="form-container-block-subtitle">Галерея</div>
			<div className="flex-block form-container-photo-gallery">
				<span>
					Рекомендуемый размер изображений не менее 1280×800 px. Формат изображений: jpg, png.
					Размер файла не должен превышать 5 мб
				</span>
				{imageErrorState?.gallery && (
					<span style={{ marginTop: "10px", color: "#ED0A34" }}>{imageErrorState?.gallery}</span>
				)}
				<div className="form-container-photo-gallery-items">
					{filmCrewState.photos?.length > 0 && filmCrewState.photos[0] !== null
						? filmCrewState.photos.map((elem, index) => (
								<div
									key={index}
									className="form-container-photo-gallery-item"
									style={{
										backgroundImage: `url('https://atips.io${elem}')`,
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "50% 50%",
									}}>
									<div
										className="remove-preview-btn"
										onClick={() =>
											checkUserWritePerm(userAccessState, "videos") &&
											handleRemoveGalleryPhoto(elem)
										}>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M2.70711 1.29289L2 0.585785L0.585785 2L1.29289 2.70711L4.58579 6L1.29289 9.29289L0.585785 10L2 11.4142L2.70711 10.7071L6 7.41421L9.29289 10.7071L10 11.4142L11.4142 10L10.7071 9.29289L7.41421 6L10.7071 2.70711L11.4142 2L10 0.585785L9.29289 1.29289L6 4.58579L2.70711 1.29289Z"
												fill="#212121"
											/>
										</svg>
									</div>
								</div>
						  ))
						: null}

					<div
						className={`form-container-photo-gallery-item empty ${
							loadingStatus.loading === 1 ? "loading" : ""
						}`}>
						<input
							disabled={!checkUserWritePerm(userAccessState, "videos")}
							type="file"
							name="galler"
							accept="image/jpg, image/jpeg, image/png, image/webp"
							onChange={onGalleryPhotoChange}
						/>
					</div>
				</div>
			</div>
			{checkUserWritePerm(userAccessState, "videos") ? (
				<div className="modal-buttons">
					<div className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отменить</span>
					</div>
					<div className="button action-button" onClick={() => handleCreateClick()}>
						<span>
							{!state ? "Создать" : "Сохранить"} карточку{" "}
							{filmCrewState.position_id === 1
								? "актёра"
								: filmCrewState.position_id === 2
								? "режиссера"
								: filmCrewState.position_id === 3
								? "сценариста"
								: filmCrewState.position_id === 4
								? "оператора"
								: filmCrewState.position_id === 5
								? "продюсера"
								: ""}
						</span>
					</div>
				</div>
			) : (
				<Cluster className="modal-buttons" style={{ marginTop: "24px" }}>
					<div></div>
					<div className="button action-button border-button" onClick={() => handleModal()}>
						<span>Вернуться</span>
					</div>
				</Cluster>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		uploadImage: state.uploadImage,
		uploadGalleryImage: state.uploadGalleryImage,
		loadingStatus: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	postFilmCrew,
	putFilmCrew,
	postUploadImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFilmCrew);
