import styled from "styled-components";

export const CancelButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 9px 20px;
	width: 115px;
	height: 40px;
	background: #ffffff;
	border: 2px solid #d1d8e1;
	cursor: pointer;
	border-radius: 80px;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #212121;
	&:hover {
		border: 2px solid #005bff;
	}
`;

export const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 9px 20px;
	width: 97px;
	height: 40px;
	border: none;
	background: #005bff;
	cursor: pointer;
	border-radius: 80px;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #ffffff;
`;

export const EmptyFallback = styled.div`
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	color: #94a0b1;
`;
