import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { putArea } from "../../../../Actions/PriceActions.js";
const ModalNewPrice = ({ area, putArea, loader }) => {
	const { handleModal } = useContext(ModalContext);
	const [newPriceState, setNewPriceState] = useState({
		price: "",
	});
	const [errorState, setErrorState] = useState(false);

	const handlePriceChange = (e) => {
		if (errorState) {
			setErrorState(false);
		}
		setNewPriceState({ ...newPriceState, price: e.target.value });
	};

	const handleSendPriceClick = () => {
		if (newPriceState.price <= 0) {
			setErrorState(true);
		} else {
			// const user = JSON.parse(localStorage.getItem("user"));
			putArea(area, newPriceState.price);
		}
	};
	return (
		<div className="modal-field add-new-price active">
			<div className="modal-field-title">Добавить новую цену</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Стоимость клика, ₽</div>
					<div className="input-field" style={{ width: "160px" }}>
						<input
							className={`${errorState ? "error-input" : ""}`}
							type="number"
							value={newPriceState.price}
							placeholder="0"
							onChange={handlePriceChange}
						/>
					</div>
				</div>
			</div>
			<div className="modal-buttons">
				<div className="button action-button border-button" onClick={() => handleModal()}>
					<span>Отменить</span>
				</div>
				<div
					className={`button action-button ${loader.loading ? "preloader" : ""}`}
					onClick={() => handleSendPriceClick()}
				>
					<span>Применить</span>
				</div>
			</div>
		</div>
	);
};

const mapStateTpProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	putArea,
};

export default connect(mapStateTpProps, mapDispatchToProps)(ModalNewPrice);
