import React, { useEffect, useState } from "react";
import UserStatisticInfoAccordionField from "./UserStatisticInfoAccordionField.jsx";
import { UserStatisticInfoField } from "./UserStatisticInfoField.jsx";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_MOVIEGOER_AGG, GET_SELLER_AGG } from "../../../../GraphQL/HomePage.js";

/**
 */
const UsersStatistic = ({ filtersState }) => {
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_MOVIEGOER_AGG, {
		variables: {},
	});
	const [getSellerStatistic, { data: sellerData }] = useLazyQuery(GET_SELLER_AGG, {
		variables: {},
	});
	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;
		getStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
		getSellerStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
	}, [filtersState]);

	return (
		<>
			<div className="home-table half">
				<div className="form-container-block-title">
					<b>Портреты зрителей</b>
				</div>
				<UserStatisticInfoAccordionField
					collection={data?.getMoviegoerAggItem?.countries || []}
					title="Страны и регионы"
				/>
				<UserStatisticInfoField
					collection={data?.getMoviegoerAggItem?.sex_info || []}
					title="Пол"
					color="blue"
				/>
				<UserStatisticInfoField
					collection={data?.getMoviegoerAggItem?.age_info || []}
					title="Возраст"
					color="pink"
				/>
			</div>
			<div className="home-table half">
				<div className="form-container-block-title">
					<b>Портреты рекламодателей</b>
				</div>
				<UserStatisticInfoAccordionField
					collection={sellerData?.getSellerAggItem?.countries || []}
					title="Страны и регионы"
				/>
				{/* <UserStatisticInfoField
			collection={sellerData?.getSellerAggItem?.sex_info || []}
			title="Пол"
			color="blue"
		  />
		  <UserStatisticInfoField
			collection={sellerData?.getSellerAggItem?.age_info || []}
			title="Возраст"
			color="pink"
		  /> */}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	// getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersStatistic);
