import { gql } from "@apollo/client";

export const GET_MAIN_OWNER_PROFIT = gql`
	query getStatisticPage($owner_id: Int!, $lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: $owner_id, click: 1, created_at: { gte: $gte, lte: $lte } }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			aggregation {
				total_sum
				income_profit
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;

export const GET_TIPS_OWNER_SHOW = gql`
	query getStatisticPage($owner_id: Int!, $lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: $owner_id, click: 0, created_at: { gte: $gte, lte: $lte } }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;
export const GET_TOP5_OWNER_STATISTIC = gql`
	query getEfficiencyItem($owner_id: Int!, $lte: DateTime, $gte: DateTime) {
		getEfficiencyItem(filter: { owner_id: $owner_id, created_at: { gte: $gte, lte: $lte } }) {
			best_products {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getSellersEfficiencyItem(
			filter: { owner_id: $owner_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_service_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
			best_product_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getTipsEfficiencyItem(filter: { owner_id: $owner_id, created_at: { gte: $gte, lte: $lte } }) {
			best_service_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
			best_products_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
		}
	}
`;

export const GET_MOVIEGOER_OWNER_AGG = gql`
	query getMoviegoerAggItem($owner_id: Int!, $lte: DateTime, $gte: DateTime) {
		getMoviegoerAggItem(filter: { owner_id: $owner_id, created_at: { gte: $gte, lte: $lte } }) {
			countries {
				percent
				total_count
				name
				cities {
					percent
					total_count
					name
				}
			}
			sex_info {
				percent
				total_count
				name
			}
			age_info {
				percent
				total_count
				name
			}
		}
	}
`;
