import React, { useContext, useEffect, useState } from "react";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts.js";
import { Tooltip } from "../../../../../ui-kit/tooltip/Tooltip.jsx";
import { GET_TOP5_SELLER_STATISTIC } from "../../../../../GraphQL/SellerStatistic.js";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { getFormatedNumberValue } from "../../../HomePage/scripts.js";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../../../../ui-kit/layouts/Stack.jsx";

const BestCategories = ({ sellerState, filtersState }) => {
	const [prodState, setProdState] = useState([]);
	const [servState, setServeState] = useState([]);
	const [getEfficiencyItem, { data, loading: tipsShowLoading }] = useLazyQuery(
		GET_TOP5_SELLER_STATISTIC,
		{
			variables: {},
		}
	);

	useEffect(() => {
		if (
			!sellerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;

		getEfficiencyItem({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				seller_id: sellerState.id,
			},
		});
	}, [sellerState, filtersState]);

	return (
		<>
			<div className="flex-wrapper">
				<StatisticBlockContainer className="content-wrapper">
					<div className="form-container-block-title">
						<b>Лучшие категории товаров</b>
					</div>
					<div className="list-table-header home-best-categories" style={{ marginTop: "34px" }}>
						<div className="list-table-header-element">
							<span>Название</span>
						</div>

						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Клики</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{!!data?.category?.best_products_cat.length &&
							data?.category?.best_products_cat.map((elem) => (
								<div className="list-table-item">
									<div className="list-table-item-element">
										{/* <img src={elem.URL_HD_IMAGE} alt="" /> */}
										<span>{elem.name}</span>
									</div>
									<div className="list-table-item-element">
										<span>{elem.views_count}</span>
									</div>
									<div className="list-table-item-element">
										<span>{elem.clicks_count}</span>
									</div>
									<div
										className={`list-table-item-element ${elem.percent > 0 ? "green" : "orange"}`}
									>
										<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
									</div>
									<Tooltip text={elem.name} />
								</div>
							))}
					</div>
				</StatisticBlockContainer>
				<StatisticBlockContainer className="content-wrapper">
					<div className="form-container-block-title">
						<b>Лучшие товары</b>
					</div>
					<div className="list-table-header home-best-products" style={{ marginTop: "34px" }}>
						<div className="list-table-header-element">
							<span>Название</span>
						</div>

						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Клики</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-products">
						{!!data?.product?.best_products?.length &&
							data?.product?.best_products.map((elem) => <ProductItem elem={elem} />)}
					</div>
				</StatisticBlockContainer>
			</div>
			<div className="flex-wrapper">
				<StatisticBlockContainer className="content-wrapper">
					<div className="form-container-block-title">
						<b>Лучшие категории услуг</b>
					</div>
					<div className="list-table-header home-best-categories" style={{ marginTop: "34px" }}>
						<div className="list-table-header-element">
							<span>Название</span>
						</div>

						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Клики</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{!!data?.category?.best_services_cat.length &&
							data?.category?.best_services_cat.map((elem) => (
								<div className="list-table-item">
									<div className="list-table-item-element">
										{/* <img src={elem.URL_HD_IMAGE} alt="" /> */}
										<span>{elem.name}</span>
									</div>
									<div className="list-table-item-element">
										<span>{elem.views_count}</span>
									</div>
									<div className="list-table-item-element">
										<span>{elem.clicks_count}</span>
									</div>
									<div
										className={`list-table-item-element ${elem.percent > 0 ? "green" : "orange"}`}
									>
										<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
									</div>
									<Tooltip text={elem.name} />
								</div>
							))}
					</div>
				</StatisticBlockContainer>
				<StatisticBlockContainer className="content-wrapper">
					<div className="form-container-block-title">
						<b>Лучшие услуги</b>
					</div>
					<div className="list-table-header home-best-products" style={{ marginTop: "34px" }}>
						<div className="list-table-header-element">
							<span>Название</span>
						</div>

						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Клики</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-products">
						{!!data?.product?.best_services?.length &&
							data?.product?.best_services.map((elem) => <ProductItem elem={elem} />)}
					</div>
				</StatisticBlockContainer>
			</div>
		</>
	);
};

export const ProductItem = ({ elem }) => {
	const [productState, setProductState] = useState();
	const dispatch = useDispatch();

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/product/${elem.product_id}`)
			.then((prod) => {
				setProductState(prod.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [elem]);

	return productState ? (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<img src={productState?.URL_HD_IMAGE} alt="" />
				<span>{elem?.name}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.views_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className={`list-table-item-element ${elem.percent > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.name} />
		</div>
	) : null;
};

export default BestCategories;
