import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { CategorySelect } from "../../filters/AtipsBrightboxesFiltersComponent";
import { CategoryModal } from "../../../../../common/category-modal/CategoryModal.jsx";
import { getCategyAttr } from "../../../../../Actions/CategoriesActions";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { DText14 } from "../../../../../ui-kit/typography/Typography";
import styled from "styled-components";
import TipGenderSelect from "./TipGenderSelect";
import TipAgeSelect from "./TipAgeSelect";
import { AttributeSelect } from "../../../../../common/attribute-select/AttributeSelect";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";
import { isArr } from "../../../scripts/utils";

const StyledLabel = styled(DText14)`
	color: #94a0b1;
`;

const BaseTipEdit = ({
	categoriesList,
	tipSettingsState,
	setTipSettingsState,
	sellerState,
	getCategyAttr,
	categoryAttr,
}) => {
	const { handleModal, modal } = useContext(ModalContext);
	const dispatch = useDispatch();
	const [categoriesState, setCategoriesState] = useState([]);
	const [attrState, setAttrState] = useState([]);
	const [categoryId, setCategoryId] = useState(null);
	const [categoryAttrs, setCategoryAttrs] = useState({});
	const [filteredAttrs, setFilteredAttrs] = useState([]);
	const [flag, setFlagState] = useState(true);

	useEffect(() => {
		setCategoriesState(categoriesList);
	}, [categoriesList]);

	useEffect(() => {
		setAttrState(categoryAttr);
	}, [categoryAttr]);

	useEffect(() => {
		getCategyAttr();
	}, []);

	useEffect(async () => {
		if (!categoryId) return;
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/categories/attributes/${categoryId}`)
			.then((attr) => {
				const array = attr?.data?.category?.predefined_attributes;
				let initialValue = {};
				if (array && isArr(array)) {
					initialValue = array.reduce((obj, item) => {
						return {
							...obj,
							[item["id"]]: item,
						};
					}, initialValue);
				}

				if (!flag) {
					const filtered = tipSettingsState.attributes_settable.filter((elem) =>
						[10, 11, 12, 13].includes(elem.frame_id)
					);
					setTipSettingsState({ ...tipSettingsState, attributes_settable: filtered });
				}
				setCategoryAttrs(initialValue);
				flag && setFlagState(false);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [categoryId]);

	useEffect(() => {
		if (tipSettingsState.category && tipSettingsState.category.id !== categoryId) {
			setCategoryId(tipSettingsState.category.id);
		}
	}, [tipSettingsState]);

	useEffect(() => {
		if (!attrState || !attrState.length) return [];
		const filteres = attrState.map((elem) => {
			const filterd = elem.attributes.filter((elem1) => elem1.id in categoryAttrs);
			return { ...elem, attributes: filterd };
		});
		const resultFilter = filteres.filter((elem) => elem.attributes.length);
		setFilteredAttrs(resultFilter);
	}, [categoryAttrs, attrState]);

	return (
		<Stack space="16px">
			{!sellerState && categoriesState.length > 0 ? (
				<>
					<Stack space="5px">
						<StyledLabel className="form-field-label">Категория</StyledLabel>

						<div className="input-field" style={{ position: "relative" }}>
							<CategorySelect
								onClick={() =>
									handleModal(
										<CategoryModal
											categories={categoriesState}
											handleCategoryClick={(newValue) =>
												setTipSettingsState({
													...tipSettingsState,
													category_id: newValue.id,
													category: newValue,
												})
											}
										/>
									)
								}
							>
								<span className="category-value">{tipSettingsState?.category?.title || "Все"}</span>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
										fill="#212121"
									></path>
								</svg>
							</CategorySelect>
							<button
								className={`clear-chosen-container ${
									!!tipSettingsState?.category_id > 0 ? "active" : ""
								}`}
								// style={{right: ""}}
								onClick={() =>
									setTipSettingsState({ ...tipSettingsState, category_id: null, category: null })
								}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
										fill="#212121"
									></path>
								</svg>
							</button>
						</div>
					</Stack>

					{tipSettingsState?.category?.title ? (
						<>
							<Stack space="5px" style={{ zIndex: 10 }}>
								<StyledLabel className="form-field-label">Пол</StyledLabel>

								<TipGenderSelect
									tipSettingsState={tipSettingsState}
									setTipSettingsState={setTipSettingsState}
								/>
							</Stack>
							<Stack space="5px" style={{ zIndex: 9 }}>
								<StyledLabel className="form-field-label">Возрастные категории</StyledLabel>
								<TipAgeSelect
									tipSettingsState={tipSettingsState}
									setTipSettingsState={setTipSettingsState}
								/>
							</Stack>
							<Stack space="5px" style={{ zIndex: 8 }}>
								<StyledLabel className="form-field-label">Атрибуты</StyledLabel>
								<AttributeSelect
									disabled={true}
									collection={filteredAttrs}
									parentCollection={filteredAttrs}
									selectedAttrs={tipSettingsState.attributes_settable}
									onSelect={(e) =>
										setTipSettingsState({ ...tipSettingsState, attributes_settable: e })
									}
								/>
							</Stack>
							{/* <Stack space="5px" style={{ zIndex: 7 }}>
								<StyledLabel className="form-field-label">Размер</StyledLabel>
								<TipSizeSelect
									tipSettingsState={tipSettingsState}
									setTipSettingsState={setTipSettingsState}
								/>
							</Stack> */}
						</>
					) : null}
				</>
			) : null}
		</Stack>
	);
};
const mapStateToProps = (state) => {
	return {
		categoriesList: state.categoriesList,
		categoryAttr: state.categoryAttr,
	};
};

const mapDispatchToProps = {
	getCategyAttr,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseTipEdit);
