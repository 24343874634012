import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import ModalBrightboxInfo from "../ModalBrightboxInfo/ModalBrightboxInfo.jsx";
import { formatStatisticDate } from "./formatDate";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
const StatisticContent = ({
	statisticList,
	activeColumnState,
	setStatisticOrderType,
	paramsState,
	setParamsState,
	isLoading,
	mainStatState,
	setMainStatState,
}) => {
	const { handleModal } = useContext(ModalContext);

	return mainStatState.length > 0 && !isLoading ? (
		<div className="list-table stat-list">
			{mainStatState.map((item, index) => (
				<Item
					item={item}
					key={index}
					activeColumnState={activeColumnState}
					setStatisticOrderType={setStatisticOrderType}
					paramsState={paramsState}
					setParamsState={setParamsState}
				/>
			))}
		</div>
	) : null;
};

const Item = memo(
	({ item, activeColumnState, setStatisticOrderType, paramsState, setParamsState }) => {
		const { handleModal } = useContext(ModalContext);
		const [viewerState, setViewerState] = useState(null);

		return (
			<div
				className="list-table-item action-button"
				onClick={() =>
					handleModal(
						<ModalBrightboxInfo
							itemState={item}
							paramsState={paramsState}
							setParamsState={setParamsState}
							setStatisticOrderType={setStatisticOrderType}
						/>
					)
				}
			>
				<div
					className={`list-table-item-element ${
						!activeColumnState.tips_id?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.tips_id.width }}
				>
					<span>{item.tips_id || "Нет значений"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.price?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.price.width }}
				>
					<span>{item.price ? item.price : "0"}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.percent?.active ? "hidden" : ""
					} non-sort`}
					style={{ width: activeColumnState.percent.width }}
				>
					<span>{item.percent ? item.percent : "0"}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.profit?.active ? "hidden" : ""
					} non-sort`}
					style={{ width: activeColumnState.profit.width }}
				>
					<span>{item.percent ? Math.round((item.price * item.percent) / 100) : item.price}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.platform?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.platform.width }}
				>
					<span>{item.platform || "Нет значений"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.seller?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.seller.width }}
				>
					<span>{item.seller || "Нет значений"}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.product?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.product.width }}
				>
					<span>{item.product}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.category?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.category.width }}
				>
					<span>{item.category || "Нет значения"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.video?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.video.width }}
				>
					<span>{item.video}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.blogger?.active ? "hidden" : ""
					} non-sort`}
					style={{ width: activeColumnState.blogger.width }}
				>
					<span>{item.blogger}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.genre?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.genre.width }}
				>
					<span>
						{Array.isArray(item.genre) && item.genre?.length > 0
							? item.genre.map((genre, index) =>
									index + 1 === item.genre.length ? `${genre}` : `${genre}, `
							  )
							: "Нет значений"}
					</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.sex?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.sex.width }}
				>
					<span>{item?.sex || "Нет значения"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.age?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.age.width }}
				>
					<span>{item?.age || "Нет значения"}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.country?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.country.width }}
				>
					<span>{item?.country || "Нет значения"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.city?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.city.width }}
				>
					<span>{item?.city || "Нет значения"}</span>
				</div>
				<div
					className={`list-table-item-element ${!activeColumnState.click?.active ? "hidden" : ""}`}
					style={{ width: activeColumnState.click.width }}
				>
					<span>{item.click == 1 ? "Клик" : "Показ"}</span>
				</div>
				<div
					className={`list-table-item-element ${
						!activeColumnState.create_date?.active ? "hidden" : ""
					}`}
					style={{ width: activeColumnState.create_date.width }}
				>
					{/* <span>{formatedDate(item.created_at)}</span> */}
					<span>{String(formatStatisticDate(new Date(item.create_date).getTime(), 3))}</span>
				</div>
			</div>
		);
	}
);

const mapStateToProps = (state) => {
	return {
		statisticList: state.statisticList,
		viewersList: state.viewersList.mapped,
	};
};

// const mapDispatchToProps = {
// 	getStatistic,
// };

export default connect(mapStateToProps, null)(StatisticContent);
