import React, { useContext } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { ChildDragHandle } from "../../../../universal/dragHandle/DragHandle.jsx";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import AddSeriesModal from "../AddSeriesModal.jsx";
import DeleteModal from "../DeleteModal.jsx";
import { useEffect } from "react";
import { CONTRUCTOR_URL } from "../../../../../Actions/consts.js";
import TokenService from "../../../../../services/token.service.js";
import { useUserAccessContext } from "../../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../../scripts/utils.js";

export const SerialSortableItem = sortableElement(
	({ value, filmState, setFilmState, seasonIndex }) => {
		const index = value.index;
		const { userAccessState } = useUserAccessContext();
		const elem = value.childElem;

		useEffect(() => {
			if (elem.order === null) {
				let seasonIndex = filmState.seasons.findIndex((el) => el.id === elem.season_id);
				let timeArray = filmState.seasons;
				timeArray[seasonIndex].childs[index] = {
					...elem,
					order: index + 1,
				};
				elem.order = index + 1;
				setFilmState({ ...filmState, seasons: timeArray });
			}
		}, []);

		const { handleModal } = useContext(ModalContext);
		return (
			<div className="form-container-block-season-episode">
				<div
					className="form-container-block-season-episode-title action-button"
					onClick={() =>
						handleModal(
							<AddSeriesModal
								elem={elem}
								filmState={filmState}
								userAccessState={userAccessState}
								setFilmState={setFilmState}
								seasonIndex={seasonIndex}
								serialIndex={index}
							/>
						)
					}
				>
					Эпизод {index + 1}, {elem.title}
				</div>
				{checkUserWritePerm(userAccessState, "videos") && (
					<div className="season-episode-control">
						<div
							className="season-episode-control-item action-button"
							onClick={() =>
								handleModal(
									<AddSeriesModal
										elem={elem}
										filmState={filmState}
										userAccessState={userAccessState}
										setFilmState={setFilmState}
										seasonIndex={seasonIndex}
										serialIndex={index}
									/>
								)
							}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_2602_5098)">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M17.7073 0.292928C17.5198 0.105377 17.2654 7.40135e-06 17.0002 3.89015e-10C16.735 -7.39219e-06 16.4806 0.105348 16.2931 0.292888L4.5858 12C4.21072 12.3751 4 12.8838 4 13.4142L4 18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H9.58578C10.1162 19 10.6249 18.7893 11 18.4142L22.7068 6.7071C23.0973 6.31659 23.0973 5.68346 22.7068 5.29293L17.7073 0.292928ZM6 13.4142L17.0001 2.41424L20.5855 5.99998L9.58578 17L6 17L6 13.4142ZM1 21H0L0 23H1H23H24V21H23H1Z"
										fill="#94A0B1"
									></path>
								</g>
								<defs>
									<clipPath id="clip0_2602_5098">
										<rect width="24" height="24" fill="white"></rect>
									</clipPath>
								</defs>
							</svg>
						</div>
						<a
							href={`${CONTRUCTOR_URL}?id=${
								elem.video_id
							}&token=${TokenService.getLocalAccessToken()}&movie_id=${filmState?.movie?.id}`}
							target="_blank"
						>
							<div className="season-episode-control-item action-button">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1 4C1 2.34315 2.34315 1 4 1L7 1L13.375 1L17 1L20 1C21.6569 1 23 2.34315 23 4V7V12V17V20C23 21.6569 21.6569 23 20 23H17H7H4C2.34315 23 1 21.6569 1 20L1 17L1 12L1 7L1 4ZM4 3C3.44772 3 3 3.44772 3 4L3 6H6V3H4ZM8 3V7V11L16 11V7V3H13.375L8 3ZM18 3V6H21V4C21 3.44772 20.5523 3 20 3H18ZM21 8H18V11H21V8ZM21 13H18V16H21V13ZM21 18H18V21H20C20.5523 21 21 20.5523 21 20V18ZM16 21V17V13L8 13V17V21H16ZM6 21V18H3L3 20C3 20.5523 3.44772 21 4 21H6ZM3 16H6V13H3V16ZM3 11H6L6 8H3V11Z"
										fill="#94A0B1"
									></path>
								</svg>
							</div>
						</a>
						<div
							className="season-episode-control-item action-button"
							onClick={() =>
								handleModal(
									<DeleteModal userAccessState={userAccessState} type="serial" elem={elem} />
								)
							}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
									fill="#94A0B1"
								></path>
							</svg>
						</div>
					</div>
				)}
				{checkUserWritePerm(userAccessState, "videos") && <ChildDragHandle />}
			</div>
		);
	}
);

export const SerialSortableContainer = sortableContainer(({ children }) => {
	return (
		<div className="form-container-block-season-episodes ui-sortable" style={{ overflowY: "auto" }}>
			{children}
		</div>
	);
});
