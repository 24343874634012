import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles/PaginationBlock.scss";

const PaginationBlock = ({ filterState, setFilterState, pagination }) => {
	const setPageRef = useRef(null);
	const [maxPageState, setMaxPageState] = useState(pagination.max_pages);
	const [pageNumberCount, setPageNumberCount] = useState([]);

	useEffect(() => {
		let arr = [];
		for (let index = 0; index < pagination.max_pages; index++) {
			arr.push(index);
		}
		setPageNumberCount(arr);
		setMaxPageState(pagination.max_pages);
	}, [pagination]);

	const setPageHandler = (event) => {
		const value = event.target.innerHTML;
		if (Number(value) >= 1 && Number(value) <= maxPageState) {
			setFilterState({ ...filterState, page: Number(value) });
		}
	};

	useEffect(() => {
		// вешаем ref на весь список и с помощью делегирования событий обрабатываем клики по цифрам
		if (setPageRef && setPageRef.current) {
			setPageRef.current.addEventListener("click", setPageHandler);
		}

		return () => {
			setPageRef.current?.removeEventListener("click", setPageHandler);
		};
	});

	const slidePageHandler = (action) => {
		// переключение по страницам с помощью кнопок "вперед, назад"
		if (!action && pagination.page >= 0) {
			setFilterState({ ...filterState, page: Number(pagination.page) - 1 });
		} else if (action) {
			setFilterState({ ...filterState, page: Number(pagination.page) + 1 });
		}
	};

	return (
		<footer className="section-pagination">
			<div className="pages-counts">
				<ul className="pages-list" ref={setPageRef}>
					<li className="pages-list__item button button_left">
						<button
							disabled={pagination.page == 1 ? true : false}
							className="button_left-arrow"
							onClick={() => slidePageHandler(false)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.4142 8.00015L14.7071 8.70726L11.4142 12.0002L14.7071 15.293L15.4142 16.0002L14 17.4144L13.2929 16.7073L9.29289 12.7073C8.90237 12.3167 8.90237 11.6836 9.29289 11.293L13.2929 7.29304L14 6.58594L15.4142 8.00015Z"
									fill="#212121"
								></path>
							</svg>
						</button>
					</li>
					{/* Данный вывод подточен только для тех случаев, когда кол-во страниц больше 2
                        не стал заморачиваться над логикой отображения страниц
                        TODO *временное решение*
                    */}
					{pageNumberCount.map((count, index) =>
						(pagination.page - count >= 0 && pagination.page - count <= 2) ||
						(count - pagination.page >= 0 && count - pagination.page <= 2) ||
						count == 0 ||
						count + 1 === pagination.max_pages ||
						count == pageNumberCount.length ? (
							<li
								className={`pages-list__item ${pagination.page == count + 1 ? "current" : ""}`}
								key={index}
							>
								{Number(count + 1)}
							</li>
						) : pagination.page - count == 3 || count - pagination.page == 3 ? (
							<li className={`pages-list__item `} key={index}>
								...
							</li>
						) : null
					)}
					<li className="pages-list__item button button_right">
						<button
							disabled={pagination.page == maxPageState ? true : false}
							className="button_right-arrow"
							onClick={() => slidePageHandler(true)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.58569 8.00015L9.2928 8.70726L12.5857 12.0002L9.2928 15.293L8.58569 16.0002L9.99991 17.4144L10.707 16.7073L14.707 12.7073C15.0975 12.3167 15.0975 11.6836 14.707 11.293L10.707 7.29304L9.99991 6.58594L8.58569 8.00015Z"
									fill="#212121"
								></path>
							</svg>
						</button>
					</li>
				</ul>
			</div>
		</footer>
	);
};

export default connect(null, null)(PaginationBlock);
