import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { handleToggleOpenClick } from "../../../scripts/PersonalAreaScripts";
import Select from "react-select";
import { customStyles } from "../../../static";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";

const SideAgesFilters = ({ sideFilters, setSideFilters }) => {
  const [ageState, setAgeState] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // обнуление
    if (sideFilters.age_id === null) {
      setSelectedValues(null);
    }
  }, [sideFilters]);

  useEffect(() => {
    selectedValues &&
      setSideFilters({ ...sideFilters, age_id: selectedValues?.id || null });
  }, [selectedValues]);

  useEffect(async () => {
    dispatch({
      type: "SHOW_MAIN_LOADER",
    });
    await axios
      .get(`${baseUrl}/frame/10`)
      .then((age) => {
        setAgeState(age.data.attributes);
      })
      .finally(() => {
        dispatch({
          type: "HIDE_MAIN_LOADER",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="statistics-filter-items-block">
      <div
        className="statistics-filter-items-block-title"
        onClick={(e) => handleToggleOpenClick(e)}
      >
        Возраст
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z"
            fill="#212121"
          ></path>
        </svg>
      </div>
      <div className="statistics-filter-items-block-subitems">
        <div className="flex-block flex-start" style={{ zIndex: 4 }}>
          <div className="form-field" style={{ width: "100%" }}>
            <div className="input-field">
              <Select
                name="seller"
                value={selectedValues}
                options={ageState}
                styles={customStyles}
                onChange={(newValue) => setSelectedValues(newValue)}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                placeholder="Все"
                isSearchable={true}
                className="search-wrapper side-filters_city"
                classNamePrefix="select"
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? "Загрузка" : "Нет значений"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => {
// 	return {
// 		genresList: state.videoGenresList,
// 	};
// };

// const mapDispatchToProps = {
// getCategories,
// };

export default connect(null, null)(SideAgesFilters);
