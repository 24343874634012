export const getYearList = () => {
	var now = new Date();
	const arr = [];
	for (let i = 1980; i <= now.getFullYear(); i++) {
		arr.unshift(Number(i));
	}
	return arr;
};

export const formatDate = (value, hour = 0) => {
	const currentDate = new Date(value);
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1;
	const day = currentDate.getDate();
	const hours = (currentDate.getHours() + hour) % 24;
	const minutes = currentDate.getMinutes();
	return `${day}.${String(month).length === 1 ? `0${month}` : month}.${year}, ${
		hours < 10 ? "0" + hours : hours
	}:${minutes < 10 ? "0" + minutes : minutes}`;
};

export const onSelectItem = (selectedList, selectedItem, state, setState, key) => {
	if (key === "rating") {
		setState({ ...state, movie_rating: selectedItem });
		return;
	} else if (key === "owner_id") {
		setState({ ...state, owner_id: selectedItem });
		return;
	} else {
		setState({ ...state, [key]: selectedList });
	}
};

export const singleSearchSelectItem = (selectedList, selectedItem, state, setState, key, ref) => {
	setState({ ...state, selectedValue: selectedItem });
	ref.current.resetSelectedValues();
	return;
};

export const onSelectItemModal = (
	selectedList,
	selectedItem,
	state,
	setState,
	type,
	handleModal
) => {
	let characters = state.characters;
	const currentValue = characters[type - 1][type];
	currentValue.push(selectedItem);
	characters[type - 1][type] = currentValue;
	setState({ ...state, characters: characters });
	handleModal();
};

export const onRemoveItem = (selectedList, selectedItem, state, setState, key) => {
	setState({ ...state, [key]: selectedList });
};

export const handleClearSelectedClick = (state, setState, key, ref) => {
	setState({ ...state, [key]: "" });
	ref.current.resetSelectedValues();
};

export const onInputTextAreaChange = (evt, objName, state, setState) => {
	// evt - event, objName - значение поля, следующего изменить (предусмотрена вложенность, два значения передаются черед запятую)
	// state, setstate - изменение самого состояния
	const value = evt.target.value; // присваимваем значение инпута;
	const arr = objName.split(",");
	if (arr?.length === 1) {
		setState({ ...state, [arr[0]]: value });
	} else if (arr?.length === 2) {
		setState({ ...state, [arr[0]]: { ...state[arr[0]], [arr[1]]: value } });
	}
};

export const handleRemovePeopleClick = (id, index, position_id, state, setState) => {
	let characters = state?.characters;
	const filteredState = characters[index][position_id]?.filter((i) => i.id !== id);
	characters[index][position_id] = filteredState;
	setState({ ...state, characters: characters });
	// setState({ ...state, [type]: state[type].filter((i) => i.id !== id) });
};
export const takeId = (elem) => {
	let iter = [];
	elem.map((item) => {
		iter.push(item?.id);
	});
	return iter;
};

const resolveSeasons = (arr) => {
	if (arr.length === 0) return;
	let obj = {};
	for (let index = 0; index < arr.length; index++) {
		const seriesArray = [];
		for (let i = 0; i < arr[index].childs?.length; i++) {
			seriesArray.push(arr[index].childs[i].id);
		}
		obj[index] = {
			arr: seriesArray,
			key: arr[index].id,
		};
	}
	return obj;
};

export const serializePhotos = (arr) => {
	if (!arr?.length) return [];
	else {
		const newArr = arr.map((elem) => elem.URL_HD_PREVIEW);
		return newArr;
	}
};

export const serializeCharacters = (arr) => {
	if (!arr?.length) {
		return [
			{
				1: [],
			},
			{
				2: [],
			},
			{
				3: [],
			},
			{
				4: [],
			},
			{
				5: [],
			},
		];
	} else
		return [
			{
				1: arr.filter((elem) => elem.position_id == 1),
			},
			{
				2: arr.filter((elem) => elem.position_id == 2),
			},
			{
				3: arr.filter((elem) => elem.position_id == 3),
			},
			{
				4: arr.filter((elem) => elem.position_id == 4),
			},
			{
				5: arr.filter((elem) => elem.position_id == 5),
			},
		];
};

export const parseMovies = (elem) => {
	const obg = {
		// ...elem,
		title: elem.title?.length > 0 ? elem.title : "",
		slug: elem.slug?.length > 0 ? elem.slug : "",
		movie_format_id: elem.movie_format?.id,
		duration: elem.duration?.length > 0 || elem.duration === null ? elem.duration : "",
		year: elem.year !== null && Number(elem.year) > 0 ? Number(elem.year) : 0,
		rating_id: elem.movie_rating !== null && elem?.movie_rating?.id ? elem.movie_rating.id : 0,
		owner_id: elem.owner_id !== null && elem?.owner_id ? elem.owner_id : 1,
		photos: elem.photos !== null && elem.photos?.length > 0 ? elem.photos : [],
		rate: elem.rate !== null ? elem.rate : 0,
		published: elem.published,
		genres: elem.genres !== null && elem.genres?.length > 0 ? takeId(elem.genres) : [],
		countries: elem.countries !== null && elem.countries?.length > 0 ? takeId(elem.countries) : [],
		characters_ids: [
			...takeId(elem.characters[0]?.[1]),
			...takeId(elem.characters[1]?.[2]),
			...takeId(elem.characters[2]?.[3]),
			...takeId(elem.characters[3]?.[4]),
			...takeId(elem.characters[4]?.[5]),
		],
		characters: [
			{
				1: takeId(elem.characters[0]?.[1]),
			},
			{
				2: takeId(elem.characters[1]?.[2]),
			},
			{
				3: takeId(elem.characters[2]?.[3]),
			},
			{
				4: takeId(elem.characters[3]?.[4]),
			},
			{
				5: takeId(elem.characters[4]?.[5]),
			},
		],
		parent_id: elem.parent_id,
		hashtags: elem.hashtags !== null && elem.hashtags?.length > 0 ? takeId(elem.hashtags) : [],
		URL_SD_HORIZONTAL_PREVIEW:
			elem.URL_SD_HORIZONTAL_PREVIEW?.length > 0 ? elem.URL_SD_HORIZONTAL_PREVIEW : "",
		URL_SD_VERTICAL_PREVIEW:
			elem.URL_SD_VERTICAL_PREVIEW?.length > 0 ? elem.URL_SD_VERTICAL_PREVIEW : "",
		URL_SD_trailer: elem.URL_SD_trailer,
		URL_HD_trailer: elem.URL_HD_trailer,
		URL_FULL_HD_trailer: elem.URL_FULL_HD_trailer,
		short_description: elem.short_description?.length > 0 ? elem.short_description : "",
		detailed_description: elem.detailed_description?.length > 0 ? elem.detailed_description : "",
		politics:
			elem.politics?.length > 0
				? typeof elem.politics[0] === "object"
					? [elem.politics[0].id]
					: [elem.politics[0]]
				: [1],
		// childs: null,
		// sortable: elem.childs?.length !== 0 ? resolveSeasons(elem.childs) : null,
	};
	for (let key in obg) {
		if (obg[key] === null) {
			delete obg[key];
		}
	}
	return obg;
};

export const parseToUnbelivableSeries = (elem) => {
	const takeId = (elem) => {
		let iter = [];
		elem.map((item) => {
			iter.push(item?.id);
		});
		return iter;
	};
	const obg = {
		title: elem.title?.length > 0 ? elem.title : null,
		title: elem.slug?.length > 0 ? elem.slug : null,
		format: 58,
		videoDuration:
			elem.videoDuration?.length > 0 || elem.videoDuration === null ? elem.videoDuration : null,
		sd: elem.sd !== null && elem.sd?.length > 0 ? elem.sd : null,
		hd: elem.hd !== null && elem.hdLink?.length > 0 ? elem.hd : null,
		fhd: elem.fhd !== null && elem.fhdLink?.length > 0 ? elem.fhd : null,
		shortText: elem.shortText !== null && elem.shortText?.length > 0 ? elem.shortText : null,
		fullText: elem.fullText !== null && elem.fullText?.length > 0 ? elem.fullText : null,
		gallery: elem.gallery !== null && elem.gallery?.length > 0 ? elem.gallery : null,
		childs: null,
		sort: elem.sort?.length > 0 && elem[sort] !== null ? elem[sort] : null,
	};
	for (let key in obg) {
		if (obg[key] === null) {
			delete obg[key];
		}
	}
	return obg;
};

export const openInNewTab = (url) => {
	const newWindow = window.open(url, "_blank", "noopener,noreferrer");
	if (newWindow) newWindow.opener = null;
};

export const formatTimeCode = (time) => {
	if (time < 3600) return new Date(time * 1000).toISOString().slice(14, 19);
	else return new Date(time * 1000).toISOString().slice(11, 19);
};

// парсинг муви блогеров
export const parseBloggerMovie = (elem) => {
	const obg = {
		// ...elem,
		title: elem.title?.length > 0 ? elem.title : "",
		slug: elem.slug?.length > 0 ? elem.slug : "",
		movie_format_id: elem.movie_format?.id,
		duration: elem.duration?.length > 0 || elem.duration === null ? elem.duration : "",
		year: elem.year !== null && Number(elem.year) > 0 ? Number(elem.year) : 0,
		rating_id: elem.movie_rating !== null && elem?.movie_rating?.id ? elem.movie_rating.id : 0,
		photos: elem.photos !== null && elem.photos?.length > 0 ? elem.photos : [],
		rate: elem.rate !== null ? elem.rate : 0,
		published: elem.published,
		genres: elem.genres !== null && elem.genres?.length > 0 ? takeId(elem.genres) : [],
		countries: elem.countries !== null && elem.countries?.length > 0 ? takeId(elem.countries) : [],
		parent_id: elem.parent_id,
		hashtags: elem.hashtags !== null && elem.hashtags?.length > 0 ? takeId(elem.hashtags) : [],
		URL_SD_HORIZONTAL_PREVIEW:
			elem.URL_SD_HORIZONTAL_PREVIEW?.length > 0 ? elem.URL_SD_HORIZONTAL_PREVIEW : "",
		URL_SD_VERTICAL_PREVIEW:
			elem.URL_SD_VERTICAL_PREVIEW?.length > 0 ? elem.URL_SD_VERTICAL_PREVIEW : "",
		URL_SD_trailer: elem.URL_SD_trailer,
		URL_HD_trailer: elem.URL_HD_trailer,
		URL_FULL_HD_trailer: elem.URL_FULL_HD_trailer,
		short_description: elem.short_description?.length > 0 ? elem.short_description : "",
		detailed_description: elem.detailed_description?.length > 0 ? elem.detailed_description : "",
		politics:
			elem.politics?.length > 0
				? typeof elem.politics[0] === "object"
					? [elem.politics[0].id]
					: [elem.politics[0]]
				: [1],
		// childs: null,
		// sortable: elem.childs?.length !== 0 ? resolveSeasons(elem.childs) : null,
	};
	for (let key in obg) {
		if (obg[key] === null) {
			delete obg[key];
		}
	}
	return obg;
};
