export const initialSideFiltersState = {
	category: null,
	genres: null,
	gender: null,
	age_id: null,
	country: null,
	region: null,
	is_show: null,
	is_click: null,
	video_id: null,
	owner: null,
};
