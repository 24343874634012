import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DynamicSelect } from "../../../../../ui-kit/select/DynamicSelect";
import styled from "styled-components";
import { DText14, DText15 } from "../../../../../ui-kit/typography/Typography";

const Wrapper = styled.div`
	z-index: 1;
	.dynamic-select-selected-item {
		background: transparent;
		padding: 0;
	}
`;
/**
 *
 * type: 1 - Видео
 * type: 2 - Сезон
 * type: 3 - Movie
 */
const SelectStatisticEpisodes = ({ seasons, setSideVideoStaticState, mainVideoState }) => {
	const [episodesState, setEpisodesState] = useState({
		list: [],
		currentValue: null,
	});
	useEffect(() => {
		if (!seasons?.length) {
			setSideVideoStaticState({
				id: mainVideoState.id,
				type: 3,
			});
			return;
		}
		const newArray = [
			{
				title: "Все эпизоды",
				id: mainVideoState.id,
				type: 3,
			},
		];
		seasons.map((season, index) => {
			newArray.push({ ...season, title: `Сезон ${index + 1}, ${season.title}`, type: 2 });
			if (season?.episodes.length > 0) {
				season.episodes.map((episod, index) =>
					newArray.push({
						...episod,
						title: `Эпизод ${index + 1}, ${episod.title}`,
						type: 2,
					})
				);
			}
		});
		setEpisodesState({
			...episodesState,
			list: newArray,
			currentValue: {
				title: "Все эпизоды",
				id: mainVideoState.id,
				type: 3,
			},
		});
	}, [mainVideoState]);

	useEffect(() => {
		if (!!episodesState?.currentValue?.id) setSideVideoStaticState(episodesState.currentValue);
	}, [episodesState]);

	return seasons?.length > 0 ? (
		<Wrapper>
			<DynamicSelect
				maxWidth="168px"
				minWidth="142px"
				collection={episodesState.list}
				option={StatusesOption}
				arrowType="custom"
				selected={episodesState.currentValue}
				onSelect={(e) => setEpisodesState({ ...episodesState, currentValue: e })}
			/>
		</Wrapper>
	) : null;
};

const StyledDtext15 = styled(DText15)`
	overflow: hidden;
	width: 100%;
	max-width: calc(168px - 24px);
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StatusesOption = ({ title }) => {
	return <StyledDtext15>{title}</StyledDtext15>;
};

export default connect(null, null)(SelectStatisticEpisodes);
