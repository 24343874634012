import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import "./PoliticsPage.scss";
import EditPoliticsComponent from "./EditPoliticsComponent.jsx";
import { deletePolitic, getPolitics, getPoliticsLocation } from "../../../Actions/PoliticsAction";
import UniversalDeleteModal from "../../universal/UniversalDeleteModal.jsx";
import styled from "styled-components";
import { useUserAccessContext } from "../context/UserAccessContext.js";
import { checkUserWritePerm } from "../scripts/utils.js";

export const CountriesTipWrapper = styled.div`
	.help-tip {
		background: #fff;
		border-radius: 8px;
		bottom: calc(100% + 12px);
		display: none;
		width: 400px;
		filter: drop-shadow(8px 8px 28px rgba(46, 69, 102, 0.2));
		left: calc(50% + 200px);
		padding: 16px;
		pointer-events: none;
		position: absolute;
		transform: translateX(calc(-100% + 12px));
		white-space: normal;
		span {
			white-space: normal !important;
			font-size: 13px !important;
			line-height: 20px !important;
			overflow: visible !important;
			position: relative !important;
			/* text-overflow: unset !important; */
			width: auto !important;
		}
		img {
			left: calc(50% - 32px);
			position: absolute;
			top: 100%;
		}
	}
	&:hover {
		.help-tip {
			align-items: center;
			justify-content: center;
			display: flex;
		}
	}
`;

const PoliticsPage = ({
	title,
	politicsList,
	getPolitics,
	deletePolitic,
	politicsLocation,
	getPoliticsLocation,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [politicsCollectionState, setPoliticsCollectionState] = useState([]);
	const { userAccessState } = useUserAccessContext();
	const [editMenuState, setEditMenuState] = useState({
		id: "",
	});
	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		if (politicsList.length === 0) {
			getPolitics();
		}
		if (politicsLocation.length === 0) {
			getPoliticsLocation(5);
		}
	}, []);

	useEffect(() => {
		setPoliticsCollectionState(politicsList);
	}, [politicsList]);

	return (
		<section className="personal-area-item">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Политика<sup>{politicsCollectionState.length}</sup>
				</h1>

				<button
					className="button_add-category"
					disabled={!checkUserWritePerm(userAccessState, "politics")}
					onClick={() => handleModal(<EditPoliticsComponent />)}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить новую политику
				</button>
			</header>
			{politicsCollectionState.length > 0 ? (
				<div className="politics-content-wrapper content-wrapper">
					<div className="list-table-header video-list">
						<div className="list-table-header-element non-sort" style={{ width: "53.4%" }}>
							<span>Название</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "53.5%" }}>
							<span>Страны</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "18.5%" }}>
							<span>Доступ к видео</span>
						</div>
						{checkUserWritePerm(userAccessState, "politics") ? (
							<div className="list-table-header-element" style={{ width: "5.1%" }}>
								<div style={{ width: "40px" }}></div>
							</div>
						) : null}
					</div>
					<div className="list-table video-list">
						{politicsCollectionState.map((politic) => (
							<div
								className={`list-table-item ${
									!checkUserWritePerm(userAccessState, "politics") ? "read-only" : ""
								}`}
								key={politic.id}
							>
								<div
									className="list-table-item-element"
									style={{ width: "53.4%" }}
									onClick={() =>
										checkUserWritePerm(userAccessState, "politics") &&
										handleModal(<EditPoliticsComponent data={politic} />)
									}
								>
									<span>{politic?.title}</span>
								</div>
								<CountriesTipWrapper
									className="list-table-item-element"
									style={{ width: "53.5%" }}
									onClick={() =>
										checkUserWritePerm(userAccessState, "politics") &&
										handleModal(<EditPoliticsComponent data={politic} />)
									}
								>
									<div className="help-tip">
										<span>
											{!politic?.locations_settable?.length
												? "Весь мир"
												: politic?.locations_settable.map((loc, index) =>
														index + 1 !== politic?.locations_settable.length
															? `${loc.title}, `
															: `${loc.title}`
												  )}
										</span>
										<img src="images/icons/ui/arrow.svg" alt="" />
									</div>
									<span>
										{!politic?.locations_settable?.length
											? "Весь мир"
											: politic?.locations_settable.map((loc, index) =>
													index + 1 !== politic?.locations_settable.length
														? `${loc.title}, `
														: `${loc.title}`
											  )}
									</span>
								</CountriesTipWrapper>
								<div
									className="list-table-item-element"
									style={{ width: "18.5%" }}
									onClick={() =>
										checkUserWritePerm(userAccessState, "politics") &&
										handleModal(<EditPoliticsComponent data={politic} />)
									}
								>
									<span>{politic?.available === false ? "Запрещено" : "Разрешено"}</span>
								</div>
								{checkUserWritePerm(userAccessState, "politics") ? (
									<div className="list-table-item-element" style={{ width: "5.1%" }}>
										{/* TODO: вынести хинт в отдельный компонент (модальное окно),
                                который будет иметь backdor, с обработкой закрытия при клике в любое место */}
										<div
											className={`list-table-item-submenu action-button ${true ? "active" : ""}`}
											tabIndex="0"
											onFocus={() => null}
											onClick={() =>
												setEditMenuState({
													...editMenuState,
													id: editMenuState.id > 0 ? "" : politic.id,
												})
											}
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M11.5 7C10.6716 7 10 6.32843 10 5.5C10 4.67157 10.6716 4 11.5 4C12.3284 4 13 4.67157 13 5.5C13 6.32843 12.3284 7 11.5 7ZM11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5C13 13.3284 12.3284 14 11.5 14ZM10 19.5C10 20.3284 10.6716 21 11.5 21C12.3284 21 13 20.3284 13 19.5C13 18.6716 12.3284 18 11.5 18C10.6716 18 10 18.6716 10 19.5Z"
													fill="#94A0B1"
												/>
											</svg>
											<div
												className={`list-table-item-submenu ${
													politic.id === editMenuState.id ? "active" : ""
												}`}
											>
												<div
													className="list-table-item-submenu-item"
													onClick={() => handleModal(<EditPoliticsComponent data={politic} />)}
												>
													Изменить политику
												</div>
												<div
													className="list-table-item-submenu-item"
													onClick={() =>
														handleModal(
															<UniversalDeleteModal
																title={`политику`}
																deleteFunc={deletePolitic}
																id={politic.id}
																goBack={false}
															/>
														)
													}
												>
													Удалить
												</div>
											</div>
										</div>
									</div>
								) : null}
							</div>
						))}
					</div>
				</div>
			) : null}
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		politicsList: state.politicsList,
		politicsLocation: state.politicsLocation,
	};
};

const mapDispatchToProps = {
	getPolitics,
	getPoliticsLocation,
	deletePolitic,
};

export default connect(mapStateToProps, mapDispatchToProps)(PoliticsPage);
