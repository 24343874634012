import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { H5Text } from "../../../../../ui-kit/typography/Typography";
import { Input } from "../../../../../ui-kit/input/Input";
import { Button } from "../../../../../ui-kit/button/Button";
import { initialUser } from "../initial";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";

const AddOwnerStep = ({ userState = initialUser, setUserState, setActive }) => {
	const handleInputChange = (e, key) => {
		e.preventDefault();
		const value = e.target.value;
		setUserState({
			...userState,
			[key]: value,
		});
	};

	return (
		<Stack space="24px">
			<Stack space="16px">
				<H5Text>Аккаунт</H5Text>
				<Input
					label="Email*"
					type="text"
					name="mail"
					value={userState?.email}
					onChange={(e) => handleInputChange(e, "email")}
					placeholder="example@atips.ru"
				/>
			</Stack>
			<Cluster justify="space-between" style={{ marginTop: "32px" }}>
				<Button size="big" variant="secondary" onClick={() => setActive(1)} text="Назад" />
				<Button
					size="big"
					disabled={!userState.email.length}
					variant="primary"
					onClick={() => setActive(3)}
					text="Продолжить"
				/>
			</Cluster>
		</Stack>
	);
};

// const mapStateToProps = (state) => {
//   return {
//     login: state.login,
//   };
// };

// const mapDispatchToProps = {
//   postLogin,
// };

export default connect(null, null)(AddOwnerStep);
