import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivatePersonalRoute = ({ state, keyVal }) => {
	return !!state[keyVal] && state[keyVal] > 0 ? <Outlet /> : <Navigate to="/" />;
};

const PrivateVideosUsersRoute = ({ status, name }) => {
	if (name === "videos") {
		return status === 2 ? <Outlet /> : <Navigate to="/videos" />;
	} else if (name === "users") {
		return status === 2 ? <Outlet /> : <Navigate to="/users" />;
	}
};

export { PrivatePersonalRoute, PrivateVideosUsersRoute };
