import axios from "axios";
import { baseUrl, marketUrl } from "./consts";
export const getSellers = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if (!arg?.limit) {
		arg = { ...arg, limit: 1000 };
	}
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/seller`, {
				params: {
					...arg,
				},
			})
			.then((sellers) => {
				dispatch({
					type: "GET_SELLERS",
					sellers,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCurrentSeller = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/sellers/${id}`)
			.then((seller) => {
				dispatch({
					type: "GET_CURRENT_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getReviewExternalSellerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?size=1&page=1&application_status=not_accepted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_REVIEW_EXTERNAL_SELLER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getDeletedExternalSellerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?size=1&page=1&application_status=deleted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_DELETED_EXTERNAL_SELLER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getBlockedExternalSellerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?size=1&page=1&application_status=rejected`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_BLOCKED_EXTERNAL_SELLER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getActiveExternalSellerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?size=1&page=1&application_status=accepted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ACTIVE_EXTERNAL_SELLER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllReviewExternalSeller = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?application_status=not_accepted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_REVIEW_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getAllBlockedExternalSeller = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?application_status=rejected&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_BLOCKED_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getAllDeletedExternalSeller = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?application_status=deleted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_DELETED_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllActiveExternalSeller = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;
	// if (!("order_by" in arg)) arg = { ...arg, order_by: "id" };

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?application_status=accepted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_ACTIVE_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getExternalSeller = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users/${id}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllExternalSellerBalance = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users/stats/total-balance`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_EXTERNAL_SELLER_BALANCE",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const patchExternalSeller = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "PATCH",
				service: 3,
				endpoint: `/api/users/${id}`,
				data_json: data,
			})
			.then((seller) => {
				dispatch({
					type: "PATCH_EXTERNAL_SELLER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getSellerBalance = (id, page, limit) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/balance/balance-transactions?user_id=${id}&page=${page}&size=${limit}&type=requested`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_SELLER_BALANCE",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getSellerDocuments = (id, offset, limit) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/selleracts`, {
				params: {
					offset,
					limit,
					external_id: id,
				},
			})
			.then((seller) => {
				dispatch({
					type: "GET_SELLER_DOCUMENTS",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
