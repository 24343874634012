import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../../../Actions/consts";

const formatGraphDate = (date, type) => {
	if (type === "year" || type === "full") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "month") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
				day: "numeric",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "week") {
		return new Date(date)
			.toLocaleString("ru", {
				day: "numeric",
				weekday: "short",
				month: "long",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "day") {
		return new Date(date)
			.toLocaleString("ru", {
				month: "short",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})
			.split(" ", 3)
			.join(" ");
	}
};

export const fetchCurrentVideoViewByPeriod = async ({ vars, dispatch, period }) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-sessions-by-period?${queryString}`,
			data: "",
		})
		.then((views) => {
			const data = views.data.map((elem) => {
				return { ...elem, date: formatGraphDate(elem.date, period) };
			});
			dispatch({
				type: "GET_VIDEO_VIEWS_GRAPH",
				views: data,
				period,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
