import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import { baseUrl } from "../../../Actions/consts";
import { SendResumeModal } from "../StatisticPage/SendResumeModal";
import styled from "styled-components";
import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import { ResultModalOrd } from "./ResultModalOrd";

const Backdrop = styled.div`
	position: fixed;
	left: 0;
	top: 0%;
	height: 100vh;
	width: 100vw;
	z-index: 1;
`;

const initialCalendarState = {
	date_to: null,
	date_from: null,
};

export const SendOrdModal = () => {
	const [emailState, setEmailState] = useState("");
	const calendarRef = useRef(null);
	const [loading, setLoadingState] = useState(false);
	const { handleModal } = useContext(ModalContext);
	const [calendarState, setCalendarState] = useState(initialCalendarState);
	const [ddState, setDdState] = useState(false);
	const handleInputChange = (evt) => {
		// обработчик заполнение инпутов
		const val = evt.target.value; // присваиваем значение инпута
	};

	const handleToggleCalendar = (event) => {
		event.stopPropagation();
		if (!!calendarRef && event.target === calendarRef.current) setDdState(true);
	};

	const removeAllDate = (e) => {
		e.stopPropagation();
		setCalendarState(initialCalendarState);
	};

	const handleAddClick = async () => {
		setLoadingState(true);

		const startTimeStamp = new Date(calendarState.date_from);
		const endTimeStamp = new Date(calendarState.date_to);

		startTimeStamp.setHours(0);
		endTimeStamp.setHours(23);
		endTimeStamp.setMinutes(59);
		await axios
			.get(`${baseUrl}/domain/sellers/exportord`, {
				params: {
					date_from: startTimeStamp,
					date_to: endTimeStamp
				},
			})
			.then(() => {
				handleModal(<ResultModalOrd />);
			})
			.finally(() => {
				setLoadingState(false);
			})
			.catch((e) => {
				console.error(e);
			});
	};

	const onDateChange = (dates, e) => {
		e.stopPropagation();
		const [start, end] = dates;
		setCalendarState({
			date_from: start,
			date_to: end,
		});
		if (end) {
			setDdState(false);
		}
	};

	return (
		<>
			<div className="add-category-wrapper">
				<div className="modal-field-title">Отправить акты в ОРД</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"></path>
					</svg>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Дата</div>

						<div className={`input-field date-range-field `}>
							<div
								id="statistics-range-visible"
								className={`statistics-range-visible ${ddState ? "active" : ""}`}
								onClick={(e) => handleToggleCalendar(e)}
								tabIndex="0">
								<div className="calendar-icon" ref={calendarRef}></div>
								<input
									readOnly
									placeholder="Выберите даты"
									value={
										calendarState.date_from > 0
											? `${
													calendarState.date_from > 0
														? new Date(calendarState.date_from).toLocaleDateString()
														: ""
											  }${
													!!calendarState.date_to > 0
														? ` - ${new Date(calendarState.date_to).toLocaleDateString()}`
														: ""
											  } `
											: ""
									}
								/>
								<div
									className={`statistics-range-calendar-clear ${
										!!calendarState.date_from ? "active" : ""
									}`}
									onClick={(e) => removeAllDate(e)}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.70701 7.2928L7.99991 6.58569L6.58569 7.99991L7.2928 8.70701L10.5857 11.9999L7.2928 15.2928L6.58569 15.9999L7.99991 17.4141L8.70701 16.707L11.9999 13.4141L15.2928 16.707L15.9999 17.4141L17.4141 15.9999L16.707 15.2928L13.4141 11.9999L16.707 8.70701L17.4141 7.99991L15.9999 6.58569L15.2928 7.2928L11.9999 10.5857L8.70701 7.2928Z"
											fill="#212121"></path>
									</svg>
								</div>
								<Backdrop className="backdrop" onClick={() => setDdState(false)} />
								<div className={`date-picker-wrapper `}>
									<DatePicker
										onChange={onDateChange}
										startDate={calendarState.date_from}
										endDate={calendarState.date_to}
										// onBlur={handleOnBlur}
										dateFormat="dd.MM.yyyy"
										selectsRange
										locale={ru}
										inline
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal-buttons delete">
					<button className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отменить</span>
					</button>
					<button
						disabled={!calendarState.date_from || !calendarState.date_to}
						className={`button action-button create-category-button ${
							loading === 1 ? "preloader" : ""
						} ${
							!calendarState.date_from || !calendarState.date_to
								? "disabled"
								: ""
						}`}
						onClick={handleAddClick}>
						<span>Отправить</span>
					</button>
				</div>
			</div>
		</>
	);
};
