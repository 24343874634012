import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import EditCategories from "./EditAttribute.jsx";
import styled from "styled-components";
import { Cluster } from "../../../ui-kit/layouts/Cluster.jsx";
import {
	CategoryColumnWrapper,
	CategoryContentWrapper,
	ChildElement,
	ColumnTitle,
	FilteredItemWrapper,
	ParentElement,
} from "./wrappers.jsx";
import { DTBold15, DText15 } from "../../../ui-kit/typography/Typography.jsx";
import { Button } from "../../../ui-kit/button/Button.jsx";
import { useUserAccessContext } from "../context/UserAccessContext.js";
import { checkUserWritePerm, sortArrByAlphabet } from "../scripts/utils.js";
import { getEditableFrames } from "../../../Actions/FramesActions.js";
import AddNewFrame from "./AddNewFrame.jsx";
import AddNewAttribute from "./AddNewAttribute.jsx";
import EditAttribute from "./EditAttribute.jsx";
import EditFrame from "./EditFrame.jsx";
import DeleteAttribute from "./DeleteAttribute.jsx";
import DeleteFrameModal from "./DeleteFrameModal.jsx";

const StyledInput = styled.input`
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px;
	line-height: 30px;
	margin-left: 30px;
	outline: none;
	width: 230px;
	margin-top: 22px;
`;

const initialCategoryState = {
	0: {
		list: [],
		active: null,
		index: null,
	},
	1: {
		list: [],
		active: null,
		index: null,
	},
	2: {
		list: [],
		active: null,
		index: null,
	},
};

const CategoryAttributesPage = ({ editableFrames, loader, title, getEditableFrames }) => {
	const dispatch = useDispatch();
	const { handleModal, modal = false } = useContext(ModalContext);
	const [categoriesListState, setCategoriesListState] = useState(initialCategoryState);
	const [searchState, setSearchState] = useState("");
	const [filteredCategory, setFilteredCategory] = useState([]);

	const [editCategoriesVisible, setEditCategoriesVisible] = useState(null);

	useEffect(() => {
		getEditableFrames();
	}, []);

	useEffect(() => {
		document.title = title;
	}, [title]);

	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		if (!searchState.length || !editableFrames.length) {
			setFilteredCategory([]);
			return;
		}

		let filteredArray = [];
		const collection = [...editableFrames];

		collection.forEach((elem, index) => {
			if (elem.attributes.length === 0) {
				if (elem.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
					filteredArray.push([{ ...elem, index }]);
				}
			} else {
				elem.attributes.forEach((elem1, index1) => {
					if (elem1.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
						filteredArray.push([
							{ ...elem, index: index },
							{ ...elem1, index: index1 },
						]);
					}
				});
			}
		});
		setFilteredCategory(filteredArray);
	}, [searchState]);

	useEffect(() => {
		if (editableFrames.length > 0) {
			const collection = editableFrames;
			let subCollection = [];
			if (
				categoriesListState[0].index !== null &&
				categoriesListState[0]?.active?.id === collection[categoriesListState[0].index]?.id
			) {
				subCollection = sortArrByAlphabet({
					arr: collection[categoriesListState[0].index]?.attributes,
				});
			}
			// collection[categoriesListState[0].index]?.attributes ?
			setCategoriesListState({
				...categoriesListState,
				0: {
					...categoriesListState[0],
					list: collection,
				},
				1: {
					...categoriesListState[1],
					list: subCollection,
				},
			});
			// if (categoriesListState[1].index)
		}
	}, [editableFrames]);

	useEffect(() => {
		if (editableFrames.length > 0) {
			setCategoriesListState({
				...initialCategoryState,
				0: { list: editableFrames, active: null, index: null },
			});
		}
	}, []);

	useEffect(() => {
		if (loader.loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loader, modal]);

	const handleDowloadLinkClick = () => {
		window.open(`https://atips.io/export/categories`);
	};

	const handleSelectCategoryClick = (index, category, idx) => {
		switch (index) {
			case 0:
				const sorted = sortArrByAlphabet({
					arr: category.attributes,
				});
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[index], active: category, index: idx },
					1: { active: null, list: sorted },
				});
				return;
			case 1:
				setCategoriesListState({
					...categoriesListState,
					1: { ...categoriesListState[index], active: category, index: idx },
				});
				return;
			default:
				return;
		}
	};

	const handleDeleteCategoryClick = (index) => {
		switch (index) {
			case 0:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[0], active: null, index: null },
					1: initialCategoryState[1],
				});
				return;
			case 1:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[0], active: null, index: null },
					1: initialCategoryState[1],
				});
				return;
			default:
				return;
		}
	};

	const handleSelectBySearch = (index, list) => {
		setSearchState("");
		setFilteredCategory([]);
		switch (index) {
			case 0:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: editableFrames,
						active: list[0],
						index: list[0]?.index,
					},
					1: initialCategoryState[1],
				});
				return;
			case 1:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: editableFrames,
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].attributes,
						active: list[1],
						index: list[1]?.index,
					},
				});
				return;
			default:
				return;
		}
	};

	return (
		<>
			{editCategoriesVisible !== null ? (
				<EditCategories
					elem={editCategoriesVisible?.elem}
					isService={editCategoriesVisible?.isService}
					onClose={() => setEditCategoriesVisible(null)}
				/>
			) : null}
			<section className="personal-area-item categories" style={{ paddingBottom: "0" }}>
				<header className="personal-area categories-header">
					<Cluster align="center" style={{ width: "100%" }}>
						<h1 className="categories-header-text" style={{ width: "auto" }}>
							Атрибуты
						</h1>
						<StyledInput
							placeholder="Введите название атрибута"
							value={searchState}
							onChange={(e) => setSearchState(e.target.value)}
						/>
					</Cluster>

					{/* <button
						className="button_add-category"
						onClick={() => handleDowloadLinkClick()}
						style={{ marginRight: "20px" }}
					>
						<img src="images/icons/ui/files/white/upload.svg" alt="плюс" />
						Выгрузить
					</button> */}
				</header>
				{filteredCategory.length > 0 ? (
					filteredCategory.map((elem, index) => (
						<FilteredItemWrapper
							onClick={() => handleSelectBySearch(elem.length - 1, elem)}
							key={index}
						>
							<DTBold15>{elem[elem.length - 1].title}</DTBold15>
							<DText15>
								{elem.map((item, index) => {
									let res = "";
									if (index !== elem.length - 1) {
										return res + `${item.title} > `;
									} else return res + item.title;
								})}
							</DText15>
						</FilteredItemWrapper>
					))
				) : searchState.length > 0 ? (
					<DText15 style={{ color: "#94a0b1" }}>Ничего не найдено</DText15>
				) : (
					<>
						<CategoryContentWrapper>
							{[0, 1].map((column, index) => (
								<CategoryColumnWrapper key={index}>
									<>
										<Cluster
											justify="space-between"
											align="center"
											style={{ padding: "0 10px", marginBottom: "16px" }}
										>
											<ColumnTitle>
												{column === 0 ? categoriesListState[column].active?.title : ""}
											</ColumnTitle>
											{checkUserWritePerm(userAccessState, "categories") &&
											(column === 0 ||
												(categoriesListState[column - 1].active &&
													categoriesListState[column - 1].active.id)) ? (
												<Button
													variant="transparent"
													text="Добавить"
													onClick={() =>
														handleModal(
															column === 0 ? (
																<AddNewFrame />
															) : (
																<AddNewAttribute
																	parentId={categoriesListState[column - 1].active.id}
																/>
															)
														)
													}
													startIcon={
														<svg
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M13 6V5L11 5V6L11 11H6H5L5 13H6H11L11 18V19H13V18L13 13H18L19 13V11L18 11H13L13 6Z"
																fill="#94A0B1"
															/>
														</svg>
													}
												/>
											) : null}
										</Cluster>
										{categoriesListState[column]?.list?.length
											? categoriesListState[column].list.map((elem, index) =>
													elem.attributes && elem.attributes.length > 0 ? (
														<ParentElement
															key={elem.id}
															onClick={() => handleSelectCategoryClick(column, elem, index)}
															className={
																categoriesListState[column].active &&
																categoriesListState[column].active.id === elem.id
																	? "selected"
																	: ""
															}
														>
															<span>{elem.title}</span>
															{checkUserWritePerm(userAccessState, "categories") ? (
																<Cluster
																	align="center"
																	space="16px"
																	className="actions-wrapper"
																	justify="flex-end"
																>
																	<svg
																		onClick={(e) => (
																			e.stopPropagation(),
																			handleModal(
																				column === 0 ? (
																					<EditFrame elem={elem} />
																				) : (
																					<EditAttribute elem={elem} />
																				)
																			)
																		)}
																		width="24"
																		height="24"
																		viewBox="0 0 24 24"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			clipRule="evenodd"
																			d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
																			fill="#94A0B1"
																		/>
																	</svg>
																</Cluster>
															) : null}
														</ParentElement>
													) : (
														<ChildElement
															key={elem.id}
															onClick={() => handleSelectCategoryClick(column, elem, index)}
															className={
																categoriesListState[column].active &&
																categoriesListState[column].active.id === elem.id
																	? "selected"
																	: ""
															}
														>
															<span>{elem.title}</span>
															{checkUserWritePerm(userAccessState, "categories") ? (
																<Cluster
																	align="center"
																	space="16px"
																	justify="flex-end"
																	className={`actions-wrapper ${!elem?.id ? "hidden" : ""}`}
																>
																	<svg
																		onClick={(e) => (
																			e.stopPropagation(),
																			handleModal(
																				column === 0 ? (
																					<EditFrame elem={elem} />
																				) : (
																					<EditAttribute elem={elem} />
																				)
																			)
																		)}
																		width="24"
																		height="24"
																		viewBox="0 0 24 24"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			clipRule="evenodd"
																			d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
																			fill="#94A0B1"
																		/>
																	</svg>
																	{!elem.attributes || elem.attributes.length === 0 ? (
																		<svg
																			onClick={(e) => (
																				e.stopPropagation(),
																				handleDeleteCategoryClick(column),
																				handleModal(
																					column === 0 ? (
																						<DeleteFrameModal id={elem.id} attr={elem} />
																					) : (
																						<DeleteAttribute id={elem.id} attr={elem} />
																					)
																				)
																			)}
																			width="24"
																			height="24"
																			viewBox="0 0 24 24"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				fillRule="evenodd"
																				clipRule="evenodd"
																				d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
																				fill="#94A0B1"
																			/>
																		</svg>
																	) : null}
																</Cluster>
															) : null}
														</ChildElement>
													)
											  )
											: null}
									</>
								</CategoryColumnWrapper>
							))}
							<CategoryColumnWrapper key={2} />
						</CategoryContentWrapper>
					</>
				)}
			</section>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		editableFrames: state.editableFrames,
	};
};

const mapDispatchToProps = {
	getEditableFrames,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAttributesPage);
