import AuthService from "../services/auth.service";

const initialState = {
	token: "",
};

const AuthorizationReducer = (login = initialState, action) => {
	switch (action.type) {
		case "POST_LOGIN":
			if (action.success) {
				return { ...action.login.data, success: true };
			} else {
				return { success: false };
			}
		case "POST_LOGOUT":
			return initialState;
		default:
			return login;
	}
};

export const AuthUserReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_AUTH_USER":
			return action.user.data;
		case "POST_LOGOUT":
			AuthService.logout();
			return null;
		default:
			return state;
	}
};

export { AuthorizationReducer };
