import axios from "axios";
import { baseUrl, extraCategoriesUrl, updateCategoryUrl } from "./consts";

export const getExtraCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(extraCategoriesUrl)
			.then((categor) => {
				dispatch({
					type: "GET_EXTRA_CATEGORIES",
					categor,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getClearCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/category`)
			.then((categor) => {
				dispatch({
					type: "GET_CLEAR_CATEGORIES",
					categor,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(extraCategoriesUrl)
			.then((categor) => {
				dispatch({
					type: "GET_CATEGORIES",
					categor,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const refreshCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(updateCategoryUrl)
			.then(() => {
				dispatch(getCategories());
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			});
	};
};

export const postCategories = (item, isService) => {
	const obj = {
		parent_id: item.parent_id,
		titles: item.title,
	};
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/multicategories`, obj)
			.then((categor) => {
				// dispatch({
				// 	type: "POST_CATEGORIES",
				// 	categor,
				// 	isService,
				// });
			})
			.finally(() => {
				dispatch(refreshCategories());
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				dispatch({
					type: "HIDE_ALL",
				});
				console.log(error);
			});
	};
};

export const putCategories = (item, itemId, isService) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios

			.put(`${baseUrl}/domain/categories/${itemId}`, item)
			.then((categor) => {
				dispatch({
					type: "PUT_CATEGORIES",
					categor,
					isService,
				});
			})
			.finally(() => {
				dispatch(refreshCategories());
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteCategories = (itemId, isService) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/category/${itemId}`)
			.then((categor) => {
				dispatch({
					type: "DELETE_CATEGORIES",
					categor,
					isService,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
				dispatch(refreshCategories());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCategyAttr = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(
				`${baseUrl}/domain/frames/attributeslist?frame_ids=1&frame_ids=2&frame_ids=15&frame_ids=16&frame_ids=17&frame_ids=18&frame_ids=19&frame_ids=20&frame_ids=21&frame_ids=22&frame_ids=23&frame_ids=24&frame_ids=25&frame_ids=26&frame_ids=27&frame_ids=28&frame_ids=29&frame_ids=30&frame_ids=31&frame_ids=32&frame_ids=33&frame_ids=34&frame_ids=35&frame_ids=37`
			)
			.then((categor) => {
				dispatch({
					type: "GET_CATEGORY_ATTR",
					categor,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				// dispatch(refreshCategories());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
