import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { formatedDate } from "../static.js";
import { getPeriod } from "./scripts.js";
import "./styles/Homepage.scss";
import AreaFilters from "./UsersStatistic/PeriodFilters/AreaFilters.jsx";
import { PeriodFilters } from "./UsersStatistic/PeriodFilters/PeriodFilters.jsx";
import UsersStatistic from "./UsersStatistic/UsersStatistic.jsx";
import MeaningfulCards from "./meaningful-cards/MeaningfulCards.jsx";
import BestCategories from "./best-categories/BestCategories.jsx";
import BestVideos from "./best-videos/BestVideos.jsx";
import styled from "styled-components";
import { H3Text } from "../../../ui-kit/typography/Typography.jsx";
import IncomeCategoryBlock from "./IncomeCategoryBlock/IncomeCategoryBlock.jsx";
import VideoViewBlock from "./VideoViewsBlock/VideoViewBlock.jsx";
import BestMovies from "./best-videos/BestMovies.jsx";

const Wrapper = styled.div`
	margin-top: 48px;
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const HomePage = ({ title }) => {
	const [filtersState, setFiltersState] = useState({
		from_datetime: getPeriod()[4].startDate,
		to_datetime: getPeriod()[4].endDate,
		period_name: getPeriod()[4].period_name,
		group_by: getPeriod()[4].group_by,
		date_key: 4,
	});
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<section className="personal-area-item home">
			<div className="list-title">
				<div className="list-title-text">
					<h2>Сводка</h2>
				</div>
				<div className="list-title-selects">
					<div className="list-title-date">
						<span>
							{`${
								filtersState.date_key == 4 ? "..." : formatedDate(filtersState.from_datetime)
							} — ${formatedDate(filtersState.to_datetime)}`}
						</span>
					</div>
					<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
				</div>
			</div>
			<div className="prices-content-wrapper content-wrapper">
				<MeaningfulCards filtersState={filtersState} />
				<div className="list-title-text" style={{ marginBottom: "32px", marginTop: "48px" }}>
					<h2>Эффективность</h2>
				</div>
				<div className="home-tables" style={{ marginTop: "16px" }}>
					<BestCategories filtersState={filtersState} />
				</div>
				<Wrapper>
					<H3Text>Доходы</H3Text>
					<IncomeCategoryBlock />
				</Wrapper>
				<Wrapper>
					<H3Text>Видео</H3Text>
					<VideoViewBlock />
				</Wrapper>
				<BestVideos />
				<BestMovies />
				<div className="list-title" style={{ marginTop: "48px" }}>
					<div className="list-title-text">
						<h2>Пользователи</h2>
					</div>
				</div>

				<div className="home-tables users-statistic">
					<UsersStatistic filtersState={filtersState} />
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
