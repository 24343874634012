import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SellerFiltersComponent from "./SellerFilters/SellerFiltersComponent.jsx";
import "./styles/SellerStyles.scss";

import { history } from "../../../Store/Store.ts";
import { initialSellerTab } from "./static.js";
import ActiveSellerPage from "./pages/ActiveSellerPage.jsx";
import ReviewSellerPage from "./pages/ReviewSellerPage.jsx";
import BlockedSellerPage from "./pages/BlockedSellerPage.jsx";
import SellerTabs from "./seller-tabs/SellerTabs.jsx";
import axios from "axios";
import { baseUrl } from "../../../Actions/consts.js";
import DeletedSellerPage from "./pages/DeletedSellerPage.jsx";
import { SendResumeModal } from "../StatisticPage/SendResumeModal.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import { DownloadResumeModal } from "./DownloadResumeModal.jsx";
import { SendOrdModal } from "./SendOrdModal.jsx";

const SellerPage = ({ title }) => {
	const [tab, setTab] = useState(initialSellerTab[0]);
	const { handleModal } = useContext(ModalContext);
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [lastSearch, setLastSearch] = useState(true);

	const [searchParams, setSearchParams] = useSearchParams();
	const [total, setTotal] = useState(0);

	const [mainFilterState, setMainFilterState] = useState({
		filters: searchParams.get("filters") !== null ? JSON.parse(searchParams.get("filters")) : {},
		order_by: "-serial_id",
		size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
		page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
	});

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters:
						params.get("filters") !== null
							? JSON.parse(params.get("filters"))
							: { order_by: "serial_id" },
					size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
					page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 3,
				endpoint: `/api/users?size=1&page=1`,
				data: "",
			})
			.then((seller) => {
				setTotal(seller.data.total);
			});
	}, []);

	const handleChangeTab = (value) => {
		setTab(value);
		setMainFilterState({ ...mainFilterState, page: 1 });
	};
	return (
		<section className="personal-area-item sellers">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Рекламодатели
					<sup>
						{/* {!!sellersList.pagination.max_elements ? sellersList.pagination.max_elements : ""} */}
						{total || 0}
					</sup>
				</h1>
				<button
					className="button_add-category"
					onClick={() => handleModal(<DownloadResumeModal />)}>
					<img src="images/icons/ui/files/white/upload.svg" alt="плюс" />
					Выгрузить
				</button>
				<button
					className="button_add-category button_add-sendord"
					onClick={() => handleModal(<SendOrdModal />)}>
					Отправить в ОРД
				</button>
			</header>
			<SellerTabs collection={initialSellerTab} active={tab} onChange={handleChangeTab} />
			<div className="videos-content-wrapper content-wrapper">
				<SellerFiltersComponent filterState={mainFilterState} setFilterState={setMainFilterState} />
				{tab.id === 1 ? (
					<ActiveSellerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 2 ? (
					<ReviewSellerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 3 ? (
					<BlockedSellerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 4 ? (
					<DeletedSellerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : null}
			</div>
		</section>
	);
};

export default SellerPage;
