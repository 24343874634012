import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";
import { getFormatedNumberValue } from "../../../HomePage/scripts";
import { Tooltip } from "../../../../../ui-kit/tooltip/Tooltip";
import { useLazyQuery } from "@apollo/client";
import { GET_VIEWER_TOP5_STATISTIC } from "../../../../../GraphQL/ViewerPage";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import styled from "styled-components";
import { DTBold14 } from "../../../../../ui-kit/typography/Typography";
import { EmptyFallback } from "../../../BloggerPage/pages/Buttons";

const HeaderStyle = styled.span`
	background: transparent;
	border: none;
	outline: none;
	box-sizing: border-box;
	color: #94a0b1;
	cursor: pointer;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	position: relative;
`;
const StyledButton = styled(DTBold14)`
	cursor: pointer;
	color: #94a0b1;
	&.active {
		color: #212121;
	}
`;

const BestViewerCategories = ({ filtersState, viewerState }) => {
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_VIEWER_TOP5_STATISTIC, {
		variables: {
			blogger_id: null,
		},
	});
	const [bestTipTab, setBestTipTab] = useState(true);
	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;
		getStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				moviegoer_id: viewerState.id,
			},
		});
	}, [filtersState]);
	return (
		<Stack space="16px" style={{ width: "100%", marginTop: "16px" }}>
			<Cluster space="16px">
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Любимые категории товаров</b>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<HeaderStyle>Название</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Показы</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Переходы</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Доход, ₽</HeaderStyle>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{data?.getCatEfficiencyItem?.best_products_cat?.map((elem, index) => (
							<CategoryItem elem={elem} key={index} />
						))}
					</div>
				</div>
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Любимые категории услуг</b>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<HeaderStyle>Название</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Показы</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Переходы</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Доход, ₽</HeaderStyle>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{data?.getCatEfficiencyItem?.best_services_cat?.map((elem, index) => (
							<CategoryItem elem={elem} key={index} />
						))}
					</div>
				</div>
			</Cluster>
			<Cluster space="16px">
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие типсы</b>
						<Cluster space="12px">
							<StyledButton
								className={bestTipTab ? "active" : ""}
								onClick={() => setBestTipTab(true)}
							>
								Товары
							</StyledButton>
							<StyledButton
								className={!bestTipTab ? "active" : ""}
								onClick={() => setBestTipTab(false)}
							>
								Услуги
							</StyledButton>
						</Cluster>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<HeaderStyle>ID</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Переходы</HeaderStyle>
						</div>
						<div className="list-table-header-element">
							<HeaderStyle>Доход, ₽</HeaderStyle>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{bestTipTab
							? data?.getTipsEfficiencyItem?.best_products_tips.map((elem, index) => (
									<TipsItem elem={elem} key={index} />
							  ))
							: data?.getTipsEfficiencyItem?.best_service_tips?.map((elem, index) => (
									<TipsItem elem={elem} key={index} />
							  ))}
					</div>
				</div>
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Любимые плейлисты</b>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<HeaderStyle>Название</HeaderStyle>
						</div>
						<div className="list-table-header-element" style={{ width: "364px" }}>
							<HeaderStyle>Просмотров видео</HeaderStyle>
						</div>
					</div>
					<div className="list-table home-best-categories">
						<Cluster justify="center">
							<EmptyFallback>Ничего не найдено</EmptyFallback>
						</Cluster>
					</div>
				</div>
			</Cluster>
		</Stack>
	);
};

export const TipsItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				{/* <img src={productState?.URL_HD_IMAGE} alt="" /> */}
				<span>{elem?.tips_id}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
		</div>
	);
};

export const CategoryItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<span>{elem?.name}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.views_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.name} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BestViewerCategories);
