import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleToggleOpenClick } from "../../../scripts/PersonalAreaScripts";
import "./sideFiltersStyle.scss";
import { DropdownCitySearch } from "../../../BrightboxesPage/dropdownVideoSearch/DropdownCitySearch";

const SideCitiesFilters = ({ sideFilters, setSideFilters }) => {
	const [selectedValues, setSelectedValues] = useState(null);

	useEffect(() => {
		// обнуление
		if (sideFilters.region === null) {
			setSelectedValues(null);
		}
	}, [sideFilters]);

	useEffect(() => {
		selectedValues && setSideFilters({ ...sideFilters, region: selectedValues?.id || null });
	}, [selectedValues]);

	return (
		<div className="statistics-filter-items-block">
			<div
				className="statistics-filter-items-block-title"
				onClick={(e) => handleToggleOpenClick(e)}
			>
				Город
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="statistics-filter-items-block-subitems">
				<DropdownCitySearch
					selected={selectedValues?.title || ""}
					onClear={() => setSelectedValues([])}
					onSelect={(newValue) => setSelectedValues(newValue)}
				/>
			</div>
		</div>
	);
};

export default connect(null, null)(SideCitiesFilters);
