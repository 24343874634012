import axios from "axios";
import { baseUrl } from "./consts";

export const getEditableFrames = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/frames/attributeslist`)
			.then((frame) => {
				dispatch({
					type: "GET_EDITABLE_FRAMES",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postAttribute = (data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/attribute`, data)
			.then((frame) => {
				dispatch({
					type: "POST_ATTRIBUTE",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteAttribute = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.delete(`${baseUrl}/domain/attributesafe?id=${id}`)
			.then((frame) => {
				dispatch({
					type: "DELETE_ATTRIBUTE",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteFrame = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.delete(`${baseUrl}/frame/${id}`)
			.then((frame) => {
				dispatch({
					type: "DELETE_ATTRIBUTE",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const putAttribute = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.put(`${baseUrl}/attribute/${id}`, data)
			.then((frame) => {
				dispatch({
					type: "PUT_ATTRIBUTE",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putFrame = (data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/frames/updateframetitle?id=${data.id}&title=${data.title}`)
			.then((frame) => {
				dispatch({
					type: "PUT_FRAME",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postFrame = (data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/frames`, data)
			.then((frame) => {
				dispatch({
					type: "POST_FRAME",
					frame,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				dispatch(getEditableFrames());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
