import axios from "axios";
import { baseUrl, bloggerUrl, cinemaUrl } from "../../../../Actions/consts";

export const getBestVideos = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => {
			if (key === "date_start" || key === "date_end")
				return key + "=" + new Date(vars[key]).toISOString();
			else return key + "=" + vars[key];
		})
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/best-videos?${queryString}`,
			data: "",
		})
		.then((prod) => {
			onReady(prod.data.items);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getBestFilms = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => {
			if (key === "date_start" || key === "date_end")
				return key + "=" + new Date(vars[key]).getTime();
			else return key + "=" + vars[key];
		})
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/best-movies?${queryString}`,
			data: "",
		})
		.then((prod) => {
			onReady(prod.data.items);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
