import React, { useEffect } from "react";
import { initialMonthData } from "./static";
import { useState } from "react";
import IncomeCards from "./income-cards/IncomeCards";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { connect, useDispatch } from "react-redux";
import { fetchVideoViewByPeriod } from "./fetchVideoViewByPeriod";
import VideoViewGraph from "./VideoViewGraph.jsx";
import { PeriodFilters } from "../../../HomePage/UsersStatistic/PeriodFilters/PeriodFilters";

const VideoViewBlock = ({ videoViewsGraph, bloggerState }) => {
	const dispatch = useDispatch();
	const [toggleVideoBlock, setToggleVideoBlock] = useState("video");
	const [dataState, setDataState] = useState(initialMonthData());
	const [filtersState, setFiltersState] = useState({});
	useEffect(() => {
		if (
			!bloggerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;
		fetchVideoViewByPeriod({
			vars: {
				content_creator_id: bloggerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				group_by: filtersState?.group_by || "day",
				content_creator_video_format: toggleVideoBlock === "video" ? 0 : 1,
			},
			dispatch,
			period: filtersState.period_name,
			format: toggleVideoBlock,
		});
	}, [bloggerState, toggleVideoBlock, filtersState]);

	useEffect(() => {
		if (
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name ||
			!videoViewsGraph[filtersState?.period_name][toggleVideoBlock]?.length
		)
			return;
		const newArray = videoViewsGraph[filtersState.period_name][toggleVideoBlock].filter(
			(elem) => !!elem
		);
		setDataState(newArray);
	}, [videoViewsGraph, filtersState, toggleVideoBlock]);

	return (
		<Stack space="16px">
			<div className="income-info-block category">
				<div className="list-title" style={{ height: "auto" }}>
					<div className="income-info-block__header list-title-text">
						<button
							onClick={() => setToggleVideoBlock("video")}
							className={toggleVideoBlock === "video" ? "active" : ""}
						>
							Видео
						</button>
						<button
							onClick={() => setToggleVideoBlock("shorts")}
							className={toggleVideoBlock === "shorts" ? "active" : ""}
						>
							Shorts
						</button>
						<div className="list-title-selects" style={{ marginLeft: "auto" }}>
							<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
						</div>
					</div>
				</div>
				<VideoViewGraph dataState={dataState} period={filtersState.period_name} />
			</div>
			<IncomeCards bloggerState={bloggerState} filtersState={filtersState} />
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		videoViewsGraph: state.bloggerVideoViewsGraph,
	};
};

const mapDispatchToProps = {
	// getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewBlock);
