import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Cluster } from "../../ui-kit/layouts/Cluster";
import { Stack } from "../../ui-kit/layouts/Stack";
import { Button } from "../../ui-kit/button/Button.jsx";
import Select from "react-select";
import { customStyles } from "../../components/PersonalArea/static";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../Actions/consts";
import { getCategyAttr } from "../../Actions/CategoriesActions";

const Container = styled(Cluster)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.4);
`;

export const AddAttributesModal = ({ onClose, frames }) => {
	const [titleState, setTitleState] = useState("");
	const [selectedFrames, setSelectedFrames] = useState();
	const dispatch = useDispatch();

	const handleAddAttribute = async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/attribute`, { title: titleState, frame_id: selectedFrames.id })
			.then((categor) => {
				dispatch({
					type: "POST_CATEGORY_ATTR",
					categor,
				});
				dispatch(getCategyAttr());
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				onClose();
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<Container align="center" justify="center">
			<div className="add-category-wrapper">
				<div className="modal-field-title">Создать атрибут</div>
				<div className="modal-field-close" onClick={() => onClose()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<Stack>
					<div className="flex-block">
						<div className="form-field">
							<div className="form-field-label">Фрейм</div>
							<div className="input-field">
								<Select
									name="attribute"
									value={selectedFrames}
									options={frames}
									styles={customStyles}
									onChange={(newValue) => setSelectedFrames(newValue)}
									getOptionLabel={(option) => option.title}
									getOptionValue={(option) => option.id}
									placeholder="Все"
									isSearchable={true}
									className="search-wrapper side-filters_city"
									classNamePrefix="select"
									noOptionsMessage={({ inputValue }) => (!inputValue ? "Загрузка" : "Нет значений")}
								/>
							</div>
						</div>
					</div>
					<div className="flex-block">
						<div className="form-field">
							<div className="form-field-label">Название атрибута</div>
							<div className="input-field">
								<input
									type="text"
									placeholder="Ввведите название атрибута"
									style={{ width: "440px" }}
									id="category-title"
									onChange={(e) => setTitleState(e.target.value)}
									value={titleState}
								/>
							</div>
						</div>
					</div>
				</Stack>
				<div className="modal-buttons">
					<Button
						variant="secondary"
						className="button action-button border-button"
						onClick={() => onClose()}
						text="Отменить"
					/>
					<Button
						variant="primary"
						text="Создать атрибут"
						disabled={!selectedFrames || !titleState || !titleState?.length}
						onClick={() => handleAddAttribute()}
					/>
				</div>
			</div>
		</Container>
	);
};
