import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { removeEmpty } from "../../../../Actions/ActionsScirpts";
import { getCountries } from "../../../../Actions/LocationActions";
import { getVideoGenres } from "../../../../Actions/videos-actions/VideosActions.js";
import SideCitiesFilters from "./SideFilters/SideCitiesFilters.jsx";
import SideClickStatusFilters from "./SideFilters/SideClickStatusFilters.jsx";
import SideCountryFilters from "./SideFilters/SideCountryFilters.jsx";
import SideGenderFilters from "./SideFilters/SideGenderFilters.jsx";
import SideGenresFilters from "./SideFilters/SideGenresFilters.jsx";
import { SideVideoFilters } from "./SideFilters/SideVideoFilters.jsx";
import { initialSideFiltersState } from "./static";
import SideAgesFilters from "./SideFilters/SideAgesFilters";
import { getAllActiveOwner } from "../../../../Actions/OwnerActions";
import SideOwnersFilters from "./SideFilters/SideOwnersFilters";

const StatisticEditFilterSlide = ({
	status,
	setStatus,
	getVideoGenres,
	genresList,
	countriesList,
	getCountries,
	paramsState,
	getAllActiveOwner,
	setParamsState,
}) => {
	const [sideFilters, setSideFilters] = useState(initialSideFiltersState);
	useEffect(() => {
		if (status.active && status.name === "filter") {
			genresList.length === 0 && getVideoGenres();
			getAllActiveOwner({ is_active: true });
			countriesList.length === 0 && getCountries();
		}
	}, [status]);

	const clearFilters = () => {
		setSideFilters(initialSideFiltersState);
	};

	const applySideFilters = () => {
		const clearSideFilter = removeEmpty({ ...sideFilters });
		setParamsState({ ...paramsState, sideFilters: clearSideFilter });
	};
	return (
		<div
			id="statistics-filter"
			className={`show-statistic-filter ${
				status.active && status.name === "filter" ? "active" : ""
			}`}
		>
			<div className="statistics-filter-scroller">
				<div className="statistics-filter-title">Все фильтры</div>
				<div
					className="statistics-filter-close action-button"
					onClick={() => setStatus({ active: false, name: "" })}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="statistics-filter-items">
					<SideOwnersFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					{/* <SideCatregoryFilters sideFilters={sideFilters} setSideFilters={setSideFilters} /> */}
					<SideVideoFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideGenresFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideGenderFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideAgesFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideCountryFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideCitiesFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideClickStatusFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
				</div>
			</div>
			<div className="statistics-buttons">
				<div
					className="button action-button border-button"
					style={{ width: "auto" }}
					onClick={() => clearFilters()}
				>
					<span style={{ whiteSpace: "nowrap" }}>Очистить все</span>
				</div>
				<div className="button" onClick={() => applySideFilters()}>
					<span>Применить</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		genresList: state.videoGenresList,
		countriesList: state.countriesList,
	};
};

const mapDispatchToProps = {
	getVideoGenres,
	getCountries,
	getAllActiveOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticEditFilterSlide);
