import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getVideoGenres } from "../../../../Actions/videos-actions/VideosActions.js";
import "./styles/EditVideos.scss";
import { initialState } from "./videoStatic.js";
import { VideoBookmarks } from "./VideoBookmarks.jsx";
import EditBloggerVideoPolitics from "../edit-blogger-video/politics/EditBloggerVideoPolitics.jsx";
import VideoEditSidebar from "./universal/VideoEditSidebar.jsx";
import { getVideo } from "../../../../Actions/videos-actions/getMovieVideo.js";
import EditBloggerVideoContent from "../edit-blogger-video/content/EditBloggerVideoContent.jsx";
import styled from "styled-components";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import {
	DTBold14,
	DTBold15,
	DTList14,
	DText15,
} from "../../../../ui-kit/typography/Typography.jsx";
import EditBloggerVideoStatistic from "../edit-blogger-video/statistic/EditBloggerVideoStatistic.jsx";
import { PeriodFilters } from "../../HomePage/PeriodFilters.jsx";
import { bloggerUrl, baseUrl } from "../../../../Actions/consts.js";
import axios from "axios";
import { Stack } from "../../../../ui-kit/layouts/Stack.jsx";
import qs from "qs";
import { parseBloggerMovie, takeId } from "../sctipts.js";
import { formatedDate } from "../../static.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
// 1 -  фильм, 2 - сериал, 3 - блог

const Wrapper = styled(Cluster)`
	background: #ffffff;
	border-radius: 80px;
	padding: 10px 16px;
	height: 40px;
	white-space: nowrap;
`;

const StyledBloggerAvatar = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 32px;
`;

const RadioBlock = styled.div`
	position: absolute;
	right: 0;
	top: calc(50% - 12px);
	box-sizing: border-box;
	border-radius: 50%;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	width: 24px;
	height: 24px;
	content: "";
	border: 8px solid rgba(237, 10, 52, 0.2);
	background-color: #ed0a34;
	&.active {
		border: 8px solid rgba(13, 194, 104, 0.2);
		background-color: #0dc268;
	}
`;

const StyledLinks = styled(Cluster)`
	cursor: pointer;
	&:hover {
		color: #005bff;
		svg {
			path {
				fill: #005bff;
			}
		}
	}
`;

const VideoBadge = () => {
	return (
		<Wrapper space="8px" align="center">
			<DTBold14>Видео блогера</DTBold14>
			<img src="images/icons/video.svg" />
		</Wrapper>
	);
};

const ShortsBadge = () => {
	return (
		<Wrapper space="8px" align="center">
			<DTBold14>Shorts</DTBold14>
			<img src="images/icons/shorts.svg" />
		</Wrapper>
	);
};

const EditBloggerVideo = ({ getVideo, videosList, getVideoGenres, title }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [activeBookMark, setActiveBookMark] = useState(0); // состояние активной вкладки
	const [errorState, setErrorState] = useState(false);
	const [filtersState, setFiltersState] = useState({});
	const [bloggerVideo, setBloggerVideo] = useState();
	useEffect(() => {
		document.title = title;
	}, [title]);
	const { userAccessState } = useUserAccessContext();
	const [mainVideoState, setMainVideoState] = useState({
		title: "",
		duration: 0,
		published: false,
		year: "",
		rating: null,
		preview: "",
		movie_rating: null,
		slug: "",
		is_banned: true,
		photos: [],
		genres: [],
		politics: [],
		hashtags: [],
		characters: [
			{
				1: [],
			},
			{
				2: [],
			},
			{
				3: [],
			},
			{
				4: [],
			},
			{
				5: [],
			},
		],
		URL_SD_HORIZONTAL_PREVIEW: "",
		URL_SD_VERTICAL_PREVIEW: "",
		URL_SD_trailer: "",
		URL_HD_trailer: "",
		URL_FULL_HD_trailer: "",
	}); // главное состояние компонента
	useEffect(() => {
		// получение списка фильмов, возрастных ограничений, жанров, хештегов
		if (
			location.pathname !== "/newfilm" &&
			location.pathname !== "/newserial" &&
			location.pathname !== "/newblog"
		) {
			getVideo(location.search.split("=")[1]);
		} else {
			setMainVideoState({
				...mainVideoState,
				movie_format:
					location.pathname === "/newfilm"
						? { id: 1 }
						: location.pathname === "/newserial"
						? { id: 2 }
						: location.pathname === "/newblog"
						? { id: 3 }
						: { id: 1 },
			});
		}
		getVideoGenres();
	}, []);

	useEffect(async () => {
		// запись в состояние компонента списка фильмов
		if (
			location.search.split("=")[1] == videosList.id ||
			location.pathname === "/newfilm" ||
			location.pathname === "/newserial" ||
			location.pathname === "/newblog"
		) {
			if (videosList.external_id) {
				dispatch({
					type: "SHOW_MAIN_LOADER",
				});
				await axios
					.post(`${baseUrl}/domain/other`, {
						method: "GET",
						service: 2,
						endpoint: `/api/dashboard/videos/${videosList.external_id}`,
						data: "",
					})
					.then(async (video) => {
						await axios
							.post(`${baseUrl}/domain/other`, {
								method: "GET",
								service: 2,
								endpoint: `/api/dashboard/users/${video.data.user_id}`,
								data: "",
							})
							.then((e) => {
								setBloggerVideo({ ...video.data, user: e.data });
							})
							.catch((e) => {
								console.log(e);
								setBloggerVideo(video.data);
							});
					})
					.finally(() => {
						dispatch({
							type: "HIDE_MAIN_LOADER",
						});
					})
					.catch((error) => {
						console.log(error);
					});
			}
			setMainVideoState({
				...videosList,
			});
		}
	}, [videosList]);

	const handleBanClick = async (is_banned) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "PATCH",
				service: 2,
				endpoint: `/api/dashboard/videos/${videosList.external_id}`,
				data_json: {
					is_banned: !is_banned,
					is_active: is_banned ? bloggerVideo.is_active : false,
				},
			})
			.then((video) => setBloggerVideo(video.data))
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		const parsedMovie = parseBloggerMovie(mainVideoState);
		await axios
			.put(`${baseUrl}/domain/movies/${mainVideoState.id}`, {
				...mainVideoState,
				genres: takeId(mainVideoState.genres),
				politics: takeId(mainVideoState.politics),
				published: is_banned ? mainVideoState.published : false,
				is_banned: !is_banned,
			})
			.then((video) => getVideo(location.search.split("=")[1]))
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const goBack = () => {
		setMainVideoState(initialState);
		navigate(-1);
	};

	return (
		<section className="personal-area-item edit">
			<header className="personal-area edit-videos-header">
				<button className="form-page-back" onClick={() => goBack()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				{!!mainVideoState?.movie_format?.id ? (
					<Cluster align="center" justify="space-between">
						<h1 style={{ whiteSpace: "normal" }}>
							{mainVideoState.id ? mainVideoState.title : "Без названия"}
						</h1>
						{location.pathname === "/newblog" || mainVideoState?.movie_format_id === 3 ? (
							<VideoBadge />
						) : (
							<ShortsBadge />
						)}
					</Cluster>
				) : null}
				<Cluster justify="space-between" align="center">
					<Cluster align="center" space="12px">
						<StyledBloggerAvatar src={bloggerVideo?.user?.avatar_url} alt="Блогер" />
						<DTBold15>
							{bloggerVideo?.user?.name} ID {bloggerVideo?.user?.serial_id}
						</DTBold15>
						<StyledLinks align="center" space="10px">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
									fill="#212121"
								/>
							</svg>
							<DTBold15
								onClick={() =>
									navigate(
										`/statistic?${qs.stringify({
											filters: JSON.stringify({ video_id: mainVideoState.video[0].id }),
										})}`
									)
								}
							>
								Статистика по типсам
							</DTBold15>
						</StyledLinks>
					</Cluster>
					<Stack align="flex-end" space="12px" justify="flex-end" style={{ width: "200px" }}>
						<DTList14 style={{ paddingRight: "32px" }}>
							<RadioBlock
								className={bloggerVideo?.is_active && mainVideoState?.published ? "active" : ""}
							/>
							{bloggerVideo?.is_active && mainVideoState?.published ? "О" : "Не о"}публиковано
						</DTList14>
						<div
							id="product-tumbler"
							className={`${
								bloggerVideo?.is_banned || mainVideoState?.is_banned ? "disabled" : ""
							} ${!checkUserWritePerm(userAccessState, "videos") ? "readOnly" : ""}`}
							onClick={() =>
								checkUserWritePerm(userAccessState, "videos") &&
								handleBanClick(bloggerVideo?.is_banned || mainVideoState?.is_banned)
							}
						>
							<div id="product-tumbler-text" style={{ right: "64px", width: "137px" }}>
								<span style={{ left: "0px", width: "137px" }}>Разблокировано</span>
								<span style={{ left: "0px" }}>Заблокировано</span>
							</div>
						</div>
					</Stack>
				</Cluster>
				<div className="bookmark-wrapper">
					<VideoBookmarks activeBookMark={activeBookMark} setActiveBookMark={setActiveBookMark} />
					{activeBookMark === 1 ? (
						<Cluster space="24px" align="center">
							<DText15 style={{ color: "#94A0B1" }}>
								{`${
									filtersState.date_key == 4 ? "..." : formatedDate(filtersState.from_datetime)
								} — ${formatedDate(filtersState.to_datetime)}`}
							</DText15>
							<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
						</Cluster>
					) : null}
				</div>
			</header>
			<div className="form-container-blocks">
				{!!mainVideoState.movie_format?.id ? (
					activeBookMark === 2 ? (
						<>
							<EditBloggerVideoPolitics
								mainVideoState={mainVideoState}
								setMainVideoState={setMainVideoState}
								bloggerVideo={bloggerVideo}
							/>
							<VideoEditSidebar
								mainVideoState={mainVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
						</>
					) : activeBookMark === 1 ? (
						<EditBloggerVideoStatistic
							mainVideoState={mainVideoState}
							setMainVideoState={setMainVideoState}
							bloggerVideo={bloggerVideo}
							filtersState={filtersState}
						/>
					) : (
						<>
							<EditBloggerVideoContent
								mainVideoState={mainVideoState}
								setMainVideoState={setMainVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
							<VideoEditSidebar
								mainVideoState={mainVideoState}
								setMainVideoState={setMainVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
						</>
					)
				) : null}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.currentVideo,
		videoGenresList: state.videoGenresList,
		politicsList: state.politicsList,
	};
};

const mapDispatchToProps = {
	getVideo,
	getVideoGenres,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBloggerVideo);
