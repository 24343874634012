import { getSearch } from "connected-react-router";
import * as React from "react";
import { memo } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Search from "../../../../ui-kit/search/Search.jsx";
import { useDebounce } from "../../scripts/useDebounse.jsx";

const CustomSearchDropDown = memo(
	({ searchVideosList = [], setMainFilterState, mainFilterState }) => {
		const [results, setResults] = useState([]);
		const [selectedValue, setSelectedValue] = useState();
		const [inputValue, setInputValue] = useState(() => mainFilterState?.movie_title || "");

		useEffect(() => {
			if (searchVideosList.length > 0) {
				const newCollection = [...results, ...searchVideosList];
				setResults(newCollection);
			}
		}, [searchVideosList]);

		const debouncedSearch = useDebounce(() => inputValue, 300);

		useEffect(() => {
			setMainFilterState({ ...mainFilterState, movie_title: debouncedSearch, offset: 0 });
		}, [debouncedSearch]);

		const handleChange = (e) => {
			const { target } = e;
			// if (!target.value.trim()) return setResults([]);

			const value = target.value;
			setInputValue(value);
		};
		return (
			<>
				<input
					type="text"
					placeholder="Поиск по названию"
					value={inputValue}
					onChange={handleChange}
				/>
				<div
					className="input-search input-icon"
					// onClick={(e) =>
					// 	handleFilteredClick("search", searchInput, mainFilterState, setMainFilterState)
					// }
				></div>
			</>
			// <Search
			// 	results={results}
			// 	value={inputValue}
			// 	renderItem={(item) => <span>{item.label}</span>}
			// 	onChange={handleChange}
			// 	onSelect={(item) => setSelectedValue(item)}
			// />
		);
	}
);

// const mapStateToProps = (state) => {
// 	return {
// 		searchVideosList: state.searchVideosList,
// 	};
// };

// const mapDispatchToProps = {
// 	getSearchVideo()
// };

export default connect(null, null)(CustomSearchDropDown);
