import React, { useEffect, useState } from "react";
import "./Badge.scss";

export const SimpleBadge = ({ value, backgroundColor }) => {
	const currentColor = !!backgroundColor ? backgroundColor : value < 0 ? "#FF9E00" : "#0DC268";
	return (
		<div className="bardge-container">
			<div className={`badge-wrapper`} style={{ backgroundColor: currentColor }}>
				<span>{value}</span>
			</div>
		</div>
	);
};
