export const PlaylistReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_PLAYLIST":
			return action.playlist.data.playlists;

		case "GET_CURRENT_PLAYLIST":
			const data = action.playlist.data;
			let array = [...state];
			const playlistIdx = array.findIndex((elem) => elem.id === data.id);
			array[playlistIdx] = data;
			return array;

		case "POST_PLAYLIST":
			state.push(action.playlist.data);
			return state;

		case "PUT_PLAYLIST":
			const newArray = state.map((elem) => {
				if (elem.id === action.playlist.data.id) {
					return action.playlist.data;
				} else return elem;
			});
			return newArray;

		case "ADD_PLAYLIST_VIDEO":
			let playList = action.playlist;
			let currentPlaylistIdx = state.findIndex((elem) => elem.id === playList.id);
			if (currentPlaylistIdx < 0) {
				return state;
			}
			let playlists = state.slice(0);
			playlists[currentPlaylistIdx].movies.push(playList.video);
			return playlists;

		case "DELETE_PLAYLIST_VIDEO":
			playList = action.playlist;
			currentPlaylistIdx = state.findIndex((elem) => elem.id === playList.id);
			if (currentPlaylistIdx < 0) {
				return state;
			}
			playlists = state.slice(0);
			const result = playlists[currentPlaylistIdx].movies.filter(
				(elem) => elem.id !== playList.video
			);
			playlists[currentPlaylistIdx].movies = result;
			return playlists;
		case "DELETE_PLAYLIST":
			const filtererState = state.filter((item, index) => item.id !== action.playlist.data.id);
			return filtererState;
		default:
			return state;
	}
};
