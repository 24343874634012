import React, { useContext, useEffect } from "react";
import { initialMonthData } from "./static";
import { useState } from "react";
import { Stack } from "../../../../../../ui-kit/layouts/Stack";
import { connect, useDispatch } from "react-redux";
import { fetchCurrentVideoViewDepthByPeriod } from "./fetchVideoViewByPeriod";
import VideoViewDepthGraph from "./VideoViewDepthGraph.jsx";
import { H5Text } from "../../../../../../ui-kit/typography/Typography";
import { Button } from "../../../../../../ui-kit/button/Button";
import { Cluster } from "../../../../../../ui-kit/layouts/Cluster";
import { ModalContext } from "../../../../../modal/ModalContext/ModalContext.tsx";
import { ViewDepthInfoModal } from "../../../../../../common/info-blocks/ViewDepthInfoModal";

const VideoViewBlock = ({
  videoViewsGraph,
  mainVideoState,
  filtersState,
}) => {
  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const [dataState, setDataState] = useState(initialMonthData());
  useEffect(() => {
    if (
      !mainVideoState.id ||
      !filtersState.from_datetime ||
      !filtersState.to_datetime ||
      !filtersState.period_name
    )
      return;
    fetchCurrentVideoViewDepthByPeriod({
      vars: {
        date_start: new Date(filtersState.from_datetime).toISOString(),
        date_end: new Date(filtersState.to_datetime).toISOString(),
        // group_by: filtersState?.group_by || "day",
        content_creator_video_id: mainVideoState.id,
      },
      dispatch,
      period: filtersState.period_name,
      duration: mainVideoState.duration,
    });
  }, [filtersState, mainVideoState]);

  useEffect(() => {
    if (
      !filtersState.from_datetime ||
      !filtersState.to_datetime ||
      !filtersState.period_name
    )
      return;
    setDataState(videoViewsGraph[filtersState.period_name]);
  }, [videoViewsGraph, filtersState, mainVideoState]);
  return (
    <Stack space="16px">
      <div className="income-info-block category">
        <div className="list-title">
          <Cluster space="8px" align="center">
            <H5Text>Глубина просмотра</H5Text>
            <Button
              onClick={() => handleModal(<ViewDepthInfoModal />)}
              variant="white-icon"
              startIcon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_12459_6685)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12.4268 8C11.7002 8 11.1335 8.43078 10.9351 8.95437L10.5807 9.88947L8.71053 9.18074L9.06489 8.24563C9.57297 6.90491 10.9126 6 12.4268 6C14.3334 6 16 7.45774 16 9.4C16 10.1949 15.7961 10.9659 15.291 11.5941C14.7836 12.2252 14.0547 12.6096 13.1978 12.7807C13.1526 12.7897 13.1297 12.7994 13.1204 12.8045C13.1145 12.8127 13.0983 12.8389 13.0796 12.8988C13.0503 12.9923 13.0266 13.1293 13.0135 13.3283C13.0004 13.5271 13 13.7433 13 14L13 15H11L11 14L11 13.9355C10.9995 13.4849 10.9989 12.8505 11.171 12.3012C11.2686 11.9893 11.4346 11.6545 11.7244 11.3718C12.0205 11.0828 12.3906 10.9023 12.8064 10.8193C13.3111 10.7186 13.5811 10.5289 13.7323 10.3409C13.8858 10.15 14 9.85687 14 9.4C14 8.69129 13.3624 8 12.4268 8ZM13.1151 12.808C13.115 12.8079 13.1161 12.8069 13.1189 12.8053C13.1166 12.8073 13.1152 12.8081 13.1151 12.808ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                      fill="#94A0B1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_12459_6685">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              }
            />
          </Cluster>
        </div>
        <VideoViewDepthGraph
          dataState={dataState}
          period={filtersState?.period_name}
        />
      </div>
    </Stack>
  );
};

const mapStateToProps = (state) => {
  return {
    videoViewsGraph: state.CurrentVideoViewsDepthGraph,
  };
};

const mapDispatchToProps = {
  // getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewBlock);
