import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";
import { ModalContext } from "../../components/modal/ModalContext/ModalContext.tsx";
import "./CategoryModal.scss";
import { initialCategoryTabs } from "./static.js";
import { CategoryTabs } from "./CategoryTabs.jsx";
import { Cluster } from "../../ui-kit/layouts/Cluster.jsx";
import { useDispatch } from "react-redux";
import {
	CategoryColumnWrapper,
	CategoryContentWrapper,
	ChildElement,
	FilteredItemWrapper,
	FilteredItemsContainer,
	ParentElement,
} from "./wrappers.jsx";
import { DTBold15, DText15 } from "../../ui-kit/typography/Typography.jsx";

const StyledInput = styled.input`
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px;
	line-height: 30px;
	margin-left: 30px;
	outline: none;
	width: 300px;
	margin-top: 9px;
`;

const ModalContentWrapper = styled.div`
	box-sizing: border-box;
	padding: 32px;
	width: 100vw;
	max-width: 100%;
	min-width: 100%;
	height: 100vh;
	margin: -50px 0px;
	position: relative;
	background: #fff;
	overflow: hidden;
	max-height: 100vh;
	min-height: 100vh;
	/* border-radius: 16px; */
`;

const CloseButton = styled.button`
	display: block;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: none;
	svg {
		width: 24px;
		height: 24px;
		&:hover {
			path {
				fill: #005bff;
			}
		}
	}
`;

const initialCategoryState = {
	0: {
		list: [],
		active: null,
		index: null,
	},
	1: {
		list: [],
		active: null,
		index: null,
	},
	2: {
		list: [],
		active: null,
		index: null,
	},
	3: {
		list: [],
		active: null,
		index: null,
	},
	4: {
		list: [],
		active: null,
		index: null,
	},
};

export const initialCategoryTabsState = [
	{
		id: 1,
		title: "Товары",
	},
	{
		id: 2,
		title: "Услуги",
	},
];

export const CategoryModal = ({ categories, handleCategoryClick }) => {
	const { handleModal, modal = false } = useContext(ModalContext);
	const [categoriesListState, setCategoriesListState] = useState(initialCategoryState);
	const [categoryTabState, setCategoryTabState] = useState(initialCategoryTabsState[0]);
	const [searchState, setSearchState] = useState("");
	const [filteredCategory, setFilteredCategory] = useState([]);

	useEffect(() => {
		if (!searchState.length || !categories.length || !categories[categoryTabState.id - 1].length) {
			setFilteredCategory([]);
			return;
		}

		let filteredArray = [];
		const collection = [...categories[0], ...categories[1]];

		collection.forEach((elem, index) => {
			if (elem.childs.length === 0) {
				if (elem.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
					filteredArray.push([{ ...elem, index }]);
				}
			} else {
				elem.childs.forEach((elem1, index1) => {
					if (elem1.childs.length === 0) {
						if (elem1.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
							filteredArray.push([
								{ ...elem, index: index },
								{ ...elem1, index: index1 },
							]);
						}
					} else {
						elem1.childs.forEach((elem2, index2) => {
							if (elem2.childs.length === 0) {
								if (elem2.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
									filteredArray.push([
										{ ...elem, index: index },
										{ ...elem1, index: index1 },
										{ ...elem2, index: index2 },
									]);
								}
							} else {
								elem2.childs.forEach((elem3, index3) => {
									if (elem3.childs.length === 0) {
										if (elem3.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
											filteredArray.push([
												{ ...elem, index: index },
												{ ...elem1, index: index1 },
												{ ...elem2, index: index2 },
												{ ...elem3, index: index3 },
											]);
										}
									} else {
										elem3.childs.forEach((elem4, index4) => {
											if (elem4.childs.length === 0) {
												if (
													elem4.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1
												) {
													filteredArray.push([
														{ ...elem, index: index },
														{ ...elem1, index: index1 },
														{ ...elem2, index: index2 },
														{ ...elem3, index: index3 },
														{ ...elem4, index: index4 },
													]);
												}
											}
										});
									}
								});
							}
						});
					}
				});
			}
		});
		setFilteredCategory(filteredArray);
	}, [searchState, categoryTabState]);

	useEffect(() => {
		if (categories.length > 0) {
			const collection = categories[categoryTabState.id - 1];

			setCategoriesListState({
				...categoriesListState,
				0: {
					...categoriesListState[0],
					list: collection,
				},
				1: {
					...categoriesListState[1],
					list:
						categoriesListState[0].index !== null
							? collection[categoriesListState[0].index]?.childs || []
							: [],
				},
				2: {
					...categoriesListState[2],
					list:
						categoriesListState[0].index !== null && categoriesListState[1].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									?.childs || []
							: [],
				},
				3: {
					...categoriesListState[3],
					list:
						categoriesListState[0].index !== null &&
						categoriesListState[1].index !== null &&
						categoriesListState[2].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									.childs[categoriesListState[2].index]?.childs || []
							: [],
				},
				4: {
					...categoriesListState[4],
					list:
						categoriesListState[0].index !== null &&
						categoriesListState[1].index !== null &&
						categoriesListState[2].index !== null &&
						categoriesListState[3].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									.childs[categoriesListState[2].index].childs[categoriesListState[3].index]
									?.childs || []
							: [],
				},
			});
		}
	}, [categories]);

	const handleSelectCategoryClick = (index, category, idx) => {
		switch (index) {
			case 0:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[index], active: category, index: idx },
					1: { active: null, list: category.childs },
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 1:
				setCategoriesListState({
					...categoriesListState,
					1: { ...categoriesListState[index], active: category, index: idx },
					2: { active: null, list: category.childs },
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 2:
				setCategoriesListState({
					...categoriesListState,
					2: { ...categoriesListState[index], active: category, index: idx },
					3: { active: null, list: category.childs },
					4: initialCategoryState[4],
				});
				return;
			case 3:
				setCategoriesListState({
					...categoriesListState,
					3: { ...categoriesListState[index], active: category, index: idx },
					4: { active: null, list: category.childs },
				});
				return;
			case 4:
				setCategoriesListState({
					...categoriesListState,
					4: { ...categoriesListState[4], active: category, index: idx },
				});
				return;
			default:
				return;
		}
	};

	useEffect(() => {
		if (categoryTabState && categories.length > 0) {
			setCategoriesListState({
				...initialCategoryState,
				0: { list: categories[categoryTabState.id - 1], active: null, index: null },
			});
		}
	}, [categoryTabState]);

	const handleSelectBySearch = (index, list) => {
		setSearchState("");
		setFilteredCategory([]);
		setCategoryTabState(initialCategoryTabsState[list[0].parent_id - 1]);
		switch (index) {
			case 0:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categories[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: initialCategoryState[1],
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 1:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categories[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 2:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categories[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 3:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categories[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: {
						list: list[2].childs,
						active: list[3],
						index: list[3]?.index,
					},
					4: initialCategoryState[3],
				});
				return;
			case 4:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categories[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: {
						list: list[2].childs,
						active: list[3],
						index: list[3]?.index,
					},
					4: {
						list: list[3].childs,
						active: list[4],
						index: list[4]?.index,
					},
				});
				return;
			default:
				return;
		}
	};

	return (
		<ModalContentWrapper
			className="modal-content__wrapper category-popup scrollbar"
			id="categories-scroll-container"
		>
			<div className="modal-header modal-header--with-title">
				<Cluster align="center">
					<h3 className="modal-title">Выбрать категорию товара или услуг</h3>
					<StyledInput
						placeholder="Введите название категории"
						value={searchState}
						onChange={(e) => setSearchState(e.target.value)}
					/>
				</Cluster>
				<CloseButton
					type="button"
					className="icon-button icon-btn modal-close-btn icon-btn--xs"
					onClick={() => handleModal()}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</CloseButton>
			</div>
			{filteredCategory.length > 0 ? (
				<FilteredItemsContainer>
					{filteredCategory.map((elem) => (
						<FilteredItemWrapper
							onClick={() => (handleCategoryClick(elem[elem.length - 1]), handleModal())}
						>
							<DTBold15>{elem[elem.length - 1].title}</DTBold15>
							<DText15>
								{elem.map((item, index) => {
									let res = "";
									if (index === 0) {
										if (item.parent_id === 1) {
											res = `Товары > `;
										} else {
											res = `Услуги > `;
										}
									}
									if (index !== elem.length - 1) {
										return res + `${item.title} > `;
									} else return res + item.title;
								})}
							</DText15>
						</FilteredItemWrapper>
					))}
				</FilteredItemsContainer>
			) : searchState.length > 0 ? (
				<DText15 style={{ color: "#94a0b1" }}>Ничего не найдено</DText15>
			) : (
				<>
					<CategoryTabs
						collection={initialCategoryTabsState}
						active={categoryTabState}
						onChange={(e) => setCategoryTabState(e)}
					/>
					<CategoryContentWrapper>
						{[0, 1, 2, 3, 4].map((column) => (
							<CategoryColumnWrapper>
								<>
									{categoriesListState[column].list.map((elem, index) =>
										elem.childs.length > 0 ? (
											<ParentElement
												onClick={() => handleSelectCategoryClick(column, elem, index)}
												className={
													categoriesListState[column].active &&
													categoriesListState[column].active.id === elem.id
														? "selected"
														: ""
												}
											>
												<span>{elem.title}</span>
												<Cluster
													align="center"
													space="16px"
													className="actions-wrapper"
													justify="flex-end"
												>
													<svg
														width="7"
														height="12"
														viewBox="0 0 7 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.585938 1.99966L1.29304 2.70677L4.58594 5.99966L1.29304 9.29256L0.585938 9.99966L2.00015 11.4139L2.70726 10.7068L6.70726 6.70677C7.09778 6.31625 7.09778 5.68308 6.70726 5.29256L2.70726 1.29256L2.00015 0.585449L0.585938 1.99966Z"
															fill="#94A0B1"
														></path>
													</svg>
												</Cluster>
											</ParentElement>
										) : (
											<ChildElement
												onClick={() => (handleCategoryClick(elem), handleModal())}
												className={
													categoriesListState[column].active &&
													categoriesListState[column].active.id === elem.id
														? "selected"
														: ""
												}
											>
												<span>{elem.title}</span>
											</ChildElement>
										)
									)}
								</>
							</CategoryColumnWrapper>
						))}
					</CategoryContentWrapper>
				</>
			)}
		</ModalContentWrapper>
	);
};
