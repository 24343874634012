import axios from "axios";
import { baseUrl } from "./consts";

export const getCountries = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/frame/5`)
			.then((countries) => {
				dispatch({
					type: "GET_COUNTRIES",
					countries,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
