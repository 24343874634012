import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { DText15, H2Text } from "../../../../ui-kit/typography/Typography";
import { formatDate } from "../../VideosPage/sctipts";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import StatusSelect from "./StatusSelect";
import { ReportInfo } from "./ReportInfo";
import { getCurrentReport, putCurrentReport } from "../../../../Actions/ReportsActions";
import ReportHistory from "./ReportHistory";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";
import { baseUrl } from "../../../../Actions/consts";
import axios from "axios";

const ReportDetail = ({ title, currentReport, getCurrentReport, putCurrentReport }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [ticketState, setTicketState] = useState(null);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		const ticketId = searchParams.get("id");
		if (!ticketId) {
			navigate("/reports");
			return;
		}
		getCurrentReport(ticketId);
	}, [searchParams]);

	useEffect(async () => {
		if (!currentReport) return;

		if (currentReport.status === 0) {
			putCurrentReport(currentReport.id, {
				status: 1,
			});
		}

		setTicketState(currentReport);
		if (currentReport.ticket_type !== 3) return;
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/comments/${currentReport.comment_id}`,
				data: "",
			})
			.then((com) => {
				setTicketState({ ...currentReport, comment_text: com.data.text });
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [currentReport]);

	return (
		<section className="personal-area-item edit viewer-statistic">
			<header className="personal-area edit-videos-header" style={{ marginBottom: "0" }}>
				<Cluster align="center" justify="space-between">
					<Stack space="24px">
						<button className="form-page-back" onClick={() => navigate("/reports")}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
									fill="#94A0B1"
								/>
							</svg>
							Вернуться назад
						</button>
						<H2Text>Тикет {ticketState?.id}</H2Text>
						<DText15 style={{ color: "#94A0B1" }}>
							Создан {formatDate(ticketState?.created_at, 3)}
						</DText15>
					</Stack>
					{ticketState && (
						<StatusSelect
							ticket={ticketState}
							disabled={!checkUserWritePerm(userAccessState, "reports")}
						/>
					)}
				</Cluster>
			</header>
			{ticketState && (
				<>
					<div className="flex-wrapper">
						<ReportInfo report={ticketState} />
					</div>

					<div className="flex-wrapper">
						<ReportHistory report={ticketState} />
					</div>
				</>
			)}
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		currentReport: state.currentReport,
	};
};

const mapDispatchToProps = {
	getCurrentReport,
	putCurrentReport
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
