import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getAllActiveExternalSeller } from "../../../../Actions/SellersActions.js";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import "../styles/SellerStyles.scss";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { CancelButton, EmptyFallback } from "./Buttons.jsx";
import ExtertnalPaginationBlock from "../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import BanSellerModal from "./modal/BanSellerModal.jsx";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { useLazyQuery } from "@apollo/client";
import { GET_SELLER_TIPS } from "../../../../GraphQL/SellerStatistic.js";

const ActiveSellerPage = ({
	getAllActiveExternalSeller,
	sellersList,
	mainFilterState,
	setMainFilterState,
}) => {
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { handleModal } = useContext(ModalContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const [sellersState, setSellersState] = useState([]);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getAllActiveExternalSeller({ ...filteredState });
		if ((!firstLoad && lastSearch) || filteredFilters.page !== searchParams.get("page")) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	useEffect(() => {
		setSellersState(sellersList.list);
	}, [sellersList]);

	const handleCancelClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<BanSellerModal id={id} filteredState={mainFilterState} />);
	};

	const handleOrderClick = (key, filterState, setFilterState) => {
		if (!("order_by" in filterState)) {
			setFilterState({ ...filterState, order_by: key });
			return;
		}
		if (filterState.order_by !== key) {
			setFilterState({ ...filterState, order_by: key });
		} else {
			setFilterState({ ...filterState, order_by: `-${key}` });
		}
	};

	return (
		<div className="active-seller-page">
			{sellersState.length > 0 ? (
				<>
					<div className="list-table-header seller-list">
						<div
							className="list-table-header-element"
							style={{ width: "240%" }}
							onClick={() => handleOrderClick("name", mainFilterState, setMainFilterState)}
						>
							<span>Название</span>
						</div>
						<div
							className="list-table-header-element non-sort"
							style={{ width: "166%" }}
							// onClick={() => handleOrderClick("tips_bought", mainFilterState, setMainFilterState)}
						>
							<span>
								Типсов
								<br />
								закуплено
							</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "214%" }}
							onClick={() =>
								handleOrderClick("money_replenished", mainFilterState, setMainFilterState)
							}
						>
							<span>Всего внесено, ₽</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "195%" }}
							onClick={() => handleOrderClick("money_spent", mainFilterState, setMainFilterState)}
						>
							<span>
								Потрачено
								<br />
								на рекламу, ₽
							</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "174%" }}
							onClick={() => handleOrderClick("balance", mainFilterState, setMainFilterState)}
						>
							<span>Остаток, ₽</span>
						</div>
						{checkUserWritePerm(userAccessState, "sellers") ? (
							<div className="list-table-header-element" style={{ width: "115px" }}></div>
						) : null}
					</div>
					<div className="list-table stat-list selers-list">
						{sellersState.map((elem, index) => (
							<SellerListItem handleCancelClick={handleCancelClick} elem={elem} key={index} />
						))}
					</div>
				</>
			) : (
				<EmptyFallback>Пока нет рекламодателей</EmptyFallback>
			)}
			{Object.keys(sellersList.pagination).length !== 0 &&
			!!sellersList.pagination.max_pages &&
			sellersState.length > 0 &&
			sellersList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={sellersList.pagination}
				/>
			) : null}
		</div>
	);
};
const SellerListItem = ({ elem, handleCancelClick }) => {
	const [getTipStatistic, { data }] = useLazyQuery(GET_SELLER_TIPS);
	const [sellerState, setSellerState] = useState(null);
	const [fullSellerState, setFullSellerState] = useState(null);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		axios
			.get(`${baseUrl}/domain/sellers/${elem.id}`)
			.then((seller) => {
				setSellerState(seller.data);
			})
			.catch(() => {
				return null;
			});
	}, [elem]);

	useEffect(() => {
		if (!sellerState) return;
		axios
			.get(`${baseUrl}/seller/${sellerState?.id}`)
			.then((seller) => {
				setFullSellerState(seller.data);
			})
			.catch(() => {
				return null;
			});
		getTipStatistic({
			variables: { seller_id: sellerState?.id },
		});
	}, [sellerState]);

	return (
		<Link to={`/sellers-statistic?id=${elem?.id}`} className="list-table-item">
			<div className="list-table-item-element">
				{elem.avatar_url && elem.avatar_url?.length > 0 ? (
					<img src={elem.avatar_url} alt="profile" />
				) : (
					<img src="images/profile-frame.png" alt="profile" />
				)}
				<div className="list-table-item-element-info">
					<span>{elem.name}</span> <small>ID {sellerState?.id}</small>
				</div>
			</div>
			<div className="list-table-item-element">
				<span>{data?.click?.pageInfo?.itemsCount || 0}</span>
			</div>
			<div className="list-table-item-element">
				<span>{elem?.money_replenished / 100 || 0}</span>
			</div>
			<div className="list-table-item-element">
				<span>{elem?.money_spent / 100 || 0}</span>
			</div>
			<div className="list-table-item-element">
				<span>{elem?.balance / 100 || 0}</span>
			</div>
			{checkUserWritePerm(userAccessState, "sellers") ? (
				<div className="list-table-item-element" style={{ paddingLeft: "0" }}>
					<CancelButton onClick={(e) => handleCancelClick(e, elem.id)}>Отключить</CancelButton>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		sellersList: state.allExternalSelllersReducer.active,
	};
};

const mapDispatchToProps = {
	getAllActiveExternalSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSellerPage);
