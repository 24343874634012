import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { deleteMoviePlaylist } from "../../../../Actions/PlayListActions.js";
import { takeId } from "../../scripts/PersonalAreaScripts.js";
import { PlaylistSortableContainer, PlaylistSortableItem } from "./PlaylistSortableItem.jsx";

// врапперы видео
const PlaylistItem = ({
	filmState,
	setFilmState,
	loader,
	playlistItem,
	index: PlaylistIndex,
	deleteMoviePlaylist,
}) => {
	const [localFilmState, setLocalFilmState] = useState([]);
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.map((elem, index) => (elem.order = index + 1));
		return result;
	};

	useEffect(() => {
		const timeArray = [...filmState];
		const sortedFilms = timeArray[PlaylistIndex].movies;
		sortedFilms.sort((a, b) => {
			if (Number(a.order) > Number(b.order)) {
				return 1;
			}
			if (Number(a.order) < Number(b.order)) {
				return -1;
			}
			return 0;
		});
		timeArray[PlaylistIndex].movies = sortedFilms;
		setLocalFilmState(timeArray);
	}, [filmState]);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const items = reorder(localFilmState[PlaylistIndex].movies, oldIndex, newIndex);
		const timeArray = [...localFilmState];
		timeArray[PlaylistIndex].movies = items;

		let arr = [...timeArray[PlaylistIndex].movies];
		arr = takeId(arr, "movie_id");
		const resultobj = arr.map((elem, index) => ({ [String(elem)]: String(index + 1) }));
		deleteMoviePlaylist(timeArray[PlaylistIndex].id, {
			...timeArray[PlaylistIndex],
			movies: resultobj,
		});

		setFilmState(timeArray);
	};

	const onSortStart = ({ node }) => {};
	return playlistItem.movies?.length > 0 ? (
		// контейнер перетаскивания
		<PlaylistSortableContainer
			onSortEnd={onSortEnd}
			useDragHandle
			onSortStart={onSortStart}
			helperClass="form-container-block-playlist"
		>
			{playlistItem.movies.map((childElem, index) => (
				<PlaylistSortableItem
					key={`item-${index}`}
					index={index}
					value={{ childElem, index }}
					playlistIndex={PlaylistIndex}
					filmState={localFilmState}
					setFilmState={setFilmState}
					deleteMoviePlaylist={deleteMoviePlaylist}
				/>
			))}
		</PlaylistSortableContainer>
	) : null;
};

// const mapStateToProps = (state) => {
// 	return {
// 		loader: state.loadingStatus,
// 	};
// };

const mapDispatchToProps = {
	deleteMoviePlaylist,
};
export default connect(null, mapDispatchToProps)(PlaylistItem);
