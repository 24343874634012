export const handleBloggerSelectFilters = (
	event,
	filterKey,
	item,
	deleteState = null,
	setDeleteState = null,
	dropDownState,
	setDropdownState,
	mainState,
	setMainState
) => {
	event.stopPropagation();
	if (deleteState !== null && setDeleteState !== null) {
		setDeleteState({ ...deleteState, currentValue: [] });
	}
	setMainState({
		...mainState,
		page: 1,
		filters: { ...mainState.filters, [filterKey]: item },
	});
	setDropdownState({ ...dropDownState, [filterKey]: !dropDownState[filterKey] });
};

export const handleBloggerMultiSelectActiveClick = (
    evt,
    localState,
    setLocalState,
    item
  ) => {
    evt.stopPropagation();
    const current = evt.currentTarget;
    let timeArray = localState.currentValue;
    let newArray = timeArray;
    if (current.classList.contains("selected")) {
      newArray = timeArray.filter((elem) => item.id !== elem);
    } else {
      newArray.push(item.id);
    }
    // current.classList.toggle("selected");
    setLocalState({ ...localState, currentValue: newArray });
  };