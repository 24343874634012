import { gql } from "@apollo/client";

export const GET_SELLER_TIPS = gql`
	query getStatisticPage($seller_id: Int, $lte: DateTime, $gte: DateTime) {
		show: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, seller_id: $seller_id, click: 0, created_at: { gte: $gte, lte: $lte } }
			sort: { field: price, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
		click: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, seller_id: $seller_id, click: 1, created_at: { gte: $gte, lte: $lte } }
			sort: { field: price, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;

export const GET_TOP5_SELLER_STATISTIC = gql`
	query getEfficiencyItem($seller_id: Int, $lte: DateTime, $gte: DateTime) {
		product: getEfficiencyItem(
			filter: { owner_id: 1, seller_id: $seller_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_products {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		category: getCatEfficiencyItem(
			filter: { owner_id: 1, seller_id: $seller_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_products_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
		}
	}
`;
