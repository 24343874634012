import React from "react";
import { connect } from "react-redux";
import { formatTimeCode } from "../../components/PersonalArea/VideosPage/sctipts.js";

const GadgetsDescriptionComponent = ({
	name,
	className = "",
	type,
	data,
	total_amount,
	total_watch,
}) => {
	return (
		<>
			<div className={`statistic-description-content ${className}`}>
				<div className="statistic-content-wrapper">
					<div
						className="statistic-description-content__title statistic-description-row-element"
						style={{ width: "20%" }}
					>
						<b>{name}</b>
					</div>
					<div
						className="statistic-description-content__title statistic-description-row-element"
						style={{ width: "30%" }}
					>
						<div className="form-container-progress-block-right">
							<div className="form-container-progress-block-line">
								<div
									className="form-container-progress-block-linefill"
									style={{
										width: total_amount
											? (data?.amount / total_amount).toFixed(2) * 100 + "%"
											: 0 + "%",
									}}
								></div>
							</div>
							<span className="percent-value">
								{total_amount
									? Math.round((data?.amount / total_amount).toFixed(2) * 100) + "%"
									: 0 + "%"}
							</span>
							<span className="current-value">{(data?.amount || 0).toLocaleString("ru-RU")}</span>
						</div>
					</div>
					<div
						className="statistic-description-content__title statistic-description-row-element"
						style={{ width: "31%" }}
					>
						{" "}
						<div className="form-container-progress-block-right">
							<div className="form-container-progress-block-line">
								<div
									className="form-container-progress-block-linefill"
									style={{
										width: total_watch
											? Math.round((data?.total_watch_time / total_watch).toFixed(2) * 100) + "%"
											: 0 + "%",
									}}
								></div>
							</div>
							<span className="percent-value">
								{Math.round((data?.total_watch_time / total_watch || 0).toFixed(2) * 100) + "%"}
							</span>
							<span className="current-value">
								{data?.total_watch_time / 3600 > 1000
									? data?.total_watch_time.toLocaleString("ru-RU")
									: formatTimeCode(data?.total_watch_time || 0)}
							</span>
						</div>
					</div>
					<div
						className="statistic-description-content__title statistic-description-row-element"
						style={{ width: "19%" }}
					>
						<span>{formatTimeCode(data?.average_session_time || 0)}</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default connect(null, null)(GadgetsDescriptionComponent);
