import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const StyledTitleSpan = styled.span`
	&::before {
		background-color: ${(props) => props.color};
	}
`;

const SourcesDescriptionComponent = ({ className = "", sourceData }) => {
	return (
		<div className={`statistic-description-content ${className}`}>
			<div className="statistic-content-wrapper">
				<div
					className="statistic-description-content__title statistic-description-row-element"
					style={{ width: "20%" }}
				>
					<StyledTitleSpan className={`title`} color={sourceData.color}>
						{sourceData.title}
					</StyledTitleSpan>
				</div>
				<div
					className="statistic-description-content__title statistic-description-row-element"
					style={{ width: "30%" }}
				>
					<div className="form-container-value-block-right">
						<span className="current-value">{sourceData.value.toLocaleString("ru-RU")}</span>
						<b className="percent-value">{sourceData.percentValue}</b>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, null)(SourcesDescriptionComponent);
