import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { COUNTRIES_COLOR } from "./static.js";
import { Accordion } from "../../../../ui-kit/accordion/Accordion.jsx";

export const UserStatisticInfoAccordionField = ({ title, collection = {} }) => {
  const AccordionContent = ({ data, color }) => {
    return data?.length > 0 ? (
      <div className="form-container-progress-blocks">
        {data.map((region, index) => (
          <div
            className="form-container-progress-block"
            key={`${region?.id}-${index}`}
          >
            <span style={{ fontWeight: "400" }}>{region.name}</span>
            <div className="form-container-progress-block-right">
              <div className="form-container-progress-block-line">
                <div
                  className="form-container-progress-block-linefill"
                  style={{
                    width: Math.round(region.percent) + "%",
                    backgroundColor: color,
                  }}
                ></div>
              </div>
              <span style={{ color: color, fontWeight: "400" }}>{`${Math.round(
                region.percent
              )}%`}</span>
              <span style={{ color: color, fontWeight: "400" }}>
                {Math.round(region.total_count)}
              </span>
            </div>
          </div>
        ))}
      </div>
    ) : null;
  };

  return collection && collection?.length > 0 ? (
    <div className="form-container-progress-blocks">
      {!!title && (
        <div className="form-container-progress-block header">
          <span>
            <b>{title}</b>
          </span>
        </div>
      )}
      {collection.map((elem, index) => {
        return (
          <Accordion
            key={index}
            headerContent={() => (
              <div className="form-container-progress-block">
                <span className="header-name">{elem.name}</span>
                <div className="form-container-progress-block-right">
                  <div className="form-container-progress-block-line">
                    <div
                      className="form-container-progress-block-linefill"
                      style={{
                        // width: Math.round(elem.percent) + "%",
                        // backgroundColor: COUNTRIES_COLOR[index % 5],
                      }}
                    ></div>
                  </div>
                  <span
                    style={{ color: COUNTRIES_COLOR[index % 5] }}
                  >{`${Math.round(elem.percent)}%`}</span>
                  <span style={{ color: COUNTRIES_COLOR[index % 5] }}>
                    {elem.total_count}
                  </span>
                </div>
              </div>
            )}
          >
            {elem?.cities && (
              <AccordionContent
                data={elem.cities}
                color={COUNTRIES_COLOR[index % 5]}
              />
            )}
          </Accordion>
        );
      })}
    </div>
  ) : null;
};

// const mapStateToProps = (state) => {
//   return {
//   };
// };

const mapDispatchToProps = {};

export default connect(null, null)(UserStatisticInfoAccordionField);
