import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styles/OwnerStyles.scss";
import { baseUrl } from "../../../Actions/consts";
import { history } from "../../../Store/Store";
import { initialOwnerTab } from "./static.js";
import OwnerTabs from "./owner-tabs/OwnerTabs.jsx";
import BlockedBloggerPage from "./pages/BlockedOwnerPage.jsx";
import ActiveBloggerPage from "./pages/ActiveOwnerPage.jsx";
import OwnerFiltersComponent from "./OwnerFilters/OwnerFiltersComponent.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import AddOwnerPopup from "./add-owner-popup/AddOwnerPopup";
import axios from "axios";
import { useUserAccessContext } from "../context/UserAccessContext";
import { checkUserWritePerm } from "../scripts/utils";

const OwnerPage = ({ title }) => {
	const [tab, setTab] = useState(initialOwnerTab[0]);
	const { handleModal } = useContext(ModalContext);

	useEffect(() => {
		document.title = title;
	}, [title]);

	const [lastSearch, setLastSearch] = useState(true);
	const { userAccessState } = useUserAccessContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const [total, setTotal] = useState(0);

	const [mainFilterState, setMainFilterState] = useState({
		filters: searchParams.get("filters") !== null ? JSON.parse(searchParams.get("filters")) : {},
		limit: searchParams.get("limit") !== null ? searchParams.get("limit") : 20,
		offset: searchParams.get("offset") !== null ? searchParams.get("offset") : 0,
	});

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					limit: searchParams.get("limit") !== null ? searchParams.get("limit") : 20,
					offset: searchParams.get("offset") !== null ? searchParams.get("offset") : 0,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		axios
			.get(`${baseUrl}/domain/owners`, {
				params: { limit: 1, offset: 0 },
			})
			.then((owner) => {
				setTotal(owner.data.paginate_.total_rows);
			});
	}, []);

	return (
		<section className="personal-area-item owner">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Правообладатели
					<sup>{total || 0}</sup>
				</h1>
				<button
					disabled={!checkUserWritePerm(userAccessState, "owners")}
					className="button_add-category"
					onClick={() => handleModal(<AddOwnerPopup />)}
					style={{ marginRight: "20px" }}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить правообладателя
				</button>
			</header>
			<OwnerTabs collection={initialOwnerTab} active={tab} onChange={setTab} />
			<div className="videos-content-wrapper content-wrapper">
				<OwnerFiltersComponent filterState={mainFilterState} setFilterState={setMainFilterState} />
				{tab.id === 1 ? (
					<ActiveBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 3 ? (
					<BlockedBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : null}
			</div>
		</section>
	);
};

export default OwnerPage;
