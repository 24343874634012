import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { baseUrl, marketUrl } from "../../../../../Actions/consts";

export const AcceptOrderModal = ({ id, onSuccess, order }) => {
	const { handleModal } = useContext(ModalContext);
	const [loaderState, setLoaderState] = useState(() => false);

	const handleAddClick = () => {
		setLoaderState(true);
		// axios
		// 	.post(`${baseUrl}/domain/other`, {
		// 		method: "POST",
		// 		service: 3,
		// 		endpoint: `/api/balance/balance-transactions/${id}/approve2`,
		// 		data: "",
		// 	})
		// 	.then(() => {
		// 		onSuccess();
		// 	})
		// 	.finally(() => {
		// 		setLoaderState(false);
		// 		handleModal();
		// 	})
		// 	.catch(() => {
		// 		return null;
		// 	});
		axios
			.post(`${baseUrl}/domain/other`, {
				method: "POST",
				service: 3,
				endpoint: `/api/balance/balance-transactions/${id}/approve`,
				data: "",
			})
			.then(() => {
				onSuccess();
			})
			.finally(() => {
				setLoaderState(false);
				handleModal();
			})
			.catch(() => {
				return null;
			});
	};
	return (
		<>
			<div className="add-category-wrapper">
				<div className="modal-field-title">
					Подтвердить оплату
					<br />
					на сумму {order.amount / 100} руб.
				</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="modal-buttons">
					<button className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отмена</span>
					</button>
					<button
						className={`button action-button create-category-button ${
							loaderState ? "preloader" : ""
						}`}
						onClick={() => handleAddClick()}
					>
						<span>Принять</span>
					</button>
				</div>
			</div>
		</>
	);
};
