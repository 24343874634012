import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App/App.jsx";
import { appStore, history } from "./Store/Store";
import { setupInterceptors } from "./services/setupInterceptors.js";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { graphqlDomen } from "./Actions/consts";

export const client = new ApolloClient({
	// TODO: пересадить uri на env переменную и привязаться к ней
	uri: graphqlDomen,
	cache: new InMemoryCache(),
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<Provider store={appStore}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	</ApolloProvider>,
	document.getElementById("root")
);

setupInterceptors();
