export const initialAccessVideoState = [
	{
		id: 1,
		title: "Разрешить видео",
		value: true,
	},
	{
		id: 2,
		title: "Запретить видео",
		value: false,
	},
];

export const initialAccessTipsState = [
	{
		id: 1,
		title: "Показывать типсы",
		value: true,
	},
	{
		id: 2,
		title: "Не показывать типсы",
		value: false,
	},
];

export const accessCountry = [
	{
		id: 1,
		name: "Весь мир",
	},
	{
		id: 2,
		name: "Определенные страны",
	},
];
