import * as React from "react";
import { useState } from "react";
import { getPeriod } from "../scripts/period";
import { useEffect } from "react";
import "./period-filter.scss";
export const PeriodFilters = ({ filtersState, setFiltersState }) => {
  const [periodState, setPeriodState] = useState(getPeriod());
  const [activeDropDown, setActiveDropDown] = useState(false);

  const handleSelectAreaClick = (event, key) => {
    event.stopPropagation();
    setFiltersState({
      ...filtersState,
      from_datetime: periodState[key].startDate,
      to_datetime: periodState[key].endDate,
      period_name: periodState[key].name,
      group_by: periodState[key].group_by,
      date_key: key,
    });
    setActiveDropDown(!activeDropDown);
  };

  useEffect(() => {
    if (!("data_key" in filtersState)) {
      setFiltersState({
        from_datetime: periodState[4].startDate,
        to_datetime: periodState[4].endDate,
        period_name: periodState[4].name,
        group_by: periodState[4].group_by,
        date_key: 4,
      });
    }
  }, []);

  return (
    <>
      <div
        className={`list-title-select ${activeDropDown ? "active" : ""}`}
        tabIndex="0"
        onClick={(e) => setActiveDropDown(!activeDropDown)}
        onBlur={(e) => setActiveDropDown(false)}
      >
        <div className="list-title-select-btn">
          {periodState[filtersState?.date_key]?.title}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99991 9.58545L8.70701 10.2926L11.9999 13.5854L15.2928 10.2926L15.9999 9.58545L17.4141 10.9997L16.707 11.7068L12.707 15.7068C12.3165 16.0973 11.6833 16.0973 11.2928 15.7068L7.2928 11.7068L6.58569 10.9997L7.99991 9.58545Z"
              fill="#212121"
            ></path>
          </svg>
        </div>
        <div className="list-title-select-submenu">
          {Object.keys(periodState).map((key) => (
            <div
              className="list-title-select-submenu-item"
              key={key}
              onClick={(e) => handleSelectAreaClick(e, key)}
            >
              {periodState[key].title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
