import styled from "styled-components";

export const ErrorNotification = styled.div`
	width: 100%;
	padding: 6px 16px;
	text-align: center;
	color: #ed0a34;
	font-size: 15px;
	font-weight: 400;
`;

export const EmptyNotification = styled.div`
	width: 100%;
	padding: 6px 16px;
	text-align: center;
	color: #94a0b1;
	font-weight: 400;
	font-size: 15px;
`;
