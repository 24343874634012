import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleClearSelectedClick, onRemoveItem, onSelectItem } from "../../../VideosPage/sctipts";

const initialState = [
	{
		id: 359,
		title: "0-17",
	},
	{
		id: 360,
		title: "18-24",
	},
	{
		id: 361,
		title: "25-35",
	},
	{
		id: 362,
		title: "36-48",
	},
	{
		id: 363,
		title: "49-56",
	},
	{
		id: 364,
		title: "57-65",
	},
	{
		id: 365,
		title: "66+",
	},
];

const TipAgeSelect = ({ tipSettingsState, setTipSettingsState }) => {
	const multiselectRef = React.createRef();
	const [ageState, setAgeState] = useState({
		age: tipSettingsState.attributes_settable.filter((elem) => elem.id >= 359 && elem.id <= 365),
	});
	const [flagState, setFlagState] = useState(false);

	useEffect(() => {
		if (flagState) return;
		const filteredState = tipSettingsState.attributes_settable.filter(
			(elem) => elem.id >= 359 && elem.id <= 365
		);

		setAgeState({ age: filteredState });
		setFlagState(true);
	}, [tipSettingsState]);

	useEffect(() => {
		let attrs = tipSettingsState.attributes_settable.filter(
			(elem) => elem.id < 359 || elem.id > 365
		);
		attrs = [...attrs, ...ageState.age];
		setTipSettingsState({ ...tipSettingsState, attributes_settable: attrs });
	}, [ageState]);

	return (
		<div className="input-field" style={{ position: "relative" }}>
			<button
				className={`clear-chosen-container ${ageState?.age?.length >= 1 ? "active" : ""}`}
				style={{ zIndex: 3 }}
				onClick={() => handleClearSelectedClick(ageState, setAgeState, "age", multiselectRef)}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
						fill="#212121"
					></path>
				</svg>
			</button>
			<Multiselect
				ref={multiselectRef}
				className="multiCustom"
				options={initialState} // Options to display in the dropdown
				selectedValues={ageState.age || []} // Preselected value to persist in dropdown
				onSelect={(selectedList, selectedItem) =>
					onSelectItem(selectedList, selectedItem, ageState, setAgeState, "age")
				} // Function will trigger on select event
				onRemove={(selectedList, removeItem) =>
					onRemoveItem(selectedList, removeItem, ageState, setAgeState, "age")
				} // Function will trigger on remove event
				displayValue="title" // Property name to display in the dropdown options
				hidePlaceholder={true}
				avoidHighlightFirstOption={true}
				emptyRecordMsg="Нет доступных"
				customCloseIcon={
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
							fill="#212121"
						/>
					</svg>
				}
				placeholder="Все"
			/>
		</div>
	);
};

export default connect(null, null)(TipAgeSelect);
