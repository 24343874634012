import React, { useEffect } from "react";
import { GET_VIDEO_TIPS } from "../../../../../GraphQL/Statistic";
import { useLazyQuery } from "@apollo/client";

export const MovieTipsStatistic = ({ videoState, tipsCount }) => {
	const [getShowStatistic, { data: showData }] = useLazyQuery(GET_VIDEO_TIPS, {
		variables: {
			click: 0,
			video_id: null,
		},
	});
	const [getClickStatistic, { data: clickData }] = useLazyQuery(GET_VIDEO_TIPS, {
		variables: {
			click: 1,
			video_id: null,
		},
	});

	useEffect(() => {
		if (!videoState) return;
		getShowStatistic({
			variables: {
				click: 0,
				video_id: videoState?.id,
			},
		});
		getClickStatistic({
			variables: {
				click: 1,
				video_id: videoState?.id,
			},
		});
	}, [videoState]);

	return videoState ? (
		<>
			<div className="form-container-right-item">
				Кол-во типсов <span>{tipsCount ? tipsCount : 0}</span>
			</div>
			<div className="form-container-right-item">
				Показы типсов{" "}
				<span>
					{showData?.getStatisticPage?.pageInfo?.itemsCount
						? showData?.getStatisticPage?.pageInfo?.itemsCount
						: 0}
				</span>
			</div>
			<div className="form-container-right-item">
				Переходы{" "}
				<span>
					{clickData?.getStatisticPage?.pageInfo?.itemsCount
						? clickData?.getStatisticPage?.pageInfo?.itemsCount
						: 0}
				</span>
			</div>
			<div className="form-container-right-item">
				Доход, ₽{" "}
				<span>
					{showData?.getVideosPage?.items?.length ? showData?.getVideosPage?.items[0].profit : 0}
				</span>
			</div>
			<div className="form-container-right-divider"></div>
		</>
	) : null;
};
