import React from "react";
import { connect } from "react-redux";
import GadgetsChartComponent from "./GadgetsChartComponent.jsx";
import GadgetsDescriptionComponent from "./GadgetsDescriptionComponent.jsx";
import "./GadgetsStatisticStyle.scss";
import { StatisticBlockContainer } from "../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";

const GadgetsStatisticComponent = ({ lines, period, statisticState }) => {
	return statisticState ? (
		<StatisticBlockContainer>
			<div className="gadgets-statistic-header">
				<div className="gadgets-statistic-header__title">Устройства</div>
			</div>
			<div className="gadgets-statistic-content">
				<GadgetsChartComponent lines={lines} period={period} />
			</div>
			{/* TODO: вынести в отдельный компонент */}
			<div className="gadgets-statistic-description">
				<div className="statistic-description-header">
					<div
						className="statistic-description-header__title statistic-description-row-element"
						style={{ width: "20%" }}
					>
						Тип Устройства
					</div>
					<div
						className="statistic-description-header__title statistic-description-row-element"
						style={{ width: "30%" }}
					>
						Просмотры
					</div>
					<div
						className="statistic-description-header__title statistic-description-row-element"
						style={{ width: "31%" }}
					>
						Время просмотра, часы
					</div>
					<div
						className="statistic-description-header__title statistic-description-row-element"
						style={{ width: "19%" }}
					>
						Среднее время просмотра
					</div>
				</div>
				<GadgetsDescriptionComponent
					type="mobile"
					name="Мобильные устройства"
					className="blue"
					data={statisticState.mobile}
					total_amount={statisticState.amount}
					total_watch={statisticState.total_watch_time}
				/>
				<GadgetsDescriptionComponent
					type="desktop"
					name="Десктоп"
					className="pink"
					data={statisticState.desktop}
					total_amount={statisticState.amount}
					total_watch={statisticState.total_watch_time}
				/>
				<GadgetsDescriptionComponent
					type="tv"
					name="ТВ"
					className="green"
					data={statisticState.tv}
					total_amount={statisticState.amount}
					total_watch={statisticState.total_watch_time}
				/>
				<GadgetsDescriptionComponent
					type="tablet"
					name="Планшет"
					className="purple"
					data={statisticState.tablet}
					total_amount={statisticState.amount}
					total_watch={statisticState.total_watch_time}
				/>
			</div>
		</StatisticBlockContainer>
	) : null;
};

export default connect(null, null)(GadgetsStatisticComponent);
