import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { getFilmCrew } from "../../../../../Actions/videos-actions/VideosActions";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import CreateFilmCrew from "../../CreateFilmCrew/CreateFilmCrew.jsx";
import { onSelectItemModal } from "../../sctipts";

import "../styles/ModalStyle.scss";
import FilmCrewSelect from "../../../../universal/FilmCrewSelect/FilmCrewSelect";
import styled from "styled-components";

const StyledSearchControl = styled.div`
	background: #f4f4f8;
	border-radius: 8px;
`;

const SelectFilmCrew = ({
	getFilmCrew,
	type,
	filmCrewList,
	filmState,
	setFilmState,
	userAccessState,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [filmCrewState, setFilmCrewState] = useState([]);
	// useEffect(() => {
	// 	getFilmCrew();
	// }, []);

	// useEffect(() => {
	// if (filmCrewList.length > 0) {
	// setFilmCrewState(filmCrewList);
	// }
	// }, [filmCrewList]);

	return (
		<div className="modal-field add-people-modal active">
			<div className="modal-field-title">
				Добавить{" "}
				{type === 1
					? "актёра"
					: type === 2
					? "режиссера"
					: type === 3
					? "сценариста"
					: type === 4
					? "оператора"
					: type === 5
					? "продюсера"
					: ""}
			</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block flex-start">
				<div className="form-field">
					<div className="input-field">
						<StyledSearchControl>
							<FilmCrewSelect
								type={type}
								selected={filmCrewState}
								onClear={() => setFilmCrewState([])}
								onSelect={(newValue) =>
									onSelectItemModal(null, newValue, filmState, setFilmState, type, handleModal)
								}
								placeholder="Поиск по имени или фамилии"
							/>
						</StyledSearchControl>
						{/* <Multiselect
							className="single"
							options={filmCrewState.filter((item) => item.position?.id === type)} // Options to display in the dropdown
							onSelect={(selectedList, selectedItem) =>
								onSelectItemModal(
									selectedList,
									selectedItem,
									filmState,
									setFilmState,
									type,
									handleModal
								)
							} // Function will trigger on select event
							displayValue="first_name" // Property name to display in the dropdown options
							hidePlaceholder={true}
							placeholder={"Поиск по имени"}
							avoidHighlightFirstOption={true}
							loading={filmCrewState.length > 0 ? false : true}
						/> */}
					</div>
				</div>
			</div>
			<div className="modal-buttons" style={{ marginTop: "24px" }}>
				<div
					className="button action-button border-button"
					onClick={() =>
						handleModal(<CreateFilmCrew userAccessState={userAccessState} type={type} />)
					}
				>
					<span>
						Создать новую карточку{" "}
						{type === 0
							? "актёра"
							: type === 1
							? "режиссера"
							: type === 2
							? "сценариста"
							: type === 3
							? "оператора"
							: type === 4
							? "продюсера"
							: ""}
					</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		filmCrewList: state.filmCrewList,
	};
};

const mapDispatchToProps = {
	getFilmCrew,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFilmCrew);
