import axios from "axios";
import { baseUrl } from "../consts";
import { getVideo } from "./getMovieVideo";

export const postAdditionalVideos = (additionalDatas, movie_id, movie_title) => {
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/video`, { ...additionalDatas, movie_id: movie_id, title: movie_title })
			.then((video) => {
				dispatch(getVideo(movie_id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postAdditionalSerial = (additionalDatas, movie_id, movie_title) => {
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/serial`, { ...additionalDatas, movie_id: movie_id, title: movie_title })
			.then((video) => {
				dispatch(getVideo(movie_id));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postMovies = (datas, additionalDatas) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/movies`, datas)
			.then((video) => {
				if (video.data.movie_format_id === 1) {
					dispatch(
						postAdditionalVideos(
							datas?.URL_SD_HORIZONTAL_PREVIEW
								? { ...additionalDatas, URL_SD_PREVIEW: datas?.URL_SD_HORIZONTAL_PREVIEW }
								: additionalDatas,
							video.data.id,
							video.data.title
						)
					);
				} else {
					dispatch(postAdditionalSerial(additionalDatas, video.data.id, video.data.title));
				}
			})
			// .finally(() => {
			// 	dispatch({
			// 		type: "HIDE_MAIN_LOADER",
			// 	});
			// })

			// .then(() => {
			// 	dispatch({
			// 		type: "HIDE_ALL",
			// 	});
			// })
			.catch((error) => {
				console.log(error);
			});
	};
};
