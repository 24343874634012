import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import { getFormatedNumberValue } from "../scripts";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip";
import { useLazyQuery } from "@apollo/client";
import { GET_TOP5_STATISTIC } from "../../../../GraphQL/HomePage";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import styled from "styled-components";
import { DTBold14 } from "../../../../ui-kit/typography/Typography";

const StyledButton = styled(DTBold14)`
	cursor: pointer;
	color: #94a0b1;
	&.active {
		color: #212121;
	}
`;

const BestCategories = ({ filtersState }) => {
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_TOP5_STATISTIC, {
		variables: {
			blogger_id: null,
		},
	});
	const [bestSelTab, setBestSelTab] = useState(true);
	const [bestTipTab, setBestTipTab] = useState(true);
	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;
		getStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
	}, [filtersState]);

	return (
		<Stack space="16px" style={{ width: "100%" }}>
			<Cluster space="16px">
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие категории товаров</b>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<span>Название</span>
						</div>
						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Переходы</span>
						</div>
						<div className="list-table-header-element">
							<span>Доход, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{data?.getCatEfficiencyItem?.best_products_cat?.map((elem, index) => (
							<CategoryItem elem={elem} key={index} />
						))}
					</div>
				</div>
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие категории услуг</b>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<span>Название</span>
						</div>
						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Переходы</span>
						</div>
						<div className="list-table-header-element">
							<span>Доход, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{data?.getCatEfficiencyItem?.best_services_cat?.map((elem, index) => (
							<CategoryItem elem={elem} key={index} />
						))}
					</div>
				</div>
			</Cluster>
			<Cluster space="16px">
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие товары</b>
					</div>
					<div className="list-table-header home-best-products">
						<div className="list-table-header-element">
							<span>Название</span>
						</div>
						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Переходы</span>
						</div>
						<div className="list-table-header-element">
							<span>Доход, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-products">
						{data?.getEfficiencyItem?.best_products?.map((elem, index) => (
							<ProductItem elem={elem} key={index} />
						))}
					</div>
				</div>
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие услуги</b>
					</div>
					<div className="list-table-header home-best-products">
						<div className="list-table-header-element">
							<span>Название</span>
						</div>
						<div className="list-table-header-element">
							<span>Показы</span>
						</div>
						<div className="list-table-header-element">
							<span>Переходы</span>
						</div>
						<div className="list-table-header-element">
							<span>Доход, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-products">
						{data?.getEfficiencyItem?.best_services?.map((elem, index) => (
							<ProductItem elem={elem} key={index} />
						))}
					</div>
				</div>
			</Cluster>
			<Cluster space="16px">
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие типсы</b>
						<Cluster space="12px">
							<StyledButton
								className={bestTipTab ? "active" : ""}
								onClick={() => setBestTipTab(true)}
							>
								Товары
							</StyledButton>
							<StyledButton
								className={!bestTipTab ? "active" : ""}
								onClick={() => setBestTipTab(false)}
							>
								Услуги
							</StyledButton>
						</Cluster>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<span>ID</span>
						</div>
						<div className="list-table-header-element">
							<span>Переходы</span>
						</div>
						<div className="list-table-header-element">
							<span>Доход, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{bestTipTab
							? data?.getTipsEfficiencyItem?.best_products_tips.map((elem, index) => (
									<TipsItem elem={elem} key={index} />
							  ))
							: data?.getTipsEfficiencyItem?.best_service_tips?.map((elem, index) => (
									<TipsItem elem={elem} key={index} />
							  ))}
					</div>
				</div>
				<div className="home-table half">
					<div className="form-container-block-title">
						<b>Лучшие рекламодатели</b>
						<Cluster space="12px">
							<StyledButton
								className={bestSelTab ? "active" : ""}
								onClick={() => setBestSelTab(true)}
							>
								Товары
							</StyledButton>
							<StyledButton
								className={!bestSelTab ? "active" : ""}
								onClick={() => setBestSelTab(false)}
							>
								Услуги
							</StyledButton>
						</Cluster>
					</div>
					<div className="list-table-header home-best-categories">
						<div className="list-table-header-element">
							<span>Название</span>
						</div>
						<div className="list-table-header-element">
							<span>Типсы</span>
						</div>
						<div className="list-table-header-element">
							<span>Закупки, ₽</span>
						</div>
						<div className="list-table-header-element"></div>
					</div>
					<div className="list-table home-best-categories">
						{bestSelTab
							? data?.getSellersEfficiencyItem?.best_product_sellers.map((elem, index) => (
									<SellerItem elem={elem} key={index} />
							  ))
							: data?.getSellersEfficiencyItem?.best_service_sellers?.map((elem, index) => (
									<SellerItem elem={elem} key={index} />
							  ))}
					</div>
				</div>
			</Cluster>
		</Stack>
	);
};

export const CategoryItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<span>{elem?.name}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.views_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.name} />
		</div>
	);
};

export const TipsItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				{/* <img src={productState?.URL_HD_IMAGE} alt="" /> */}
				<span>{elem?.tips_id}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
		</div>
	);
};
export const SellerItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				{/* <img src={productState?.URL_HD_IMAGE} alt="" /> */}
				<span>{elem?.seller}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.seller} />
		</div>
	);
};

export const ProductItem = ({ elem }) => {
	const [productState, setProductState] = useState();
	const dispatch = useDispatch();

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/product/${elem.product_id}`)
			.then((prod) => {
				setProductState(prod.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [elem]);

	return productState ? (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<img src={productState?.URL_HD_IMAGE} alt="" />
				<span>{elem?.name}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.views_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.name} />
		</div>
	) : null;
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BestCategories);
