import React from "react";
import { connect } from "react-redux";
import { AsideToggleContext } from "../../PersonalArea/context/AsideToggleContext";
import "./styles/Navbar.scss";

const PersonalAreaNavbar = ({ userState }) => {
	const { toggleFunction } = React.useContext(AsideToggleContext);
	return (
		<div className="personal-area-header">
			<div className="personal-area-header-wrapper">
				<button className="button_aside-toggler" onClick={toggleFunction}>
					<img src="images/icons/ui/actions/menu-list.svg" alt="burger" />
				</button>

				{!!userState?.avatar_url && userState?.avatar_url?.length > 0 ? (
					<div
						className="user-profile-icon"
						style={{
							backgroundImage: `url('https://atips.io/${userState.avatar_url}')`,
							backgroundSize: "auto 100%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "50% 50%",
						}}
					></div>
				) : (
					<div
						className="user-profile-icon"
						style={{
							backgroundColor: "#005bff",
						}}
					>
						<span>{`${
							userState?.first_name?.length > 0 ? userState?.first_name?.split("")[0] : ""
						}${
							userState?.second_name?.length > 0 ? userState.second_name?.split("")[0] : ""
						}`}</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default connect(null, null)(PersonalAreaNavbar);
