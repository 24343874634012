import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthUser, postLogin } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";

const Authorization = ({ login, postLogin, getAuthUser }) => {
	let navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(() => false); // состояние скрытия/показа пароля false - скрыть, true - показать
	const [formFieldErrorState, setFormFieldErrorState] = useState(() => false); // состояние ошибки true - ошибка
	const [authState, setAuthState] = useState({
		email: "",
		password: "",
	});
	const handleAuthChange = (evt) => {
		// обработчик заполнение инпутов
		const value = evt.target.value; // присваимваем значение инпута
		setAuthState({ ...authState, [evt.target.name]: value }); //
	};

	const sendUser = () => {
		postLogin(authState);
	};

	useEffect(() => {
		if (login?.success && !!login?.access_token && !!login?.refresh_token) {
			localStorage.setItem("access", login.access_token);
			localStorage.setItem("refresh", login.refresh_token);
			localStorage.setItem("user", JSON.stringify(login.user));
			getAuthUser(login.user.id);
			setFormFieldErrorState(false);
			navigate("/");
		} else if (login?.success !== undefined && !login?.success) {
			setFormFieldErrorState(true);
		}
	}, [login]);

	return (
		<section
			id="auth"
			onKeyDown={(e) => (e.key === "Enter" ? sendUser(formFieldErrorState) : null)}
		>
			<div id="auth-form">
				<img src="images/icons/main/auth_logo.svg" alt="" id="logo" />
				<h2 className="title">Вход в сервис</h2>
				<div className="flex-block">
					<div className={`form-field ${formFieldErrorState ? "error" : ""}`}>
						<div className="form-field-label">Логин</div>
						<div className="input-field">
							<input
								id="login-input"
								name="email"
								value={authState.email}
								onChange={(e) => handleAuthChange(e)}
							/>
						</div>
						<div className="form-field-error">Проверьте правильность ввода</div>
					</div>
				</div>
				<div className="flex-block">
					<div className={`form-field ${formFieldErrorState ? "error" : ""}`}>
						<div className="form-field-label">Пароль</div>
						<div className="input-field">
							<input
								type={showPassword ? "text" : "password"}
								id="password-input"
								name="password"
								value={authState.password}
								onChange={(e) => handleAuthChange(e)}
							/>
							<div
								className={`input-field-eye input-icon ${showPassword ? "show-password" : ""}`}
								onClick={() => setShowPassword(!showPassword)}
							></div>
						</div>
						<div className="form-field-error">Проверьте правильность ввода</div>
					</div>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<button className="button big-button" onClick={() => sendUser(formFieldErrorState)}>
							Войти
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		login: state.login,
	};
};

const mapDispatchToProps = {
	postLogin,
	getAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
