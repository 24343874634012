export const normalizeFilters = (arg) => {
	if (!!arg.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			if (arg.filters[key] !== null) {
				arg = { ...arg, [key]: arg.filters[key] };
			}
		}
		delete arg.filters;
	}
	if (!!arg.sideFilters && arg.sideFilters !== null) {
		const clearSideFilters = arg.sideFilters;
		for (let key in clearSideFilters) {
			if (clearSideFilters[key] !== null) {
				arg = { ...arg, [key]: clearSideFilters[key] };
			}
		}
		delete arg.sideFilters;
	}
	if (!("from_datetime" in arg) || !("to_datetime" in arg)) {
		arg["from_datetime"] = "2021-01-01T00:00:00+00:00";
		arg["to_datetime"] = new Date().toISOString();
	}
	if (!("is_click" in arg)) arg.is_click = true;
	if (!("is_show" in arg)) arg.is_show = false;
	return arg;
};

export const handleStatisticFilterClick = (type, value, mainFilterState, setMainFilterState) => {
	if (type === "sort") {
		if (
			mainFilterState?.order_by === value &&
			(mainFilterState?.reversed === false || mainFilterState?.reversed === true)
		) {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed: !mainFilterState.reversed,
				page: 1,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed: false,
				page: 1,
			});
		}
	} else if (value.length === 0) {
		if (!!mainFilterState.filters && type in mainFilterState.filters) {
			let objectCopy = { ...mainFilterState, page: 1 };
			delete objectCopy.filters[type];
			setMainFilterState(objectCopy);
		}
	} else if (
		type === "formats_ids" ||
		type === "genres_ids" ||
		type === "area" ||
		type === "areaId" ||
		type === "areaid"
	) {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	} else if (type === "years_") {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	}
};

export const handleStatisticSelectFilters = (
	event,
	filterKey,
	item,
	deleteState = null,
	setDeleteState = null,
	dropDownState,
	setDropdownState,
	mainState,
	setMainState
) => {
	event.stopPropagation();
	if (deleteState !== null && setDeleteState !== null) {
		setDeleteState({ ...deleteState, currentValue: [] });
	}
	handleStatisticFilterClick(filterKey, item, mainState, setMainState);
	setDropdownState({ ...dropDownState, [filterKey]: !dropDownState[filterKey] });
};
