import React, { useEffect, useState } from "react";
import "./Accordion.scss";

/**
 */
export const Accordion = ({ headerContent, isOpen = false, children }) => {
	const [open, setOpen] = useState(isOpen);
	return (
		<div className="accordion-wrapper">
			<div className={`accordion-header ${open ? "open" : "close"}`} onClick={() => setOpen(!open)}>
				{headerContent()}
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.00003 9.58545L8.70714 10.2926L12 13.5854L15.2929 10.2926L16 9.58545L17.4142 10.9997L16.7071 11.7068L12.7071 15.7068C12.3166 16.0973 11.6834 16.0973 11.2929 15.7068L7.29292 11.7068L6.58582 10.9997L8.00003 9.58545Z"
						fill="#212121"
					/>
				</svg>
			</div>
			<div className={`accordion-content ${open ? "open" : "close"}`}>{open && children}</div>
		</div>
	);
};
