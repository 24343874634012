export const formatStatisticDate = (value) => {
	const currentDate = new Date(value);
	currentDate.setHours(new Date(value).getHours());
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1;
	const day = currentDate.getDate();
	const hours = currentDate.getHours();
	const minutes = currentDate.getMinutes();
	return `${day}.${String(month).length === 1 ? `0${month}` : month}.${year}, ${
		hours < 10 ? "0" + hours : hours
	}:${minutes < 10 ? "0" + minutes : minutes}`;
};
