import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import { customStyles } from "../../../static.js";
import "./EditVideoPolitics.scss";
import EditPoliticsComponent from "../../../PoliticsPage/EditPoliticsComponent.jsx";
import { getPoliticsLocation } from "../../../../../Actions/PoliticsAction.js";

const EditVideoPolitics = ({
	bloggerVideo,
	setMainVideoState,
	bloggerPoliticsLocation,
	getPoliticsLocation,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [politicsState, setPoliticsState] = useState([]);
	const [selectedPoliticsState, setSelectedPoliticsState] = useState(
		bloggerVideo.access_policy?.id ? bloggerVideo.access_policy : null
	);
	useEffect(() => {
		getPoliticsLocation(5);
	}, []);

	useEffect(() => {
		if (selectedPoliticsState) {
			const found = politicsState.find((elem) => elem.id === selectedPoliticsState.id);
			if (found) {
				setSelectedPoliticsState(found);
			}
		}
	}, [politicsState]);
	console.log(bloggerPoliticsLocation);

	return (
		<div className="form-container first-col video-politics">
			<div className="form-container">
				<div className="form-container-block">
					<div className="form-container-block-title">
						<b>Текущая политика</b>
						{/* <button
							className="button_edit-politics button"
							onClick={() => handleModal(<EditPoliticsComponent data={selectedPoliticsState} />)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16.62 2.25a1 1 0 0 0-1.414 0l-9.62 9.62A2 2 0 0 0 5 13.283V17a1 1 0 0 0 1 1h3.716a2 2 0 0 0 1.415-.586l9.62-9.62a1 1 0 0 0 0-1.414l-4.13-4.13ZM7 13.283l8.913-8.913 2.716 2.716L9.716 16H7v-2.716ZM3 20H2v2h20v-2H3Z"
									fill="#212121"
								/>
							</svg>
							<span>Редактировать</span>
						</button> */}
					</div>

					<div className="flex-block-col" style={{ border: "none" }}>
						<div className="form-field">
							<div className="form-field-label">Политика</div>
							<div className="input-field">
								<Select
									className="search-wrapper"
									isDisabled={true}
									classNamePrefix="select"
									name="video"
									styles={customStyles}
									value={selectedPoliticsState}
									options={politicsState}
									onChange={(newValue) => setSelectedPoliticsState(newValue)}
									getOptionLabel={(option) => option.title}
									getOptionValue={(option) => option.id}
									placeholder={"Выберите"}
									isSearchable={false}
									noOptionsMessage={() => "нет данных"}
								/>
							</div>
							<p className="politics-description">{selectedPoliticsState?.description || ""}</p>
						</div>
						<div className="form-field">
							<div className="form-field-label">Доступ к видео</div>
							<div className="input-field">
								{selectedPoliticsState?.is_accessible ? (
									<span>
										{!selectedPoliticsState.access_regions ||
										selectedPoliticsState.access_regions.length === 0
											? "Разрешить во всём мире"
											: `Разрешить в ${selectedPoliticsState.access_regions.length} странах`}
									</span>
								) : (
									<span>
										{selectedPoliticsState.access_regions.length === 0
											? "Запретить во всём мире"
											: `Запретить в ${selectedPoliticsState.access_regions.length} странах`}
									</span>
								)}
							</div>
							{bloggerPoliticsLocation.list.length > 0 ? (
								<div className="input-field">
									<div className="selected-country-wrapper">
										{selectedPoliticsState?.access_regions?.map((elem) => (
											<div className="selected-country" key={elem.id}>
												{bloggerPoliticsLocation.map[elem].title}
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>
						<div className="form-field">
							<div className="form-field-label">Типсы</div>
							<div className="input-field">
								{selectedPoliticsState.has_tips ? (
									<span>
										{!selectedPoliticsState.tips_access_regions ||
										selectedPoliticsState.tips_access_regions.length === 0
											? "Показывать во всём мире"
											: `Показывать в ${selectedPoliticsState.tips_access_regions.length} странах`}
									</span>
								) : (
									<span>
										{!selectedPoliticsState.tips_access_regions ||
										selectedPoliticsState.tips_access_regions.length === 0
											? "Не показывать во всём мире"
											: `Не показывать в ${selectedPoliticsState.tips_access_regions.length} странах`}
									</span>
								)}
							</div>
							{bloggerPoliticsLocation.list.length > 0 ? (
								<div className="input-field">
									<div className="selected-country-wrapper">
										{selectedPoliticsState?.tips_access_regions?.map((elem) => (
											<div className="selected-country" key={elem.id}>
												{bloggerPoliticsLocation.map[elem].title}
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>

						{/* <div className="form-field">
              <div className="input-field">
                <span>
                  Показывать в{" "}
                  {selectedPoliticsState?.access_regions?.length || 0} странах:
                </span>
              </div>
              {bloggerPoliticsLocation.list.length > 0 ? (
                <div className="input-field">
                  <div className="selected-country-wrapper">
                    {selectedPoliticsState?.access_regions?.map((elem) => (
                      <div className="selected-country" key={elem.id}>
                        {bloggerPoliticsLocation.map[elem].title}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div> */}
					</div>
					{/* <button
						className="button border-button"
						onClick={() => handleModal(<EditPoliticsComponent />)}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M9 2.667v-1H7V7H1.667v2H7v5.333h2V9h5.333V7H9V2.667Z"
								fill="#212121"
							/>
						</svg>
						<span>Добавить политику</span>
					</button> */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		politicsList: state.politicsList,
		bloggerPoliticsLocation: state.bloggerPoliticsLocation,
	};
};

const mapDispatchToProps = {
	getPoliticsLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVideoPolitics);
