import React from "react";
import { connect } from "react-redux";

const МeaningfulStatisticCards = ({ balanceState, balance }) => {
	return (
		<div className="meaningful-statistic-cards-wrapper">
			<div className="card-wrapper purple">
				<div className="card-main-info ">
					<div className="card-main-info__value">
						<b>{balance?.toLocaleString("ru-RU") || "0"} ₽</b>{" "}
						{/* <Badge value={12} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Баланс рекламодателя</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{balanceState?.total_replenish_amount?.toLocaleString("ru-RU") || "0"} ₽</b>{" "}
						{/* <Badge value={6} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Всего внесено</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{(
								balanceState?.total_replenish_amount - balanceState?.current_balance || 0
							)?.toLocaleString("ru-RU") || "0"}{" "}
							₽
						</b>{" "}
						{/* <Badge value={-4} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Потрачено на рекламу</div>
			</div>
		</div>
	);
};

export default connect(null, null)(МeaningfulStatisticCards);
