import axios from "axios";
import { removeEmpty } from "./ActionsScirpts";
import { baseUrl } from "./consts";

export const getUsers = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/users`)
			.then((users) => {
				dispatch({
					type: "GET_USERS",
					users,
				});
			})
			.then(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getSortedUsers = (arg) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/user`, {
				params: {
					...arg,
				},
			})
			.then((users) => {
				dispatch({
					type: "GET_USERS",
					users,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const refreshUsers = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/user`)
			.then((users) => {
				dispatch({
					type: "GET_USERS",
					users,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCurrentUser = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/user/${id}`)
			.then((user) => {
				dispatch({
					type: "GET_CURRENT_USER",
					user,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postUser = (datas) => {
	const filteredState = removeEmpty(datas);
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/user/register`, filteredState)
			.then((user) => {
				dispatch({
					type: "POST_USER",
					user,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putUser = (datas, id) => {
	const filteredState = removeEmpty(datas);
	return async (dispatch) => {
		dispatch({
			type: "HIDE_SAVE_INFO",
		});
		await axios
			.put(`${baseUrl}/user/update?user_id=${id}`, filteredState)
			.then((user) => {
				dispatch({
					type: "PUT_USER",
					user,
				});
			})
			.then(() => {
				dispatch({
					type: "SHOW_SAVE_INFO",
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteUser = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/user/${id}`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((user) => {
				dispatch({
					type: "DELETE_USER",
					user,
				});
			})

			.then(() => {
				dispatch(refreshUsers());
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAreas = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/platform`)
			.then((areas) => {
				dispatch({
					type: "GET_AREAS",
					areas,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
