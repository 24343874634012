import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../universal/Sidebar/Sidebar.jsx";
import BrightboxesPage from "./BrightboxesPage/BrightboxesPage.jsx";
import CategoriesPage from "./CategoriesPage/CategoriesPage.jsx";
import HomePage from "./HomePage/HomePage.jsx";
import PricesPage from "./PricesPage/PricesPage.jsx";
import StatisticPage from "./StatisticPage/StatisticPage.jsx";
import UsersPage from "./UsersPage/UsersPage.jsx";
import VideosPage from "./VideosPage/VideosPage.jsx";
import EditVideos from "./VideosPage/EditVideos/EditVideos.jsx";
import { AsideToggleContext } from "./context/AsideToggleContext.js";
import DropDownSaveInfo from "../universal/DropDownSaveInfo/DropDownSaveInfo.jsx";
import "./styles/PersonalArea.scss";
import EditUsers from "./UsersPage/EditUsers/EditUsers.jsx";
import PersonalAreaNavbar from "../universal/Navbar/PersonalAreaNavbar.jsx";
import { connect } from "react-redux";
import { getAuthUser } from "../../Actions/AuthorizationActions.js";
import { PrivatePersonalRoute, PrivateVideosUsersRoute } from "./PrivatePersonalRoute.js";
import SellerPage from "./SellerPage/SellerPage.jsx";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import preloaderJson from "./preloader.json";
import ViewerPage from "./ViewerPage/ViewerPage.jsx";
import ViewerStatisticComponent from "./ViewerPage/ViewerStatistic/ViewerStatisticComponent.jsx";
import PoliticsPage from "./PoliticsPage/PoliticsPage.jsx";
import PlaylistsPage from "./PlaylistsPage/PlaylistsPage.jsx";
import SellerStatisticComponent from "./SellerPage/SellerStatistic/SellerStatisticComponent.jsx";
import BloggerStatisticComponent from "./BloggerPage/BloggerStatistic/BloggerStatisticComponent.jsx";
import BloggerPage from "./BloggerPage/BloggerPage.jsx";
import EditBloggerVideo from "./VideosPage/EditVideos/EditBloggerVideo.jsx";
import OwnerPage from "./OwnersPage/OwnerPage.jsx";
import OwnerStatisticComponent from "./OwnersPage/OwnerStatistic/OwnerStatisticComponent.jsx";
import ReportDetail from "./ReportsPage/report-detail/ReportDetail.jsx";
import { useUserAccessContext } from "./context/UserAccessContext.js";
import CategoryAttributesPage from "./CategoryAttributesPage/CategoryAttributesPage.jsx";

const ReportsPage = lazy(() => import("./ReportsPage/ReportsPage.jsx"));

const PersonalArea = ({ mainLoader, statisticLoadingStatus, authUser }) => {
	const { toggle } = React.useContext(AsideToggleContext);
	const { userAccessState: userState, setUserAccessState: setUserState } = useUserAccessContext();

	useEffect(() => {
		if (!authUser || Array.isArray(authUser)) return;
		// Здесь записываем в состояние контекста пользователя информацию о его доступах
		setUserState({
			...userState,
			id: authUser.id,
			avatar_url: authUser.avatar_url,
			login: authUser.email,
			first_name: authUser.first_name,
			second_name: authUser.second_name,
			brightboxes: Number(authUser.subparam_1),
			videos: Number(authUser.subparam_2),
			statistics: Number(authUser.subparam_3),
			sellers: Number(authUser.subparam_4),
			viewers: Number(authUser.subparam_5),
			categories: Number(authUser.subparam_6),
			playlists: Number(authUser.subparam_7),
			prices: Number(authUser.subparam_8),
			politics: Number(authUser.subparam_9),
			users: Number(authUser.subparam_10),
			bloggers: Number(authUser.subparam_11),
			owners: Number(authUser.subparam_12),
			reports: Number(authUser.subparam_13),
		});
	}, [authUser]);

	return (
		<>
			<div id="block-on-mobile">
				<img src="images/auth_logo.svg" alt="" id="mobile-logo" />
			</div>
			<DropDownSaveInfo />

			<Sidebar userState={userState} />
			<div
				className={`personal-area-section animte-menu ${toggle ? "closed" : ""}`}
				style={{ overflowY: "auto" }}
			>
				<PersonalAreaNavbar userState={userState} />
				<Suspense
					fallback={
						<div className={`main-loader-wrapper active`}>
							<Lottie loop animationData={preloaderJson} play style={{ width: 50, height: 50 }} />
						</div>
					}
				>
					<Routes>
						<Route exact path="/" element={<HomePage title="Atips Global" />} />
						{userState !== null ? (
							<>
								<Route
									exact
									path={"/tips" | "/owner-tips" | "/blogger-tips"}
									element={<PrivatePersonalRoute state={userState} keyVal="brightboxes" />}
								>
									<Route
										exact
										path="/tips"
										element={<BrightboxesPage title="Типсы" accessStatus={userState.brightboxes} />}
									/>
									<Route
										exact
										path="/owner-tips"
										element={<BrightboxesPage title="Типсы" accessStatus={userState.brightboxes} />}
									/>
									<Route
										exact
										path="/blogger-tips"
										element={<BrightboxesPage title="Типсы" accessStatus={userState.brightboxes} />}
									/>
								</Route>

								<Route
									exact
									path="/categories"
									element={<PrivatePersonalRoute state={userState} keyVal="categories" />}
								>
									<Route
										exact
										path="/categories"
										element={
											<CategoriesPage title="Категории" accessStatus={userState.categories} />
										}
									/>
								</Route>
								<Route
									exact
									path="/category-attributes"
									element={<PrivatePersonalRoute state={userState} keyVal="categories" />}
								>
									<Route
										exact
										path="/category-attributes"
										element={
											<CategoryAttributesPage
												title="Категории"
												accessStatus={userState.categories}
											/>
										}
									/>
								</Route>
								<Route
									exact
									path="/playlist"
									element={<PrivatePersonalRoute state={userState} keyVal="playlists" />}
								>
									<Route
										exact
										path="/playlist"
										element={<PlaylistsPage title="Плейлисты" accessStatus={userState.playlists} />}
									/>
								</Route>

								<Route
									exact
									path={
										"/videos" |
										"owner-videos" |
										"blogger-videos" |
										"/newfilm" |
										"/film" |
										"/newserial" |
										"/serial" |
										"/newblog" |
										"/blog"
									}
									element={<PrivatePersonalRoute state={userState} keyVal="videos" />}
								>
									<Route
										exact
										path="/videos"
										element={<VideosPage title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/owner-videos"
										element={<VideosPage title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/blogger-videos"
										element={<VideosPage title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/newfilm"
										element={<EditVideos title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/film"
										element={<EditVideos title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/newserial"
										element={<EditVideos title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/serial"
										element={<EditVideos title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/newblog"
										element={<EditVideos title="Видео" accessStatus={userState.videos} />}
									/>
									<Route
										exact
										path="/blogger-video"
										element={<EditBloggerVideo title="Видео" accessStatus={userState.videos} />}
									/>
								</Route>

								<Route
									exact
									path="/statistic"
									element={<PrivatePersonalRoute state={userState} keyVal="statistics" />}
								>
									<Route
										exact
										path="/statistic"
										element={
											<StatisticPage title="Статистика" accessStatus={userState.statistics} />
										}
									/>
								</Route>

								<Route
									exact
									path={"/bloggers" | "/bloggers-statistic"}
									element={<PrivatePersonalRoute state={userState} keyVal="bloggers" />}
								>
									<Route
										exact
										path="/bloggers"
										element={<BloggerPage title="Блогеры" accessStatus={userState.bloggers} />}
									/>
									<Route
										exact
										path="/bloggers-statistic"
										element={
											<BloggerStatisticComponent
												title="Статистика блогера"
												accessStatus={userState.sellers}
											/>
										}
									/>
								</Route>
								<Route
									exact
									path={"/owners" | "/owner-statistic"}
									element={<PrivatePersonalRoute state={userState} keyVal="owners" />}
								>
									<Route
										exact
										path="/owners"
										element={<OwnerPage title="Правообладатели" accessStatus={userState.owners} />}
									/>
									<Route
										exact
										path="/owner-statistic"
										element={
											<OwnerStatisticComponent
												title="Статистика правообладателя"
												accessStatus={userState.owners}
											/>
										}
									/>
								</Route>
								<Route
									exact
									path={"/sellers" | "/sellers-statistic"}
									element={<PrivatePersonalRoute state={userState} keyVal="categories" />}
								>
									<Route
										exact
										path="/sellers"
										element={<SellerPage title="Рекламодатели" accessStatus={userState.sellers} />}
									/>
									<Route
										exact
										path="/sellers-statistic"
										element={
											<SellerStatisticComponent
												title="Рекламодатели"
												accessStatus={userState.sellers}
											/>
										}
									/>
								</Route>

								<Route
									exact
									path={"/viewers" | "viewers-statistic"}
									element={<PrivatePersonalRoute state={userState} keyVal="viewers" />}
								>
									<Route
										exact
										path="/viewers"
										element={<ViewerPage title="Зрители" accessStatus={userState.viewers} />}
									/>
									<Route
										exact
										path="/viewers-statistic"
										element={
											<ViewerStatisticComponent title="Зрители" accessStatus={userState.viewers} />
										}
									/>
								</Route>

								<Route
									exact
									path="/prices"
									element={<PrivatePersonalRoute state={userState} keyVal="prices" />}
								>
									<Route
										exact
										path="/prices"
										element={<PricesPage title="Цены" accessStatus={userState.prices} />}
									/>
								</Route>

								<Route
									exact
									path={"/politics"}
									element={<PrivatePersonalRoute state={userState} keyVal="politics" />}
								>
									<Route
										exact
										path="/politics"
										element={<PoliticsPage title="Политика" accessStatus={userState.politics} />}
									/>
								</Route>
								<Route
									exact
									path={"/reports" | "/report-detail"}
									element={<PrivatePersonalRoute state={userState} keyVal="reports" />}
								>
									<Route exact path="/reports" element={<ReportsPage title="Жалобы" />} />
									<Route
										exact
										path="/report-detail"
										element={<ReportDetail title="Тикет жалобы" accessStatus={userState.reports} />}
									/>
								</Route>

								<Route
									exact
									path={"/users" | "/user" | "/newuser"}
									element={<PrivatePersonalRoute state={userState} keyVal="users" />}
								>
									<Route
										exact
										path="/users"
										element={<UsersPage title="Пользователи" accessStatus={userState.users} />}
									/>

									<Route
										exact
										path={"/newuser"}
										element={<PrivateVideosUsersRoute status={userState.users} name="users" />}
									>
										<Route
											exact
											path="/newuser"
											element={<EditUsers title="Пользователи" accessStatus={userState.users} />}
										/>
									</Route>
									<Route
										exact
										path="/user"
										element={<EditUsers title="Пользователи" accessStatus={userState.users} />}
									/>
								</Route>
							</>
						) : null}
					</Routes>
				</Suspense>
				<div
					className={`main-loader-wrapper ${
						mainLoader > 0 || statisticLoadingStatus ? "active" : "passive"
					}`}
				>
					<Lottie loop animationData={preloaderJson} play style={{ width: 50, height: 50 }} />
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authUser,
		mainLoader: state.mainLoader,
		statisticLoadingStatus: state.statisticLoadingStatus,
	};
};

const mapDispatchToProps = {
	getAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalArea);
