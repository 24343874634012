import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { DTBold13, DText13, H4Text } from "../../../../ui-kit/typography/Typography.jsx";
import { Stack } from "../../../../ui-kit/layouts/Stack.jsx";
import { initialUser } from "./initial.js";
import FirstRegStep from "./add-owner-steps/FirstAddOwnerStep.jsx";
import SecondRegStep from "./add-owner-steps/SecondAddOwnerStep.jsx";
import ThirdRegStep from "./add-owner-steps/ThirdAddOwnerStep.jsx";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import AddOwnerTabs from "./AddOwnerTabs";
import styled from "styled-components";

const Wrapper = styled.div`
	background: #fff;
	border-radius: 16px;
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	background-size: cover;
	.add-owner-form {
		position: relative;
		display: block;
		width: 410px;
		padding: 56px 45px 52px;
		background: #ffffff;
		border-radius: 16px;
		box-sizing: border-box;

		img.add-owner-form-logo {
			position: relative;
			display: block;
			margin: 0 auto 48px;
		}
	}
`;

const AddOwnerPopup = ({}) => {
	let navigate = useNavigate();
	const [userState, setUserState] = useState(initialUser);
	const [formFieldErrorState, setFormFieldErrorState] = useState(() => false);
	const [activeTabState, setActiveTabState] = useState(1);

	return (
		<Wrapper>
			<div className="add-owner-form" style={{ width: "600px" }}>
				<Stack>
					<img src="images/icons/main/auth_logo.svg" alt="" className="add-owner-form-logo" />
					<Cluster justify="center">
						<H4Text className="title">Добавление правообладателя</H4Text>
					</Cluster>
				</Stack>
				<AddOwnerTabs active={activeTabState} userState={userState} setUserState={setUserState} />
				{activeTabState === 1 ? (
					<FirstRegStep
						setActive={setActiveTabState}
						userState={userState}
						setUserState={setUserState}
					/>
				) : activeTabState === 2 ? (
					<SecondRegStep
						setActive={setActiveTabState}
						userState={userState}
						setUserState={setUserState}
					/>
				) : activeTabState === 3 ? (
					<ThirdRegStep
						setActive={setActiveTabState}
						userState={userState}
						setUserState={setUserState}
					/>
				) : (
					<FirstRegStep
						setActive={setActiveTabState}
						userState={userState}
						setUserState={setUserState}
					/>
				)}
			</div>
		</Wrapper>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddOwnerPopup);
