export const initialSellerTab = [
	{
		id: 1,
		title: "Активные",
	},
	{
		id: 2,
		title: "На рассмотрении",
	},
	{
		id: 3,
		title: "Отключенные",
	},
	{
		id: 4,
		title: "Удаленные",
	},
];

const normalizeValue = (value) => {
	return value.toString().length > 1 ? value : `0${value}`;
};

export const initialVideoDepth = () => {
	let arr = [];
	for (let index = 0; index <= 4998; index++) {
		const hour = normalizeValue(Math.floor(index / 3600));
		const minute = normalizeValue(Math.floor((index - 3600 * hour) / 60));
		const seconds = normalizeValue(index % 60);
		if (hour >= 1) {
			arr.push({
				videoDepth: Math.floor(Math.random() * 1) + 10,
				time: `${hour}:${minute}:${seconds}`,
			});
		} else {
			arr.push({
				videoDepth: Math.floor(Math.random() * 1) + 10,
				time: `${minute}:${seconds}`,
			});
		}
	}
	return arr;
};
