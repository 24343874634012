import React from "react";
import "./styles/Tooltip.scss";

export const Tooltip = ({ text }) => {
	return (
		<div className={`tooltip  bg-white`}>
			<p className={`tooltip-text T-center pv-12 ph-16  T13-list `}>{text}</p>
			<div className="tooltip-arrow" />
		</div>
	);
};
