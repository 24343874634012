import { mapBy, nativeMapBy } from "../components/PersonalArea/scripts/mapBy";

const initialViewers = {
	list: [],
	pagination: {},
};

const initialResult = {
	active: initialViewers,
	deleted: initialViewers,
};

export const ViewersReducer = (state = initialResult, action) => {
	switch (action.type) {
		case "GET_VIEWERS":
			// const mapped = nativeMapBy(action.viewers.data.moviegoers, "id");
			const data = action.viewers.data;
			return {
				...state,
				active: {
					list: data.items,
					pagination: {
						max_elements: action.viewers.data.total_rows,
						page: data.page,
						limit: data.size,
						max_pages: Math.ceil(data.total / data.size),
					},
				},
			};
		default:
			return state;
	}
};
export const ViewersCurrentReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_CURRENT_VIEWERS":
			return action.viewers.data;

		default:
			return state;
	}
};
export const ExternalViewersReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_EXTERNAL_VIEWER":
			return action.viewer.data;
		case "PATCH_EXTERNAL_VIEWER":
			return action.viewer.data;
		default:
			return state;
	}
};
const initialExternalViewersTotal = {
	1: 0, // активные
	2: 0, // отлюченные
	3: 0, // удаленные
};

export const ExternalViewerTotalReducer = (state = initialExternalViewersTotal, action) => {
	switch (action.type) {
		case "GET_ACTIVE_EXTERNAL_VIEWER_TOTAL": {
			const data = action.viewer.data;
			return {
				...state,
				1: data.total,
			};
		}
		case "GET_BLOCKED_EXTERNAL_VIEWER_TOTAL": {
			const data = action.viewer.data;
			return {
				...state,
				3: data.total,
			};
		}
		case "GET_DELETED_EXTERNAL_VIEWER_TOTAL": {
			const data = action.viewer.data;
			return {
				...state,
				2: data.total,
			};
		}
		default:
			return state;
	}
};
