import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DTBold15 } from "../typography/Typography";

const StyledButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 12px 32px;
	width: ${(props) => (props.full ? "100%" : "auto")};
	border-radius: 80px;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 160%;
	display: flex;
	align-items: center;
	text-align: center;
	gap: 10px;
	cursor: pointer;
	border: none;
	&.big {
		padding: 16px 32px;
	}
	&.small {
		padding: 9px 14px;
		${DTBold15} {
			font-size: 14px;
		}
	}
	&.secondary {
		border: 2px solid #d1d8e1;
		color: #212121;
		background: #ffffff;
		&:hover {
			border: 2px solid #0052e4;
		}
	}
	&.icon {
		padding: 0px;
		width: 24px;
		height: 24px;
		gap: 0px;
		&:hover {
			svg {
				path {
					fill: #0052e4;
				}
			}
		}
		background: transparent;
	}
	&.white-icon {
		background: #ffffff;
		gap: 0px;
		&.preloader {
			img {
				display: none;
			}
			width: 48px;
			height: 48px;
		}
		&.small {
			width: 40px;
			height: 40px;
		}
		&:hover {
			svg {
				path {
					fill: #0052e4;
				}
			}
		}
		padding: 12px;
		width: 48px;
		height: 48px;
	}

	&.primary-icon {
		background: #0052e4;
		color: #ffffff;
		gap: 0px;
		&.preloader {
			img {
				display: none;
			}
			width: 48px;
			height: 48px;
		}
		&.small {
			width: 40px;
			height: 40px;
		}
		&:hover {
			background: #0052e4;
		}
		&:disabled {
			background: #d1d8e1;
			color: #94a0b1;
			&:hover {
				background: #d1d8e1;
			}
		}
		padding: 12px;
		width: 48px;
		height: 48px;
	}
	&.hot {
		background: #ed0a34;
		color: #ffffff;

		&:disabled {
			background: #d1d8e1;
			color: #94a0b1;
			&:hover {
				background: #ed0a34;
			}
		}
		&:hover {
			background: #ed0a34;
		}
	}
	&.primary {
		background: #0052e4;
		color: #ffffff;

		&:disabled {
			background: #d1d8e1;
			color: #94a0b1;
			&:hover {
				background: #d1d8e1;
			}
		}
		&:hover {
			background: #0052e4;
		}
	}
	&.transparent {
		background: transparent;
		color: #94a0b1;
		padding: 0;
		cursor: pointer;
		${DTBold15} {
			font-weight: 400;
		}
		svg {
			path {
				fill: #94a0b1;
			}
		}
		&:hover {
			color: #0052e4;
			svg {
				path {
					fill: #0052e4;
				}
			}
		}
	}
	&.link {
		position: relative;
		display: inline-block;
		&:disabled {
			cursor: default;
			${DTBold15} {
				color: #94a0b1;
			}
		}
		${DTBold15} {
			font-size: 14px;
			line-height: 23px;
			font-weight: 400;
		}
		color: #005bff;
		cursor: pointer;
		border: none;
		padding: 0;
		background: transparent;
	}
	&.white {
		color: #212121;
		background: #ffffff;
	}
	&.preloader {
		width: 122px;
		width: ${(props) => (props.full ? "100%" : "122px")};
		&.big {
			height: 54px;
		}
		${DTBold15} {
			pointer-events: none;
			font-size: 0;
			color: #fff;
		}
		&::after {
			animation: loaderAnimation 1s linear infinite;
			background: url("/images/icons/ui/status/white/loader.svg") no-repeat;
			background-size: contain;
			content: "";
			display: block;
			height: 20px;
			left: calc(50% - 10px);
			position: absolute;
			top: calc(50% - 10px);
			width: 20px;
		}
	}
`;

export const Button = ({
	variant = "secondary",
	onClick,
	className,
	text,
	size,
	disabled = false,
	full = false,
	startIcon = null,
	endIcon = null,
}) => {
	return (
		<StyledButton
			className={`${variant} ${className} ${size}`}
			onClick={onClick}
			disabled={disabled}
			full={full}
		>
			{startIcon}
			<DTBold15>{text}</DTBold15>
			{endIcon}
		</StyledButton>
	);
};
