import axios from "axios";
import { baseUrl } from "./consts";
import AuthService from "../services/auth.service";

export const postLogin = (datas) => {
	var bodyFormData = new FormData();
	bodyFormData.append("username", datas.login);
	bodyFormData.append("password", datas.password);
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/user/login`, datas, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			// .post(`${baseUrl}/auth/login`, bodyFormData)
			.then((login) => {
				dispatch({
					type: "POST_LOGIN",
					login,
					success: true,
				});
			})
			.catch((error) => {
				dispatch({
					type: "POST_LOGIN",
					success: false,
				});
				console.log(error);
			});
	};
};

export const getAuthUser = (id) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/user/get/${id}`)
			.then((user) => {
				dispatch({
					type: "GET_AUTH_USER",
					user,
				});
			})
			.catch((error) => {
				AuthService.logout();
				console.log(error);
			});
	};
};

export const postLogout = () => {
	return async (dispatch) => {
		dispatch({
			type: "POST_LOGOUT",
		});
	};
};
