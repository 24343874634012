import styled from "styled-components";
import { DTBold15, DText15 } from "../../../ui-kit/typography/Typography";

export const CategoryContentWrapper = styled.div`
	display: flex;
	left: 27px;
	margin-top: 24px;
	width: calc(100%);
	height: calc(100vh - 10px - 24px - 56px - 104px);
`;

export const CategoryColumnWrapper = styled.div`
	border-right: 1px solid #e2e8f0;
	box-sizing: border-box;
	display: block;
	height: auto;
	overflow-y: scroll;
	padding-left: 5px;
	position: relative;
	width: calc(100% / 3);
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(148, 160, 177, 0.3);
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
`;

export const ParentElement = styled.div`
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	line-height: 24px;
	margin-right: 5px;
	padding: 10px 8px 10px 12px;
	position: relative;
	overflow: hidden;
	span {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		/* display: flex; */
	}
	justify-content: space-between;
	.actions-wrapper {
		width: 24px;
		display: none;
	}
	&.selected {
		background: #e2e8f0;
		color: #0052e4;
	}
	&:hover {
		span {
			width: calc(100% - 24px);
		}
		background: #f4f4f8;
		color: #0052e4;
		.actions-wrapper {
			visibility: visible;
			width: 24px;
			display: flex;
			&.hidden {
				visibility: hidden;
			}
		}
	}
	svg:hover {
		path {
			fill: #0052e4;
		}
	}
`;

export const ColumnTitle = styled(DText15)`
	color: #94a0b1;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const ChildElement = styled.div`
	border-radius: 8px;
	color: #94a0b1;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	margin-right: 5px;
	padding: 10px 12px;
	position: relative;
	justify-content: space-between;
	display: flex;
	align-items: center;
	/* width: 100%; */
	span {
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
	.actions-wrapper {
		display: none;
	}
	&.selected {
		background: #e2e8f0;
	}
	&:hover {
		span {
			width: calc(100% - 64px);
		}
		background: #f4f4f8;
		color: #0052e4;
		.actions-wrapper {
			width: 64px;
			display: flex;
			&.hidden {
				visibility: hidden;
			}
		}
	}
	svg:hover {
		path {
			fill: #0052e4;
		}
	}
`;

export const FilteredItemWrapper = styled.div`
	border-bottom: 1px solid #e2e8f0;
	cursor: pointer;
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	margin-right: 5px;
	padding: 10px 12px;
	position: relative;
	&:hover {
		background: #f4f4f8;
		${DTBold15} {
			color: #0052e4;
		}
	}
	${DText15} {
		color: #94a0b1;
	}
`;
