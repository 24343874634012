import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts";
import { customStyles } from "../../static";
import { useSearchParams } from "react-router-dom";
import {
	handleBloggerMultiSelectActiveClick,
	handleBloggerSelectFilters,
} from "../../VideosPage/pages/blogger-videos/scripts";
import { baseUrl } from "../../../../Actions/consts";
import axios from "axios";
import { DropdownCitySearch } from "../../BrightboxesPage/dropdownVideoSearch/DropdownCitySearch";

const initialDropDownState = {
	areaId: false,
	is_verified: false,
};

const initialStatuses = [
	{
		title: "Подтвержден",
		id: "true",
	},
	{
		title: "Не подтвержден",
		id: "false",
	},
];

const ViewerPageComponent = ({ filterState, setFilterState }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [citiesState, setCitiesState] = useState({
		currentValue: "",
		list: [],
	});

	const [genderState, setGenderState] = useState({
		list: [
			{
				id: "male",
				title: "Мужской",
			},
			{
				id: "female",
				title: "Женский",
			},
		],
		currentValue: "",
	});

	const [videoStatusesState, setVideoStatusesState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.is_verified
			? JSON.parse(searchParams.get("filters")).is_verified
			: [],
		list: initialStatuses,
	});

	const [searchInput, setSearchInput] = useState(
		!!JSON.parse(searchParams.get("filters"))?.title
			? JSON.parse(searchParams.get("filters"))?.title
			: ""
	);

	const [dropDownState, setDropdownState] = useState(initialDropDownState);

	const handleSearchInputChange = (e) => {
		e.stopPropagation();
		let value = e.target.value;
		setSearchInput(value);
	};

	useEffect(() => {
		setSearchInput(!!filterState.filters?.title ? filterState.filters.title : "");

		const asyncFunc = async () => {
			await axios
				.get(`${baseUrl}/attribute/${filterState.filters?.city_id}`)
				.then((attribute) => {
					setCitiesState({ ...citiesState, currentValue: attribute.data });
				})
				.catch((error) => {
					console.log(error);
				});
		};
		!!filterState.filters?.city_id && asyncFunc();

		setGenderState({
			...genderState,
			currentValue: !!filterState.filters?.gender
				? genderState.list.find((elem) => elem.id === filterState.filters.gender)
				: "",
		});
	}, [filterState]);

	return (
		<div className="list-filter flex-block" style={{ marginBottom: "38px" }}>
			<div className="form-field">
				<div className="form-field-label"></div>
				<div className="input-field left-icon">
					<input
						type="text"
						placeholder="Поиск по имени"
						onKeyPress={(e) =>
							e.key === "Enter"
								? handleFilteredClick("search_page", searchInput, filterState, setFilterState)
								: null
						}
						value={searchInput.length > 0 ? searchInput : ""}
						onChange={(e) => handleSearchInputChange(e)}
					/>
					<div
						className="input-search input-icon"
						onClick={(e) =>
							handleFilteredClick("search", searchInput, filterState, setFilterState)
						}></div>
				</div>
			</div>
			<div className="form-field">
				<div className="form-field-label">Пол</div>
				<div className="input-field">
					<Select
						name="options"
						className="search-wrapper"
						classNamePrefix="select"
						value={genderState.currentValue}
						options={genderState.list}
						onChange={(newValue) => (
							setGenderState({ ...genderState, currentValue: newValue }),
							setFilterState({
								...filterState,
								page: 1,
								filters: { ...filterState.filters, gender: newValue.id },
							})
						)}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.title}
						placeholder="Все"
						styles={customStyles}
						isSearchable={true}
						noOptionsMessage={() => "нет данных"}
					/>
					<button
						className={`clear-chosen-container ${
							!!genderState.currentValue?.title > 0 ? "active" : ""
						}`}
						onClick={() => (
							setGenderState({ ...genderState, currentValue: "" }),
							setFilterState({
								...filterState,
								page: 1,
								filters: { ...filterState.filters, gender: "" },
							})
						)}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
								fill="#212121"></path>
						</svg>
					</button>
				</div>
			</div>
			<div className="form-field">
				<div className="form-field-label">Город</div>
				<div className="input-field">
					<DropdownCitySearch
						selected={citiesState?.currentValue?.title || ""}
						onClear={() => (
							setCitiesState({ ...citiesState, currentValue: "" }),
							setFilterState({
								...filterState,
								page: 1,
								filters: { ...filterState.filters, city_id: "" },
							})
						)}
						onSelect={(newValue) => (
							setCitiesState({ ...citiesState, currentValue: newValue }),
							setFilterState({
								...filterState,
								page: 1,
								filters: { ...filterState.filters, city_id: newValue.id },
							})
						)}
					/>
				</div>
			</div>
			<div className="form-field">
				<div className="form-field-label">Статус</div>
				<div className="input-field">
					<div
						className={`select-block multiselect ${dropDownState.is_verified ? "active" : ""}`}
						onFocus={() => null}
						onClick={() =>
							setDropdownState({
								...dropDownState,
								is_verified: !dropDownState.is_verified,
							})
						}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="2">
						<div className="select-block-button">
							<span>
								{videoStatusesState.currentValue.length > 0 &&
								videoStatusesState.currentValue.length !== videoStatusesState.list.length
									? videoStatusesState.currentValue.map((elem, index) =>
											index !== videoStatusesState.currentValue.length - 1
												? `${videoStatusesState.list.find((item) => item.id == elem)?.title}, `
												: `${videoStatusesState.list.find((item) => item.id == elem)?.title}`
									  )
									: "Все"}
							</span>
						</div>
						<div className="select-block-list">
							<div className="select-block-list-scroll">
								{videoStatusesState.list?.length > 0
									? videoStatusesState.list.map((status) => (
											<div
												key={status.id}
												className={`select-block-item ${
													videoStatusesState.currentValue.includes(status.id) ? "selected" : ""
												}`}
												onClick={(e) =>
													handleBloggerMultiSelectActiveClick(
														e,
														videoStatusesState,
														setVideoStatusesState,
														status
													)
												}>
												<span>{status?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className="select-block-control">
								<div
									className="select-block-cancel button-select"
									onClick={(event) =>
										handleBloggerSelectFilters(
											event,
											"is_verified",
											[],
											videoStatusesState,
											setVideoStatusesState,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}>
									Очистить
								</div>
								<div
									className="select-block-select button-select"
									onClick={(event) =>
										handleBloggerSelectFilters(
											event,
											"is_verified",
											videoStatusesState.currentValue,
											null,
											null,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}>
									Применить
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, null)(ViewerPageComponent);
