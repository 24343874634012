import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getSellerBalance } from "../../../../../Actions/SellersActions.js";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import PaginationBlock from "../../../../universal/PaginationBlock/PaginationBlock.jsx";
import { TotalContainer, TotalTitle, TotalValue, TotalWrapper } from "./PaymentsTotal.jsx";
import { SubmitButton } from "../../pages/Buttons.jsx";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import UniversalDeleteModal from "../../../../universal/UniversalDeleteModal.jsx";
import { AcceptOrderModal } from "./AcceptOrderModal.jsx";

const TableRowHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	position: relative;
	height: 24px;
	padding: 0 10px;
`;

const HeaderElement = styled.div`
	background: transparent;
	border: none;
	outline: none;
	box-sizing: border-box;
	color: #94a0b1;
	cursor: pointer;
	font-size: 14px;
	line-height: 24px;
	/* padding-left: 20px; */
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	height: 20px;
	span {
		display: flex;
		align-items: center;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 20px);
		&:after {
			background: url("images/icons/ui/actions/gray/sort_filled.svg") no-repeat;
			content: "";
			display: inline-block;
			height: 24px;
			position: relative;
			vertical-align: top;
			width: 24px;
		}
		&:hover {
			color: #005bff;
			&:after {
				background: url("images/icons/ui/actions/blue/sort_filled.svg") no-repeat;
			}
		}
	}
	/* &::after {
		background: url("images/icons/ui/actions/gray/sort_filled.svg") no-repeat;
		content: "";
		display: inline-block;
		height: 24px;
		position: relative;
		vertical-align: top;
		width: 24px;
	} */
`;

const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
`;

const TableRowContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	position: relative;
	background: #ffffff;
	/* border: 1px solid #d1d8e1; */
	/* border-radius: 16px; */
	border-bottom: 1px solid #e2e8f0;
	padding: 8px 10px;
`;

const ContentElement = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 20px;
	font-size: 14px;
	box-sizing: border-box;
	/* padding-left: 20px; */
	/* height: 20px; */
`;

const EmptyFallbackContainer = styled.div`
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #94a0b1;
	margin: 24px auto 0;
`;

const PaymentsHistoryPage = ({ getSellerBalance, sellerBalanceReducer, id, balanceState }) => {
	const { handleModal } = useContext(ModalContext);
	const [collection, setCollection] = useState([]);
	const [paginationState, setPaginationState] = useState({
		offset: 0,
		limit: 5,
	});

	useEffect(() => {
		if (!id) return;
		getSellerBalance(
			id,
			Math.round(paginationState.offset / paginationState.limit) + 1,
			paginationState.limit
		);
	}, [paginationState, id]);

	useEffect(() => {
		setCollection([...sellerBalanceReducer?.list]);
	}, [sellerBalanceReducer]);

	return (
		<StatisticBlockContainer className="content-wrapper">
			<HeaderContainer className="gadgets-statistic-header">
				<div className="gadgets-statistic-header__title">История</div>
				<TotalContainer>
					<TotalWrapper>
						<TotalTitle>Сумма, ₽</TotalTitle>
						<TotalValue>
							{balanceState?.total_replenish_amount?.toLocaleString("ru-RU") || "0"}
						</TotalValue>
					</TotalWrapper>
					<TotalWrapper>
						<TotalTitle>Потрачено, ₽</TotalTitle>
						<TotalValue>
							{(
								balanceState?.total_replenish_amount - balanceState?.current_balance || 0
							)?.toLocaleString("ru-RU") || "0"}
						</TotalValue>
					</TotalWrapper>
					<TotalWrapper>
						<TotalTitle>Остаток, ₽</TotalTitle>
						<TotalValue>{balanceState?.current_balance?.toLocaleString("ru-RU") || "0"}</TotalValue>
					</TotalWrapper>
				</TotalContainer>
			</HeaderContainer>
			{collection?.length > 0 && collection[0].user_id === id ? (
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<TableRowHeader>
						<HeaderElement style={{ width: "25%", left: "-10px" }}>
							<span>Метод пополнения</span>
						</HeaderElement>
						<HeaderElement style={{ width: "45%" }}>
							<span>Номер транзакции</span>
						</HeaderElement>
						<HeaderElement style={{ width: "15%" }}>
							<span>Сумма, ₽</span>
						</HeaderElement>
						<HeaderElement style={{ width: "15%" }}>
							<span>Дата</span>
						</HeaderElement>
						<HeaderElement style={{ width: "100px" }}></HeaderElement>
					</TableRowHeader>
					{collection.map((elem, index) => (
						<TableRowContent key={index}>
							<ContentElement style={{ width: "25%" }}>Безналичный расчет</ContentElement>
							<ContentElement style={{ width: "45%" }}>{elem?.id}</ContentElement>
							<ContentElement style={{ width: "calc(15%)" }}>
								{Number(elem?.amount) / 100}
							</ContentElement>
							<ContentElement style={{ width: "15%" }}>
								{elem.created_at.split("T")[0].split("-").reverse().join(".")}
							</ContentElement>
							<ContentElement style={{ width: "100px" }}>
								<SubmitButton
									onClick={() =>
										handleModal(
											<AcceptOrderModal
												order={elem}
												onSuccess={() =>
													getSellerBalance(
														id,
														Math.round(paginationState.offset / paginationState.limit) + 1,
														paginationState.limit
													)
												}
												id={elem.id}
											/>
										)
									}
								>
									Принять
								</SubmitButton>
							</ContentElement>
						</TableRowContent>
					))}
				</div>
			) : (
				<EmptyFallbackContainer>Ничего не найдено</EmptyFallbackContainer>
			)}
			{Object.keys(sellerBalanceReducer.pagination).length !== 0 &&
			!!sellerBalanceReducer.pagination.max_pages &&
			sellerBalanceReducer.list.length > 0 &&
			sellerBalanceReducer.pagination.max_pages > 1 ? (
				<PaginationBlock
					filterState={paginationState}
					setFilterState={setPaginationState}
					pagination={sellerBalanceReducer.pagination}
				/>
			) : null}
		</StatisticBlockContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		sellerBalanceReducer: state.sellerBalanceReducer,
	};
};

const mapDispatchToProps = {
	getSellerBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsHistoryPage);
