import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { StatisticBlockContainer } from "../../../../ui-kit/wrappers-containers/StatisticBlockContainer";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { DText15, H4Text } from "../../../../ui-kit/typography/Typography";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../Actions/consts";
import { Link } from "react-router-dom";
import { PeriodFilters } from "../UsersStatistic/PeriodFilters/PeriodFilters";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip";
import { getBestFilms } from "./getBestVideos";
import { Tabs } from "../../../../ui-kit/tabs/Tabs";

const BEST_VIDEOS_TABS = [
	{
		id: 1,
		title: "Фильмы",
	},
	{
		id: 2,
		title: "Сериалы",
	},
];

const BestMovies = () => {
	const dispatch = useDispatch();
	const [movieTabsState, setMovieTabsState] = useState(BEST_VIDEOS_TABS[0]);
	const [filtersState, setFiltersState] = useState({});
	const [bestFilmsCollection, setBestFilmsCollection] = useState([]);
	const [bestSerialsCollection, setBestSerialsCollection] = useState([]);

	useEffect(async () => {
		if (!filtersState.from_datetime || !filtersState.to_datetime) return;

		// Лучшие видео и shorts блогеров
		getBestFilms(
			{
				size: 5,
				page: 1,
				video_format: 1,
				date_start: filtersState.from_datetime,
				date_end: filtersState.to_datetime,
			},
			dispatch,
			(dashboardVideo) => {
				getBestFilms(
					{
						size: 5,
						page: 1,
						video_format: 6,
						date_start: filtersState.from_datetime,
						date_end: filtersState.to_datetime,
					},
					dispatch,
					(ownerVideo) => {
						const sortedCollection = [...dashboardVideo, ...ownerVideo].sort((a, b) => {
							if (a.views < b.views) return 1;
							if (a.views > b.views) return -1;
							return 0;
						});
						sortedCollection.slice(0, 5);
						setBestFilmsCollection(sortedCollection);
					}
				);
			}
		);
		getBestFilms(
			{
				size: 5,
				page: 1,
				video_format: 2,
				date_start: filtersState.from_datetime,
				date_end: filtersState.to_datetime,
			},
			dispatch,
			(dashboardVideo) => {
				getBestFilms(
					{
						size: 5,
						page: 1,
						video_format: 7,
						date_start: filtersState.from_datetime,
						date_end: filtersState.to_datetime,
					},
					dispatch,
					(ownerVideo) => {
						const sortedCollection = [...dashboardVideo, ...ownerVideo].sort((a, b) => {
							if (a.views < b.views) return 1;
							if (a.views > b.views) return -1;
							return 0;
						});
						sortedCollection.slice(0, 5);
						setBestSerialsCollection(sortedCollection);
					}
				);
			}
		);
	}, [filtersState]);

	return (
		<StatisticBlockContainer className="content-wrapper" marginTop="16px">
			<Stack space="28px" style={{ width: "100%" }}>
				<Cluster justify="space-between" align="center">
					<H4Text>Лучшие фильмы и сериалы</H4Text>
					<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
				</Cluster>
				<Tabs collection={BEST_VIDEOS_TABS} active={movieTabsState} onChange={setMovieTabsState} />
				<Stack>
					<div className="list-table-header home-best-videos">
						<div className="list-table-header-element">
							<span>Видео</span>
						</div>
						<div className="list-table-header-element">
							<span>Просмотры</span>
						</div>
					</div>
					<div className="list-table home-best-videos">
						{movieTabsState.id === 1
							? bestFilmsCollection.map((video, index) => (
									<MovieItem video={video} filtersState={filtersState} key={index} />
							  ))
							: bestSerialsCollection.map((video, index) => (
									<MovieItem video={video} filtersState={filtersState} key={index} />
							  ))}
					</div>
				</Stack>
			</Stack>
		</StatisticBlockContainer>
	);
};

const MovieItem = ({ video, filtersState }) => {
	const [videoViewState, setVideoViewState] = useState(null);
	const [dashboardVideoState, setDashboardVideoState] = useState(null);
	const dispatch = useDispatch();

	useEffect(async () => {
		if (!filtersState.from_datetime || !filtersState.to_datetime) return;
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/video/watch-amount?video_id=${video.id}&date_start=${new Date(
					filtersState.from_datetime
				).getTime()}&${new Date(filtersState.to_datetime).getTime()}&is_first=true`,
				data: "",
			})
			.then((prod) => {
				setVideoViewState(prod.data.amount);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/video/${video.id}`)
			.then((video) => {
				setDashboardVideoState(video.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [video, filtersState]);

	return (
		<Link className="list-table-item" to={`/film?id=${dashboardVideoState?.movie?.id}`}>
			<div className="list-table-item-element">
				<div className="preview-container">
					<img
						src={
							dashboardVideoState?.URL_SD_PREVIEW
								? `https://atips.io/${dashboardVideoState?.URL_SD_PREVIEW}`
								: `https://atips.io/${dashboardVideoState?.movie?.URL_SD_HORIZONTAL_PREVIEW}`
						}
					/>
					<div
						className="blur-bg"
						style={{
							backgroundImage: `url('${
								dashboardVideoState?.URL_SD_PREVIEW
									? `https://atips.io/${dashboardVideoState?.URL_SD_PREVIEW}`
									: `https://atips.io/${dashboardVideoState?.movie?.URL_SD_HORIZONTAL_PREVIEW}`
							}')`,
						}}
					></div>
				</div>
				<Stack className="stack" space="8px">
					<DText15>{dashboardVideoState?.title}</DText15>
					{/* <DText13 style={{ color: "#94A0B1" }}>
						Опубликовано {formatDate(video.created_at).split(",")[0]}
					</DText13> */}
				</Stack>
			</div>
			<div className="list-table-item-element">
				<span>{`${video.views.toLocaleString("ru-RU")} (${
					videoViewState ? videoViewState.toLocaleString("ru-RU") : 0
				})`}</span>
				<Tooltip
					text={`Всего: ${video.views.toLocaleString("ru-RU")} \n Уникальных: ${
						videoViewState ? videoViewState.toLocaleString("ru-RU") : 0
					}`}
				/>
			</div>
		</Link>
	);
};

export default connect(null, null)(BestMovies);
