import axios from "axios";
import { bloggerUrl, baseUrl, cinemaUrl } from "../../../../../Actions/consts";

// Кол-во просмотров
export const getTotalViewAmount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-amount?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.amount);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getBloggerPercent = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/ownerpercent`, {
			params: {
				...vars,
			},
		})
		.then((price) => {
			onReady(price.data.items[0]);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalVideos = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/movies/filter?limit=1&offset=0`, {
			params: {
				...vars,
			},
		})
		.then((video) => {
			onReady(video.data.paginate_.total_rows);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalTips = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/tips/1/0`, {
			params: {
				...vars,
				is_published: true,
			},
		})
		.then((blogger) => {
			if ("status_code" in blogger.data) {
				onReady(0);
			} else {
				onReady(blogger.data.paginate_.total_rows);
			}
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
export const getTotalAverageTime = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/average-session-time?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.average_session_time);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
