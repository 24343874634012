export const getFormatedNumberValue = (value) => {
	return Number(value) ? Number(value)?.toLocaleString("ru-RU") || 0 : 0;
};

export const getPeriod = () => {
	const actualDate = new Date();
	const startDate = new Date("2023-01-01");
	const yearAgo = new Date().setFullYear(actualDate.getFullYear() - 1);
	const monthAgo = new Date().setMonth(actualDate.getMonth() - 1);
	const weekAgo = new Date().setDate(actualDate.getDate() - 7);
	const dayAgo = new Date().setDate(actualDate.getDate() - 1);
	return {
		0: {
			startDate: dayAgo,
			endDate: actualDate.getTime(),
			title: "За день",
			group_by: "hour",
			period_name: "day",
		},
		1: {
			startDate: weekAgo,
			endDate: actualDate.getTime(),
			title: "За неделю",
			group_by: "day",
			period_name: "week",
		},
		2: {
			startDate: monthAgo,
			endDate: actualDate.getTime(),
			title: "За месяц",
			group_by: "day",
			period_name: "month",
		},
		3: {
			startDate: yearAgo,
			endDate: actualDate.getTime(),
			title: "За год",
			group_by: "month",
			period_name: "year",
		},
		4: {
			startDate: startDate,
			endDate: actualDate.getTime(),
			title: "За всё время",
			group_by: "month",
			period_name: "full",
		},
	};
};
