import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { EmptyFallback, SubmitButton } from "./Buttons.jsx";
import { getAllBlockedOwner } from "../../../../Actions/OwnerActions.js";
import PaginationBlock from "../../../universal/PaginationBlock/PaginationBlock.jsx";
import ActivateOwnerModal from "./modal/ActivateOwnerModal.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../Actions/consts.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { GET_MAIN_OWNER_PROFIT } from "../../../../GraphQL/OwnerStatistic.js";
import { useQuery } from "@apollo/client";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip.jsx";

const BlockedBloggerPage = ({
	ownersList,
	mainFilterState,
	setMainFilterState,
	getAllBlockedOwner,
}) => {
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { handleModal } = useContext(ModalContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [ownersState, setOwnersState] = useState([]);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getAllBlockedOwner({ ...filteredState, is_active: false });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	useEffect(() => {
		setOwnersState(ownersList.list);
	}, [ownersList]);

	const handleCancelClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<ActivateOwnerModal id={id} filteredState={mainFilterState} />);
	};

	return (
		<div className="blocked-owner-page">
			{ownersState.length > 0 ? (
				<>
					<div className="list-table-header owner-list">
						<div
							className="list-table-header-element"
							style={{ width: "546%" }}
							onClick={() =>
								handleFilteredClick("sort", "name", mainFilterState, setMainFilterState)
							}
						>
							<span className="non-sort">Название</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "140%" }}
							onClick={() =>
								handleFilteredClick("sort", "atips", mainFilterState, setMainFilterState)
							}
						>
							<span className="non-sort">Кол-во видео</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "164%" }}
							onClick={() =>
								handleFilteredClick("sort", "atips", mainFilterState, setMainFilterState)
							}
						>
							<span className="non-sort">Просмотры</span>
						</div>
						<div
							className="list-table-header-element"
							style={{ width: "142%" }}
							onClick={() =>
								handleFilteredClick("sort", "sum", mainFilterState, setMainFilterState)
							}
						>
							<span className="non-sort">Переходы</span>
						</div>
						{checkUserWritePerm(userAccessState, "owners") ? (
							<div className="list-table-header-element" style={{ width: "115px" }}></div>
						) : null}
					</div>
					<div className="list-table stat-list owner-list">
						{ownersState.map((elem, index) => (
							<OwnerListItem handleCancelClick={handleCancelClick} elem={elem} key={index} />
						))}
					</div>
				</>
			) : (
				<EmptyFallback>Пока нет блогеров</EmptyFallback>
			)}
			{Object.keys(ownersList.pagination).length !== 0 &&
			!!ownersList.pagination.max_pages &&
			ownersState.length > 0 &&
			ownersList.pagination.max_pages > 1 ? (
				<PaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={ownersList.pagination}
				/>
			) : null}
		</div>
	);
};

const OwnerListItem = ({ elem, handleCancelClick }) => {
	const dispatch = useDispatch();
	const { userAccessState } = useUserAccessContext();
	const [videosCount, setVideosCount] = useState(0);
	const [watchAmount, setWatchAmount] = useState(0);
	const [uniqWatchAmount, setUniqWatchAmount] = useState(0);
	const { data } = useQuery(GET_MAIN_OWNER_PROFIT, {
		variables: {
			owner_id: elem.id,
		},
	});

	useEffect(() => {
		// кол-во видео
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		axios
			.get(`${baseUrl}/domain/movies/filter`, {
				params: { limit: 1, offset: 0, owner_id: elem.id },
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.then((owner) => {
				setVideosCount(owner.data.paginate_.total_rows);
			});

		// кол-во просмотров
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/video/watch-amount?owner_id=${elem.id}`,
				data: "",
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.then((res) => {
				setWatchAmount(res.data.amount);
			});

		// кол-во уникальных просмотров
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/video/watch-amount?owner_id=${elem.id}&is_first=true`,
				data: "",
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.then((res) => {
				setUniqWatchAmount(res.data.amount);
			});
	}, []);

	return (
		<Link to={`/owner-statistic?id=${elem?.id}`} className="list-table-item">
			<div className="list-table-item-element">
				<div className="list-table-item-element-info">
					<span>{elem.name}</span> <small>ID {elem?.id}</small>
				</div>
			</div>
			<div className="list-table-item-element">
				<span>{videosCount}</span>
			</div>
			<div className="list-table-item-element">
				<span>
					{watchAmount} ({uniqWatchAmount})
				</span>
				<Tooltip
					text={`Всего: ${watchAmount.toLocaleString("ru-RU")} \n Уникальных: ${
						uniqWatchAmount ? uniqWatchAmount.toLocaleString("ru-RU") : 0
					}`}
				/>
			</div>
			<div className="list-table-item-element">
				<span>{data?.getStatisticPage?.pageInfo?.itemsCount || 0}</span>
			</div>
			{checkUserWritePerm(userAccessState, "owners") ? (
				<div className="list-table-item-element" style={{ paddingLeft: "0" }}>
					<SubmitButton style={{ width: "136px" }} onClick={(e) => handleCancelClick(e, elem.id)}>
						Активировать
					</SubmitButton>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		ownersList: state.ownersList.blocked,
	};
};

const mapDispatchToProps = {
	getAllBlockedOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedBloggerPage);
