import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import PlaylistItem from "../PlaylistItem/PlaylistItem.jsx";
import { ParentDragHandle } from "../../../universal/dragHandle/DragHandle.jsx";
import CreateEditPlaylistModal from "../modals/CreatePlaylistModal.jsx";
import UniversalDeleteModal from "../../../universal/UniversalDeleteModal.jsx";
import AddVideoPlaylistsModal from "../modals/AddVideoPlaylistsModal.jsx";
import { deletePlaylist, getCurrentPlaylist } from "../../../../Actions/PlayListActions";
import { useDispatch } from "react-redux";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { nativeMapBy } from "../../scripts/mapBy.js";
import { CantDeleteModal } from "../modals/CantDeleteModal";

// компонент плейлиста
const SortableItem = sortableElement(({ value, filmState, setFilmState, genresMap }) => {
	const { userAccessState } = useUserAccessContext();
	const dispatch = useDispatch();
	const elem = value.elem;
	const index = value.index;
	const { handleModal } = useContext(ModalContext);

	const handleOpenContent = (evt) => {
		evt.stopPropagation();
		const current = evt.currentTarget;
		const parrent = current.parentNode;
		parrent.classList.toggle("open");
	};

	const handleDeleteClick = (id) => {
		dispatch(deletePlaylist(id));
	};

	return (
		<div className="form-container-block-season">
			<div className="form-container-block-season-header" onClick={(e) => handleOpenContent(e)}>
				<div
					className="form-container-block-season-title"
					style={{
						paddingRight: "100px",
						textOverflow: "ellipsis",
						overflow: "hidden",
						whiteSpace: "nowrap",
					}}>
					Плейлист {index + 1},{" "}
					<span>
						{elem.title}
						<span>
							{elem?.genre && elem?.genre.length
								? `Жанры: ${elem.genre
										?.split(",")
										.map((elem) => genresMap[elem].title)
										.join(", ")}`
								: `Выбрано фильмов и сериалов: ${elem?.movies?.length}`}
						</span>
					</span>
				</div>
				<div className="form-container-block-season-arrow">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.99997 9.58545L8.70707 10.2926L12 13.5854L15.2929 10.2926L16 9.58545L17.4142 10.9997L16.7071 11.7068L12.7071 15.7068C12.3166 16.0973 11.6834 16.0973 11.2929 15.7068L7.29286 11.7068L6.58575 10.9997L7.99997 9.58545Z"
							fill="#212121"></path>
					</svg>
				</div>
			</div>
			{checkUserWritePerm(userAccessState, "playlists") ? <ParentDragHandle /> : null}
			<div className="form-container-block-season-container">
				<div className="playlist-control">
					{checkUserWritePerm(userAccessState, "playlists") ? (
						<div className="playlist-control-buttons">
							<div
								className="edit-playlist playlist-control-button action-button"
								onClick={() =>
									handleModal(
										<CreateEditPlaylistModal userAccessState={userAccessState} data={elem} />
									)
								}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M16.62 2.25a1 1 0 0 0-1.414 0l-9.62 9.62A2 2 0 0 0 5 13.284V17a1 1 0 0 0 1 1h3.716a2 2 0 0 0 1.415-.585l9.62-9.62a1 1 0 0 0 0-1.415l-4.13-4.13ZM7 13.284l8.913-8.913 2.716 2.716L9.716 16H7v-2.716Zm-4 6.717H2v2h20v-2H3Z"
										fill="#212121"
									/>
								</svg>
								<span>Изменить</span>
							</div>
							<div
								className="remove-playlist playlist-control-button action-button"
								onClick={() =>
									elem?.movies?.length ? (
										handleModal(<CantDeleteModal />)
									) : (
										handleModal(
											<UniversalDeleteModal
												title="плейлист"
												id={elem.id}
												deleteFunc={handleDeleteClick}
											/>
										)
									)
								}>
								<span>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
											fill="#94A0B1"></path>
									</svg>
								</span>
								Удалить
							</div>
						</div>
					) : null}
					{/* <div className="video-type-card">
						<div className="video-type-card__title">Тип карточек видео</div>

						<div className="card-content-wrapper">
							<div className="video-type__name">Крупные вертикальные</div>
							<div
								className="edit-playlist playlist-control-button action-button"
								onClick={() =>
									handleModal(
										<CardType
											currentTypeState={filmState[index].cardType}
											setCurrentTypeState={(val) => handleChangeCardType(val)}
										/>
									)
								}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M16.62 2.25a1 1 0 0 0-1.414 0l-9.62 9.62A2 2 0 0 0 5 13.284V17a1 1 0 0 0 1 1h3.716a2 2 0 0 0 1.415-.585l9.62-9.62a1 1 0 0 0 0-1.415l-4.13-4.13ZM7 13.284l8.913-8.913 2.716 2.716L9.716 16H7v-2.716Zm-4 6.717H2v2h20v-2H3Z"
										fill="#212121"
									/>
								</svg>
							</div>
						</div>
					</div> */}
					<div className="playlist-child-title">
						<span className="playlist-child-title__text">ВИДЕО</span>
						{checkUserWritePerm(userAccessState, "playlists") ? (
							<div
								className="button add-episode action-button border-button small-button"
								onClick={() =>
									handleModal(<AddVideoPlaylistsModal playlistId={elem.id} playlist={elem} />)
								}>
								<img src="images/icons/ui/actions/plus.svg" alt="плюс"></img>
								<span>Добавить видео</span>
							</div>
						) : null}
					</div>
				</div>
				<PlaylistItem
					filmState={filmState}
					setFilmState={setFilmState}
					playlistItem={elem}
					index={index}
				/>
			</div>
		</div>
	);
});

const SortableContainer = sortableContainer(({ children }) => {
	return (
		<div className="form-container-block-seasons ui-sortable" style={{ overflowY: "auto" }}>
			{children}
		</div>
	);
});

// врапперы плейлистов
const PlaylistsComponent = ({
	playlistState,
	setPlaylistState,
	getCurrentPlaylist,
	videoGenresList,
}) => {
	const [genresMapState, setGenresMapState] = useState({});

	// данный флаг вызывает ререндер
	const { userAccessState } = useUserAccessContext();
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.map((elem, index) => (elem.order = index + 1));
		return result;
	};
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const items = reorder(playlistState, oldIndex, newIndex);
		setPlaylistState(items);
	};
	const onSortStart = ({ node }) => {
		console.log(node);
	};

	useEffect(() => {
		setGenresMapState(nativeMapBy(videoGenresList, "id"));
	}, [videoGenresList]);

	return (
		<div className="form-container-block">
			{playlistState.length > 0 ? (
				<SortableContainer
					onSortEnd={onSortEnd}
					useDragHandle
					onSortStart={onSortStart}
					helperClass="form-container-block-season2">
					{playlistState.length > 0
						? playlistState.map((elem, index) =>
								elem !== null ? (
									<SortableItem
										userAccessState={userAccessState}
										disabled={!checkUserWritePerm(userAccessState, "playlists")}
										getCurrentPlaylist={getCurrentPlaylist}
										key={`item-${elem.id}`}
										genresMap={genresMapState}
										index={index}
										value={{ elem, index }}
										filmState={playlistState}
										setFilmState={setPlaylistState}
									/>
								) : null
						  )
						: null}
				</SortableContainer>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videoGenresList: state.videoGenresList,
	};
};

const mapDispatchToProps = {
	getCurrentPlaylist,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistsComponent);
