import axios from "axios";
import { baseUrl } from "./consts";

export const getCurrentOwner = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/owner/${id}`)
			.then((owner) => {
				dispatch({
					type: "GET_CURRENT_OWNER",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putCurrentOwner = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.put(`${baseUrl}/owner/${id}`, data)
			.then((owner) => {
				dispatch({
					type: "PUT_CURRENT_OWNER",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllActiveOwner = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/owners`, {
				params: { ...arg },
			})
			.then((owner) => {
				dispatch({
					type: "GET_ALL_ACTIVE_OWNERS",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllBlockedOwner = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/owners`, {
				params: { ...arg },
			})
			.then((owner) => {
				dispatch({
					type: "GET_ALL_BLOCKED_OWNERS",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getBlockedOwnerTotal = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/owners`, {
				params: { offst: 0, limit: 1, is_active: false },
			})
			.then((owner) => {
				dispatch({
					type: "GET_BLOCKED_OWNERS_TOTAL",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getActiveOwnerTotal = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/owners`, {
				params: { offst: 0, limit: 1, is_active: true },
			})
			.then((owner) => {
				dispatch({
					type: "GET_ACTIVE_OWNERS_TOTAL",
					owner,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
