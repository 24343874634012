import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { GET_VIEWER_TIPS } from "../../../../GraphQL/ViewerPage.js";
import { useLazyQuery } from "@apollo/client";
import {
	getVieweTotalWatchAmount,
	getViewerAverageWatchTime,
	getViewerTotalWatchTime,
} from "./get-viewer-totals.js";
import { Stack } from "../../../../ui-kit/layouts/Stack.jsx";
import { DText15, H4Text } from "../../../../ui-kit/typography/Typography.jsx";

const МeaningfulStatisticCards = ({ viewerState, filtersState }) => {
	const dispatch = useDispatch();
	const [totalWatchTime, setTotalWatchTime] = useState(0);
	const [totalWatchAmount, setTotalWatchAmount] = useState(0);
	const [uniqWatchAmount, setUniqWatchAmount] = useState(0);
	const [averageWatchTime, setAverageWatchTime] = useState(0);
	const [getTipsStatistic, { data: tips, loading: tipsShowLoading }] = useLazyQuery(
		GET_VIEWER_TIPS,
		{
			variables: {},
		}
	);

	useEffect(() => {
		if (
			!viewerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;

		getTipsStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				moviegoer_id: viewerState.id,
			},
		});
		getViewerTotalWatchTime(
			{
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				viewer_id: viewerState.external_id,
			},
			dispatch,
			(e) => setTotalWatchTime(e)
		);
		getViewerAverageWatchTime(
			{
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				viewer_id: viewerState.external_id,
			},
			dispatch,
			(e) => setAverageWatchTime(e)
		);
		getVieweTotalWatchAmount(
			{
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				viewer_id: viewerState.external_id,
			},
			dispatch,
			(e) => setTotalWatchAmount(e)
		);
		getVieweTotalWatchAmount(
			{
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				viewer_id: viewerState.external_id,
				is_first: true,
			},
			dispatch,
			(e) => setUniqWatchAmount(e)
		);
	}, [viewerState, filtersState]);
	return (
		<div className="meaningful-statistic-cards-wrapper">
			<div className="card-wrapper purple">
				<div className="card-main-info ">
					<div className="card-main-info__value">
						<b>{tips?.click?.aggregation.total_sum.toLocaleString("ru-RU") | "0"} ₽</b>{" "}
						{/* <Badge value={12} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Общий доход со зрителя</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{totalWatchTime > 60
								? `${(totalWatchTime / 60).toFixed(2)} мин.`
								: `${Math.round(totalWatchTime)} сек.`}
						</b>
						{/* <IndicatorArrow positive={true} /> */}
					</div>
				</div>
				<div className="card-description">Общее время просмотра видео</div>
				{/* <img className="card-graph" src={statisticGraphic} alt="graph" /> */}
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{averageWatchTime > 60
								? `${(averageWatchTime / 60).toFixed(2)} мин.`
								: `${Math.round(averageWatchTime)} сек.`}
						</b>
						{/* <IndicatorArrow positive={false} /> */}
					</div>
				</div>
				<div className="card-description">Средний просмотр видео</div>
			</div>
			<div className="card-wrapper double">
				<Stack space="16px">
					<Stack stretch={true}>
						<H4Text>{Number(totalWatchAmount).toLocaleString("ru-RU")}</H4Text>
						<DText15>Всего просмотров</DText15>
					</Stack>
					<Stack stretch={true}>
						<H4Text>{Number(uniqWatchAmount).toLocaleString("ru-RU")}</H4Text>
						<DText15>Уникальных просмотров</DText15>
					</Stack>
				</Stack>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{tips?.show?.pageInfo.itemsCount.toLocaleString("ru-RU") | "0"}</b>{" "}
						{/* <Badge value={6} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Просмотров типсов</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{tips?.click?.pageInfo.itemsCount.toLocaleString("ru-RU") | "0"}</b>
						{/* <Badge value={-4} valueType={"%"} /> */}
					</div>
				</div>
				<div className="card-description">Клики типсов</div>
			</div>
		</div>
	);
};

export default connect(null, null)(МeaningfulStatisticCards);
