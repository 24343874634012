import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";
import BaseTipEdit from "./BaseTipEdit";
import { DTBold14, DText15 } from "../../../../../ui-kit/typography/Typography";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { takeCategoryParents } from "../../pages/scripts";
import { getCategories } from "../../../../../Actions/CategoriesActions";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";
import { takeId } from "../../../scripts/PersonalAreaScripts";
import SponseredTipEdit from "./SponseredTipEdit";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { Button } from "../../../../../ui-kit/button/Button";
import VideoUneditablePreviewModal from "../VideoUneditablePreviewModal";

const TipEditModal = ({
	tips,
	opened,
	onClose,
	getCategories,
	categoriesList,
	onUpdateList,
	src,
	timeCodeState,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [tipSettingsState, setTipSettingsState] = useState(() => tips);
	const dispatch = useDispatch();
	const [sellerState, setSellerState] = useState(null);
	const [categoriesState, setCategoriesState] = useState();
	const [categoryBreads, setCategoryBreads] = useState([]);
	useEffect(() => {
		getCategories();
	}, []);
	useEffect(() => {
		setCategoriesState(categoriesList);
	}, [categoriesList]);
	const handleEditClick = async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		const attrs = takeId(tipSettingsState.attributes_settable);
		const prod = takeId(tipSettingsState.predefined_products);
		await axios
			.put(`${baseUrl}/domain/tips/${tipSettingsState.id}`, {
				...tipSettingsState,
				attributes_settable:
					tipSettingsState.predefined_products.length > 0
						? takeId(tipSettingsState.predefined_products?.[0]?.attributes_settable)
						: attrs,
				predefined_products: prod?.length ? prod : [],
				predefined: prod?.length ? 1 : 0,
				category_id:
					tipSettingsState.predefined_products.length > 0
						? tipSettingsState.predefined_products?.[0]?.category_id
						: tipSettingsState?.category_id,
			})
			.then((video) => {})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
				onUpdateList();
				onClose();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (!categoriesList.length || !tipSettingsState.category || !tipSettingsState.category.id) {
			return;
		}

		let filteredArray = [];
		const collection = [...categoriesList[0], ...categoriesList[1]];

		collection.forEach((elem, index) => {
			if (elem.childs.length === 0) {
				if (elem.id === tipSettingsState.category.id) {
					filteredArray = [{ ...elem, index }];
				}
			} else {
				elem.childs.forEach((elem1, index1) => {
					if (elem1.childs.length === 0) {
						if (elem1.id === tipSettingsState.category.id) {
							filteredArray = [
								{ ...elem, index: index },
								{ ...elem1, index: index1 },
							];
						}
					} else {
						elem1.childs.forEach((elem2, index2) => {
							if (elem2.childs.length === 0) {
								if (elem2.id === tipSettingsState.category.id) {
									filteredArray = [
										{ ...elem, index: index },
										{ ...elem1, index: index1 },
										{ ...elem2, index: index2 },
									];
								}
							} else {
								elem2.childs.forEach((elem3, index3) => {
									if (elem3.childs.length === 0) {
										if (elem3.id === tipSettingsState.category.id) {
											filteredArray = [
												{ ...elem, index: index },
												{ ...elem1, index: index1 },
												{ ...elem2, index: index2 },
												{ ...elem3, index: index3 },
											];
										}
									} else {
										elem3.childs.forEach((elem4, index4) => {
											if (elem4.childs.length === 0) {
												if (elem4.id === tipSettingsState.category.id) {
													filteredArray = [
														{ ...elem, index: index },
														{ ...elem1, index: index1 },
														{ ...elem2, index: index2 },
														{ ...elem3, index: index3 },
														{ ...elem4, index: index4 },
													];
												}
											}
										});
									}
								});
							}
						});
					}
				});
			}
		});
		setCategoryBreads(filteredArray);
	}, [categoriesList, tipSettingsState]);

	return (
		<div className="main-modal" style={{ display: opened ? "block" : "none" }}>
			<Cluster
				className="modal-content"
				id="modal-content"
				justify="center"
				align="center"
				style={{ height: "100%", display: "flex" }}
			>
				<div className="add-category-wrapper" style={{ width: "580px", margin: "auto" }}>
					<div className="modal-field-title">
						{!sellerState ? tipSettingsState?.category?.title || "Изменить типс" : "Изменить типс"}
						<DTBold14 style={{ color: "#005bff" }}>
							{categoryBreads.map((item, index) => {
								let res = "";
								if (index === 0) {
									if (item.parent_id === 1) {
										res = `Товары > `;
									} else {
										res = `Услуги > `;
									}
								}
								if (index !== categoryBreads.length - 1) {
									return res + `${item.title} > `;
								} else return res + item.title;
							})}
						</DTBold14>
					</div>
					<div className="modal-field-close" onClick={() => onClose()}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
								fill="#212121"
							></path>
						</svg>
					</div>
					<Stack space="16px">
						<Cluster justify="space-between" align="center">
							<DText15>ID {tipSettingsState.id}</DText15>
							{src?.length > 0 ? (
								<Button
									onClick={(e) => (
										e.preventDefault(),
										e.stopPropagation(),
										handleModal(<VideoUneditablePreviewModal src={src} timeCode={timeCodeState} />)
									)}
									startIcon={
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g clipPath="url(#clip0_1442_3428)">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M4 0C1.79086 0 0 1.79086 0 4V18.5008C0 18.5009 0 18.5009 0 18.5009V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V9.88069L24.1248 9.78087C24.556 9.43586 24.626 8.80657 24.281 8.37531C24.2007 8.27495 24.105 8.19416 24 8.13362V4C24 1.79086 22.2091 0 20 0H4ZM22 8.91944V4C22 2.89543 21.1046 2 20 2H4C2.89543 2 2 2.89543 2 4V16.5794L5.42662 14.1808C5.79026 13.9262 6.27817 13.9418 6.62478 14.2191L11.0001 17.7194L22 8.91944ZM2 19.0207V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V11.4807L11.6248 19.7809C11.2596 20.073 10.7406 20.073 10.3754 19.7809L5.96011 16.2486L2 19.0207ZM10.0001 10C11.1047 10 12.0001 9.10457 12.0001 8C12.0001 6.89543 11.1047 6 10.0001 6C8.89552 6 8.00009 6.89543 8.00009 8C8.00009 9.10457 8.89552 10 10.0001 10ZM10.0001 12C12.2092 12 14.0001 10.2091 14.0001 8C14.0001 5.79086 12.2092 4 10.0001 4C7.79095 4 6.00009 5.79086 6.00009 8C6.00009 10.2091 7.79095 12 10.0001 12Z"
													fill="#94A0B1"
												/>
											</g>
											<defs>
												<clipPath id="clip0_1442_3428">
													<rect width="24" height="24" fill="white" />
												</clipPath>
											</defs>
										</svg>
									}
									variant="white-icon"
								/>
							) : null}
						</Cluster>

						<BaseTipEdit
							sellerState={sellerState}
							setSellerState={setSellerState}
							tipSettingsState={tipSettingsState}
							setTipSettingsState={setTipSettingsState}
						/>
						<SponseredTipEdit
							sellerState={sellerState}
							setSellerState={setSellerState}
							tipSettingsState={tipSettingsState}
							setTipSettingsState={setTipSettingsState}
						/>
					</Stack>
					<Cluster className="modal-buttons delete">
						<button className="button action-button border-button" onClick={() => onClose()}>
							<span>Отменить</span>
						</button>
						<button
							disabled={
								!tipSettingsState?.category_id && !tipSettingsState?.predefined_products?.length
							}
							className={`button action-button create-category-button`}
							onClick={() => handleEditClick()}
						>
							<span>Изменить типс</span>
						</button>
					</Cluster>
				</div>
			</Cluster>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		categoriesList: state.categoriesList,
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(TipEditModal);
