import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import MeaningfulVideoStatisticCards from "./MeaningfulVideoStatisticCards.jsx";
import "./EditVideoStatistic.scss";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { initialCinemaSources } from "./static.js";
import SourcesDescriptionComponent from "./SourcesDescriptionComponent.jsx";
import { useNavigate } from "react-router-dom";
import PieChartComponent from "../../../../universal/PieCharts/PieChartComponent.jsx";
import { useLazyQuery } from "@apollo/client";
import { GET_VIDEO_MOVIEGOER_AGG, GET_VIDEO_SELLER_AGG } from "../../../../../GraphQL/VideoPage.js";
import DashboardVideoGadgetsStatistic from "./dashboardVideoGadgetsStatistic/DashboardVideoGadgetsStatistic.jsx";
import DashboardVideoViewBlock from "./dashboardVideoViewsBlock/DashboardVideoViewBlock.jsx";
import DashboardVideoViewsDepthBlock from "./dashboardVideoViewsDepthBlock/DashboardVideoViewsDepthBlock.jsx";
import { EmptyNotification } from "../../../../../ui-kit/search/notifications.js";
import { H5Text } from "../../../../../ui-kit/typography/Typography.jsx";
import TipCategoryStatistic from "../../edit-blogger-video/statistic/TipCategoryStatistic.jsx";
import UserStatisticInfoAccordionField from "../../../HomePage/UsersStatistic/UserStatisticInfoAccordionField.jsx";
import { UserStatisticInfoField } from "../../../HomePage/UsersStatistic/UserStatisticInfoField.jsx";

const EditVideoStatistic = ({ filtersState, mainVideoState, additionalVideoState }) => {
	const navigate = useNavigate();
	const [trafficSourceState, setTrafficSourceState] = useState([]);
	const dispatch = useDispatch();
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_VIDEO_MOVIEGOER_AGG, {
		variables: {
			video_id: null,
		},
	});
	const [getSellerStatistic, { data: sellerData }] = useLazyQuery(GET_VIDEO_SELLER_AGG, {
		variables: {
			video_id: null,
		},
	});

	useEffect(() => {
		if (
			!mainVideoState ||
			!mainVideoState.video?.[0]?.id ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;

		// TODO
		getStatistic({
			variables: {
				video_id: mainVideoState.video?.[0]?.id,
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
		getSellerStatistic({
			variables: {
				video_id: mainVideoState.video?.[0]?.id,
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});

		// getVideoTrafficSources(
		// 	{
		// 		content_creator_video_id: bloggerVideo.id,
		// 		date_start: new Date(filtersState.from_datetime).toISOString(),
		// 		date_end: new Date(filtersState.to_datetime).toISOString(),
		// 	},
		// 	dispatch,
		// 	(e) => setTrafficSourceState(e)
		// );
	}, [filtersState]);

	return (
		<div className="form-container full-width video-statistic">
			<div className="statistic-type-title">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
						fill="#212121"
					/>
				</svg>
				<span className="animte-menu" onClick={() => navigate("/statistic?id=11")}>
					Статистика по типсам
				</span>
			</div>

			{/* <div className="form-container">
				<div className="form-container-block empty-datas">Пока нет данных</div>
			</div> */}
			<MeaningfulVideoStatisticCards
				filtersState={filtersState}
				mainVideoState={mainVideoState}
				additionalVideoState={additionalVideoState}
			/>
			<DashboardVideoViewBlock filtersState={filtersState} mainVideoState={mainVideoState} />
			<DashboardVideoViewsDepthBlock filtersState={filtersState} mainVideoState={mainVideoState} />
			<DashboardVideoGadgetsStatistic filtersState={filtersState} mainVideoState={mainVideoState} />
			<div className="columns-wrapper">
				<div className="first-col columns-wrapper__col">
					<StatisticBlockContainer>
						<div className="gadgets-statistic-header">
							<H5Text className="gadgets-statistic-header__title">Источники в кинотеатре</H5Text>
						</div>
						{trafficSourceState.length === 0 ? (
							<EmptyNotification>Нет данных</EmptyNotification>
						) : (
							<>
								<PieChartComponent collection={[...trafficSourceState]} />
								<div className="description-wrapper">
									{trafficSourceState.map((elem, index) => (
										<SourcesDescriptionComponent sourceData={elem} key={index} />
									))}
								</div>
							</>
						)}
					</StatisticBlockContainer>

					<StatisticBlockContainer>
						<H5Text>Портреты зрителей</H5Text>
						{data?.getMoviegoerAggItem?.countries.length === 0 ? (
							<EmptyNotification>Нет данных</EmptyNotification>
						) : (
							<>
								<UserStatisticInfoAccordionField
									collection={data?.getMoviegoerAggItem?.countries || []}
									title="Страны и регионы"
								/>
								<UserStatisticInfoField
									collection={data?.getMoviegoerAggItem?.sex_info || []}
									title="Пол"
									color="blue"
								/>
								<UserStatisticInfoField
									collection={data?.getMoviegoerAggItem?.age_info || []}
									title="Возраст"
									color="pink"
								/>
							</>
						)}
					</StatisticBlockContainer>
				</div>
				<div className="second-col columns-wrapper__col">
					<TipCategoryStatistic mainVideoState={mainVideoState} dashboardVideoState={null} />
					<StatisticBlockContainer>
						<H5Text>Портреты рекламодателей</H5Text>
						{sellerData?.getSellerAggItem?.countries?.length === 0 ? (
							<EmptyNotification>Нет данных</EmptyNotification>
						) : (
							<UserStatisticInfoAccordionField
								collection={sellerData?.getSellerAggItem?.countries || []}
								title="Страны и регионы"
							/>
						)}
					</StatisticBlockContainer>
				</div>
			</div>
		</div>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 	};
// };

// const mapDispatchToProps = {
// };

export default connect(null, null)(EditVideoStatistic);
