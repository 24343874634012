import React, { useEffect } from "react";
import { initialMonthData } from "./static";
import { useState } from "react";
import { Stack } from "../../../../../../ui-kit/layouts/Stack";
import { connect, useDispatch } from "react-redux";
import { fetchCurrentVideoViewByPeriod } from "./fetchVideoViewByPeriod";
import VideoViewGraph from "./VideoViewGraph.jsx";
import { H5Text } from "../../../../../../ui-kit/typography/Typography";

const VideoViewBlock = ({
  videoViewsGraph,
  mainVideoState,
  filtersState,
}) => {
  const dispatch = useDispatch();
  const [dataState, setDataState] = useState(initialMonthData());
  useEffect(() => {
    if (
      !mainVideoState.id ||
      !filtersState.from_datetime ||
      !filtersState.to_datetime ||
      !filtersState.period_name
    )
      return;
    fetchCurrentVideoViewByPeriod({
      vars: {
        date_start: new Date(filtersState.from_datetime).toISOString(),
        date_end: new Date(filtersState.to_datetime).toISOString(),
        group_by: filtersState?.group_by || "day",
        content_creator_video_id: mainVideoState.id,
      },
      dispatch,
      period: filtersState.period_name,
    });
  }, [filtersState, mainVideoState]);

  useEffect(() => {
    if (
      !filtersState.from_datetime ||
      !filtersState.to_datetime ||
      !filtersState.period_name
    )
      return;
    setDataState(videoViewsGraph[filtersState.period_name]);
  }, [videoViewsGraph, filtersState, mainVideoState]);
  return (
    <Stack space="16px">
      <div className="income-info-block category">
        <div className="list-title">
          <H5Text>Просмотры</H5Text>
        </div>
        <VideoViewGraph dataState={dataState} period={filtersState?.period_name} />
      </div>
    </Stack>
  );
};

const mapStateToProps = (state) => {
  return {
    videoViewsGraph: state.currentVideoViewsGraph,
  };
};

const mapDispatchToProps = {
  // getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewBlock);
