import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts";
import { getAreas } from "../../../../Actions/UsersActions";
import { useSearchParams } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";

const SellerFiltersComponent = ({ filterState, setFilterState, categoriesList }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleModal } = useContext(ModalContext);

	const [searchInput, setSearchInput] = useState("");
	const [emailSearchInput, setEmailSearchInput] = useState("");

	const handleSearchInputChange = (e) => {
		e.stopPropagation();
		let value = e.target.value;
		setSearchInput(value);
	};

	useEffect(() => {
		setSearchInput(
			!!JSON.parse(searchParams.get("filters"))?.name
				? JSON.parse(searchParams.get("filters"))?.name
				: ""
		);
	}, [searchParams]);

	return (
		<div className="list-filter flex-block" style={{ marginBottom: "38px" }}>
			<div className="form-field">
				<div className="input-field left-icon">
					<input
						type="text"
						placeholder="Поиск по названию"
						onKeyPress={(e) =>
							e.key === "Enter"
								? handleFilteredClick("sellerSearch", searchInput, filterState, setFilterState)
								: null
						}
						value={searchInput.length > 0 ? searchInput : ""}
						onChange={(e) => handleSearchInputChange(e)}
					/>
					<div
						className="input-search input-icon"
						onClick={(e) =>
							handleFilteredClick("sellerSearch", searchInput, filterState, setFilterState)
						}
					></div>
				</div>
			</div>
			<div className="form-field">
				<div className="input-field left-icon">
					<input
						type="text"
						placeholder="Поиск по email"
						onKeyPress={(e) =>
							e.key === "Enter"
								? handleFilteredClick("email", emailSearchInput, filterState, setFilterState)
								: null
						}
						value={emailSearchInput.length > 0 ? emailSearchInput : ""}
						onChange={(e) => setEmailSearchInput(e.target.value)}
					/>
					<div
						className="input-search input-icon"
						onClick={(e) =>
							handleFilteredClick("email", emailSearchInput, filterState, setFilterState)
						}
					></div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		categoriesList: state.extraCategoriesReducer,
		// areasList: state.areasList,
	};
};

const mapDispatchToProps = {
	getAreas,
	// getSellers,
	// getExtraCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerFiltersComponent);
