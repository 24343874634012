import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import "../styles/BloggerStyles.scss";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { CancelButton, EmptyFallback, SubmitButton } from "./Buttons.jsx";
import ExtertnalPaginationBlock from "../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import AcceptBloggerModal from "./modal/AcceptBloggerModal.jsx";
import RejectBloggerModal from "./modal/RejectBloggerModal.jsx";
import { getAllReviewExternalBlogger } from "../../../../Actions/BloggersActions.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";

const ReviewBloggerPage = ({
	getAllReviewExternalBlogger,
	sellersList,
	mainFilterState,
	setMainFilterState,
}) => {
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { handleModal } = useContext(ModalContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [sellersState, setSellersState] = useState([]);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getAllReviewExternalBlogger({ ...filteredState });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	useEffect(() => {
		setSellersState(sellersList.list);
	}, [sellersList]);

	const handleCancelClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<RejectBloggerModal id={id} filteredState={mainFilterState} />);
	};

	const handleSubmitClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<AcceptBloggerModal id={id} filteredState={mainFilterState} />);
	};

	return (
		<div className="review-seller-page">
			{sellersState.length > 0 ? (
				<>
					<div className="list-table-header seller-list">
						<div
							className="list-table-header-element"
							style={{ width: "100%" }}
							onClick={() =>
								handleFilteredClick("sort", "name", mainFilterState, setMainFilterState)
							}
						>
							<span>Имя</span>
						</div>
						{checkUserWritePerm(userAccessState, "bloggers") ? (
							<div className="list-table-header-element" style={{ width: "218px" }}></div>
						) : null}
					</div>
					<div className="list-table stat-list selers-list">
						{sellersState.map((elem, index) => (
							<SellerListItem
								handleCancelClick={handleCancelClick}
								elem={elem}
								key={index}
								handleSubmitClick={handleSubmitClick}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyFallback>Пока нет блогеров</EmptyFallback>
			)}
			{Object.keys(sellersList.pagination).length !== 0 &&
			!!sellersList.pagination.max_pages &&
			sellersState.length > 0 &&
			sellersList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={sellersList.pagination}
				/>
			) : null}
		</div>
	);
};

const SellerListItem = ({ elem, handleCancelClick, handleSubmitClick }) => {
	const { userAccessState } = useUserAccessContext();

	return (
		<Link to={`/bloggers-statistic?id=${elem?.id}`} className="list-table-item">
			<div className="list-table-item-element">
				{elem.avatar_url && elem.avatar_url?.length > 0 ? (
					<img src={elem.avatar_url} alt="profile" />
				) : (
					<img src="images/profile-frame.png" alt="profile" />
				)}
				<div className="list-table-item-element-info">
					<span>{elem.name}</span> <small>ID {elem?.serial_id}</small>
				</div>
			</div>
			{checkUserWritePerm(userAccessState, "bloggers") ? (
				<div className="list-table-item-element" style={{ paddingLeft: "0" }}>
					<SubmitButton onClick={(e) => handleSubmitClick(e, elem.id)}>Принять</SubmitButton>
					<CancelButton onClick={(e) => handleCancelClick(e, elem.id)}>Отклонить</CancelButton>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		sellersList: state.allExternalBloggersReducer.review,
	};
};

const mapDispatchToProps = {
	getAllReviewExternalBlogger,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBloggerPage);
