import axios from "axios";
import { baseUrl } from "../consts";

export const deleteVideo = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/movie/${`${id}`}`)
			// .then(() => {
				// dispatch({
				// 	type: "HIDE_ALL",
				// });
			// })
			.finally(() => {
				dispatch({
					type: "DELETE_CURRENT_VIDEO",
					payload: id,
				});
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteAddVideo = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/video/${`${id}`}`)
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};