import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { postUploadImages } from "../../../../../Actions/videos-actions/VideosActions.js";
import {
	handleClearSelectedClick,
	handleRemovePeopleClick,
	onInputTextAreaChange,
	onRemoveItem,
	onSelectItem,
} from "../../sctipts.js";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import SelectFilmCrew from "../content/SelectFilmCrew.jsx";
import CreateFilmCrew from "../../CreateFilmCrew/CreateFilmCrew.jsx";
import CreateSeasons from "../../CreateSeasons/CreateSeasons.jsx";
import "./EditVideosContent.scss";
import axios from "axios";
import { getCountries } from "../../../../../Actions/LocationActions.js";
import { useUserAccessContext } from "../../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../../scripts/utils.js";
import { DraggableArea } from "react-draggable-tags";

const prewPhotoUploadParams = {
	URL_SD_VERTICAL_PREVIEW: {
		width: 1080,
		height: 1920,
		crop: 1,
	},
	URL_SD_HORIZONTAL_PREVIEW: {
		width: 1920,
		height: 1080,
		crop: 1,
	},
};

const EditVideoContent = ({
	mainVideoState,
	setMainVideoState,
	additionalVideoState,
	setAdditionalVideoState,
	videoAgesList,
	videoGenresList,
	postUploadImages,
	uploadGalleryImage,
	loadingStatus,
	videoHashtags,
	errorState,
	countriesList,
	getCountries,
	ownersList,
}) => {
	const dispatch = useDispatch();
	const { handleModal, modal } = useContext(ModalContext);
	const [prewDragState, setPrewDragState] = useState(() => false); // состояние drag для превьюшки
	const [imageErrorState, setImageErrorState] = useState(null);
	// т.е. пока false он даже не смотрит на аплоад
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
	const [ownersState, setOwnersState] = useState([]);
	const { userAccessState } = useUserAccessContext();
	const [videoAgesState, setVideoAgesState] = useState([]); // состояние возрастных ограничений
	const [videoGenresState, setVideoGenresState] = useState([]); // состояние жанров
	const [videoHashtagsState, setVideoHashtagsState] = useState([]); // состояние хештегов
	const multiselectRef = React.createRef(); // ref жанров
	const multiselectHashRef = React.createRef(); // ref хештегов
	const [countriesState, setCountriesState] = useState([]);

	useEffect(() => {
		setCountriesState(countriesList);
	}, [countriesList]);

	useEffect(() => {
		countriesList.length === 0 && getCountries();
	}, []);

	useEffect(() => {
		dispatch({ type: "RESET_UPLOAD" }); // делается это для того, чтобы занулять состояние uploadimage (он участвует и в модальном окне и вне)
	}, [modal]);

	useEffect(() => {
		// запись в состояние компонента списка возрастных ограничений
		setVideoAgesState(videoAgesList);
	}, [videoAgesList]);

	useEffect(() => {
		// запись в состояние компонента списка возрастных ограничений
		setOwnersState(ownersList?.list);
	}, [ownersList]);

	useEffect(() => {
		// запись в состояние компонента списка хештегов
		setVideoHashtagsState(videoHashtags);
	}, [videoHashtags]);

	useEffect(() => {
		// запись в состояние компонента списка жанров
		setVideoGenresState(videoGenresList);
	}, [videoGenresList]);

	const dragStartHandler = (e, key) => {
		e.preventDefault();
		setPrewDragState({ [key]: true });
	};

	const dragLeaveHandler = (e, key) => {
		e.preventDefault();
		setPrewDragState({ [key]: false });
	};

	const onDropHandler = (e, key) => {
		e.preventDefault();
		let files = [...e.dataTransfer.files];
		let reader = new FileReader();
		let file = files[0];
		const validFormats = ["image/png", "image/jpeg"];
		const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

		if (!validFormats.includes(file.type)) {
			setImageErrorState({ ...imageErrorState, [key]: "Недопустимый формат изображения" });
			setMainVideoState({
				...mainVideoState,
				[key]: undefined,
			});
			return;
		}

		if (file.size > maxSize) {
			setImageErrorState({ ...imageErrorState, [key]: "Размер файла слишком большой" });
			setMainVideoState({
				...mainVideoState,
				[key]: undefined,
			});
			return;
		}
		setImageErrorState({ ...imageErrorState, [key]: null });
		reader.onloadend = () => {
			var bodyFormData = new FormData();
			bodyFormData.append("file", file);
			axios
				.post("https://atips.io/uploads/upload.php", bodyFormData, {
					params: {
						...prewPhotoUploadParams[key],
					},
				})
				.then((res) => setMainVideoState({ ...mainVideoState, [key]: res?.data?.filename }));
			setUploadFlagState(true);
		};
		reader.readAsDataURL(files[0]);
		setPrewDragState({ [key]: false });
	};

	const onPrewPhotoChange = (event, key) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

			if (!validFormats.includes(file.type)) {
				setImageErrorState({ ...imageErrorState, [key]: "Недопустимый формат изображения" });
				setMainVideoState({
					...mainVideoState,
					[key]: undefined,
				});
				return;
			}

			if (file.size > maxSize) {
				setImageErrorState({ ...imageErrorState, [key]: "Размер файла слишком большой" });
				setMainVideoState({
					...mainVideoState,
					[key]: undefined,
				});
				return;
			}
			setImageErrorState({ ...imageErrorState, [key]: null });
			reader.onloadend = () => {
				var bodyFormData = new FormData();
				bodyFormData.append("file", file);
				axios
					.post("https://atips.io/uploads/upload.php", bodyFormData, {
						params: {
							...prewPhotoUploadParams[key],
						},
					})
					.then((res) => setMainVideoState({ ...mainVideoState, [key]: res?.data?.filename }));
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};
	const onGalleryPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
			if (!validFormats.includes(file.type)) {
				setImageErrorState({ ...imageErrorState, gallery: "Недопустимый формат изображения" });

				return;
			}

			if (file.size > maxSize) {
				setImageErrorState({ ...imageErrorState, gallery: "Размер файла слишком большой" });
				return;
			}
			setImageErrorState({ ...imageErrorState, gallery: null });
			reader.onloadend = () => {
				postUploadImages(file, "gallery");
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};

	useEffect(() => {
		if (
			(!!mainVideoState?.photos || !!mainVideoState?.photos === false) &&
			uploadGalleryImage.filename?.length > 0 &&
			uploadFlagState
		) {
			if (mainVideoState?.photos?.length === 0) {
				let arr = [];
				arr[0] = uploadGalleryImage.filename;
				setMainVideoState({ ...mainVideoState, photos: arr });
				setUploadFlagState(false);
				return;
			}
			const arr = mainVideoState.photos || [];
			arr.push(uploadGalleryImage.filename);
			setMainVideoState({ ...mainVideoState, photos: arr });
			setUploadFlagState(false);
		}
	}, [uploadGalleryImage]);

	const handleRemoveGalleryPhoto = (id) => {
		const index = mainVideoState.photos.findIndex((item) => item === id);
		let arr = mainVideoState.photos;
		arr.splice(index, 1);
		setMainVideoState({ ...mainVideoState, photos: arr });
	};

	useEffect(() => {
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;");
			tx[i].addEventListener("keydown", OnInput, false);
		}

		function OnInput() {
			this.style.height = "auto";
			this.style.height = this.scrollHeight + "px";
		}
		return () => {
			for (let i = 0; i < tx.length; i++) {
				tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;");
				tx[i].removeEventListener("", OnInput, false);
			}
		};
	}, [mainVideoState]);

	const handleCharacterOrder = (key1, key2, result) => {
		const charactersArray = [...mainVideoState.characters];
		charactersArray[key1][key2] = result;
		setMainVideoState({ ...mainVideoState, characters: charactersArray });
	};
	return (
		<div className="form-container first-col">
			<div className="form-container">
				<div className="form-container-block">
					<div className="form-container-block-title" style={{ marginBottom: "0" }}>
						<b>Формат</b>
						{mainVideoState.movie_format.id === 1
							? "Фильм"
							: mainVideoState.movie_format.id === 2
							? "Сериал"
							: mainVideoState.movie_format.id === 3
							? "Видео блогера"
							: mainVideoState.movie_format.id === 4
							? "Shorts"
							: mainVideoState.movie_format.id === 5
							? "Live"
							: mainVideoState.movie_format.id === 6
							? "Фильм правообладателя"
							: "Сериал правообладателя"}
					</div>
					{mainVideoState.duration !== null ? (
						<>
							<div
								className="form-container-block-subtitle time-duration"
								style={{ marginTop: "32px" }}>
								Длительность
							</div>
							<div className="flex-block flex-start">
								<div className="form-field" style={{ width: "147px" }}>
									<span className="time-duration">
										{mainVideoState.duration === null
											? ""
											: `${Math.floor(mainVideoState.duration / 3600)} ч ${Math.floor(
													(mainVideoState.duration % 3600) / 60
											  )} мин`}
									</span>
								</div>
							</div>
						</>
					) : null}
				</div>
				{mainVideoState.movie_format.id === 1 || mainVideoState.movie_format.id === 6 ? (
					<div className="form-container-block">
						<div className="form-container-block-title">
							<b>Ссылки на видеопоток</b>
						</div>
						<div className="flex-block flex-start">
							<div className="form-field">
								<div className="form-field-label">SD</div>
								<div className="input-field">
									<input
										disabled={!checkUserWritePerm(userAccessState, "videos")}
										value={
											additionalVideoState?.URL_SD_SOURCE === null
												? ""
												: additionalVideoState?.URL_SD_SOURCE
										}
										onChange={(e) =>
											onInputTextAreaChange(
												e,
												"URL_SD_SOURCE",
												additionalVideoState,
												setAdditionalVideoState
											)
										}
									/>
								</div>
							</div>
						</div>
						<div className="flex-block flex-start">
							<div className="form-field">
								<div className="form-field-label">HD</div>
								<div className="input-field">
									<input
										disabled={!checkUserWritePerm(userAccessState, "videos")}
										value={
											additionalVideoState?.URL_HD_SOURCE === null
												? ""
												: additionalVideoState?.URL_HD_SOURCE
										}
										onChange={(e) =>
											onInputTextAreaChange(
												e,
												"URL_HD_SOURCE",
												additionalVideoState,
												setAdditionalVideoState
											)
										}
									/>
								</div>
							</div>
						</div>
						<div className="flex-block flex-start">
							<div className="form-field">
								<div className="form-field-label">Full HD</div>
								<div className="input-field">
									<input
										disabled={!checkUserWritePerm(userAccessState, "videos")}
										value={
											additionalVideoState?.URL_FULL_HD_SOURCE === null
												? ""
												: additionalVideoState?.URL_FULL_HD_SOURCE
										}
										onChange={(e) =>
											onInputTextAreaChange(
												e,
												"URL_FULL_HD_SOURCE",
												additionalVideoState,
												setAdditionalVideoState
											)
										}
									/>
								</div>
							</div>
						</div>
					</div>
				) : (mainVideoState.movie_format.id === 2 || mainVideoState.movie_format.id === 7) &&
				  mainVideoState.hasOwnProperty("id") &&
				  additionalVideoState?.seasons ? (
					<CreateSeasons filmState={additionalVideoState} setFilmState={setAdditionalVideoState} />
				) : null}
				{/* <VideoCinemaCategory /> */}

				<div className="form-container-block">
					<div className="form-container-block-title">
						<b>Описание</b>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Название</div>
							<div className="input-field">
								<input
									disabled={!checkUserWritePerm(userAccessState, "videos")}
									value={mainVideoState.title === null ? "" : mainVideoState.title}
									className={errorState && mainVideoState.title.length === 0 ? "error" : ""}
									onChange={(e) =>
										onInputTextAreaChange(e, "title", mainVideoState, setMainVideoState)
									}
								/>
							</div>
						</div>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Ссылка</div>
							<div className="input-field">
								<input
									disabled={!checkUserWritePerm(userAccessState, "videos")}
									value={mainVideoState.slug === null ? "" : mainVideoState.slug}
									className={errorState && mainVideoState.slug.length === 0 ? "error" : ""}
									onChange={(e) =>
										onInputTextAreaChange(e, "slug", mainVideoState, setMainVideoState)
									}
								/>
							</div>
						</div>
					</div>
					{mainVideoState.movie_format.id === 1 ||
					mainVideoState.movie_format.id === 2 ||
					mainVideoState.movie_format.id === 6 ||
					mainVideoState.movie_format.id === 7 ? (
						<>
							<div className="flex-block flex-start" style={{ zIndex: 10 }}>
								<div className="form-field">
									<div className="form-field-label">Год производства</div>
									<div className="input-field">
										<input
											disabled={!checkUserWritePerm(userAccessState, "videos")}
											value={mainVideoState.year === null ? "" : mainVideoState.year}
											type="number"
											onChange={(e) =>
												onInputTextAreaChange(e, "year", mainVideoState, setMainVideoState)
											}
										/>
									</div>
								</div>
								<div className="form-field">
									<div className="form-field-label">Возрастной рейтинг</div>
									<div className="input-field">
										<Multiselect
											disable={!checkUserWritePerm(userAccessState, "videos")}
											className={
												errorState && mainVideoState.title.length === 0 ? "error single" : "single"
											}
											options={videoAgesState} // Options to display in the dropdown
											selectedValues={
												!!mainVideoState?.movie_rating?.id
													? videoAgesState.filter(
															(elem) => elem.id === mainVideoState.movie_rating.id
													  )
													: ""
											}
											// Preselected value to persist in dropdown
											onSelect={(selectedList, selectedItem) =>
												onSelectItem(
													selectedList,
													selectedItem,
													mainVideoState,
													setMainVideoState,
													"rating"
												)
											} // Function will trigger on select event
											displayValue="title" // Property name to display in the dropdown options
											hidePlaceholder={true}
											avoidHighlightFirstOption={true}
											placeholder="Выберите"
											emptyRecordMsg="Нет доступных"
											singleSelect
											loading={videoAgesState.length > 0 ? false : true}
										/>
									</div>
								</div>
							</div>
							<div className="flex-block flex-start" style={{ zIndex: 9 }}>
								<div className="form-field">
									<div className="form-field-label">Базовая оценка</div>
									<div className="input-field">
										<input
											disabled={!checkUserWritePerm(userAccessState, "videos")}
											value={mainVideoState.rate === null ? "" : mainVideoState.rate}
											type="number"
											onChange={(e) =>
												onInputTextAreaChange(e, "rate", mainVideoState, setMainVideoState)
											}
										/>
									</div>
								</div>
								<div className="form-field">
									<div className="form-field-label">Правообладатель</div>
									<div className="input-field">
										<Multiselect
											disable={!checkUserWritePerm(userAccessState, "videos")}
											className={
												errorState && mainVideoState.title.length === 0 ? "error single" : "single"
											}
											options={ownersState} // Options to display in the dropdown
											selectedValues={
												!!mainVideoState?.owner_id
													? ownersState.filter((elem) => elem.id === mainVideoState.owner_id)
													: ""
											}
											// Preselected value to persist in dropdown
											onSelect={(selectedList, selectedItem) =>
												onSelectItem(
													selectedList,
													selectedItem.id,
													mainVideoState,
													setMainVideoState,
													"owner_id"
												)
											} // Function will trigger on select event
											displayValue="name" // Property name to display in the dropdown options
											hidePlaceholder={true}
											avoidHighlightFirstOption={true}
											placeholder="Выберите"
											emptyRecordMsg="Нет доступных"
											singleSelect
											loading={ownersState.length > 0 ? false : true}
										/>
									</div>
								</div>
							</div>
						</>
					) : null}

					<div className="flex-block flex-start" style={{ zIndex: 8 }}>
						<div className="form-field">
							<div className="form-field-label">Жанр</div>
							<div className="input-field">
								{checkUserWritePerm(userAccessState, "videos") &&
								mainVideoState.genres?.length >= 1 &&
								mainVideoState?.genres[0] !== null ? (
									<button
										className="button_clear-select"
										onClick={() =>
											handleClearSelectedClick(
												mainVideoState,
												setMainVideoState,
												"genres",
												multiselectRef
											)
										}>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
												fill="#212121"></path>
										</svg>
									</button>
								) : null}
								<Multiselect
									disable={!checkUserWritePerm(userAccessState, "videos")}
									className="multiCustom"
									ref={multiselectRef}
									options={videoGenresState} // Options to display in the dropdown
									selectedValues={
										mainVideoState.genres?.length > 0 && mainVideoState?.genres[0] !== null
											? mainVideoState.genres
											: ""
									} // Preselected value to persist in dropdown
									onSelect={(selectedList, selectedItem) =>
										onSelectItem(
											selectedList,
											selectedItem,
											mainVideoState,
											setMainVideoState,
											"genres"
										)
									} // Function will trigger on select event
									onRemove={(selectedList, removeItem) =>
										onRemoveItem(
											selectedList,
											removeItem,
											mainVideoState,
											setMainVideoState,
											"genres"
										)
									} // Function will trigger on remove event
									displayValue="title" // Property name to display in the dropdown options
									hidePlaceholder={true}
									avoidHighlightFirstOption={true}
									emptyRecordMsg="Нет доступных"
									customCloseIcon={
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
												fill="#212121"
											/>
										</svg>
									}
									placeholder="Выберите один или несколько жанров"
								/>
							</div>
						</div>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Страна</div>
							<div className="input-field">
								{checkUserWritePerm(userAccessState, "videos") &&
								mainVideoState.countries?.length >= 1 &&
								mainVideoState?.countries[0] !== null ? (
									<button
										className="button_clear-select"
										onClick={() =>
											handleClearSelectedClick(
												mainVideoState,
												setMainVideoState,
												"countries",
												multiselectRef
											)
										}>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
												fill="#212121"></path>
										</svg>
									</button>
								) : null}
								<Multiselect
									disable={!checkUserWritePerm(userAccessState, "videos")}
									className="multiCustom"
									ref={multiselectRef}
									options={countriesState} // Options to display in the dropdown
									selectedValues={
										mainVideoState.countries?.length > 0 && mainVideoState?.countries[0] !== null
											? mainVideoState.countries
											: ""
									} // Preselected value to persist in dropdown
									onSelect={(selectedList, selectedItem) =>
										onSelectItem(
											selectedList,
											selectedItem,
											mainVideoState,
											setMainVideoState,
											"countries"
										)
									} // Function will trigger on select event
									onRemove={(selectedList, removeItem) =>
										onRemoveItem(
											selectedList,
											removeItem,
											mainVideoState,
											setMainVideoState,
											"countries"
										)
									} // Function will trigger on remove event
									displayValue="title" // Property name to display in the dropdown options
									hidePlaceholder={true}
									avoidHighlightFirstOption={true}
									emptyRecordMsg="Нет доступных"
									customCloseIcon={
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
												fill="#212121"
											/>
										</svg>
									}
									placeholder="Выберите одну или несколько стран"
								/>
							</div>
						</div>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Краткое описание</div>
							<div className="input-field">
								<textarea
									disabled={!checkUserWritePerm(userAccessState, "videos")}
									name=""
									value={
										mainVideoState.short_description === null
											? ""
											: mainVideoState.short_description
									}
									onChange={(e) =>
										onInputTextAreaChange(e, "short_description", mainVideoState, setMainVideoState)
									}></textarea>
							</div>
						</div>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Подробное описание</div>
							<div className="input-field">
								<textarea
									disabled={!checkUserWritePerm(userAccessState, "videos")}
									name=""
									value={
										mainVideoState.detailed_description === null
											? ""
											: mainVideoState.detailed_description
									}
									onChange={(e) =>
										onInputTextAreaChange(
											e,
											"detailed_description",
											mainVideoState,
											setMainVideoState
										)
									}></textarea>
							</div>
						</div>
					</div>
					<div className="form-container-block-subtitle">Горизонтальное превью</div>
					<div
						className="flex-block form-container-block-preview"
						onDragStart={(e) =>
							checkUserWritePerm(userAccessState, "videos") &&
							dragStartHandler(e, "URL_SD_HORIZONTAL_PREVIEW")
						}
						onDragLeave={(e) =>
							checkUserWritePerm(userAccessState, "videos") &&
							dragLeaveHandler(e, "URL_SD_HORIZONTAL_PREVIEW")
						}
						onDragOver={(e) =>
							checkUserWritePerm(userAccessState, "videos") &&
							dragStartHandler(e, "URL_SD_HORIZONTAL_PREVIEW")
						}
						onDrop={(e) =>
							checkUserWritePerm(userAccessState, "videos") &&
							onDropHandler(e, "URL_SD_HORIZONTAL_PREVIEW")
						}>
						<div className="form-container-block-preview-data">
							<img src="images/icons/uploadimage.svg" alt="upload" />
							{!prewDragState.URL_SD_HORIZONTAL_PREVIEW ||
							imageErrorState?.URL_SD_HORIZONTAL_PREVIEW ? (
								<>
									<span>
										Перетащите изображение в это окно
										<br />
										или нажмите, чтобы выбрать
									</span>{" "}
									Рекомендуемый размер изображений
									<br />
									не менее 1920×1080 px
									<br />
									<br />
									Формат изображений: jpg, webp
									<br />
									Размер файла не должен превышать 5 мб
									{imageErrorState?.URL_SD_HORIZONTAL_PREVIEW && (
										<span style={{ marginTop: "10px", color: "#ED0A34", marginBottom: "-10px" }}>
											{imageErrorState?.URL_SD_HORIZONTAL_PREVIEW}
										</span>
									)}
								</>
							) : (
								<span>Отпустите изображение</span>
							)}
						</div>
						<div
							className="form-container-block-preview-image"
							style={{
								backgroundImage: `url('https://atips.io/${mainVideoState.URL_SD_HORIZONTAL_PREVIEW}')`,
								backgroundSize: "auto 90%",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "50% 50%",
							}}></div>
						<input
							disabled={!checkUserWritePerm(userAccessState, "videos")}
							type="file"
							name="URL_SD_HORIZONTAL_PREVIEW
"
							accept="image/jpg, image/jpeg, image/webp, image/png"
							onChange={(e) =>
								checkUserWritePerm(userAccessState, "videos") &&
								onPrewPhotoChange(e, "URL_SD_HORIZONTAL_PREVIEW")
							}
						/>
					</div>
					<div className="form-container-block-subtitle">Вертикальное превью</div>
					<div className="vertical-preview" style={{ display: "flex", justifyContent: "center" }}>
						<div
							className="flex-block form-container-block-preview"
							style={{ height: "720px", width: "540px" }}
							onDragStart={(e) =>
								checkUserWritePerm(userAccessState, "videos") &&
								dragStartHandler(e, "URL_SD_VERTICAL_PREVIEW")
							}
							onDragLeave={(e) =>
								checkUserWritePerm(userAccessState, "videos") &&
								dragLeaveHandler(e, "URL_SD_VERTICAL_PREVIEW")
							}
							onDragOver={(e) =>
								checkUserWritePerm(userAccessState, "videos") &&
								dragStartHandler(e, "URL_SD_VERTICAL_PREVIEW")
							}
							onDrop={(e) =>
								checkUserWritePerm(userAccessState, "videos") &&
								onDropHandler(e, "URL_SD_VERTICAL_PREVIEW")
							}>
							<div className="form-container-block-preview-data">
								<img src="images/icons/uploadimage.svg" alt="upload" />
								{!prewDragState.URL_SD_VERTICAL_PREVIEW ||
								imageErrorState?.URL_SD_VERTICAL_PREVIEW ? (
									<>
										<span>
											Перетащите изображение в это окно
											<br />
											или нажмите, чтобы выбрать
										</span>{" "}
										Рекомендуемый размер изображений
										<br />
										не менее 1080×1920 px
										<br />
										<br />
										Формат изображений: jpg, webp
										<br />
										Размер файла не должен превышать 5 мб
										{imageErrorState?.URL_SD_VERTICAL_PREVIEW && (
											<span style={{ marginTop: "10px", color: "#ED0A34" }}>
												{imageErrorState?.URL_SD_VERTICAL_PREVIEW}
											</span>
										)}
									</>
								) : (
									<span>Отпустите изображение</span>
								)}
							</div>
							<div
								className="form-container-block-preview-image"
								style={{
									backgroundImage: `url('https://atips.io/${mainVideoState.URL_SD_VERTICAL_PREVIEW}')`,
									backgroundSize: "auto 90%",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "50% 50%",
								}}></div>
							<input
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								type="file"
								name="URL_SD_VERTICAL_PREVIEW"
								accept="image/jpg, image/jpeg, image/webp, image/png"
								onChange={(e) =>
									checkUserWritePerm(userAccessState, "videos") &&
									onPrewPhotoChange(e, "URL_SD_VERTICAL_PREVIEW")
								}
							/>
						</div>
					</div>
					{mainVideoState.movie_format.id === 1 ||
					mainVideoState.movie_format.id === 2 ||
					mainVideoState.movie_format.id === 6 ||
					mainVideoState.movie_format.id === 7 ? (
						<>
							<div className="form-container-block-subtitle">Галерея</div>
							<div className="flex-block form-container-photo-gallery">
								<span>
									Рекомендуемый размер изображений не менее 1280×800 px. Формат изображений: jpg,
									png.
								</span>
								{imageErrorState?.gallery && (
									<span style={{ marginTop: "10px", color: "#ED0A34" }}>
										{imageErrorState?.gallery}
									</span>
								)}
								<div className="form-container-photo-gallery-items">
									{mainVideoState.photos?.length > 0 && mainVideoState.photos[0] !== null
										? mainVideoState.photos.map((elem, index) => (
												<div
													key={index}
													className="form-container-photo-gallery-item"
													style={{
														backgroundImage: `url('https://atips.io${elem}')`,
														backgroundSize: "cover",
														backgroundRepeat: "no-repeat",
														backgroundPosition: "50% 50%",
													}}>
													{checkUserWritePerm(userAccessState, "videos") && (
														<div
															className="remove-preview-btn"
															onClick={() => handleRemoveGalleryPhoto(elem)}>
															<svg
																width="12"
																height="12"
																viewBox="0 0 12 12"
																fill="none"
																xmlns="http://www.w3.org/2000/svg">
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M2.70711 1.29289L2 0.585785L0.585785 2L1.29289 2.70711L4.58579 6L1.29289 9.29289L0.585785 10L2 11.4142L2.70711 10.7071L6 7.41421L9.29289 10.7071L10 11.4142L11.4142 10L10.7071 9.29289L7.41421 6L10.7071 2.70711L11.4142 2L10 0.585785L9.29289 1.29289L6 4.58579L2.70711 1.29289Z"
																	fill="#212121"
																/>
															</svg>
														</div>
													)}
												</div>
										  ))
										: null}

									{checkUserWritePerm(userAccessState, "videos") && (
										<div
											className={`form-container-photo-gallery-item empty ${
												loadingStatus.loading === 1 ? "loading" : ""
											}`}>
											<input
												disabled={!checkUserWritePerm(userAccessState, "videos")}
												type="file"
												name="galler"
												accept="image/jpg, image/jpeg, image/png, image/webp"
												onChange={onGalleryPhotoChange}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="form-container-block-subtitle">Ссылки на трейлер</div>
							<div className="flex-block flex-start">
								<div className="form-field">
									<div className="form-field-label">SD</div>
									<div className="input-field">
										<input
											disabled={!checkUserWritePerm(userAccessState, "videos")}
											value={
												mainVideoState.URL_SD_trailer === null ? "" : mainVideoState.URL_SD_trailer
											}
											onChange={(e) =>
												onInputTextAreaChange(
													e,
													"URL_SD_trailer",
													mainVideoState,
													setMainVideoState
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="flex-block flex-start">
								<div className="form-field">
									<div className="form-field-label">HD</div>
									<div className="input-field">
										<input
											disabled={!checkUserWritePerm(userAccessState, "videos")}
											value={
												mainVideoState.URL_HD_trailer === null ? "" : mainVideoState.URL_HD_trailer
											}
											onChange={(e) =>
												onInputTextAreaChange(
													e,
													"URL_HD_trailer",
													mainVideoState,
													setMainVideoState
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="flex-block flex-start">
								<div className="form-field">
									<div className="form-field-label">Full HD</div>
									<div className="input-field">
										<input
											disabled={!checkUserWritePerm(userAccessState, "videos")}
											value={
												mainVideoState.URL_FULL_HD_trailer === null
													? ""
													: mainVideoState.URL_FULL_HD_trailer
											}
											onChange={(e) =>
												onInputTextAreaChange(
													e,
													"URL_FULL_HD_trailer",
													mainVideoState,
													setMainVideoState
												)
											}
										/>
									</div>
								</div>
							</div>
						</>
					) : null}
				</div>
				{/* <VideoEmbing /> */}
				{mainVideoState.movie_format.id === 1 ||
				mainVideoState.movie_format.id === 2 ||
				mainVideoState.movie_format.id === 6 ||
				mainVideoState.movie_format.id === 7 ? (
					<>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>В ролях</b>
							</div>
							<div className="form-container-block-peoples">
								{mainVideoState.characters[0][1]?.length > 0 ? (
									<DraggableArea
										tags={mainVideoState.characters[0][1]}
										render={({ tag: elem, index }) => (
											<div className="form-container-block-people" key={index}>
												<span
													className="action-button"
													style={{ color: "#212121" }}
													onClick={() =>
														handleModal(
															<CreateFilmCrew
																state={elem}
																userAccessState={userAccessState}
																mainVideoState={mainVideoState}
																setMainVideoState={setMainVideoState}
															/>
														)
													}>
													{`${elem.first_name} ${elem.second_name}`}
												</span>
												{checkUserWritePerm(userAccessState, "videos") ? (
													<div
														className="remove-people"
														onClick={() =>
															handleRemovePeopleClick(
																elem.id,
																0,
																1,
																mainVideoState,
																setMainVideoState
															)
														}>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.19701 6.13635L6.66668 5.60602L5.60602 6.66668L6.13635 7.19701L8.93935 10L6.13635 12.803L5.60602 13.3333L6.66668 14.394L7.19701 13.8637L10 11.0607L12.803 13.8637L13.3333 14.394L14.394 13.3333L13.8637 12.803L11.0607 10L13.8637 7.19701L14.394 6.66668L13.3333 5.60602L12.803 6.13635L10 8.93935L7.19701 6.13635Z"
																fill="#212121"
															/>
														</svg>
													</div>
												) : null}
											</div>
										)}
										onChange={(tags) => handleCharacterOrder(0, 1, tags)}
									/>
								) : null}
							</div>
							{checkUserWritePerm(userAccessState, "videos") ? (
								<div
									className="button add-people action-button border-button small-button"
									onClick={() =>
										handleModal(
											<SelectFilmCrew
												type={1}
												userAccessState={userAccessState}
												filmState={mainVideoState}
												setFilmState={setMainVideoState}
											/>
										)
									}>
									<span>Добавить</span>
								</div>
							) : null}
						</div>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>Режиссеры</b>
							</div>
							<div className="form-container-block-peoples">
								{mainVideoState.characters[1][2]?.length > 0 ? (
									<DraggableArea
										tags={mainVideoState.characters[1][2]}
										render={({ tag: elem, index }) => (
											<div className="form-container-block-people" key={index}>
												<span
													className="action-button"
													style={{ color: "#212121" }}
													onClick={() =>
														handleModal(
															<CreateFilmCrew
																state={elem}
																userAccessState={userAccessState}
																mainVideoState={mainVideoState}
																setMainVideoState={setMainVideoState}
															/>
														)
													}>
													{`${elem.first_name} ${elem.second_name}`}
												</span>
												{checkUserWritePerm(userAccessState, "videos") ? (
													<div
														className="remove-people"
														onClick={() =>
															handleRemovePeopleClick(
																elem.id,
																1,
																2,
																mainVideoState,
																setMainVideoState
															)
														}>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.19701 6.13635L6.66668 5.60602L5.60602 6.66668L6.13635 7.19701L8.93935 10L6.13635 12.803L5.60602 13.3333L6.66668 14.394L7.19701 13.8637L10 11.0607L12.803 13.8637L13.3333 14.394L14.394 13.3333L13.8637 12.803L11.0607 10L13.8637 7.19701L14.394 6.66668L13.3333 5.60602L12.803 6.13635L10 8.93935L7.19701 6.13635Z"
																fill="#212121"
															/>
														</svg>
													</div>
												) : null}
											</div>
										)}
										onChange={(tags) => handleCharacterOrder(1, 2, tags)}
									/>
								) : null}
							</div>
							{checkUserWritePerm(userAccessState, "videos") ? (
								<div
									className="button add-people action-button border-button small-button"
									onClick={() =>
										handleModal(
											<SelectFilmCrew
												type={2}
												userAccessState={userAccessState}
												filmState={mainVideoState}
												setFilmState={setMainVideoState}
											/>
										)
									}>
									<span>Добавить</span>
								</div>
							) : null}
						</div>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>Сценаристы</b>
							</div>
							<div className="form-container-block-peoples">
								{mainVideoState.characters[2][3]?.length > 0 ? (
									<DraggableArea
										tags={mainVideoState.characters[2][3]}
										render={({ tag: elem, index }) => (
											<div className="form-container-block-people" key={index}>
												<span
													className="action-button"
													style={{ color: "#212121" }}
													onClick={() =>
														handleModal(
															<CreateFilmCrew
																state={elem}
																userAccessState={userAccessState}
																mainVideoState={mainVideoState}
																setMainVideoState={setMainVideoState}
															/>
														)
													}>
													{`${elem.first_name} ${elem.second_name}`}
												</span>
												{checkUserWritePerm(userAccessState, "videos") ? (
													<div
														className="remove-people"
														onClick={() =>
															handleRemovePeopleClick(
																elem.id,
																2,
																3,
																mainVideoState,
																setMainVideoState
															)
														}>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.19701 6.13635L6.66668 5.60602L5.60602 6.66668L6.13635 7.19701L8.93935 10L6.13635 12.803L5.60602 13.3333L6.66668 14.394L7.19701 13.8637L10 11.0607L12.803 13.8637L13.3333 14.394L14.394 13.3333L13.8637 12.803L11.0607 10L13.8637 7.19701L14.394 6.66668L13.3333 5.60602L12.803 6.13635L10 8.93935L7.19701 6.13635Z"
																fill="#212121"
															/>
														</svg>
													</div>
												) : null}
											</div>
										)}
										onChange={(tags) => handleCharacterOrder(2, 3, tags)}
									/>
								) : null}
							</div>
							{checkUserWritePerm(userAccessState, "videos") ? (
								<div
									className="button add-people action-button border-button small-button"
									onClick={() =>
										handleModal(
											<SelectFilmCrew
												type={3}
												userAccessState={userAccessState}
												filmState={mainVideoState}
												setFilmState={setMainVideoState}
											/>
										)
									}>
									<span>Добавить</span>
								</div>
							) : null}
						</div>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>Операторы</b>
							</div>
							<div className="form-container-block-peoples">
								{mainVideoState.characters[3][4]?.length > 0 ? (
									<DraggableArea
										tags={mainVideoState.characters[3][4]}
										render={({ tag: elem, index }) => (
											<div className="form-container-block-people" key={index}>
												<span
													className="action-button"
													style={{ color: "#212121" }}
													onClick={() =>
														handleModal(
															<CreateFilmCrew
																state={elem}
																userAccessState={userAccessState}
																mainVideoState={mainVideoState}
																setMainVideoState={setMainVideoState}
															/>
														)
													}>
													{`${elem.first_name} ${elem.second_name}`}
												</span>
												{checkUserWritePerm(userAccessState, "videos") ? (
													<div
														className="remove-people"
														onClick={() =>
															handleRemovePeopleClick(
																elem.id,
																3,
																4,
																mainVideoState,
																setMainVideoState
															)
														}>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.19701 6.13635L6.66668 5.60602L5.60602 6.66668L6.13635 7.19701L8.93935 10L6.13635 12.803L5.60602 13.3333L6.66668 14.394L7.19701 13.8637L10 11.0607L12.803 13.8637L13.3333 14.394L14.394 13.3333L13.8637 12.803L11.0607 10L13.8637 7.19701L14.394 6.66668L13.3333 5.60602L12.803 6.13635L10 8.93935L7.19701 6.13635Z"
																fill="#212121"
															/>
														</svg>
													</div>
												) : null}
											</div>
										)}
										onChange={(tags) => handleCharacterOrder(3, 4, tags)}
									/>
								) : null}
							</div>
							{checkUserWritePerm(userAccessState, "videos") ? (
								<div
									className="button add-people action-button border-button small-button"
									onClick={() =>
										handleModal(
											<SelectFilmCrew
												type={4}
												userAccessState={userAccessState}
												filmState={mainVideoState}
												setFilmState={setMainVideoState}
											/>
										)
									}>
									<span>Добавить</span>
								</div>
							) : null}
						</div>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>Продюсеры</b>
							</div>
							<div className="form-container-block-peoples">
								{mainVideoState.characters[4][5]?.length > 0 ? (
									<DraggableArea
										tags={mainVideoState.characters[4][5]}
										render={({ tag: elem, index }) => (
											<div className="form-container-block-people" key={index}>
												<span
													className="action-button"
													style={{ color: "#212121" }}
													onClick={() =>
														handleModal(
															<CreateFilmCrew
																state={elem}
																userAccessState={userAccessState}
																mainVideoState={mainVideoState}
																setMainVideoState={setMainVideoState}
															/>
														)
													}>
													{`${elem.first_name} ${elem.second_name}`}
												</span>
												{checkUserWritePerm(userAccessState, "videos") ? (
													<div
														className="remove-people"
														onClick={() =>
															handleRemovePeopleClick(
																elem.id,
																4,
																5,
																mainVideoState,
																setMainVideoState
															)
														}>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M7.19701 6.13635L6.66668 5.60602L5.60602 6.66668L6.13635 7.19701L8.93935 10L6.13635 12.803L5.60602 13.3333L6.66668 14.394L7.19701 13.8637L10 11.0607L12.803 13.8637L13.3333 14.394L14.394 13.3333L13.8637 12.803L11.0607 10L13.8637 7.19701L14.394 6.66668L13.3333 5.60602L12.803 6.13635L10 8.93935L7.19701 6.13635Z"
																fill="#212121"
															/>
														</svg>
													</div>
												) : null}
											</div>
										)}
										onChange={(tags) => handleCharacterOrder(4, 5, tags)}
									/>
								) : null}
							</div>
							{checkUserWritePerm(userAccessState, "videos") ? (
								<div
									className="button add-people action-button border-button small-button"
									onClick={() =>
										handleModal(
											<SelectFilmCrew
												type={5}
												userAccessState={userAccessState}
												filmState={mainVideoState}
												setFilmState={setMainVideoState}
											/>
										)
									}>
									<span>Добавить</span>
								</div>
							) : null}
						</div>
						<div className="form-container-block">
							<div className="form-container-block-title">
								<b>Хештеги</b>
							</div>
							<div className="flex-block">
								<div className="form-field">
									<div className="input-field">
										{mainVideoState.hashtags?.length > 0 && mainVideoState?.hashtags[0] !== null ? (
											<button
												className="button_clear-select"
												onClick={() =>
													handleClearSelectedClick(
														mainVideoState,
														setMainVideoState,
														"hashtags",
														multiselectHashRef
													)
												}>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
														fill="#212121"></path>
												</svg>
											</button>
										) : null}
										<Multiselect
											disable={!checkUserWritePerm(userAccessState, "videos")}
											ref={multiselectHashRef}
											className="multiCustom"
											options={videoHashtagsState} // Options to display in the dropdown
											selectedValues={
												mainVideoState.hashtags?.length > 0 && mainVideoState?.hashtags[0] !== null
													? mainVideoState.hashtags
													: ""
											} // Preselected value to persist in dropdown
											onSelect={(selectedList, selectedItem) =>
												onSelectItem(
													selectedList,
													selectedItem,
													mainVideoState,
													setMainVideoState,
													"hashtags"
												)
											} // Function will trigger on select event
											onRemove={(selectedList, removeItem) =>
												onRemoveItem(
													selectedList,
													removeItem,
													mainVideoState,
													setMainVideoState,
													"hashtags"
												)
											} // Function will trigger on remove event
											displayValue="title" // Property name to display in the dropdown options
											hidePlaceholder={true}
											avoidHighlightFirstOption={true}
											emptyRecordMsg="Нет доступных"
											customCloseIcon={
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
														fill="#212121"
													/>
												</svg>
											}
											placeholder="Выберите один или несколько хештегов"
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videoAgesList: state.videoAgesList,
		videoGenresList: state.videoGenresList,
		uploadImage: state.uploadImage,
		uploadGalleryImage: state.uploadGalleryImage,
		loadingStatus: state.loadingStatus,
		videoHashtags: state.videoHashtags,
		countriesList: state.countriesList,
		ownersList: state.ownersList.active,
	};
};

const mapDispatchToProps = {
	postUploadImages,
	getCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVideoContent);
