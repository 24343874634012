import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const SelectCountries = ({ politicsLocation, selectedVideoLocation, setSelectedVideoLocation }) => {
	const [locationState, setLocationState] = useState([]);
	useEffect(() => {
		setLocationState(politicsLocation.attributes);
	}, [politicsLocation]);

	return (
		<div className="form-field">
			<div className="form-field-label">Страны</div>
			<div className="input-field">
				<Multiselect
					className="multiCustom side-filters_city"
					selectedValues={
						selectedVideoLocation?.length > 0 && selectedVideoLocation[0] !== null ? selectedVideoLocation : ""
					} // Preselected value to persist in dropdown
					onSelect={(selectedList, selectedItem) => setSelectedVideoLocation(selectedList)} // Function will trigger on select event
					onRemove={(selectedList, removeItem) => setSelectedVideoLocation(selectedList)} // Function will trigger on remove event
					options={locationState} // Options to display in the dropdown
					displayValue="title" // Property name to display in the dropdown options
					hidePlaceholder={true}
					avoidHighlightFirstOption={true}
					emptyRecordMsg="Нет доступных"
					customCloseIcon={
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
								fill="#212121"
							/>
						</svg>
					}
					placeholder="Выберите"
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		politicsLocation: state.politicsLocation,
	};
};

export default connect(mapStateToProps, null)(SelectCountries);
