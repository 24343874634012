import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getSellerBalance, getSellerDocuments } from "../../../../../Actions/SellersActions.js";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import PaginationBlock from "../../../../universal/PaginationBlock/PaginationBlock.jsx";
import { SubmitButton } from "../../pages/Buttons.jsx";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import UploadDocumentModal from "./UploadDocumentModal.jsx";
import { Link } from "react-router-dom";

const TableRowHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	position: relative;
	height: 24px;
	padding: 0 10px;
`;

const HeaderElement = styled.div`
	background: transparent;
	border: none;
	outline: none;
	box-sizing: border-box;
	color: #94a0b1;
	cursor: pointer;
	font-size: 14px;
	line-height: 24px;
	/* padding-left: 20px; */
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-start;
	height: 20px;
	span {
		display: flex;
		align-items: center;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 20px);
		&:after {
			background: url("images/icons/ui/actions/gray/sort_filled.svg") no-repeat;
			content: "";
			display: inline-block;
			height: 24px;
			position: relative;
			vertical-align: top;
			width: 24px;
		}
		&:hover {
			color: #005bff;
			&:after {
				background: url("images/icons/ui/actions/blue/sort_filled.svg") no-repeat;
			}
		}
	}
`;

const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
`;

const TableRowContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	position: relative;
	background: #ffffff;
	/* border: 1px solid #d1d8e1; */
	/* border-radius: 16px; */
	border-bottom: 1px solid #e2e8f0;
	padding: 8px 10px;
`;

const ContentElement = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 20px;
	font-size: 14px;
	box-sizing: border-box;
	/* padding-left: 20px; */
	/* height: 20px; */
`;

const EmptyFallbackContainer = styled.div`
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #94a0b1;
	margin: 24px auto 0;
`;

const CreateDocumentBlock = ({ getSellerDocuments, sellerDocuments, id }) => {
	const { handleModal } = useContext(ModalContext);
	const [collection, setCollection] = useState([]);
	const [paginationState, setPaginationState] = useState({
		offset: 0,
		limit: 5,
	});

	const fetchCollection = () => {
		getSellerDocuments(id, paginationState.offset, paginationState.limit);
	};

	useEffect(() => {
		if (!id) return;
		fetchCollection();
	}, [paginationState, id]);

	useEffect(() => {
		setCollection([...sellerDocuments?.list]);
	}, [sellerDocuments]);
	console.log(sellerDocuments);
	return (
		<StatisticBlockContainer className="content-wrapper">
			<HeaderContainer className="gadgets-statistic-header">
				<div className="gadgets-statistic-header__title">Документы</div>
				<SubmitButton
					onClick={() =>
						handleModal(
							<UploadDocumentModal onSuccess={() => fetchCollection()} external_id={id} />
						)
					}>
					Создать
				</SubmitButton>
			</HeaderContainer>
			{collection?.length > 0 && collection[0].external_id === id ? (
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<TableRowHeader>
						<HeaderElement style={{ width: "25%", left: "-10px" }}>
							<span>Название документа</span>
						</HeaderElement>

						<HeaderElement style={{ width: "15%" }}>
							<span>Дата</span>
						</HeaderElement>
						<HeaderElement style={{ width: "170px" }}></HeaderElement>
					</TableRowHeader>
					{collection.map((elem, index) => (
						<TableRowContent key={index}>
							<ContentElement style={{ width: "25%" }}>{elem?.title}</ContentElement>
							<ContentElement style={{ width: "15%" }}>
								{elem.created_at.split("T")[0].split("-").reverse().join(".")}
							</ContentElement>
							<ContentElement style={{ width: "170px" }}>
								<a href={elem.file} target="_blank" style={{ textDecoration: "none" }}>
									<SubmitButton style={{ width: "auto" }}>Скачать документ</SubmitButton>
								</a>
							</ContentElement>
						</TableRowContent>
					))}
				</div>
			) : (
				<EmptyFallbackContainer>Ничего не найдено</EmptyFallbackContainer>
			)}
			{Object.keys(sellerDocuments.pagination).length !== 0 &&
			!!sellerDocuments.pagination.max_pages &&
			sellerDocuments.list.length > 0 &&
			sellerDocuments.pagination.max_pages > 1 ? (
				<PaginationBlock
					filterState={paginationState}
					setFilterState={setPaginationState}
					pagination={sellerDocuments.pagination}
				/>
			) : null}
		</StatisticBlockContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		sellerDocuments: state.sellerDocuments,
	};
};

const mapDispatchToProps = {
	getSellerDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentBlock);
