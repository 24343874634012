import React, { useEffect } from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from "recharts";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import styled from "styled-components";
import { DTBold13 } from "../../../ui-kit/typography/Typography";

const TooltipWrapper = styled.div`
  white-space: nowrap;
`;

const PieChartComponent = ({ collection }) => {
  const [actualNameState, setActualNameState] = useState("");
  const [dataState, setDataState] = useState([]);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    setDataState(collection);
  }, [collection]);

  const handleOnMouseMove = (e) => {
    if (e && e?.name !== actualNameState) {
      setActualNameState(e.name);
    }
  };
  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (
      active &&
      payload &&
      payload.length &&
      typeof actualNameState === "number"
    ) {
      const sector = payload.find(
        (elem) => elem.name === actualNameState
      )?.payload;
      if (!sector) return null;
      return (
        <TooltipWrapper id="custom-tooltip" className="custom-tooltip">
          <img src="images/icons/ui/arrow.svg" alt="вниз" />
          <p className="value">{`${sector?.title}`}</p>
          <Cluster space="12px" align="center">
            <p className="name">{`${sector?.value}`}</p>
            <DTBold13>{sector?.percentValue}</DTBold13>
          </Cluster>
        </TooltipWrapper>
      );
    }
    return null;
  };

  const CustomizedActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      midAngle,
      name,
    } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (innerRadius + 5) * cos;
    const my = cy + (innerRadius + 5) * sin;
    if (mx !== tooltipPosition.x || my !== tooltipPosition.y) {
      setTooltipPosition({
        x: mx,
        y: my,
      });
    }
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 1}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  return (
    <>
      <div className="pie-chart-wrapper">
        <div className="income-info-block__content">
          {dataState && dataState.length > 0 ? (
            <ResponsiveContainer width={200} height={200}>
              <PieChart margin={{ top: 20, right: 0, left: 0, bottom: 16 }}>
                <Pie
                  data={dataState}
                  innerRadius={72}
                  activeIndex={activeIndex}
                  outerRadius={90}
                  fill="#8884d8"
                  paddingAngle={0.5}
                  dataKey="value"
                  onMouseEnter={(_, index) => setActiveIndex(index)}
                  onMouseMove={handleOnMouseMove}
                  activeShape={CustomizedActiveShape}
                >
                  {dataState.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  position={tooltipPosition}
                  content={<CustomTooltip />}
                  offset={0}
                  allowEscapeViewBox={{ x: true, y: true }}
                  isAnimationActive={false}
                  wrapperStyle={{ outline: "none" }}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PieChartComponent;
