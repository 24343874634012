import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCategories, putCategories } from "../../../Actions/CategoriesActions";
import "./styles/AddCategoryModal.scss";
import axios from "axios";
import { baseUrl } from "../../../Actions/consts";
import { Stack } from "../../../ui-kit/layouts/Stack";
import Lottie from "react-lottie-player";
import preloaderJson from "../preloader.json";
import { DTBold15, DText14 } from "../../../ui-kit/typography/Typography";
import { Button } from "../../../ui-kit/button/Button";


/**
 * Модалка примнения списка атрибутов ко дочерним категориям. "Для всех"
 */
const CascadeCategoryRequestModal = ({ item, onClose, getCategories }) => {
	const { category, predefined_attributes, delete_attributes, add_attributes } = item;
	const [loading, setLoading] = useState();
	const editCategory = async (categories_ids, attributes_ids) => {
		setLoading(true);
		await axios
			.post(`${baseUrl}/domain/frames/addattributes`, {
				add_attributes,
				delete_attributes,
				categories_ids,
			})
			.then((categor) => {
				setLoading(false);
				getCategories();
				onClose();
			})
			.finally(() => {})
			.catch((error) => {
				console.log(error);
			});
	};

	const calcCategoryCount = (category, resArray = []) => {
		let array = resArray;
		let result = null;
		if (category.childs.length === 0) {
			array.push(category.id);
			return array;
		} else {
			try {
				array.push(category.id);
				category.childs.forEach((elem) => {
					result = calcCategoryCount(elem, array);
				});
			} catch (error) {
				console.log(error);
			}
		}
		return result;
	};

	const hanndleStartRequest = () => {
		const result = calcCategoryCount(category);
		editCategory(result, predefined_attributes);
	};

	return (
		<div className="main-modal">
			<div className="modal-content">
				<div className="add-category-wrapper" style={{ maxWidth: "700px" }}>
					<div className="modal-field-title">Применение атрибутов дочерним категориям</div>
					{!loading ? (
						<div className="modal-field-close" onClick={() => onClose()}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
									fill="#212121"
								></path>
							</svg>
						</div>
					) : null}
					{loading ? (
						<Stack align="center" justify="center">
							<Lottie loop animationData={preloaderJson} play style={{ width: 50, height: 50 }} />
							<DTBold15>Обработка...</DTBold15>
							<DText14>Пожалуйста, дождитесь окончания обработки</DText14>
						</Stack>
					) : (
						<Stack align="center" justify="center">
							<Button variant="primary" text="Запустить" onClick={hanndleStartRequest} />
						</Stack>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.loadingStatus.loading,
		categoryAttr: state.categoryAttr,
	};
};

const mapDispatchToProps = {
	putCategories,
	getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CascadeCategoryRequestModal);
