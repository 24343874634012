import React, { useEffect, useState } from "react";
import { initialMonthData } from "./static";
import CategoryComponent from "./CategoryComponent.jsx";
import { getGraphPeriod } from "./scripts";
import { connect, useDispatch } from "react-redux";
import { getVideoProfitByData } from "./fetchByPeriod";
import { initialPeriodCollection } from "../../../../common/static";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";

const initialFormats = [
	{
		title: "Фильмы",
		name: "film",
		id: 1,
	},
	{
		title: "Сериалы",
		name: "serial",
		id: 2,
	},
	{
		title: "Видео блогеров",

		name: "video",
		id: 3,
	},
	{
		title: "Shorts",
		name: "shorts",
		id: 4,
	},
];

const IncomeCategoryBlock = ({ videoProfitGraph }) => {
	const dispatch = useDispatch();
	const [toggleVideoBlock, setToggleVideoBlock] = useState(initialFormats[0]);
	const [dataState, setDataState] = useState(initialMonthData());
	const [periodState, setPeriodState] = useState("full");
	const [periodDropdownState, setPeriodDropdownState] = useState(false);

	useEffect(() => {
		if (videoProfitGraph?.[periodState][toggleVideoBlock.name]?.length) return;
		const periods = getGraphPeriod(periodState);
		periods.forEach((elem, index) => {
			getVideoProfitByData({
				vars: {
					gte: elem.s1,
					lte: elem.e1,
					format_id: toggleVideoBlock.id,
				},
				index,
				dispatch,
				period: periodState,
				format: toggleVideoBlock.name,
			});
		});
	}, [periodState, toggleVideoBlock]);

	useEffect(() => {
		if (!videoProfitGraph?.[periodState][toggleVideoBlock.name]?.length) return;
		const newArray = videoProfitGraph?.[periodState][toggleVideoBlock.name].filter(
			(elem) => !!elem
		);
		if (periodState === "day" && newArray.length === 25) {
			setDataState(newArray);
		} else if (periodState === "week" && newArray.length === 8) {
			setDataState(newArray);
		} else if (periodState === "month" && newArray.length === 31) {
			setDataState(newArray);
		} else if (periodState === "year" && newArray.length === 13) {
			setDataState(newArray);
		} else if (periodState === "full" && newArray.length === 13) {
			setDataState(newArray);
		}
	}, [videoProfitGraph, periodState, toggleVideoBlock]);

	return (
		<Stack space="16px">
			<div className="income-info-block category">
				<div className="list-title">
					<div className="income-info-block__header list-title-text" style={{ width: "100%" }}>
						<Cluster space="24px">
							{initialFormats.map((elem, index) => (
								<button
									key={index}
									style={{ marginRight: "0px" }}
									onClick={() => setToggleVideoBlock(elem)}
									className={toggleVideoBlock.id === elem.id ? "active" : ""}
								>
									{elem.title}
								</button>
							))}
						</Cluster>

						<div className="list-title-selects" style={{ marginLeft: "auto" }}>
							<div
								className={`list-title-select ${periodDropdownState ? "active" : false}`}
								tabIndex="0"
								onClick={() => setPeriodDropdownState(!periodDropdownState)}
								onBlur={() => setPeriodDropdownState(false)}
							>
								<div className="list-title-select-btn">
									{initialPeriodCollection.find((elem) => elem.key === periodState)?.title ||
										"За месяц"}
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.99991 9.58545L8.70701 10.2926L11.9999 13.5854L15.2928 10.2926L15.9999 9.58545L17.4141 10.9997L16.707 11.7068L12.707 15.7068C12.3165 16.0973 11.6833 16.0973 11.2928 15.7068L7.2928 11.7068L6.58569 10.9997L7.99991 9.58545Z"
											fill="#212121"
										></path>
									</svg>
								</div>
								<div className="list-title-select-submenu">
									{initialPeriodCollection.map((elem, index) => (
										<div
											key={index}
											className="list-title-select-submenu-item"
											onClick={() => (setPeriodState(elem.key), setPeriodDropdownState(false))}
										>
											{elem.title}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<CategoryComponent dataState={dataState} period={periodState} />
			</div>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		videoProfitGraph: state.videoProfitGraph,
	};
};

const mapDispatchToProps = {
	// getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeCategoryBlock);
