import React, { useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useState } from "react";
import { connect } from "react-redux";
import "./styles/IncomeInfoBlock.scss";

const CategoryComponent = ({ dataState: data = [], period }) => {
  const [actualNameState, setActualNameState] = useState("amount");
  const [areasActiveState, setAreasActiveState] = useState({
    wildberries: true,
    amount: true,
  });
  const [leftBarActiveCheck, setLeftBarActiveCheck] = useState({
    active: false,
    name: "",
  });
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const handleOnMouseOver = (e) => {
    if (e && e.name && e.name !== actualNameState) {
      setActualNameState(e.name);
    }
  };
  const CustomTooltip = (props) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length && actualNameState.length > 0) {
      return (
        <div id="custom-tooltip" className="custom-tooltip">
          <img src="images/icons/ui/arrow.svg" alt="вниз" />
          <p className="value">{`${payload
            .find((elem) => elem.name === actualNameState)
            .value.toLocaleString("ru-RU")} просмотров`}</p>
          {period === "month" ? (
            <p className="name">{`${label} г`}</p>
          ) : period === "week" ? (
            <p className="name">{`${label.split(", ")[1]}, ${
              label.split(", ")[0]
            }`}</p>
          ) : (
            <p className="name">{`${label}`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const CustomizedActiveDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
    if (props.dataKey === actualNameState || !actualNameState) {
      if (cx !== tooltipPosition.x || cy !== tooltipPosition.y) {
        setTooltipPosition({
          x: cx,
          y: cy,
        });
      }
      return (
        <svg
          x={cx - 12}
          y={cy - 12}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.2" cx="12" cy="12" r="12" fill={`${props.fill}`} />
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x={8}
            y={8}
          >
            <circle cx="4" cy="4" r="4" fill={`${props.fill}`} />
          </svg>
        </svg>
      );
    }
    return null;
  };
  const CustomizedAxisTickX = (props) => {
    const { x, y, stroke, payload, index, visibleTicksCount } = props;

    let value = payload.value.split(" ", 2).join(" ");
    if (period === "day") {
      value = payload.value.split(" ")[2];
    }
    if (period === "week") {
      value = `${payload.value.split(", ")[1]}, ${
        payload.value.split(", ")[0]
      }`;
    }
    if (payload.index === data.length - 1) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            textAnchor="end"
            fill="#94A0B1"
            style={{ fontWeight: "400", fontSize: "13px", width: "100px" }}
          >
            {value}
          </text>
        </g>
      );
    } else if (index === 0) {
      return (
        <g transform={`translate(${60},${y})`}>
          <text
            x={0}
            y={0}
            textAnchor="start"
            fill="#94A0B1"
            style={{ fontWeight: "400", fontSize: "13px" }}
          >
            {value}
          </text>
        </g>
      );
    } else if (
      ((period === "month" || period === "day") &&
        payload.index % 3 === 0 &&
        data.length - 1 - payload.index > 2) ||
      ((period === "week" || period === "year" || period === "full") &&
        payload.index % 2 === 0)
    ) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            textAnchor="middle"
            fill="#94A0B1"
            style={{ fontWeight: "400", fontSize: "13px" }}
          >
            {value}
          </text>
        </g>
      );
    } else {
      return (
        <g style={{ display: "none" }}>
          {/* <g transform={`translate(${x},${y})`}> */}
          <text
            x={0}
            y={0}
            textAnchor="middle"
            fill="#94A0B1"
            style={{ fontWeight: "400", fontSize: "13px" }}
          >
            {value}
          </text>
        </g>
      );
    }
  };
  const CustomizedAxisTickY = (props) => {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={4}
          textAnchor="end"
          fill="#94A0B1"
          style={{ fontWeight: "400", fontSize: "13px" }}
        >
          {Number(payload.value) > 5000
            ? Number(payload.value) / 1000 + "к"
            : Number(payload.value)}
        </text>
      </g>
    );
  };
  return (
    <>
      <div
        className={`statistics-filter-bg ${
          leftBarActiveCheck.active ? "active" : ""
        }`}
        onClick={() => setLeftBarActiveCheck({ active: false, name: "" })}
      ></div>

      <div className="income-info-block__content">
        {data && data.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width="100%"
              height="100%"
              data={data}
              margin={{ top: 20, right: 0, left: 0, bottom: 16 }}
            >
              <CartesianGrid horizontal vertical={false} />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tick={<CustomizedAxisTickX />}
                tickMargin={16.5}
                tickCount={30}
                interval={0}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={<CustomizedAxisTickY />}
                tickMargin={16}
              />

              <Tooltip
                position={tooltipPosition}
                content={<CustomTooltip />}
                offset={0}
                allowEscapeViewBox={{ x: true, y: true }}
                isAnimationActive={false}
                wrapperStyle={{ outline: "none" }}
              />
              {areasActiveState.amount ? (
                <Area
                  onMouseOver={handleOnMouseOver}
                  id="amount"
                  type="monotone"
                  dataKey="amount"
                  stroke="#FD4AA7"
                  name="amount"
                  strokeWidth={2}
                  dot={false}
                  activeDot={<CustomizedActiveDot />}
                  fill="url(#video-view)"
                />
              ) : null}
              <defs>
                <linearGradient id="video-view" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="50%" stopColor="#FD4AA740" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FD4AA750" stopOpacity={0} />
                </linearGradient>
              </defs>
            </AreaChart>
          </ResponsiveContainer>
        ) : null}
      </div>
    </>
  );
};

export default connect(null, null)(CategoryComponent);
