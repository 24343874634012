export const PriceReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_PRICES":
			return action.price.data;
		case "POST_BASE_PRICE":
			return {
				...state,
				base: action.price.data.base,
			};
		default:
			return state;
	}
};

export const PriceHistoryReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_PRICES_HISTORY":
			return action.price.data.items;
		default:
			return state;
	}
};

const initialSellers = {
	list: [],
	pagination: {},
};

export const SellerPriceReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_SELLERS_PRICE":
			const data = action.sellers.data;
			const paginate = data.paginate_;
			return {
				list: data.items,
				pagination: {
					max_elements: paginate.total_rows,
					max_pages: Math.ceil(data.total / paginate.limit),
					page: Math.round(paginate.offset / paginate.limit),
					limit: paginate.limit,
				},
			};
		default:
			return state;
	}
};
