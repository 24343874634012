import { getSearch } from "connected-react-router";
import * as React from "react";
import { memo } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDebounce } from "../components/PersonalArea/scripts/useDebounse";

const BloggerVideoSearch = memo(({ setMainFilterState, mainFilterState }) => {
	const [flag, setFlag] = useState(true);
	const [inputValue, setInputValue] = useState(() => mainFilterState?.title || "");

	const debouncedSearch = useDebounce(() => inputValue, 300);
	useEffect(() => {
		if (flag && mainFilterState && mainFilterState) {
			setInputValue(mainFilterState?.filters?.title || "");
			setFlag(false);
		}
	}, [mainFilterState]);

	useEffect(() => {
		if (flag) return;
		setMainFilterState({
			...mainFilterState,
			filters: {
				...mainFilterState.filters,
				title: debouncedSearch,
			},
			page: 1,
		});
	}, [debouncedSearch]);

	const handleChange = (e) => {
		const { target } = e;

		const value = target.value;
		setInputValue(value);
	};
	return (
		<>
			<input
				type="text"
				placeholder="Поиск по названию"
				value={inputValue}
				onChange={handleChange}
			/>
			<div className="input-search input-icon"></div>
		</>
	);
});

export default connect(null, null)(BloggerVideoSearch);
