import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const LoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
	align-items: center;
	justify-content: center;
	z-index: 111;
	display: none;
    opacity: 0.5;
	&.active {
		display: flex;
	}
`;

const Loader = styled.div`
	animation: loaderAnimation 1s linear infinite;
	background: url("images/icons/ui/status/blue/loader.svg") no-repeat;
	background-size: contain;
	content: "";
	display: block;
	height: 40px;
	width: 40px;
	left: calc(50% - 20px);
	position: absolute;
	top: calc(50% - 20px);
`;


// Loader занимающий всю область родительского компонента
const OverlayLoader = ({ reorderLoader }) => {
	return (
		<LoaderWrapper className={reorderLoader > 0 ? "active" : ""}>
			<Loader />
		</LoaderWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		reorderLoader: state.reorderLoader,
	};
};

export default connect(mapStateToProps, null)(OverlayLoader);
