import React, { useEffect } from "react";
import styled from "styled-components";
import { DText14 } from "../typography/Typography";
import { Stack } from "../layouts/Stack";

const StyledStack = styled(Stack)`
	width: 100%;
`;

const StyledTextarea = styled.textarea`
	background: #f4f4f8;
	border-radius: 8px;

	border: 1px solid #f4f4f8;
	border-radius: 8px;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	color: #212121;
	width: 100%;
	padding: 16px 20px;
	outline: none;
	resize: none;
	height: 56px;
	overflow-y: hidden;
	&.error {
		border: 1px solid #ed0a34 !important;
		&:hover,
		&:focus {
			border: 1px solid #ed0a34 !important;
		}
	}
	&::placeholder {
		color: #94a0b1;
	}
	&:focus {
		border: 1px solid #7027c2;
	}
`;

export const Textarea = ({
	label = "",
	value,
	onChange,
	placeholder,
	error = false,
	disabled = false,
}) => {
	useEffect(() => {
		const tx = document.getElementsByTagName("textarea");
		if (tx.length > 0)
			for (let i = 0; i < tx.length; i++) {
				tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;");
				tx[i].addEventListener("keydown", OnInput, false);
			}

		function OnInput() {
			this.style.height = "24px";
			this.style.height = this.scrollHeight + "px";
		}
		return () => {
			for (let i = 0; i < tx.length; i++) {
				tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;");
				tx[i].removeEventListener("", OnInput, false);
			}
		};
	}, [value]);

	return (
		<StyledStack space="5px">
			{label && label.length > 0 ? <DText14 style={{ color: "#94A0B1" }}>{label}</DText14> : null}
			<StyledTextarea
				disabled={disabled}
				value={value}
				onChange={onChange}
				className={error ? "error" : ""}
				placeholder={placeholder}
			/>
		</StyledStack>
	);
};
