import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	getSortedVideos,
	getVideoGenres,
} from "../../../../../Actions/videos-actions/VideosActions.js";
import "../../styles/VideosPage.scss";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { getVideoFormats } from "../../../../../Actions/FormatsActions.js";
import PaginationBlock from "../../../../universal/PaginationBlock/PaginationBlock.jsx";
import {
	handleFilteredClick,
	handleMultiSelectActiveClick,
	handleSelectFilters,
} from "../../../scripts/PersonalAreaScripts.js";
import UniversalDeleteModal from "../../../../universal/UniversalDeleteModal.jsx";
import { removeEmpty } from "../../../../../Actions/ActionsScirpts.js";

import { history } from "../../../../../Store/Store";
import CustomSearchDropDown from "../../customSearchDropDown/CustomSearchDropDown.jsx";
import { putCurrentMovie } from "../../../../../Actions/videos-actions/putMovieVideo.js";
import { deleteVideo } from "../../../../../Actions/videos-actions/deleteMovieVideo.js";
import styled from "styled-components";
import { useUserAccessContext } from "../../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../../scripts/utils.js";

const initialDropDownState = {
	formats_ids: false,
	genres_ids: false,
	is_published: false,
};

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: transparent;
	position: fixed;
	z-index: 0;
`;

const initialStatuses = [
	{
		title: "Опубликовано",
		id: true,
	},
	{
		title: "Не опубликовано",
		id: false,
	},
];

const videoFormatsList = [
	{
		id: 6,
		title: "Фильмы",
	},
	{
		id: 7,
		title: "Сериалы",
	},
];

const OwnerVideos = ({
	videosList,
	videoGenresList,
	getVideoGenres,
	deleteVideo,
	getSortedVideos,
	setTotal,
}) => {
	const { userAccessState } = useUserAccessContext();

	let navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleModal } = useContext(ModalContext);

	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);

	const [searchInput, setSearchInput] = useState(
		!!JSON.parse(searchParams.get("filters"))?.title
			? JSON.parse(searchParams.get("filters"))?.title
			: ""
	);
	const [mainFilterState, setMainFilterState] = useState({
		filters:
			searchParams.get("filters") !== null
				? JSON.parse(searchParams.get("filters"))
				: { formats_ids: [6, 7] },
		order_by: searchParams.get("order_by") !== null ? searchParams.get("order_by") : "id",
		reversed_: true,
		limit: searchParams.get("page") !== null ? searchParams.get("limit") : 15,
		offset: searchParams.get("offset") !== null ? searchParams.get("offset") : 0,
	});
	const [mainVideoState, setMainVideoState] = useState([]);
	const [videoGenresState, setVideoGenresState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.genres_ids
			? JSON.parse(searchParams.get("filters")).genres_ids
			: [],
		list: [],
	});
	const [videoStatusesState, setVideoStatusesState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.status
			? JSON.parse(searchParams.get("filters")).status
			: [],
		list: initialStatuses,
	});
	const [videoFormatsState, setVideoFormatsState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.formats_ids
			? JSON.parse(searchParams.get("filters")).format_ids
			: [],
		list: videoFormatsList,
	});
	const [dropDownState, setDropdownState] = useState(initialDropDownState);
	const [editMenuState, setEditMenuState] = useState({
		id: "",
	});

	useEffect(() => {
		getVideoGenres();
	}, []);

	useEffect(() => {
		setVideoStatusesState({
			...videoStatusesState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.is_published
				? JSON.parse(searchParams.get("filters")).is_published
				: [],
		});

		setSearchInput(
			!!JSON.parse(searchParams.get("filters"))?.title
				? JSON.parse(searchParams.get("filters"))?.title
				: ""
		);
	}, [searchParams]);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters:
						params.get("filters") !== null
							? JSON.parse(params.get("filters"))
							: {
									formats_ids: [6, 7],
							  },
					offster: params.get("offset") !== null ? params.get("offset") : 0,
					limit: params.get("limit") !== null ? params.get("limit") : 15,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		if (searchInput.length > 0) {
			getSortedVideos({
				...mainFilterState,
				filters: {
					...mainFilterState.filters,
					title: searchInput,
				},
			});
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...mainFilterState,
					filters: JSON.stringify({ ...mainFilterState.filters, title: searchInput }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		} else {
			getSortedVideos({ ...mainFilterState });
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...mainFilterState,
					filters: JSON.stringify({ ...mainFilterState.filters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		}
	}, [mainFilterState]);

	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.genres_ids
				? JSON.parse(searchParams.get("filters")).genres_ids
				: [],
			list: videoGenresList,
		});
	}, [videoGenresList]);

	useEffect(() => {
		setVideoFormatsState({
			...videoFormatsState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.formats_ids
				? JSON.parse(searchParams.get("filters")).formats_ids
				: [],
			list: videoFormatsList,
		});
	}, [videoFormatsList]);

	useEffect(() => {
		if (videosList.list.length === 0 || !!videosList.list[0]?.genres) {
			setTotal(videosList?.pagination?.max_elements);
			setMainVideoState(videosList.list);
		}
	}, [videosList]);

	return (
		<>
			<div className="videos-content-wrapper content-wrapper">
				<div className="list-filter flex-block">
					<div className="form-field">
						<div className="form-field-label">Видео</div>
						<div className="input-field left-icon">
							<CustomSearchDropDown
								setMainFilterState={setMainFilterState}
								mainFilterState={mainFilterState}
							/>
						</div>
					</div>
					<div className="form-field">
						<div className="form-field-label">Формат</div>
						<div className="input-field">
							<div
								className={`select-block multiselect ${dropDownState.formats_ids ? "active" : ""}`}
								onFocus={() => null}
								onClick={() =>
									setDropdownState({ ...dropDownState, formats_ids: !dropDownState.formats_ids })
								}
								onBlur={() => setDropdownState(initialDropDownState)}
								tabIndex="1"
							>
								<div className="select-block-button">
									<span>
										{videoFormatsState?.currentValue?.length > 0 &&
										videoFormatsState.currentValue.length !== videoFormatsState.list.length
											? videoFormatsState.currentValue.map((elem, index) =>
													index !== videoFormatsState.currentValue.length - 1
														? `${videoFormatsState.list.find((item) => item.id == elem)?.title}, `
														: `${videoFormatsState.list.find((item) => item.id == elem)?.title}`
											  )
											: "Все"}
									</span>
								</div>
								<div className="select-block-list">
									<div className="select-block-list-scroll">
										{videoFormatsState.list.length > 0
											? videoFormatsState.list.map((format) => (
													<div
														key={format.id}
														className={`select-block-item ${
															videoFormatsState?.currentValue?.includes(format.id) ? "selected" : ""
														}`}
														onClick={(e) =>
															handleMultiSelectActiveClick(
																e,
																videoFormatsState,
																setVideoFormatsState,
																format
															)
														}
													>
														<span>{format?.title}</span>
													</div>
											  ))
											: null}
									</div>
									<div className="select-block-control">
										<div
											className="select-block-cancel button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"formats_ids",
													[6, 7],
													videoFormatsState,
													setVideoFormatsState,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Очистить
										</div>
										<div
											className="select-block-select button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"formats_ids",
													videoFormatsState.currentValue,
													null,
													null,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Применить
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="form-field">
						<div className="form-field-label">Жанр</div>
						<div className="input-field">
							<div
								className={`select-block multiselect ${dropDownState.genres ? "active" : ""}`}
								onFocus={() => null}
								onClick={() =>
									setDropdownState({ ...dropDownState, genres: !dropDownState.genres })
								}
								onBlur={() => setDropdownState(initialDropDownState)}
								tabIndex="2"
							>
								<div className="select-block-button">
									<span>
										{videoGenresState.currentValue.length > 0 &&
										videoGenresState.currentValue.length !== videoGenresState.list.length
											? videoGenresState.currentValue.map((elem, index) =>
													index !== videoGenresState.currentValue.length - 1
														? `${videoGenresState.list.find((item) => item.id == elem)?.title}, `
														: `${videoGenresState.list.find((item) => item.id == elem)?.title}`
											  )
											: "Все"}
									</span>
								</div>
								<div className="select-block-list">
									<div className="select-block-list-scroll">
										{videoGenresState.list.length > 0
											? videoGenresState.list.map((genres) => (
													<div
														key={genres.id}
														className={`select-block-item ${
															videoGenresState.currentValue.includes(genres.id) ? "selected" : ""
														}`}
														onClick={(e) =>
															handleMultiSelectActiveClick(
																e,
																videoGenresState,
																setVideoGenresState,
																genres
															)
														}
													>
														<span>{genres?.title}</span>
													</div>
											  ))
											: null}
									</div>
									<div className="select-block-control">
										<div
											className="select-block-cancel button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"genres_ids",
													[],
													videoGenresState,
													setVideoGenresState,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Очистить
										</div>
										<div
											className="select-block-select button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"genres_ids",
													videoGenresState.currentValue,
													null,
													null,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Применить
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="form-field">
						<div className="form-field-label">Статус</div>
						<div className="input-field">
							<div
								className={`select-block multiselect ${dropDownState.status ? "active" : ""}`}
								onFocus={() => null}
								onClick={() =>
									setDropdownState({
										...dropDownState,
										status: !dropDownState.status,
									})
								}
								onBlur={() => setDropdownState(initialDropDownState)}
								tabIndex="2"
							>
								<div className="select-block-button">
									<span>
										{videoStatusesState.currentValue.length > 0 &&
										videoStatusesState.currentValue.length !== videoStatusesState.list.length
											? videoStatusesState.currentValue.map((elem, index) =>
													index !== videoStatusesState.currentValue.length - 1
														? `${videoStatusesState.list.find((item) => item.id == elem)?.title}, `
														: `${videoStatusesState.list.find((item) => item.id == elem)?.title}`
											  )
											: "Все"}
									</span>
								</div>
								<div className="select-block-list">
									<div className="select-block-list-scroll">
										{videoStatusesState.list?.length > 0
											? videoStatusesState.list.map((status) => (
													<div
														key={status.id}
														className={`select-block-item ${
															videoStatusesState.currentValue.includes(status.id) ? "selected" : ""
														}`}
														onClick={(e) =>
															handleMultiSelectActiveClick(
																e,
																videoStatusesState,
																setVideoStatusesState,
																status
															)
														}
													>
														<span>{status?.title}</span>
													</div>
											  ))
											: null}
									</div>
									<div className="select-block-control">
										<div
											className="select-block-cancel button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"is_published",
													[],
													videoStatusesState,
													setVideoStatusesState,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Очистить
										</div>
										<div
											className="select-block-select button-select"
											onClick={(event) =>
												handleSelectFilters(
													event,
													"is_published",
													videoStatusesState.currentValue,
													null,
													null,
													dropDownState,
													setDropdownState,
													mainFilterState,
													setMainFilterState
												)
											}
										>
											Применить
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="list-table-header video-list">
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "title", mainFilterState, setMainFilterState)
						}
					>
						<span>Название</span>
					</div>
					<div className="list-table-header-element non-sort">
						<span className="non-sort">Формат</span>
					</div>
					<div className="list-table-header-element non-sort">
						<span className="non-sort">Жанр</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleFilteredClick("sort", "year", mainFilterState, setMainFilterState)}
					>
						<span>Год</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						// onClick={() =>
						// handleFilteredClick("sort", "atips", mainFilterState, setMainFilterState)
						// }
					>
						<span className="non-sort">Типсы</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						// onClick={() => handleFilteredClick("sort", "sum", mainFilterState, setMainFilterState)}
					>
						<span className="non-sort">Доход</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "published", mainFilterState, setMainFilterState)
						}
					>
						<span>Статус</span>
					</div>
				</div>
				<div className="list-table video-list">
					{mainVideoState.length > 0
						? mainVideoState.map((video) => (
								<div className="list-table-item" key={video.id}>
									<div className="list-table-item-element">
										<span>
											<Link to={`/film?id=${video.id}`}>{video?.title}</Link>
										</span>
									</div>
									<div className="list-table-item-element">
										<span>
											{" "}
											{video?.movie_format?.id === 6
												? "Фильмы"
												: video?.movie_format?.id === 7
												? "Сериалы"
												: "Не указано"}
										</span>
									</div>
									<div className="list-table-item-element">
										<span>
											{video?.genres.length > 0
												? video?.genres?.map((gen, index) =>
														index >= 1 ? `, ${gen?.title}` : gen?.title
												  )
												: "Не указано"}
										</span>
									</div>
									<div className="list-table-item-element">
										<span>{video?.year || 0}</span>
									</div>
									<div className="list-table-item-element">
										<span>{video?.tips_count || 0}</span>
									</div>
									<div className="list-table-item-element">
										<span>{video?.statistic?.revenue}</span>
									</div>
									<div className="list-table-item-element" style={{ width: "184px" }}>
										<div
											className={`list-table-item-status ${!video.published ? "disactive" : ""}`}
										>
											<div className="help-tip">
												<span>{video.published ? "О" : "Не о"}публиковано</span>
												<img src="images/icons/ui/arrow.svg" alt="" />
											</div>
										</div>
										{checkUserWritePerm(userAccessState, "videos") ? (
											<>
												<div
													className="list-table-item-edit action-button"
													onClick={() => navigate(`/film?id=${video.id}`)}
												>
													<svg
														width="20"
														height="21"
														viewBox="0 0 20 21"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M14.6203 1.24935C14.4328 1.0618 14.1784 0.956428 13.9132 0.956421C13.648 0.956414 13.3936 1.06177 13.2061 1.24931L3.58581 10.8695C3.21073 11.2445 3.00001 11.7532 3.00001 12.2837L3.00001 15.9999C3.00001 16.2651 3.10537 16.5195 3.2929 16.707C3.48044 16.8945 3.73479 16.9999 4.00001 16.9999H7.71622C8.24666 16.9999 8.75538 16.7892 9.13045 16.4141L18.7503 6.79396C19.1408 6.40344 19.1409 5.77031 18.7504 5.37979L14.6203 1.24935ZM5.00001 12.2837L13.9131 3.37066L16.6291 6.08683L7.71622 14.9999H5.00001L5.00001 12.2837ZM1 19.0001H0L0 21.0001H1H19H20V19.0001H19H1Z"
															fill="#94A0B1"
														/>
													</svg>
												</div>
												{/* TODO: вынести хинт в отдельный компонент (модальное окно),
                                который будет иметь backdor, с обработкой закрытия при клике в любое место */}
												<div
													className={`list-table-item-submenu action-button ${
														video.id === editMenuState.id ? "active" : ""
													}`}
													tabIndex="0"
													onClick={() =>
														setEditMenuState({
															...editMenuState,
															id: editMenuState.id > 0 ? "" : video.id,
														})
													}
													// onBlur={() => setEditMenuState({ ...editMenuState, id: "" })}
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M11.5 7C10.6716 7 10 6.32843 10 5.5C10 4.67157 10.6716 4 11.5 4C12.3284 4 13 4.67157 13 5.5C13 6.32843 12.3284 7 11.5 7ZM11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5C13 13.3284 12.3284 14 11.5 14ZM10 19.5C10 20.3284 10.6716 21 11.5 21C12.3284 21 13 20.3284 13 19.5C13 18.6716 12.3284 18 11.5 18C10.6716 18 10 18.6716 10 19.5Z"
															fill="#94A0B1"
														/>
													</svg>
													<div
														className={`list-table-item-submenu ${
															video.id === editMenuState.id ? "active" : ""
														}`}
													>
														<Backdrop
															onClick={() =>
																setEditMenuState({
																	...editMenuState,
																	id: "",
																})
															}
														/>
														<div
															className="list-table-item-submenu-item"
															onClick={() => navigate(`/film?id=${video.id}`)}
														>
															Изменить
														</div>
														<div
															className="list-table-item-submenu-item"
															onClick={() =>
																handleModal(
																	<UniversalDeleteModal
																		title={`видео`}
																		deleteFunc={deleteVideo}
																		id={video.id}
																		goBack={false}
																	/>
																)
															}
														>
															Удалить
														</div>
													</div>
												</div>
											</>
										) : null}
									</div>
								</div>
						  ))
						: null}
				</div>
			</div>
			{Object.keys(videosList.pagination).length !== 0 &&
			!!videosList.pagination.max_pages &&
			mainVideoState.length > 0 &&
			videosList.pagination.max_pages > 1 ? (
				<PaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={videosList.pagination}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.videosList,
		videoGenresList: state.videoGenresList,
		videoFormatsList: state.videoFormatsList,
		mainLoader: state.mainLoader,
	};
};

const mapDispatchToProps = {
	getSortedVideos,
	getVideoGenres,
	deleteVideo,
	getVideoFormats,
	putCurrentMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerVideos);
