import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const StatisticFiltersComponent = ({ status, setStatus, columns, setColumns }) => {
	const [columnsState, setColumnsState] = useState(columns);
	const [allowedColumnState, setAllowedColumnState] = useState();
	useEffect(() => {
		let iter = 0;
		Object.values(columnsState).reduce((sum, elem) => {
			elem.active ? iter++ : null;
		}, 0);
		setAllowedColumnState(iter);
	}, [columnsState]);

	useEffect(() => {
		setColumnsState(columns);
	}, [columns]);

	const handleSelectColumnClick = (key) => {
		if (columnsState[key].active && allowedColumnState <= 4) {
			return;
		} else {
			setColumnsState({
				...columnsState,
				[key]: { ...columnsState[key], active: !columnsState[key].active },
			});
		}
	};
	const handleSetColumnClick = (type) => {
		if (type === "set") {
			setColumns(columnsState);
		} else {
			setColumnsState(columns);
		}
		setStatus({
			active: false,
			name: "",
		});
	};
	return (
		<div
			id="statistics-filter-column"
			className={`show-statistic-filter ${
				status.active && status.name === "column" ? "active" : ""
			}`}
		>
			<div className="statistics-filter-scroller">
				<div className="statistics-filter-title">Управление столбцами</div>
				<small>Выберите от 4 до 10 вариантов</small>
				<div
					className="statistics-filter-close action-button"
					onClick={() => handleSetColumnClick("cancel")}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="statistics-filter-items">
					<div className="statistics-filter-items-block" style={{ border: "none" }}>
						<div className="flex-block flex-start">
							{/* <div className="flex-block-column">
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.brightboxId
												? "selected"
												: allowedColumnState >= 10
												? "disabled"
												: ""
										}`}
										onClick={() => handleSelectColumnClick('brightboxId')}
									>
										<span>ID типса</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.price ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('price')}
									>
										{" "}
										<span>Цена</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.area ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('area')}
									>
										{" "}
										<span>Площадка</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.seller ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('seller')}
									>
										{" "}
										<span>Продавец</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.product ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('product')}
									>
										{" "}
										<span>Товар</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.category
												? "selected"
												: allowedColumnState >= 10
												? "disabled"
												: ""
										}`}
										onClick={() => handleSelectColumnClick('category')}
									>
										{" "}
										<span>Категория</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.video ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('video')}
									>
										{" "}
										<span>Название видео</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.genre ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('genre')}
									>
										{" "}
										<span>Жанр</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.gender ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('gender')}
									>
										{" "}
										<span>Пол</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.age ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('age')}
									>
										{" "}
										<span>Возраст</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.country ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('country')}
									>
										{" "}
										<span>Страна</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.city ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('city')}
									>
										{" "}
										<span>Город</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.status ? "selected" : allowedColumnState >= 10 ? "disabled" : ""
										}`}
										onClick={() => handleSelectColumnClick('status')}
									>
										{" "}
										<span>Статус</span>
									</div>
								</div>
								<div className="form-field">
									<div
										className={`check-button-item ${
											columnsState.dateTime
												? "selected"
												: allowedColumnState >= 10
												? "disabled"
												: ""
										}`}
										onClick={() => handleSelectColumnClick('dateTime')}
									>
										{" "}
										<span>Дата, время</span>
									</div>
								</div>
							</div> */}

							<div className="flex-block-column">
								{Object.keys(columnsState).map((key, index) => (
									<div className="form-field" key={index}>
										<div
											className={`check-button-item ${
												columnsState[key].active
													? "selected"
													: allowedColumnState >= 10
													? "disabled"
													: ""
											}`}
											key={index}
											onClick={() => handleSelectColumnClick(key)}
										>
											<span>{columnsState[key].title}</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="statistics-buttons">
				<div
					className="button action-button border-button"
					onClick={() => handleSetColumnClick("cancel")}
				>
					<span>Отмена</span>
				</div>
				<div className="button use-column-visible" onClick={() => handleSetColumnClick("set")}>
					<span>
						Показать <span>{allowedColumnState}</span>
					</span>
				</div>
			</div>
		</div>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 		videosList: state.videosList,
// 	};
// };

// const mapDispatchToProps = {
// 	getVideos,
// };

export default connect(null, null)(StatisticFiltersComponent);
